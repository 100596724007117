import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { assetsStateColor, assetsStatusColor } from '../../utils';

const assetList = (asset, t) => {
  const {
    entry_date,
    delivery_date,
    serial_number,
    EasyVista_request_serial_number,
    master_record_number,
    status,
    order_date,
    affectation_date,
    analytic_code,
    contract,
    order_number,
    pv_number,
    people,
    replacement_date,
    site,
    state,
    unit_price,
    invoice_number,
    invoice_date,
    subsidiary,
    phone_Number,
    rent,
    access_type,
    net_book_value
  } = asset;

  const { client_coefficient } = contract || {};

  return {
    IT: [
      {
        label: t('assets.showAsset.serial_number'),
        span: 1,
        content: serial_number || '-'
      },
      {
        label: t('assets.showAsset.EasyVista_request_serial_number'),
        span: 1,
        content: EasyVista_request_serial_number || '-'
      },
      {
        label: t('assets.showAsset.master_record_number'),
        span: 1,
        content: master_record_number || '-'
      },
      {
        label: t('assets.showAsset.contract'),
        span: 3,
        content: contract
          ? `${contract.number} - ${contract.amendment_number}`
          : '-'
      },
      {
        label: t('assets.showAsset.people'),
        span: 3,
        content:
          (people && (
            <Link to={`/people/show/${people._id}`}>
              {`${people.first_name || ''} ${people.last_name || ''}`}
            </Link>
          )) ||
          '-'
      },
      {
        label: t('assets.showAsset.site'),
        span: 3,
        content: site ? site.name : '-'
      },
      {
        label: t('assets.showAsset.status'),
        span: 3,
        content: status && (
          <Tag color={assetsStatusColor[status]}>
            {t(`assets.tags.${status}`)}
          </Tag>
        )
      },
      {
        label: t('assets.showAsset.state'),
        span: 3,
        content: (
          <Tag color={assetsStateColor[state]}>{t(`assets.tags.${state}`)}</Tag>
        )
      },
      {
        label: t('assets.showAsset.order_number'),
        span: 3,
        content: order_number || '-'
      },
      {
        label: t('assets.showAsset.order_date'),
        span: 1,
        content: (order_date && moment(order_date).format('LL')) || '-'
      },
      {
        label: t('assets.showAsset.delivery_date'),
        span: 1,
        content: (delivery_date && moment(delivery_date).format('LL')) || '-'
      },
      {
        label: t('assets.showAsset.entry_date'),
        span: 1,
        content: (entry_date && moment(entry_date).format('LL')) || '-'
      },
      {
        label: t('assets.showAsset.unit_price'),
        span: 1,
        content: unit_price ? `${numeral(unit_price).format('0,0.00')}€` : '-'
      },
      {
        label: t('assets.showAsset.rent'),
        span: 1,
        content:
          (unit_price && client_coefficient) || rent
            ? `${numeral(
                rent || (unit_price * client_coefficient) / 100
              ).format('0,0.00')}€`
            : '-'
      }, 
      {
        label: t('assets.showAsset.net_book_value'),
        span: 1,
        content: net_book_value ? `${numeral(net_book_value).format('0,0.00')}€` : '-'
      },
      {
        label: t('assets.showAsset.analytic_code'),
        span: 1,
        content: analytic_code || '-'
      },
      {
        label: t('assets.showAsset.pv_number'),
        span: 1,
        content: pv_number || '-'
      },
      {
        label: t('assets.showAsset.replacement_date'),
        span: 1,
        content:
          (replacement_date && moment(replacement_date).format('LL')) || '-'
      },
      {
        label: t('assets.showAsset.affectation_date'),
        span: 1,
        content:
          (affectation_date && moment(affectation_date).format('LL')) || '-'
      },
      {
        label: t('assets.showAsset.invoice_number'),
        span: 1,
        content: (invoice_number && invoice_number) || '-'
      },
      {
        label: t('assets.showAsset.invoice_date'),
        span: 1,
        content: (invoice_date && moment(invoice_date).format('LL')) || '-'
      }
    ],
    Telecom: [
      {
        label: t('assets.showAsset.phoneContract.phone_number'),
        span: 3,
        content:
          phone_Number &&
          (phone_Number[0] === '0' ? phone_Number : `0${phone_Number}`).replace(
            /(.{2})/g,
            '$1 '
          )
      },
      {
        label: t('assets.showAsset.status'),
        span: 3,
        content: status && (
          <Tag color={assetsStatusColor[status]}>
            {t(`assets.tags.${status}`)}
          </Tag>
        )
      },
      {
        label: t('assets.showAsset.people'),
        span: 3,
        content: people && (
          <Link to={`/people/show/${people._id}`}>
            {`${people.first_name || ''} ${people.last_name || ''}`}
          </Link>
        )
      },
      {
        label: t('assets.showAsset.access_type'),
        span: 3,
        content: access_type || 'N/R'
      },
      {
        label: t('assets.showAsset.subsidiary'),
        span: 3,
        content: subsidiary && subsidiary.name
      }
    ]
  };
};

const extraDetailsList = (assetData, t) => {
  const {
    phone_contract,
    description,
    brand,
    age,
    cpu,
    genre,
    hdd,
    ip_address,
    os,
    product_code,
    ram
  } = assetData;
  const { sms, mms, data, com, total, options } = phone_contract || {};

  return {
    IT: [
      {
        label: t('assets.showAsset.brand'),
        span: 1,
        content: brand || '-'
      },
      {
        label: t('assets.showAsset.description'),
        span: 1,
        content: description || '-'
      },
      {
        label: t('assets.showAsset.genre'),
        span: 1,
        content: genre || '-'
      },
      {
        label: t('assets.showAsset.product_code'),
        span: 1,
        content: product_code || '-'
      },
      {
        label: t('assets.showAsset.age'),
        span: 2,
        content: age ? `${Math.round(age)} mois` : '-'
      },
      {
        label: t('assets.showAsset.os'),
        span: 3,
        content: os || '-'
      },
      {
        label: t('assets.showAsset.cpu'),
        span: 3,
        content: cpu || '-'
      },
      {
        label: t('assets.showAsset.ram'),
        span: 1,
        content: ram || '-'
      },
      {
        label: t('assets.showAsset.hdd'),
        span: 1,
        content: hdd || '-'
      },
      {
        label: t('assets.showAsset.ip_address'),
        span: 1,
        content: ip_address || '-'
      }
    ],
    Telecom: [
      {
        label: t('assets.showAsset.phoneContract.sms'),
        span: 1,
        content: sms || '-'
      },
      {
        label: t('assets.showAsset.phoneContract.mms'),
        span: 1,
        content: mms || '-'
      },
      {
        label: t('assets.showAsset.phoneContract.data'),
        span: 1,
        content: data || '-'
      },
      {
        label: t('assets.showAsset.phoneContract.com.landline'),
        span: 1,
        content: (com || {}).landline || '-'
      },
      {
        label: t('assets.showAsset.phoneContract.com.mobile'),
        span: 1,
        content: (com || {}).mobile || '-'
      },
      {
        label: t('assets.showAsset.phoneContract.com.international'),
        span: 1,
        content: (com || {}).international || '-'
      },
      {
        label: t('assets.showAsset.phoneContract.options'),
        span: 3,
        content:
          (options || []).map((opt) => (
            <div key={opt.label}>
              {`${opt.label}: ${opt.price} €`}
              <br />
            </div>
          )) || '-'
      },
      {
        label: t('assets.showAsset.phoneContract.total'),
        span: 3,
        content: total || '-'
      }
    ]
  };
};
const energyRelatedDetailsList = (assetData, t) => {
  const {
    power_consumption,
    co2_year,
    carbon_footprint,
    epeat_grade,
    epeat_medal,
    epeat3,
    tco,
    energy_star,
    recycling,
    re_use,
    donations,
    audit_date,
    valorisation_amount,
    repair,
    repair_amount
  } = assetData;

  return {
    IT: [
      {
        label: t('assets.showAsset.power_consumption'),
        span: 1,
        content: power_consumption || '-'
      },
      {
        label: t('assets.showAsset.co2_year'),
        span: 1,
        content: co2_year || '-'
      },
      {
        label: t('assets.showAsset.carbon_footprint'),
        span: 1,
        content: carbon_footprint || '-'
      },
      {
        label: t('assets.showAsset.epeat_grade'),
        span: 1,
        content: epeat_grade || '-'
      },
      {
        label: t('assets.showAsset.epeat_medal'),
        span: 1,
        content: epeat_medal || '-'
      },
      {
        label: t('assets.showAsset.epeat3'),
        span: 1,
        content: epeat3 || '-'
      },
      {
        label: t('assets.showAsset.tco'),
        span: 1,
        content: tco || '-'
      },
      {
        label: t('assets.showAsset.energy_star'),
        span: 1,
        content: energy_star || '-'
      },
      {
        label: t('assets.showAsset.recycling'),
        span: 1,
        content: recycling || '-'
      },
      {
        label: t('assets.showAsset.re_use'),
        span: 1,
        content: re_use || '-'
      },
      {
        label: t('assets.showAsset.donations'),
        span: 1,
        content: donations || '-'
      },
      {
        label: t('assets.showAsset.audit_date'),
        span: 1,
        content: audit_date || '-'
      },
      {
        label: t('assets.showAsset.valorisation_amount'),
        span: 1,
        content: valorisation_amount || '-'
      },
      {
        label: t('assets.showAsset.repair'),
        span: 1,
        content: repair || '-'
      },
      {
        label: t('assets.showAsset.repair_amount'),
        span: 1,
        content: repair_amount || '-'
      }
    ]
  };
};

export default { assetList, extraDetailsList, energyRelatedDetailsList };
