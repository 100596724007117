import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Col, InputNumber, Row, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

const Settings = ({ configs, handleConfigChange }) => {
  const { t } = useTranslation();
  const {
    article: { label_invoices },
    leaser_performance,
    client_performance,
    annual_cost
  } = configs;

  return (
    <>
      <Paragraph style={{ marginTop: 24 }} strong>
        {t('companies.configClient.labelChoice')}
      </Paragraph>
      <Select
        value={label_invoices}
        mode="tags"
        style={{ width: 400 }}
        placeholder="Tags Mode"
        onChange={(value) => handleConfigChange('label_invoices', value)}
      />
      <Paragraph style={{ marginTop: 24 }} strong>
        {t('companies.configClient.dashboardStats.title')}
      </Paragraph>
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Row type="flex">
            <Col xs={24} sm={4}>
              <label htmlFor="leaserPerf">
                {t('companies.configClient.dashboardStats.leaserPerf')}
              </label>
            </Col>
            <Col xs={24} sm={20}>
              <InputNumber
                onChange={(value) =>
                  handleConfigChange('leaser_performance', value)
                }
                name="leaserPerf"
                value={leaser_performance}
                formatter={(v) => `${v}%`}
                parser={(v) => v.replace('%', '')}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row type="flex">
            <Col xs={24} sm={4}>
              <label htmlFor="clientPerf">
                {t('companies.configClient.dashboardStats.clientPerf')}
              </label>
            </Col>
            <Col xs={24} sm={20}>
              <InputNumber
                onChange={(value) =>
                  handleConfigChange('client_performance', value)
                }
                name="clientPerf"
                value={client_performance}
                formatter={(v) => `${v}%`}
                parser={(v) => v.replace('%', '')}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row type="flex">
            <Col xs={24} sm={4}>
              <label htmlFor="annualCost">
                {t('companies.configClient.dashboardStats.annualCost')}
              </label>
            </Col>
            <Col xs={24} sm={20}>
              <InputNumber
                onChange={(value) => handleConfigChange('annual_cost', value)}
                name="annualCost"
                value={annual_cost}
                style={{ width: 130, textAlign: 'right' }}
                formatter={(v) => `${numeral(v).format('0,0')}€`}
                parser={(v) => numeral(v.replace('€', '')).value()}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

Settings.propTypes = {
  configs: PropTypes.shape({
    article: PropTypes.shape({
      label_invoices: PropTypes.string
    }),
    leaser_performance: PropTypes.number,
    client_performance: PropTypes.number,
    annual_cost: PropTypes.number
  }).isRequired,
  handleConfigChange: PropTypes.func.isRequired
};
export default Settings;
