import React from 'react';
import { Button, Icon } from 'antd';
import contractFields from './fields';
import InputGenerator from '../../components/inputGenerator';

const f_steps = (
  enums,
  contract,
  suppliers,
  refinancers,
  leasers,
  companyName,
  t,
  amendment,
  disabling,
  getFieldDecorator,
  setNewCompanyType,
  setVisible
) => {
  const addingNewCompany = (type) => {
    switch (type) {
      case 'addRefinancer':
        setNewCompanyType('REFINANCER');
        break;
      case 'addSupplier':
        setNewCompanyType('SUPPLIER');
        break;
      case 'addLeaser':
        setNewCompanyType('LEASER');
        break;
      default:
        break;
    }
    setVisible(true);
  };

  const buttonAddCompany = (type) => (
    <Button type="primary" onClick={() => addingNewCompany(type)}>
      <Icon type="plus" />
      {t(`contracts.${type}`)}
    </Button>
  );
  return [
    {
      title: t('contracts.form.first'),
      content: enums && (
        <InputGenerator
          title="contracts"
          fields={contractFields(
            enums,
            contract,
            suppliers,
            refinancers,
            leasers,
            companyName,
            t,
            amendment,
            disabling
          ).slice(0, 6)}
          getFieldDecorator={getFieldDecorator}
          extra={buttonAddCompany}
        />
      )
    },
    {
      title: t('contracts.form.second'),
      content: enums && (
        <InputGenerator
          title="contracts"
          fields={contractFields(
            enums,
            contract,
            suppliers,
            refinancers,
            leasers,
            companyName,
            t,
            amendment,
            disabling
          ).slice(6, 19)}
          getFieldDecorator={getFieldDecorator}
          extra={buttonAddCompany}
        />
      )
    },
    {
      title: t('contracts.form.last'),
      content: enums && (
        <InputGenerator
          title="contracts"
          fields={contractFields(
            enums,
            contract,
            suppliers,
            refinancers,
            leasers,
            companyName,
            t,
            amendment,
            disabling
          ).slice(19)}
          getFieldDecorator={getFieldDecorator}
          extra={buttonAddCompany}
        />
      )
    }
  ];
};

export default f_steps;
