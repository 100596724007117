/* eslint-disable react/no-typos */
import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Tag } from 'antd';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { notificationTypeColors, notificationStatusColors } from '../../utils';

const NotificationDetails = ({ notification }) => {
  const { t } = useTranslation();
  const {
    title,
    description,
    type,
    status,
    read_date,
    close_date,
    created_at
  } = notification;
  return (
    <Descriptions column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
      <Descriptions.Item
        label={t('notifications.showNotification.title')}
        span={2}
      >
        {title || '-'}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('notifications.showNotification.creationDate')}
        span={1}
      >
        {moment(created_at).format('LL') || '-'}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('notifications.showNotification.description')}
        span={2}
      >
        {description || '-'}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('notifications.showNotification.read_date')}
        span={1}
      >
        {(read_date && moment(read_date).format('LL')) || '-'}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('notifications.showNotification.type')}
        span={2}
      >
        <Tag color={notificationTypeColors[type]}>{type}</Tag>
      </Descriptions.Item>
      <Descriptions.Item
        label={t('notifications.showNotification.close_date')}
        span={1}
      >
        {(close_date && moment(close_date).format('LL')) || '-'}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('notifications.showNotification.status')}
        span={2}
      >
        <Tag color={notificationStatusColors[status]}>{status}</Tag>
      </Descriptions.Item>
    </Descriptions>
  );
};
NotificationDetails.propTypes = {
  notification: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    read_date: PropTypes.instanceOf(Date),
    close_date: PropTypes.instanceOf(Date),
    created_at: PropTypes.instanceOf(Date)
  }).isRequired
};
export default NotificationDetails;
