import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Checkbox, Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const DisplayableModulesCard = ({ configId, getConfig }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [modules, setModules] = useState({});
  const moduls = [
    'RSE',
    'IT',
    'TELECOM',
    'SOFTWARE',
    'CLOUD',
    'PRINTING',
    'CARS'
  ];
  const getModules = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/client-configs/${configId}/modules`
      });
      const mod = data ? { modules: data } : { modules: [] };
      setModules(mod);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const updateDisplayableModules = async () => {
    const body = modules.modules;
    try {
      await dispatchAPI('PATCH', {
        url: `/client-configs/${configId}/modules`,
        body
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    await getConfig();
  };

  useEffect(() => {
    getModules();
  }, []);

  return (
    <Card
      title="Modules affichables"
      style={{
        marginTop: 24
      }}
    >
      <Checkbox.Group
        style={{ width: '100%', color: 'white' }}
        onChange={(value) => setModules({ ...modules, modules: value })}
        value={modules.modules}
      >
        <Row>
          {moduls.map((d) => (
            <Col span={12} key={d}>
              <Checkbox value={d}>{d}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <Row style={{ textAlign: 'center', marginTop: 24 }}>
        <Button type="primary" onClick={updateDisplayableModules}>
          {t('companies.configClient.saveButton')}
        </Button>
      </Row>
    </Card>
  );
};

DisplayableModulesCard.propTypes = {
  configId: PropTypes.string.isRequired,
  getConfig: PropTypes.func.isRequired
};

export default DisplayableModulesCard;
