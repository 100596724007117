import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty, Icon } from 'antd';
import { Axis, Chart, Coord, Geom, Legend, Tooltip } from 'bizcharts';
import DataSet from '@antv/data-set';

const BasicChart = ({ chartsElements, iconType, transposed }) => {
  const { t } = useTranslation();

  const {
    data,
    type,
    field,
    fields,
    dimension,
    groupBy,
    key,
    value,
    cols,
    customisedPadding,
    geomSize,
    geomType,
    withArea,
    xAxis,
    yAxis,
    color,
    position,
    yAxis2,
    geomType2,
    geomSize2,
    position2,
    color2,
    customTooltip,
    customTooltip2,
    titleOffset
  } = chartsElements;

  const ds = new DataSet();
  const dv = ds.createView().source(data || []);
  dv.transform({
    type,
    field,
    dimension,
    groupBy,
    fields,
    key,
    value
  });

  return (
    <Chart
      height={450}
      data={dv}
      forceFit
      padding={customisedPadding || [20, 60, 85, 85]}
      scale={cols}
      placeholder={
        <Empty
          style={{ height: '100%' }}
          description={t('home.nodata')}
          imageStyle={{ height: '90%' }}
          image={
            <Icon
              style={{ fontSize: 350, color: '#E0E0E0' }}
              type={iconType}
              rotate={transposed ? 90 : 0}
            />
          }
        />
      }
    >
      <Coord transpose={transposed} />
      <Axis name={xAxis} />
      <Axis name={yAxis} />
      {yAxis2 && (
        <Axis
          name={yAxis2}
          position="right"
          title={{
            offset: titleOffset || 60,
            textStyle: {
              stroke: color2,
              fill: color2
            }
          }}
        />
      )}
      <Tooltip crosshairs={{ type: 'y' }} />
      <Legend itemFormatter={(item) => t(`home.dash.legends.${item}`)} />
      <Geom
        opacity={1}
        type={geomType}
        size={geomSize}
        color={color}
        position={position}
        tooltip={customTooltip}
      />
      {withArea && geomType === 'line' && (
        <Geom
          type="area"
          position={position}
          tooltip={false}
          color={color}
          opacity={0.2}
        />
      )}
      {position2 && (
        <Geom
          opacity={1}
          type={geomType2}
          size={geomSize2}
          color={color2}
          position={position2}
          tooltip={customTooltip2}
        />
      )}
    </Chart>
  );
};

BasicChart.propTypes = {
  dv: PropTypes.shape({}),
  chartsElements: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.string,
    field: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string),
    dimension: PropTypes.string,
    groupBy: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.string,
    cols: PropTypes.shape({}),
    customisedPadding: PropTypes.arrayOf(PropTypes.number),
    geomSize: PropTypes.number,
    geomType: PropTypes.string,
    withArea: PropTypes.bool,
    xAxis: PropTypes.string,
    yAxis: PropTypes.string,
    color: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
    ]),
    position: PropTypes.string,
    yAxis2: PropTypes.string,
    geomType2: PropTypes.string,
    geomSize2: PropTypes.number,
    position2: PropTypes.string,
    color2: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
    ]),
    customTooltip: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    ),
    customTooltip2: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    ),
    titleOffset: PropTypes.number
  }),
  iconType: PropTypes.string.isRequired,
  transposed: PropTypes.bool
};

BasicChart.defaultProps = {
  dv: null,
  chartsElements: {},
  transposed: false
};

export default BasicChart;
