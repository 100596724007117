import { Tag } from 'antd';
import React from 'react';
import { providerColors } from '../../utils';

const fields = (contract, t) => {
  const { provider, name, phoneContractDevice, sms, data, mms, com, total } =
    contract || {};

  return [
    {
      label: 'addContract.provider',
      span: 3,
      initialValue: provider,
      content: <Tag color={providerColors[provider]}>{provider}</Tag>,
      input: 'radio',
      buttons: [
        {
          text: 'SFR',
          value: 'SFR'
        },
        {
          text: 'ORANGE',
          value: 'ORANGE'
        },
        {
          text: 'BOUYGUES',
          value: 'BOUYGUES'
        }
      ]
    },
    {
      label: 'addContract.name',
      initialValue: name,
      content: name,
      span: 3
    },
    {
      label: 'addContract.phoneContractDevice',
      initialValue: phoneContractDevice,
      content: phoneContractDevice,
      span: 2,
      input: 'radio',
      list: [
        {
          text: t('companies.form.addContract.SMARTPHONE_VOICE'),
          value: 'SMARTPHONE_VOICE'
        },
        {
          text: t('companies.form.addContract.SMARTPHONE_DATA'),
          value: 'SMARTPHONE_DATA'
        },
        {
          text: t('companies.form.addContract.SMARTPHONE'),
          value: 'SMARTPHONE'
        },
        {
          text: t('companies.form.addContract.DATA'),
          value: 'DATA'
        },
        {
          text: t('companies.form.addContract.M2M'),
          value: 'M2M'
        },
        {
          text: t('companies.form.addContract.INTERNET'),
          value: 'INTERNET'
        },
        {
          text: t('companies.form.addContract.LANDLINE'),
          value: 'LANDLINE'
        }
      ]
    },
    {
      label: 'addContract.sms',
      initialValue: sms,
      content: sms,
      type: 'number',
      input: 'number'
    },
    {
      label: 'addContract.mms',
      initialValue: mms,
      content: mms,
      type: 'number',
      input: 'number'
    },
    {
      label: 'addContract.data',
      initialValue: data,
      content: data,
      type: 'number',
      input: 'number'
    },
    {
      label: 'addContract.com.landline',
      initialValue: com && com.landline,
      content: com && com.landline,
      type: 'number',
      input: 'number'
    },
    {
      label: 'addContract.com.mobile',
      initialValue: com && com.mobile,
      content: com && com.mobile,
      type: 'number',
      input: 'number'
    },
    {
      label: 'addContract.com.international',
      initialValue: com && com.international,
      content: com && com.international,
      type: 'number',
      input: 'number'
    },
    {
      label: 'addContract.total',
      initialValue: total,
      content: total,
      type: 'number',
      input: 'number'
    }
  ];
};

export default fields;
