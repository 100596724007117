/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';
import leftLogo from '../../assets/images/leftLog.png';
import leasetic from '../../assets/images/lease.png';
import { styles } from './utils';

const HeaderInvoiceExport = ({ invoice, contract }) => {
  return (
    <View style={{ flexDirection: 'row', marginBottom: 30 }}>
      <View style={{ ...styles.tableHeader, borderWidth: 0, flex: 4 }}>
        {invoice.from && (
          <View style={{ top: -30 }}>
            <Image
              style={{ height: '70px', width: '200px', top: -10 }}
              src={leasetic}
            />
            <View
              style={{
                fontSize: 12,
                marginTop: 3,
                fontStyle: 'Helvetica Neue Fin'
              }}
            >
              <Text> {`${invoice.from.name} `} </Text>
              <Text>
                {' '}
                {`${invoice.from.address.number} ${invoice.from.address.street}`}
              </Text>
              <Text>{invoice.from.address.additional}</Text>
              <Text>
                {`${invoice.from.address.postal_code} ${invoice.from.address.city}`}
              </Text>
              <Text>RCS</Text>
            </View>
          </View>
        )}
        <View style={{ top: -25 }}>
          <Text style={{ fontSize: 12, color: '#008c00' }}>Objet :</Text>
          <Text style={{ fontSize: 10 }}>{invoice.label}</Text>
        </View>
      </View>
      <View style={{ ...styles.tableHeader, borderWidth: 0, flex: 2 }}>
        <View>
          <Image
            style={{ height: '90px', width: '110px', left: 10 }}
            src={leftLogo}
          />
          {invoice.to && (
            <View style={{ fontSize: 12, marginTop: 10 }}>
              <Text>{`${invoice.to.name} `}</Text>
              <Text>
                {`${invoice.to.address.number} ${invoice.to.address.street}`}
              </Text>
              <Text>{invoice.to.address.additional}</Text>

              <Text>
                {`${invoice.to.address.postal_code} ${invoice.to.address.city}`}
              </Text>
            </View>
          )}
        </View>

        <View>
          <Text style={{ marginTop: 10, fontSize: 12, color: '#008c00' }}>
            {`FACTURE N° ${invoice.number || ''}`}
          </Text>
          <Text style={{ fontSize: 12, color: '#008c00' }}>
            {`CONTRAT N° ${(contract && contract.number) || ''}`}
          </Text>

          <Text style={{ fontSize: 12, color: '#008c00' }}>
            {`${moment(invoice.emission_date).format('LL')}`}
          </Text>
        </View>
      </View>
    </View>
  );
};

HeaderInvoiceExport.propTypes = {
  invoice: PropTypes.shape({
    number: PropTypes.string,
    label: PropTypes.string,
    from: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        additional: PropTypes.string,
        postal_code: PropTypes.string,
        city: PropTypes.string
      })
    }),
    to: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        additional: PropTypes.string,
        postal_code: PropTypes.string,
        city: PropTypes.string
      })
    }),
    articles: PropTypes.array,
    total_excluding_taxes: PropTypes.number,
    VAT: PropTypes.number,
    sales: PropTypes.number,
    payment_date: PropTypes.string,
    emission_date: PropTypes.string,
    total: PropTypes.number
  }).isRequired,
  contract: PropTypes.shape({
    number: PropTypes.string
  }).isRequired
};

export default HeaderInvoiceExport;
