import React from 'react';
import { Button, Card, Col, Dropdown, Icon, Row, Tooltip, Tree } from 'antd';
import {
  ITAssetsHeaders,
  PeopleHeaders,
  TelecomAssetsHeaders,
  TelecomInvoiceHeaders
} from './templatesHeaders';

const allSteps = (
  base,
  collection,
  connectHeaders,
  connectedHeaders,
  headers,
  menu,
  setTempConnection,
  setConnectedHeaders,
  steps,
  t,
  tmpConnection
) => {
  const templatesHeaders = {
    IT: ITAssetsHeaders,
    Telecom: TelecomAssetsHeaders,
    People: PeopleHeaders,
    TelecomInvoices: TelecomInvoiceHeaders
  };
  return [
    ...steps,
    {
      title: t('import.step.title.connect'),
      icon: <Icon type="link" />,
      content: (
        <>
          {['IT', 'Telecom', 'People', 'TelecomInvoices'].includes(
            collection
          ) && (
            <Row>
              <Button
                style={{ marginRight: 8 }}
                type="primary"
                onClick={() =>
                  setConnectedHeaders(templatesHeaders[collection])
                }
              >
                {`J'utilise le template`}
              </Button>
              <Tooltip title='Fichier .csv, séparateur: ";", séparateur décimales: ".", encodage: ANSI. Attention à ce que les valeurs de la colonne "Propriétaire" correspondent bien au nom renseigné dans "Nom de la compagnie"'>
                <Icon type="info-circle" />
              </Tooltip>
            </Row>
          )}
          <Row style={{ marginTop: 24 }} gutter={8}>
            <Col span={6}>
              <Card
                title={t('import.content.connection.fileHeaders.title')}
                bodyStyle={{
                  padding: 0,
                  height: 400,
                  overscrollBehavior: 'contain',
                  overflowY: 'scroll',
                  overflowX: 'hidden'
                }}
              >
                <Tree
                  onSelect={(key) =>
                    setTempConnection({
                      ...tmpConnection,
                      fileHeader: key[0]
                    })
                  }
                  style={{ marginLeft: -20, fontWeight: 600 }}
                  blockNode
                  treeData={headers
                    .filter(
                      (v, i) => headers.indexOf(v) === i && !connectedHeaders[v]
                    )
                    .sort()
                    .map((header) => ({
                      key: header,
                      title: header
                    }))}
                />
              </Card>
            </Col>
            <Col span={1} style={{ height: 400, textAlign: 'center' }}>
              <Icon
                style={{
                  position: 'relative',
                  top: '50%',
                  fontSize: 24
                }}
                type="plus-circle"
              />
            </Col>
            <Col span={6}>
              <Card
                title={t('import.content.connection.dbHeaders.title')}
                bodyStyle={{
                  padding: 0,
                  height: 400,
                  overscrollBehavior: 'contain',
                  overflowY: 'scroll',
                  overflowX: 'hidden'
                }}
              >
                <Tree
                  onSelect={(key) =>
                    setTempConnection({ ...tmpConnection, dbHeader: key[0] })
                  }
                  style={{ marginLeft: -20, fontWeight: 600 }}
                  blockNode
                  treeData={(base || [])
                    .filter(
                      (value) =>
                        !Object.values(connectedHeaders)
                          .map((header) =>
                            header.field ? header.field : header
                          )
                          .includes(value)
                    )
                    .sort((a, b) => {
                      return t(`exports.${a}`)
                        .toUpperCase()
                        .localeCompare(t(`exports.${b}`).toUpperCase());
                    })
                    .map((header) => ({
                      key: header,
                      title: t(`exports.${header}`)
                    }))}
                />
              </Card>
            </Col>
            <Col span={1} style={{ height: 400, textAlign: 'center' }}>
              <Button
                onClick={connectHeaders}
                type="primary"
                disabled={!(tmpConnection.fileHeader && tmpConnection.dbHeader)}
                style={{
                  position: 'relative',
                  top: '50%'
                }}
                icon="link"
              />
            </Col>
            <Col span={10}>
              <Card
                title={t('import.content.connection.dbHeaders.title')}
                bodyStyle={{
                  padding: 10,
                  height: 400,
                  overscrollBehavior: 'contain',
                  overflow: 'auto'
                }}
              >
                {Object.keys(connectedHeaders).length ? (
                  <ul>
                    {Object.entries(connectedHeaders).map((header) =>
                      header[1].field ? (
                        <li key={header[0]} style={{ margin: '5px 0' }}>
                          <Dropdown
                            trigger={['click']}
                            overlay={() => menu(header)}
                            style={{ margin: 2 }}
                            key={header[0]}
                          >
                            <Button size="small" type="danger">
                              {header[0]}
                              <Icon type="link" />
                              {t(`exports.${header[1].field}`)}
                              <Icon type="api" />
                              {t(`import.schemaList.${header[1].collection}`)}
                            </Button>
                          </Dropdown>
                        </li>
                      ) : (
                        <li key={header[0]} style={{ margin: '5px 0' }}>
                          <Dropdown
                            trigger={['click']}
                            overlay={() => menu(header)}
                            style={{ margin: 2 }}
                            key={header[0]}
                          >
                            <Button size="small" type="primary">
                              {header[0]}
                              <Icon type="link" />
                              {t(`exports.${header[1]}`)}
                            </Button>
                          </Dropdown>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <p
                    style={{
                      textAlign: 'center',
                      fontWeight: 600,
                      marginTop: 160
                    }}
                  >
                    {t('import.content.connection.noConnectedHeaders')}
                  </p>
                )}
              </Card>
            </Col>
          </Row>
        </>
      )
    }
  ];
};

export default allSteps;
