import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, Row, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import DescriptionList from '../../components/descriptionList/DescriptionList';
import list from './informationList';
import TelecomInvoicesTable from './TelecomInvoicesTable';
import ConsumptionHistory from './ConsumptionHistory';
import { ContentCustom, PageHeaderCustom } from '../../components';

const ShowAsset = () => {
  const { id, type } = useParams();
  const { assetList, extraDetailsList, energyRelatedDetailsList } = list;
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [asset, setAsset] = useState({});

  const getAsset = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/assets?_id=${id}&populate=contract,people,phone_contract,telecomInvoices.phone_contract,site`
      });
      setAsset(data[0]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getAsset();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeaderCustom title={t('assets.showAsset.title')} />
      <ContentCustom>
        {isLoading ? (
          <Spin
            style={{
              textAlign: 'center',
              borderRadius: '4px',
              marginBottom: '20px',
              padding: '30px 50px',
              margin: '20px 0',
              width: '100%',
              height: '100%'
            }}
          />
        ) : (
          <Row>
            {type === 'Telecom' ? (
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 10 }}>
                  <DescriptionList data={assetList(asset, t)[type]} />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 14 }}>
                  <ConsumptionHistory invoices={asset.telecomInvoices} />
                </Col>
              </Row>
            ) : (
              type && (
                <DescriptionList
                  title={t('assets.showAsset.title')}
                  data={assetList(asset, t)[type]}
                />
              )
            )}
            {type === 'IT' && (
              <>
                <Divider style={{ marginTop: 24 }} orientation="left">
                  {t('assets.showAsset.extraDivider')}
                </Divider>
                <DescriptionList data={extraDetailsList(asset, t)[type]} />
                <Divider style={{ marginTop: 24 }} orientation="left">
                  {t('assets.showAsset.energyRelatedDivider')}
                </Divider>
                <DescriptionList
                  data={energyRelatedDetailsList(asset, t)[type]}
                />
              </>
            )}
          </Row>
        )}
      </ContentCustom>
      {type === 'Telecom' && (
        <TelecomInvoicesTable data={asset.telecomInvoices} />
      )}
    </>
  );
};

export default ShowAsset;
