import React from 'react';
import { Button, Icon, Divider, Form, Select, Input, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const ContactForm = ({
  peopleId,
  ticket,
  getFieldDecorator,
  isPeopleLoading,
  getCompanyPeople,
  companyPeople,
  company,
  setFieldsValue,
  noLabelLayout,
  prefixSelector
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Divider orientation="left">
        {t('tickets.fields.divider.contact')}
      </Divider>
      <Form.Item
        label={t('tickets.fields.contact')}
        extra={
          !company && (
            <>
              <Icon
                type="warning"
                style={{
                  fontSize: '16px',
                  color: '#fadb14'
                }}
              />
              <Button type="link" style={{ fontSize: 12 }}>
                Choisissez une companie avant !
              </Button>
            </>
          )
        }
      >
        {getFieldDecorator('peopleId')(
          <Select
            allowClear
            notFoundContent={null}
            loading={isPeopleLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => getCompanyPeople(value)}
            style={{ width: 300 }}
          >
            {companyPeople.map(({ _id, first_name, last_name, job }) => (
              <Option value={_id} key={_id}>
                {`${first_name} ${last_name} - ${job || 'N/C'}`}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('tickets.fields.name')}>
        {getFieldDecorator('contact.name', {
          rules: [
            { required: true, message: t('global.form.requiredMessage') }
          ],
          initialValue: ticket && ticket.contact && ticket.contact.name
        })(
          <Select
            allowClear
            notFoundContent={null}
            loading={isPeopleLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => getCompanyPeople(value)}
            style={{ width: 300 }}
          >
            {companyPeople.map(({ _id, first_name, last_name }) => (
              <Option value={_id} key={_id}>
                {`${first_name} ${last_name}`}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('tickets.fields.phoneNumber')}>
        {getFieldDecorator('contact.phone_number.number', {
          rules: [
            { required: true, message: t('global.form.requiredMessage') },
            {
              transform: (value) => {
                if (value)
                  setFieldsValue({
                    'contact.phone_number.number': value
                      .replace(/^0/, '')
                      .replace(/ /g, '')
                  });
              }
            }
          ],
          initialValue: ticket && ticket.contact.phone_number.number
        })(<Input addonBefore={prefixSelector} />)}
      </Form.Item>
      <Form.Item label={t('tickets.fields.email')}>
        {getFieldDecorator('contact.email', {
          rules: [
            { required: true, message: t('global.form.requiredMessage') },
            { type: 'email', message: t('global.form.typeMessage') }
          ],
          initialValue: ticket && ticket.contact && ticket.contact.email
        })(<Input />)}
      </Form.Item>
      {peopleId && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Form.Item {...noLabelLayout}>
          {getFieldDecorator('updatePeople', {
            valuePropName: 'checked'
          })(<Checkbox>{t(`tickets.fields.updateContact`)}</Checkbox>)}
        </Form.Item>
      )}
      <Form.Item label={t('tickets.fields.comment')}>
        {getFieldDecorator('comment', {
          initialValue: ticket && ticket.comment
        })(<Input.TextArea rows={6} />)}
      </Form.Item>
    </>
  );
};

ContactForm.propTypes = {
  peopleId: PropTypes.string.isRequired,
  ticket: PropTypes.shape({
    contact: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.shape({
        number: PropTypes.string
      })
    }).isRequired,
    comment: PropTypes.string
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  isPeopleLoading: PropTypes.bool.isRequired,
  getCompanyPeople: PropTypes.func.isRequired,
  companyPeople: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  noLabelLayout: PropTypes.shape({}).isRequired,
  prefixSelector: PropTypes.string.isRequired
};

export default ContactForm;
