import React, { useState } from 'react';
import { DatePicker, Form, Input, Select, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ContentCustom } from '../../components';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const PaymentInformationForm = ({ companies, payment, getFieldDecorator }) => {
  const { t } = useTranslation();

  const [fetchingPayment] = useState(false);
  return (
    <ContentCustom>
      <Title level={2}>{t(`payments.create.paymentInfo`)}</Title>
      <Spin spinning={fetchingPayment}>
        <Form.Item label={t('payments.fields.number')}>
          {getFieldDecorator('number', {
            rules: [
              { required: true, message: t('global.form.requiredMessage') }
            ],
            initialValue: payment && payment.number
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('payments.fields.paymentDate')}>
          {getFieldDecorator('payment_date', {
            rules: [
              {
                required: true,
                message: t('global.form.requiredMessage')
              }
            ],
            initialValue: payment && moment(payment.payment_date)
          })(<DatePicker />)}
        </Form.Item>
        <Form.Item label={t('payments.fields.recipient')}>
          {getFieldDecorator('recipient', {
            rules: [{ required: true }],
            initialValue: payment && payment.recipient
          })(
            <Select>
              {companies &&
                companies.map(({ name, _id }) => (
                  <Option value={_id} key={name}>
                    {name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('payments.fields.comment')}>
          {getFieldDecorator('comment', {
            rules: [
              { required: false, message: t('global.form.requiredMessage') }
            ],
            initialValue: payment && payment.comment
          })(<TextArea rows={4} />)}
        </Form.Item>
      </Spin>
    </ContentCustom>
  );
};

PaymentInformationForm.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  payment: PropTypes.shape({
    recipient: PropTypes.string,
    comment: PropTypes.string,
    payment_date: PropTypes.string,
    number: PropTypes.string
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired
};

export default PaymentInformationForm;
