import React from 'react';
import { Col, Row, Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';

const OrderAmount = ({ isLoading, isTableLoading, order }) => {
  const { t } = useTranslation();
  const {
    payment_date,
    total_ht_amount,
    tva_amount,
    total_discount,
    ttc_amount
  } = order;
  return (
    <>
      <Row style={{ background: '#f0f0f0', marginTop: 50 }}>
        <Col xs={{ offset: 8 }} md={{ offset: 14 }}>
          <Skeleton active loading={isLoading} paragraph={0}>
            <Row style={{ borderBottom: '1px solid #e0e0e0' }}>
              <Col span={8} style={{ textAlign: 'left' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {`${t('orders.showOrder.total_ht_amount')} `}
                </Typography.Text>
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {`${numeral(total_ht_amount || 0).format('0,0.00')}€`}
                </Typography.Text>
              </Col>
            </Row>
            <Row style={{ borderBottom: '1px solid #e0e0e0' }}>
              <Col span={8} style={{ textAlign: 'left' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {t('orders.showOrder.total_discount')}
                </Typography.Text>
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {`${numeral(total_discount || 0).format('0,0.00')}€`}
                </Typography.Text>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ textAlign: 'left' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {t('orders.showOrder.tva')}
                </Typography.Text>
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {`${numeral(tva_amount || 0).format('0,0.00')}€`}
                </Typography.Text>
              </Col>
            </Row>
          </Skeleton>
        </Col>
      </Row>
      <Row style={{ margin: '30px 0' }}>
        <Col xs={{ offset: 8 }} md={{ offset: 14 }}>
          <Skeleton active loading={isTableLoading} paragraph={0}>
            <Row type="flex" style={{ height: '100%' }}>
              <Col span={8} style={{ textAlign: 'left' }}>
                <Typography.Text strong style={{ fontSize: '18px' }}>
                  {t('orders.showOrder.totalDue', {
                    date: payment_date
                      ? `${moment(payment_date).format('L')}`
                      : ''
                  })}
                </Typography.Text>
              </Col>
              <Col
                span={16}
                style={{
                  textAlign: 'right',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end'
                }}
              >
                <Typography.Text strong style={{ fontSize: '18px' }}>
                  {`${numeral(ttc_amount || 0 + tva_amount || 0).format(
                    '0,0.00'
                  )}€`}
                </Typography.Text>
              </Col>
            </Row>
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};

OrderAmount.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    payment_date: PropTypes.instanceOf(Date),
    total_ht_amount: PropTypes.number.isRequired,
    tva_amount: PropTypes.number.isRequired,
    total_discount: PropTypes.number.isRequired,
    ttc_amount: PropTypes.number.isRequired
  }).isRequired
};
export default OrderAmount;
