import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import pluralize from 'pluralize';
import numeral from 'numeral';
import {
  contractPeriodicityColor,
  contractStatusColor,
  contractTypeColor
} from '../../utils';

const contractList = (data, t) => {
  const {
    amendment_number,
    number,
    status,
    type,
    start_date,
    end_date,
    duration,
    periodicity,
    cession_rate,
    client_rate,
    periodicity_extension,
    linear,
    residual_value,
    customer,
    leaser,
    refinancer,
    suppliers,
    termination_delay,
    sinister_cost,
    leasetic_sinister_cost,
    client_coefficient
  } = data;

  return [
    {
      label: t('contracts.showContract.reference'),
      span: 1,
      content: `${number || '-'}${
        amendment_number ? '-' : ''
      }${amendment_number || ''}`
    },
    {
      label: t('contracts.showContract.customer'),
      span: 2,
      content: (customer && customer.name) || '-'
    },
    {
      label: t('contracts.showContract.leaser'),
      span: 1,
      content: (leaser && leaser.name) || '-'
    },
    {
      label:
        suppliers &&
        (suppliers.length > 1
          ? t('contracts.showContract.suppliers')
          : t('contracts.showContract.supplier')),
      span: 1,
      content:
        (suppliers &&
          suppliers.map(({ name }) => (
            <div key={name}>
              {name}
              <br />
            </div>
          ))) ||
        '-'
    },
    {
      label: t('contracts.showContract.refinancer'),
      span: 1,
      content: (refinancer && refinancer.name) || '-'
    },
    {
      label: t('contracts.showContract.status'),
      span: 1,
      content: status ? (
        <Tag color={contractStatusColor[status]}>
          {t(`contracts.form.options.status.${status}`).toUpperCase()}
        </Tag>
      ) : (
        '-'
      )
    },
    {
      label: t('contracts.showContract.type'),
      span: 1,
      content: type ? (
        <Tag color={contractTypeColor[type]}>
          {t(`contracts.form.options.type.${type}`).toUpperCase()}
        </Tag>
      ) : (
        '-'
      )
    },
    {
      label: t('contracts.showContract.dates'),
      span: 1,
      content: ` ${start_date ? moment(start_date).format('LL') : '-'} - ${
        end_date ? moment(end_date).format('LL') : '-'
      }`
    },
    {
      label: t('contracts.showContract.duration'),
      span: 1,
      content:
        `${duration} ${
          duration > 1
            ? pluralize(t('global.units.month'))
            : t('global.units.month')
        }` || '-'
    },
    {
      label: t('contracts.showContract.periodicity'),
      span: 1,
      content: periodicity ? (
        <Tag color={contractPeriodicityColor[periodicity]}>
          {t(`contracts.form.options.periodicity.${periodicity}`).toUpperCase()}
        </Tag>
      ) : (
        '-'
      )
    },
    {
      label: t('contracts.showContract.periodicity_extension'),
      span: 1,
      content: periodicity_extension ? (
        <Tag color={contractPeriodicityColor[periodicity_extension]}>
          {t(
            `contracts.form.options.periodicity_extension.${periodicity_extension}`
          )}
        </Tag>
      ) : (
        '-'
      )
    },
    {
      label: t('contracts.showContract.termination_delay'),
      span: 3,
      content:
        `${termination_delay} ${
          termination_delay > 1
            ? pluralize(t('global.units.month'))
            : t('global.units.month')
        }` || '-'
    },
    {
      label: t('contracts.showContract.residual_value'),
      span: 1,
      content: residual_value
        ? `${numeral(residual_value).format('0,0.00')}€`
        : '-'
    },
    {
      label: t('contracts.showContract.cession_rate'),
      span: 1,
      content: cession_rate ? `${cession_rate}%` : '-'
    },
    {
      label: t('contracts.showContract.client_rate'),
      span: 1,
      content: client_rate ? `${client_rate}%` : '-'
    },
    {
      label: t('contracts.showContract.client_coefficient'),
      span: 1,
      content: client_coefficient ? `${client_coefficient}%` : '-'
    },
    {
      label: t('contracts.showContract.linear'),
      span: 1,
      content: linear ? 'Oui' : 'Non'
    },
    {
      label: t('contracts.showContract.leasetic_sinister_cost'),
      span: 1,
      content: leasetic_sinister_cost
        ? `${numeral(leasetic_sinister_cost).format('0,0.00')}€`
        : '-'
    },
    {
      label: t('contracts.showContract.sinister_cost'),
      span: 1,
      content: sinister_cost
        ? `${numeral(sinister_cost).format('0,0.00')}€`
        : '-'
    }
  ];
};

export default { contractList };
