import React from 'react';
import { Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ResourceLandingLayout } from '../../layouts';
import { notificationStatusColors, notificationTypeColors } from '../../utils';

const ListNotifications = () => {
  const { t } = useTranslation();

  return (
    <ResourceLandingLayout
      customActionColumn
      withCreateButton={false}
      extraQuery="!user_receiver"
      resourceName="notifications"
      columns={[
        {
          title: t('notifications.list.column.title'),
          dataIndex: 'title',
          sorter: true
        },
        {
          title: t('notifications.list.column.description'),
          dataIndex: 'description',
          sorter: true
        },
        {
          title: t('notifications.list.column.type'),
          dataIndex: 'type',
          sorter: true,
          render: (tp) => <Tag color={notificationTypeColors[tp]}>{tp}</Tag>
        },
        {
          title: t('notifications.list.column.status'),
          dataIndex: 'status',
          sorter: true,
          render: (stat) => (
            <Tag color={notificationStatusColors[stat]}>{stat}</Tag>
          )
        },
        {
          title: t('notifications.list.column.read_date'),
          dataIndex: 'created_at',
          sorter: true,
          render: (read_date) => (
            <Typography>
              {(read_date && moment(read_date).format('LL')) || '-'}
            </Typography>
          )
        },
        {
          title: t('notifications.list.column.close_date'),
          dataIndex: 'close_date',
          sorter: true,
          render: (close_date) => (
            <Typography>
              {(close_date && moment(close_date).format('LL')) || '-'}
            </Typography>
          )
        }
      ]}
    />
  );
};

export default ListNotifications;
