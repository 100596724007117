import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v1';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Button,
  Typography,
  Input,
  Row,
  InputNumber,
  Divider,
  Spin
} from 'antd';
import InputGenerator from '../inputGenerator';
import {
  configClientFormItemLayout,
  tailFormItemLayout
} from '../../utils/formLayouts';
import fields from './fields';
import DescriptionList from '../descriptionList/DescriptionList';

const { Title } = Typography;

const AddPhoneContractModal = ({
  loading,
  contract,
  visible,
  action,
  handleOk,
  handleCancel,
  switchToEdit,
  form: { getFieldDecorator, validateFieldsAndScroll, resetFields }
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  const addOptionField = () => {
    const tmp = options;
    tmp.push({
      optionLabel: `addContract.options.optionLabel.${tmp.length}`,
      priceLabel: `addContract.options.priceLabel.${tmp.length}`,
      id: uuid()
    });
    setOptions([...tmp]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { addContract } = values;
        const opts = [];
        if (addContract.options) {
          const { optionLabel, priceLabel } = addContract.options;
          for (let i = 0; i < optionLabel.length; i += 1) {
            opts.push({ label: optionLabel[i], price: priceLabel[i] });
          }
        }
        const contractValues = {
          ...addContract,
          options: opts
        };
        handleOk(contractValues);
        resetFields();
        setOptions([]);
      }
    });
  };

  const cancel = () => {
    setOptions([]);
    resetFields();
    handleCancel();
  };

  const deleteOption = (key) => {
    const tmp = options.filter(({ id }) => id !== key);
    setOptions([...tmp]);
  };

  useEffect(() => {
    if (contract && contract.options && contract.options.length) {
      const tmp = [];
      contract.options.forEach((opt, idx) => {
        tmp.push({
          optionLabel: `addContract.options.optionLabel.${idx}`,
          priceLabel: `addContract.options.priceLabel.${idx}`,
          id: uuid()
        });
      });
      setOptions([...tmp]);
    }
  }, [contract]);

  let modalTitle = t('companies.contracts.addContract');
  if (action === 'show') modalTitle = t('companies.contracts.showContract');
  if (contract && action === 'edit')
    modalTitle = t('companies.contracts.editContract');

  const { _id } = contract || {};

  return (
    <Modal
      width={800}
      title={modalTitle}
      visible={visible}
      onCancel={cancel}
      footer={null}
    >
      {action === 'show' && (
        <Button
          style={{ float: 'right' }}
          type="primary"
          icon="edit"
          onClick={() => switchToEdit(_id, 'edit')}
        />
      )}
      <Title level={4}>{t('companies.form.addContract.title')}</Title>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Spin spinning={loading} size="large">
        {action === 'show' ? (
          <DescriptionList data={fields(contract, t)} translate />
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Form {...configClientFormItemLayout} onSubmit={handleSubmit}>
            <InputGenerator
              title="companies"
              fields={fields(contract, t)}
              getFieldDecorator={getFieldDecorator}
            />
            <Divider orientation="left">
              {t('companies.form.addContract.divider')}
            </Divider>
            {options.map((option, idx) => (
              <Row
                type="flex"
                justify="space-around"
                align="middle"
                key={option.optionLabel}
              >
                <Form.Item
                  label={t('companies.form.addContract.optionLabel')}
                  style={{ marginBottom: 0, width: '50%' }}
                >
                  {getFieldDecorator(option.optionLabel, {
                    initialValue:
                      contract &&
                      contract.options &&
                      contract.options[idx] &&
                      contract.options[idx].label
                  })(<Input />)}
                </Form.Item>
                <Form.Item
                  label={t('companies.form.addContract.priceLabel')}
                  style={{ marginBottom: 0, width: '45%' }}
                >
                  {getFieldDecorator(option.priceLabel, {
                    initialValue:
                      contract &&
                      contract.options &&
                      contract.options[idx] &&
                      contract.options[idx].price
                  })(<InputNumber />)}
                </Form.Item>
                <Button
                  size="small"
                  type="danger"
                  icon="delete"
                  onClick={() => deleteOption(option.id)}
                />
              </Row>
            ))}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Form.Item {...tailFormItemLayout}>
              <Button type="dashed" onClick={addOptionField} icon="plus">
                {t('companies.form.addContract.addOptionButton')}
              </Button>
            </Form.Item>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                {t('users.editcreate.form.submitButton')}
              </Button>
              <Button
                type="danger"
                onClick={handleCancel}
                style={{ margin: '0 10px' }}
              >
                {t('users.editcreate.form.cancel')}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

AddPhoneContractModal.propTypes = {
  contract: PropTypes.shape({
    _id: PropTypes.string,
    provider: PropTypes.string,
    name: PropTypes.string,
    mms: PropTypes.number,
    data: PropTypes.number,
    sms: PropTypes.number,
    com: PropTypes.shape({
      landline: PropTypes.number,
      mobile: PropTypes.number,
      international: PropTypes.number
    }),
    total: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        price: PropTypes.number
      })
    ),
    phoneContractType: PropTypes.string,
    phoneContractDevice: PropTypes.string,
    isUsefulForAnalyzes: PropTypes.bool
  }),
  loading: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  action: PropTypes.string,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  switchToEdit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired
};

AddPhoneContractModal.defaultProps = {
  contract: null,
  action: null
};

const WrappedAddPhoneContractAntForm = Form.create({
  name: 'addPhoneContract'
})(AddPhoneContractModal);

export default WrappedAddPhoneContractAntForm;
