import React from 'react';
import { Tag } from 'antd';
import {
  categoryTypesColors,
  invoiceTypesColors,
  productsTypesColors
} from '../../utils';

export default (
  product,
  productType,
  invoiceType,
  invoiceTypes,
  productTypes,
  productEnums,
  t
) => {
  const productTypeOptions = productTypes.map((type) => ({
    value: type._id,
    text: (
      <>
        <Tag color={categoryTypesColors[type.invoice_type.category]}>
          {type.invoice_type.category}
        </Tag>
        <Tag color={invoiceTypesColors[type.invoice_type.label]}>
          {type.invoice_type.label}
        </Tag>
        <Tag color={productsTypesColors[type.label]}>{type.label}</Tag>
      </>
    )
  }));

  const categoryOptions = productEnums.map((category) => ({
    value: category,
    text: <Tag color={categoryTypesColors[category]}>{category}</Tag>
  }));

  return [
    {
      label: 'category',
      input: 'select',
      required: true,
      options: categoryOptions,
      initialValue: product && product.category
    },
    {
      label: 'type',
      input: 'select',
      options: productTypeOptions,
      required: true,
      initialValue: product && product.type && product.type._id
    },
    {
      label: 'name',
      required: true,
      initialValue: product && product.name
    },
    {
      label: 'price',
      required: false,
      initialValue: product && product.price
    }
  ];
};
