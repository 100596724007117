/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  Icon,
  Popconfirm,
  Spin,
  Timeline,
  Row,
  Col,
  Badge
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import NotificationDetails from './NotificationDetails';

const ShowNotification = () => {
  const { id } = useParams();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [notification, setNotification] = useState({});
  const [badgeCount, setBadgeCount] = useState(0);
  const [visible, setVisible] = useState(false);

  const getNotification = async () => {
    try {
      setIsTableLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/notifications/${id}?populate=file`
      });
      if (data.file) {
        setBadgeCount(1);
      } else setBadgeCount(0);
      setNotification(data);
      setIsTableLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteNotification = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/notifications/${id}`
      });
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  };

  const editNotification = async () => {
    try {
      const today = Date.now();
      const body = { close_date: today, status: 'CLOSED' };
      const response = await dispatchAPI('PATCH', {
        url: `/notifications/${id}`,
        body
      });
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  };

  const downloadResource = async (id) => {
    const result = await dispatchAPI('GET', {
      responseType: 'blob',
      url: `files/${id}`
    });
  };
  useEffect(() => {
    getNotification();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('notifications.showNotification.titlePage')}
        extra={
          <div>
            <Popconfirm
              title={t('notifications.showNotification.edit.title')}
              okText={t('notifications.showNotification.edit.ok')}
              cancelText={t('notifications.showNotification.edit.cancel')}
              onConfirm={editNotification}
              icon={<Icon type="confirm" />}
            >
              <Button type="primary" icon="edit">
                {t('notifications.showNotification.editButton')}
              </Button>
            </Popconfirm>
            <Popconfirm
              title={t('notifications.showNotification.delete.title')}
              okText={t('notifications.showNotification.delete.ok')}
              cancelText={t('notifications.showNotification.delete.cancel')}
              onConfirm={deleteNotification}
              icon={<Icon type="warning" />}
            >
              <Button type="danger" icon="delete" style={{ margin: '0 4px' }}>
                {t('notifications.showNotification.deleteButton')}
              </Button>
            </Popconfirm>
          </div>
        }
      />
      {isTableLoading ? (
        <Spin
          style={{
            textAlign: 'center',
            borderRadius: '4px',
            marginBottom: '20px',
            padding: '30px 50px',
            margin: '20px 0',
            width: '100%',
            height: '100%'
          }}
        />
      ) : (
        <div>
          <ContentCustom padding={false}>
            <div
              style={{
                padding: 24,
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                width: '100%'
              }}
            >
              <NotificationDetails notification={notification} />
              <Button
                type="primary"
                onClick={() => setVisible(true)}
                style={{
                  position: 'absolute',
                  padding: 5,
                  right: -2,
                  top: 100 || '40%',
                  borderRight: 'none',
                  height: '50px'
                }}
              >
                <Badge
                  offset={[-25, -15]}
                  style={{ zIndex: 1 }}
                  count={badgeCount}
                >
                  <Icon type="file" style={{ fontSize: 16 }} />
                </Badge>
              </Button>
              <Drawer
                onClose={() => setVisible(false)}
                placement="right"
                zIndex={2}
                width={400}
                mask={false}
                getContainer={false}
                visible={visible}
                style={{ position: 'absolute' }}
                title={t('notifications.showNotification.fileTitle')}
              >
                {notification && notification.file && (
                  <Timeline>
                    <Timeline.Item key={notification.file._id} color="green">
                      <Row type="flex" style={{ height: '100%' }}>
                        <Col span={20}>
                          <a
                            style={{ height: 20, width: '80%' }}
                            onClick={() =>
                              downloadResource(
                                notification.file._id,
                                notification.file.metadata.originalName
                              )
                            }
                            type="link"
                          >
                            {notification.file.metadata.originalName}
                          </a>
                        </Col>
                      </Row>
                    </Timeline.Item>
                  </Timeline>
                )}
              </Drawer>
            </div>
          </ContentCustom>
        </div>
      )}
    </>
  );
};
export default ShowNotification;
