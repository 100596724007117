/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable-next-line react-hooks/exhaustive-deps */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer, Form, Spin, Modal } from 'antd';
import InputGenerator from '../../components/inputGenerator';
import EditAndCreateCompanyForm from '../companies/EditAndCreateCompanyForm';
import { contractFormItemLayout } from '../../utils/formLayouts';
import AllSteps from './AllSteps';
import EditAndCreateContractFormPreview from './EditAndCreateContractFormPreview';
import { ContentCustom } from '../../components';
import { spinStyle } from './utils';

const EditAndCreateContractFormBody = ({
  isVisible,
  handleSubmit,
  confirmLoading,
  setIsVisible,
  companies,
  contract,
  setVisible,
  newCompanyType,
  visible,
  handleCompanyCreated,
  isLoading,
  user,
  steps,
  clients,
  done,
  getFieldDecorator
}) => {
  const { t } = useTranslation();

  return (
    <ContentCustom>
      <Modal
        title="Title"
        visible={isVisible}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={() => setIsVisible(false)}
      >
        {companies && (
          <EditAndCreateContractFormPreview
            translation={t}
            companies={companies}
            values={contract}
          />
        )}
      </Modal>
      <Drawer
        title={t(`companies.form.${newCompanyType}`)}
        width={720}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <EditAndCreateCompanyForm
          title={newCompanyType}
          initialType={newCompanyType}
          drawer
          onSuccess={handleCompanyCreated}
        />
      </Drawer>
      {isLoading ? (
        <Spin style={spinStyle} />
      ) : (
        <Form {...contractFormItemLayout} onSubmit={handleSubmit}>
          {(user[0].role === 'SUPER-ADMIN' || user[0].role === 'ADMIN') &&
            companies && (
              <InputGenerator
                title="contracts"
                fields={[
                  {
                    label: 'customer',
                    initialValue: contract.customer,
                    required: true,
                    input: 'select',
                    options: clients
                  }
                ]}
                getFieldDecorator={getFieldDecorator}
              />
            )}
          <AllSteps steps={steps} t={t} done={done} />
        </Form>
      )}
    </ContentCustom>
  );
};

EditAndCreateContractFormBody.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  confirmLoading: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  contract: PropTypes.shape({
    customer: PropTypes.string
  }).isRequired,
  setVisible: PropTypes.func.isRequired,
  newCompanyType: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCompanyCreated: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  user: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string
    })
  ).isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string
    })
  ).isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  done: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func.isRequired
};

const WrappedEditAndCreateContractAntFormBody = Form.create({
  name: 'contracts',
  onFieldsChange: (props, changedFields) => {
    props.onChange(changedFields);
  }
})(EditAndCreateContractFormBody);

export default WrappedEditAndCreateContractAntFormBody;
