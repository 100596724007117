import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeaderCustom } from '../../components';
import OrderForm from './OrderForm';
import { formItemLayout, tailFormItemLayout } from '../../utils/formLayouts';

const EditAndCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  return (
    <>
      <PageHeaderCustom title={t(`orders.create.orderInfo`)} />
      <OrderForm
        id={id}
        formItemLayout={formItemLayout}
        tailFormItemLayout={tailFormItemLayout}
        history={history}
      />
    </>
  );
};

export default EditAndCreate;
