import React, { useEffect, useState } from 'react';
import { Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import useLanguageContext from '../../contexts/LanguageContext';
import { useStateWithSessionStorage } from '../../utils';
import TimeScaling from '../../components/timeScaling/TimeScaling';
import { TelecomContextProvider } from './context/FiltersContext';
import { ContentCustom } from '../../components';
import GeneralDashboard from './GeneralDashboard';

const { TabPane } = Tabs;

const Dashboard = () => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useStateWithSessionStorage(
    'activeKey',
    'General'
  );
  const { locale } = useLanguageContext();
  const [tabList, setTabList] = useState([]);

  useEffect(() => {
    setTabList([
      {
        key: 'General',
        tab: t('home.title.generalDashboard')
      },
      {
        key: 'IT',
        tab: t('home.title.it'),
        disabled: true
      },
      {
        key: 'Telecom',
        tab: t('home.title.telecom'),
        disabled: true
      },
      {
        key: 'Software',
        tab: t('home.title.software'),
        disabled: true
      },
      {
        key: 'Cars',
        tab: t('home.title.cars'),
        disabled: true
      },
      {
        key: 'RealEstate',
        tab: t('home.title.realEstate'),
        disabled: true
      },
      {
        key: 'RSE',
        tab: t('home.title.RSE'),
        disabled: true
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const contentList = {
    General: <GeneralDashboard />
  };

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <TelecomContextProvider>
      <ContentCustom>
        <Row>
          <Row>
            <TimeScaling />
          </Row>
        </Row>
        <Tabs
          size="large"
          activeKey={activeKey}
          animated={false}
          onChange={(key) => {
            (async () => {
              await onTabChange(key);
            })();
          }}
        >
          {tabList.map(({ key, tab, disabled }) => (
            <TabPane tab={tab} key={key} disabled={disabled}>
              {contentList[activeKey]}
            </TabPane>
          ))}
        </Tabs>
      </ContentCustom>
    </TelecomContextProvider>
  );
};

export default Dashboard;
