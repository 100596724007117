const exportHeaders = [
  {
    label: 'id_number'
  },
  {
    label: 'last_name'
  },
  {
    label: 'first_name'
  },
  {
    label: 'email'
  },
  {
    label: 'exit_date'
  },
  {
    label: 'contract_type'
  },
  {
    label: 'job'
  },
  {
    label: 'job_type'
  },
  {
    label: 'job_category'
  },
  {
    label: 'subsidiary'
  }
];
export default exportHeaders;
