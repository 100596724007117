import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { message, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import RelatedItems from './RelatedItems';
import DescriptionList from '../../components/descriptionList/DescriptionList';
import list from './informationList';
import AddContactModal from './AddContactModal';
import SiteTable from './SiteTable';
import UploadDrawer from '../../components/uploadDrawer';
import CompanyContacts from './CompanyContacts';
import { ContentCustom, PageHeaderCustom } from '../../components';
import ExtraButtonsCompany from './ExtraButtonsCompany';

const ShowCompany = () => {
  const { id, type } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { dispatchConfigs } = useConfigsContext();
  const { t } = useTranslation();
  const { companyList } = list;
  const [company, setCompany] = useState({
    address: {},
    contact: { address: {}, phone_number: {}, phone_number2: {} }
  });
  const [contactList, setContactList] = useState([]);
  const [contracts, setContracts] = useState(null);
  const [contractsId, setContractsId] = useState(null);
  const [contractsAssets, setContractsAssets] = useState(null);
  const [visible, setVisible] = useState(false);
  const [contactToEdit, setContactToEdit] = useState({ info: null });
  const [badgeCount, setBadgeCount] = useState(0);

  const getCompany = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${id}?populate=contracts,sites,files`
      });
      setCompany(data);
      setContracts(data.contracts);
      setContractsId(
        data.contracts.length && data.contracts.map(({ _id }) => _id)
      );
      if (data.files && data.files.length) {
        setBadgeCount(data.files.length);
      } else setBadgeCount(0);
      setLoading(false);
      setContactList(data.contacts);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
      setLoading(false);
    }
  };

  const fetchContractAssets = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts?_id=${contractsId}&populate=assets`
      });
      const tmp = [];
      data.forEach((contract) => {
        if (contract.assets && contract.assets.length) {
          contract.assets.forEach((asset) => {
            tmp.push(asset);
          });
        }
      });
      setContractsAssets(tmp);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
      setLoading(false);
    }
  };

  const updateContactList = async (ctcList) => {
    const body = { contacts: ctcList };
    try {
      await dispatchAPI('PATCH', {
        url: `/companies/${id}`,
        body
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setIsModalLoading(false);
    setContactToEdit({ info: null });
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async ({ addContact }) => {
    setIsModalLoading(true);
    const tmp = contactList;
    tmp.push(addContact);
    await updateContactList(tmp);
  };

  const handleCancel = () => {
    setVisible(false);
    setContactToEdit({ info: null });
  };

  const deleteContact = async (index) => {
    const tmp = contactList.filter((key, idx) => idx !== index);
    await updateContactList(tmp);
    setContactList(tmp);
  };

  const updateContactModal = (index) => {
    setVisible(true);
    setContactToEdit({
      info: contactList.filter((key, idx) => idx === index),
      index
    });
  };

  const updateContact = async ({ addContact }) => {
    const tmp = contactList;
    tmp.splice(contactToEdit.index, 1, addContact);
    await updateContactList(tmp);
  };

  const deleteCompany = async () => {
    await dispatchAPI('DELETE', { url: `/companies/${id}` });

    dispatchConfigs({
      type: 'MODIF',
      modif: true
    });
    history.push({ pathname: '../../companies', state: { type } });
  };

  useEffect(() => {
    if (id) {
      getCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (contractsId) {
      fetchContractAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsId]);

  const { _id } = company;

  return (
    <>
      <PageHeaderCustom
        title={t('companies.showCompany.title')}
        extra={
          <ExtraButtonsCompany
            company={company}
            deleteCompany={deleteCompany}
            id={id}
            type={type}
          />
        }
      />
      <div>
        <AddContactModal
          contact={contactToEdit.info}
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          updateContact={updateContact}
          isLoading={isModalLoading}
        />
        <ContentCustom padding={false}>
          <div
            style={{
              padding: 24,
              position: 'relative',
              overflow: 'hidden',
              height: '100%',
              width: '100%'
            }}
          >
            <Skeleton loading={isLoading} active paragraph={{ rows: 4 }}>
              <DescriptionList data={companyList(company, t)} />
            </Skeleton>
            <UploadDrawer
              badgeCount={badgeCount}
              getResource={getCompany}
              resourceFiles={company.files}
              resourceId={id}
              resourceName="companies"
            />
          </div>
        </ContentCustom>
        <ContentCustom marginTop={8}>
          <CompanyContacts
            contactList={contactList}
            deleteContact={deleteContact}
            isLoading={isLoading}
            showModal={showModal}
            updateContactModal={updateContactModal}
          />
        </ContentCustom>
        {(company.type || []).includes('CLIENT') && (
          <SiteTable loading={isLoading} companyId={id} />
        )}
        {((company.type || []).includes('SUPPLIER') ||
          (company.type || []).includes('CLIENT')) && (
          <ContentCustom marginTop={8}>
            <RelatedItems
              assets={contractsAssets}
              company={_id}
              companyType={company.type}
              contracts={contracts && contracts}
              isLoading={isLoading}
            />
          </ContentCustom>
        )}
      </div>
    </>
  );
};

export default ShowCompany;
