import { message } from 'antd';
import moment from 'moment';

export const getCompanyNamesUtil = async (
  dispatchAPI,
  setCompanies,
  setRefinancers,
  setSuppliers,
  setLeasers,
  setClients
) => {
  try {
    const result = await dispatchAPI('GET', {
      url: '/companies'
    });
    result.data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
    setCompanies(
      result.data.map(({ _id, name }) => ({
        key: _id,
        value: _id,
        text: name
      }))
    );
    setRefinancers(
      result.data
        .filter((element) => element.type.includes('REFINANCER'))
        .map(({ _id, ...company }) => ({
          key: _id,
          value: _id,
          text: company.name
        }))
    );
    setSuppliers(
      result.data
        .filter((element) => element.type.includes('SUPPLIER'))
        .map(({ _id, ...company }) => ({
          ...company,
          key: _id,
          value: _id,
          text: company.name
        }))
    );
    setLeasers(
      result.data
        .filter((element) => element.type.includes('LEASER'))
        .map(({ _id, ...company }) => ({
          ...company,
          key: _id,
          value: _id,
          text: company.name
        }))
    );
    setClients(
      result.data
        .filter((element) => element.type.includes('CLIENT'))
        .map(({ _id, ...company }) => ({
          ...company,
          key: _id,
          value: _id,
          text: company.name
        }))
    );
  } catch (e) {
    message.error(e.message);
  }
};

export const setFieldsValueUtil = (VAT, totalAmount, setFieldsValue) => {
  if (VAT && totalAmount) {
    setFieldsValue({
      total_amount: (VAT / 100) * totalAmount + totalAmount
    });
  }
  if (VAT === 0) {
    setFieldsValue({
      total_amount: null,
      VAT: null
    });
  }
};
export const setEndDateUtil = (duration, startDate, setEndDate) => {
  if (duration && startDate) {
    setEndDate(
      moment(startDate)
        .add(duration - 1, 'month')
        .endOf('month')
    );
  }
};

export const spinStyle = {
  textAlign: 'center',
  borderRadius: '4px',
  marginBottom: '20px',
  padding: '30px 50px',
  margin: '20px 0',
  width: '100%',
  height: '100%'
};

export const createContractUtil = async (
  v,
  end_date,
  history,
  dispatchAPI,
  setLoading,
  setFieldsValue,
  setFields,
  t
) => {
  const body = {
    ...v,
    end_date
  };
  try {
    await dispatchAPI('POST', { url: '/contracts', body });
    history.push('/contracts');
  } catch (e) {
    setLoading(false);
    setFieldsValue(v);
    message.error(`${t('global.form.error.message')}`);
    if (e.response) {
      if (e.response.status === 406) {
        const { code, suggest } = JSON.parse(e.response.data.error.description);

        if (code === 'WRONG_NUMBER') {
          setFields({
            number: {
              value: suggest,
              errors: [
                new Error(t('contracts.form.errors.invalid_number')),
                suggest &&
                  new Error(
                    t('contracts.form.errors.suggest_number', { suggest })
                  )
              ]
            }
          });
        }
      }
    }
  }
};

export const updateContractUtil = async (
  v,
  end_date,
  history,
  dispatchAPI,
  setLoading,
  t,
  amendment,
  contract,
  id,
  purpose
) => {
  if (!amendment) {
    const body = {
      ...v,
      end_date
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/contracts/${id}`,
        body
      });
      history.goBack();
    } catch (e) {
      setLoading(false);
      message.error(`${t('global.form.error.message')}`);
    }
  } else {
    const { _id } = contract;
    const body = {
      ...v,
      contract: id,
      end_date,
      amendments: null,
      _id: undefined
    };
    const method = amendment && purpose === 'edit' ? 'PATCH' : 'POST';
    try {
      await dispatchAPI(method, {
        url: `/contracts${method === 'PATCH' ? `/${_id}` : ''}`,
        body
      });
      history.goBack();
    } catch (e) {
      setLoading(false);
      message.error(`${t('global.form.error.message')}`);
    }
  }
};

export const getContractUtil = async (
  dispatchAPI,
  setContract,
  setLoading,
  id
) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/contracts/${id}`
    });
    setContract(data);
  } catch (e) {
    if (e.response) {
      message.error('Wrong username or password.');
    } else if (e.request) {
      message.error('Network error. Please check your Internet connexion.');
    } else {
      message.error('Oops, something bad happens.');
    }
  }
  setLoading(false);
};

export const handleSubmitUtil = (
  validateFieldsAndScroll,
  setConfirmLoading,
  setLoading,
  updateContract,
  id,
  contract,
  createContract
) => {
  validateFieldsAndScroll(async (err) => {
    if (!err) {
      if (id) {
        setConfirmLoading(true);
        setLoading(true);
        await updateContract(contract);
      } else if (!id) {
        setLoading(true);
        setConfirmLoading(true);
        await createContract(contract);
      }
    }
  });
};
export const doneUtil = (
  validateFieldsAndScroll,
  contract,
  end_date,
  setIsVisible,
  setContract
) => {
  if (!end_date) {
    message.error('Start date is not valid');
  }

  validateFieldsAndScroll((err) => {
    if (!err && end_date) {
      setIsVisible(true);
      setContract({ ...contract, end_date, EUA: contract.EUA || false });
    }
  });
};

export const getEnumsUtil = async (dispatchAPI, setEnums) => {
  try {
    const result = await dispatchAPI('GET', { url: '/contracts/enums' });
    setEnums(result.data);
  } catch (e) {
    message.error(e.message);
  }
};
