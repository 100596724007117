import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Row, Input, Col, Dropdown, Menu, Upload, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import ExportButton from '../components/exportButton/ExportButton';
import { DataTable, PageHeaderCustom, ContentCustom } from '../components';

const { Search } = Input;

const ResourceLandingLayout = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  exportUrl,
  withCreateButton,
  forceRefresh,
  withSubRoutes,
  withUpload,
  withSearchBar,
  path,
  linkState,
  uploadProps,
  customMenu
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      history.push({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = (
    <Menu>
      {headers && (
        <Menu.Item>
          <ExportButton
            dataName={resourceName}
            headers={headers}
            url={`/${exportUrl || resourceName}`}
            fileName={`${resourceName}.csv`}
            populate={populate}
          />
        </Menu.Item>
      )}
      {withUpload && (
        <Menu.Item>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Upload showUploadList={false} {...uploadProps}>
            <Icon type="upload" />
            {` ${t('buttons.upload')}`}
          </Upload>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <PageHeaderCustom
        title={t(`${tradKey || resourceName}.title`)}
        withSubRoutes={withSubRoutes}
      />
      <ContentCustom>
        {(withSearchBar ||
          withCreateButton ||
          headers ||
          withUpload ||
          customMenu) && (
          <Row type="flex" justify="space-between" gutter={[8, 16]}>
            <Col>
              {withSearchBar && (
                <Search
                  allowClear
                  placeholder={t('placeholder.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onSearch={(value) => searchResource(value)}
                  style={{ width: 250 }}
                />
              )}
            </Col>
            <Col>
              <Row type="flex" justify="end">
                {withCreateButton && (
                  <Link
                    to={
                      linkState
                        ? {
                            pathname: `${pathname}/create`,
                            state: linkState
                          }
                        : `${pathname}/create`
                    }
                  >
                    <Button type="primary">
                      {`${t('buttons.create')} `}
                      <Icon type="plus" />
                    </Button>
                  </Link>
                )}
                {(headers || withUpload || customMenu) && (
                  <div style={{ margin: '4px 16px' }}>
                    <Dropdown
                      overlay={customMenu || menu}
                      overlayStyle={{ minWidth: 200 }}
                      placement="bottomRight"
                    >
                      <Icon
                        type="menu"
                        style={{ fontSize: 22, cursor: 'pointer' }}
                      />
                    </Dropdown>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        )}
        {children && <Row gutter={[8, 16]}>{children}</Row>}
        <DataTable
          style={{ marginTop: 24 }}
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          path={path}
          linkState={linkState}
        />
      </ContentCustom>
    </>
  );
};

ResourceLandingLayout.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  withSubRoutes: PropTypes.bool,
  withUpload: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  path: PropTypes.string,
  linkState: PropTypes.shape({}),
  uploadProps: PropTypes.shape({}),
  customMenu: PropTypes.element
};

ResourceLandingLayout.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  dataToFetch: null,
  forceRefresh: null,
  withSubRoutes: false,
  withUpload: false,
  withSearchBar: true,
  path: null,
  linkState: null,
  uploadProps: null,
  customMenu: null
};

export default ResourceLandingLayout;
