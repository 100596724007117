import React, { useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Icon,
  Select,
  Table,
  Tag,
  Typography
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { tailFormItemLayout } from '../../utils/formLayouts';
import { assetsStatusColor } from '../../utils';
import FilterDropdown from './FilterDropdown';

import { ContentCustom } from '../../components';

const { Title, Text } = Typography;
const { Column } = Table;

const { Option } = Select;
const AssetsPart = ({
  id,
  handleMultipleSelect,
  handleChange,
  handleSearch,
  allAssets,
  isTableLoading,
  pagination,
  assets,
  selectAllAssets,
  assetsEnumValues,
  filteredInfo,
  isLoading,
  selectAssets,
  serialNumber
}) => {
  const [searchInput, setSearchInput] = useState(null);

  const history = useHistory();
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const { t } = useTranslation();
  return (
    <ContentCustom marginTop={8}>
      <Title level={2}>{t(`tickets.create.assetList`)}</Title>
      <Divider orientation="left">
        <Text>{t('tickets.create.selectAssets')}</Text>
      </Divider>
      <Select
        mode="tags"
        onChange={(value) => handleMultipleSelect(value)}
        tokenSeparators={[',']}
        optionFilterProp="label"
        value={serialNumber}
      >
        {allAssets.map((a) => (
          <Option key={a.serial_number}>{`${a.serial_number}`}</Option>
        ))}
      </Select>
      <Divider orientation="left">
        <Text>{t('tickets.create.orTableSelect')}</Text>
      </Divider>
      <Table
        dataSource={allAssets}
        loading={isTableLoading}
        onChange={handleChange}
        pagination={pagination}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) =>
            selectAssets(selectedRowKeys, selectedRows),
          selectedRowKeys: assets,
          onSelectAll: (selected, selectedRows, changeRows) =>
            selectAllAssets(selected, selectedRows, changeRows)
        }}
      >
        <Column
          title={t(`assets.list.column.city`)}
          dataIndex="city"
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
          }) => (
            <FilterDropdown
              setSearchInput={setSearchInput}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              handleSearch={handleSearch}
              handleReset={handleReset}
              clearFilters={clearFilters}
              confirm={confirm}
              placeholder="Ville"
              refSearch="city"
            />
          )}
          filterIcon={(filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#1890ff' : undefined }}
            />
          )}
          onFilterDropdownVisibleChange={(filterVisible) => {
            if (filterVisible) {
              setTimeout(() => searchInput.select());
            }
          }}
        />
        <Column
          title={t(`assets.list.column.EasyVista_request_serial_number`)}
          dataIndex="EasyVista_request_serial_number"
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
          }) => (
            <FilterDropdown
              setSearchInput={setSearchInput}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              handleSearch={handleSearch}
              handleReset={handleReset}
              clearFilters={clearFilters}
              confirm={confirm}
              placeholder="Numéro EasyVista"
              refSearch="EasyVista_request_serial_number"
            />
          )}
          filterIcon={(filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#1890ff' : undefined }}
            />
          )}
          onFilterDropdownVisibleChange={(filterVisible) => {
            if (filterVisible) {
              setTimeout(() => searchInput.select());
            }
          }}
          render={(EasyVista_request_serial_number) =>
            EasyVista_request_serial_number
          }
        />
        <Column
          title={t(`assets.list.column.serial_number`)}
          dataIndex="serial_number"
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
          }) => (
            <FilterDropdown
              setSearchInput={setSearchInput}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              handleSearch={handleSearch}
              handleReset={handleReset}
              clearFilters={clearFilters}
              confirm={confirm}
              placeholder="N° de série"
              refSearch="serial_number"
            />
          )}
          filterIcon={(filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#1890ff' : undefined }}
            />
          )}
          onFilterDropdownVisibleChange={(filterVisible) => {
            if (filterVisible) {
              setTimeout(() => searchInput.select());
            }
          }}
          render={(serial_number) => serial_number}
        />
        <Column title="Description" dataIndex="description" />
        <Column
          title={t(`assets.list.column.status`)}
          dataIndex="status"
          render={(status) => (
            <Tag color={assetsStatusColor[status]}>{status}</Tag>
          )}
          filters={
            assetsEnumValues.status &&
            assetsEnumValues.status.map((enumValue) => ({
              text: enumValue,
              value: enumValue
            }))
          }
          filteredValue={filteredInfo.status || null}
        />
      </Table>
      <Title level={4} style={{ marginTop: 10, float: 'right' }}>
        {`${t('tickets.totalAssetsSelected')}: ${
          Object.entries(assets).length
        }`}
      </Title>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Form.Item style={{ marginTop: 50 }} {...tailFormItemLayout}>
        <Button loading={isLoading} type="primary" htmlType="submit">
          {t(`tickets.form.button.${id ? 'edit' : 'create'}`)}
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          type="danger"
          onClick={() => {
            history.goBack();
          }}
        >
          {t('tickets.form.cancel')}
        </Button>
      </Form.Item>
    </ContentCustom>
  );
};

AssetsPart.propTypes = {
  id: PropTypes.string.isRequired,
  handleMultipleSelect: PropTypes.func.isRequired,
  tailFormItemLayout: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  allAssets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  assets: PropTypes.arrayOf().isRequired,
  selectAllAssets: PropTypes.func.isRequired,
  assetsEnumValues: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  filteredInfo: PropTypes.shape({
    status: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectAssets: PropTypes.func.isRequired
};

export default AssetsPart;
