import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import { reportTypes } from '../../utils';

const ReportsTable = ({ reports }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();

  const downloadResource = async (id, name) => {
    const result = await dispatchAPI('GET', {
      responseType: 'blob',
      url: `files/${id}`
    });
    const url = URL.createObjectURL(result.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <Table
        dataSource={reports.map(({ _id, ...report }) => ({
          ...report,
          key: _id
        }))}
        columns={[
          {
            title: t('tickets.showTicket.reports.fileName'),
            key: 'originalName',
            // eslint-disable-next-line react/prop-types
            render: ({ key, metadata: { originalName } }) => (
              <Button
                onClick={() => downloadResource(key, originalName)}
                type="link"
              >
                {originalName}
              </Button>
            )
          },
          {
            title: t('tickets.showTicket.reports.type'),
            dataIndex: 'type',
            filters: [
              { text: t(`tickets.tags.ERASURE`), value: 'ERASURE' },
              { text: t(`tickets.tags.DESTRUCTION`), value: 'DESTRUCTION' }
            ],
            onFilter: (value, { type }) => type.indexOf(value) === 0,
            render: (type) => (
              <Tag color={reportTypes[type]}>{t(`tickets.tags.${type}`)}</Tag>
            )
          },
          {
            title: t('tickets.showTicket.reports.uploadDate'),
            dataIndex: 'uploadDate',
            render: (date) => moment(date).format('L')
          }
        ]}
      />
    </div>
  );
};

ReportsTable.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default ReportsTable;
