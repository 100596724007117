import React from 'react';
import { Icon, Spin, notification } from 'antd';
import { LanguageContextProvider } from '../contexts/LanguageContext';
import { AuthContextProvider } from '../contexts/AuthContext';
import { ThemeContextProvider } from '../contexts/ThemeContext';
import { ConfigsContextProvider } from '../contexts/ConfigsContext';
import Router from '../routes/Router';

Spin.setDefaultIndicator(<Icon type="loading" spin />);
notification.config({ placement: 'bottomRight' });

const App = () => (
  <LanguageContextProvider>
    <ThemeContextProvider>
      <AuthContextProvider>
        <ConfigsContextProvider>
          <Router />
        </ConfigsContextProvider>
      </AuthContextProvider>
    </ThemeContextProvider>
  </LanguageContextProvider>
);

export default App;
