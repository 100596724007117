import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { userRoleColor } from '../../utils';

const userList = (data, t) => {
  const {
    first_name,
    role,
    civility,
    last_name,
    company,
    email,
    phone_number,
    landline_phone_number,
    registration_date
  } = data;

  return [
    {
      label: t('users.showUser.role'),
      span: 3,
      content: <Tag color={userRoleColor[role]}>{role}</Tag> || '-'
    },
    {
      label: t('users.showUser.civility'),
      span: 3,
      content: t(`users.showUser.gender.${civility}`) || '-'
    },
    {
      label: t('users.showUser.lastName'),
      span: 1,
      content: last_name || '-'
    },
    {
      label: t('users.showUser.firstName'),
      span: 2,
      content: first_name || '-'
    },
    {
      label: t('users.showUser.company'),
      span: 3,
      content: company ? company.name : '-'
    },
    {
      label: t('users.showUser.email'),
      span: 1,
      content: email || '-'
    },
    {
      label: t('users.showUser.phoneNumber'),
      span: 1,
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: t('users.showUser.landlinePhoneNumber'),
      span: 1,
      content:
        landline_phone_number && landline_phone_number.number ? (
          <div>
            {`${
              landline_phone_number.country_code
            } ${landline_phone_number.number.slice(
              0,
              1
            )} ${landline_phone_number.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: t('users.showUser.registration_date'),
      span: 3,
      content: registration_date ? moment(registration_date).format('LL') : '-'
    }
  ];
};

export default userList;
