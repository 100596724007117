import React from 'react';
import { useTranslation } from 'react-i18next';
import PVForm from './PVForm';
import { PageHeaderCustom } from '../../components';

const EditAndCreate = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t(`pvs.create.pvInfo`)} />
      <PVForm />
    </>
  );
};

export default EditAndCreate;
