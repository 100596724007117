import React from 'react';
import { Icon, Radio, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ExtraOptions = ({ filter, handleFilterChange, reset }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ marginTop: 8 }}>
      <Row>
        <Text strong style={{ marginBottom: 10, marginLeft: 8 }}>
          {t(`header.menu.filteredTab.invoicesFilter`)}
        </Text>
        <Icon style={{ marginLeft: 4 }} type="undo" onClick={reset} />
      </Row>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.status}
        onChange={(e) => handleFilterChange('status', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&status=OPEN">
          {t(`header.menu.filteredTab.openedTickets`)}
        </Radio.Button>
        <Radio.Button value="&status=WAITING">
          {t(`header.menu.filteredTab.waitingTickets`)}
        </Radio.Button>
        <Radio.Button value="&status=IN_PROGRESS">
          {t(`header.menu.filteredTab.inprogressTickets`)}
        </Radio.Button>
        <Radio.Button value="&status=AUDITED">
          {t(`header.menu.filteredTab.auditedTickets`)}
        </Radio.Button>
        <Radio.Button value="&status=REFUSED_BY_INSURER">
          {t(`header.menu.filteredTab.refusedTickets`)}
        </Radio.Button>
        <Radio.Button value="&status=CLOSED">
          {t(`header.menu.filteredTab.closedTickets`)}
        </Radio.Button>
        <Radio.Button value="&status=DELETED">
          {t(`header.menu.filteredTab.deletedTickets`)}
        </Radio.Button>
      </Radio.Group>
      <br />
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.type}
        onChange={(e) => handleFilterChange('type', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&type=COLLECTION">
          {t(`header.menu.filteredTab.collectionTickets`)}
        </Radio.Button>
        <Radio.Button value="&type=THEFT">
          {t(`header.menu.filteredTab.theftTickets`)}
        </Radio.Button>
        <Radio.Button value="&type=LOST">
          {t(`header.menu.filteredTab.lostTickets`)}
        </Radio.Button>
        <Radio.Button value="&type=DAMAGE">
          {t(`header.menu.filteredTab.damageTickets`)}
        </Radio.Button>
        <Radio.Button value="&type=DELIVERY">
          {t(`header.menu.filteredTab.deliveryTickets`)}
        </Radio.Button>
      </Radio.Group>
      <br />
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.priority}
        onChange={(e) => handleFilterChange('priority', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&priority=NORMAL">
          {t(`header.menu.filteredTab.normalTickets`)}
        </Radio.Button>
        <Radio.Button value="&priority=HIGH">
          {t(`header.menu.filteredTab.highTickets`)}
        </Radio.Button>
        <Radio.Button value="&priority=URGENT">
          {t(`header.menu.filteredTab.urgentTickets`)}
        </Radio.Button>
      </Radio.Group>
    </Row>
  );
};

ExtraOptions.propTypes = {
  filter: PropTypes.shape({
    status: PropTypes.string,
    type: PropTypes.string,
    priority: PropTypes.string
  }),
  handleFilterChange: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

ExtraOptions.defaultProps = {
  filter: null
};

export default ExtraOptions;
