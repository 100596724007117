export const filtersInitialState = {
  provider: null,
  device: [],
  subsidiaries: []
};

export const filtersReducer = (state, { type, payload }) => {
  switch (type) {
    case 'add':
      return {
        ...state,
        ...payload,
        ...(payload.provider && state.provider === payload.provider
          ? { provider: '' }
          : {})
      };
    case 'reset':
      return { ...filtersInitialState, provider: state.provider };
    default:
      return filtersInitialState;
  }
};
