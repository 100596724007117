import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { tailFormItemLayout } from '../../utils/formLayouts';

const Buttons = ({ addArticleField, isLoading, id }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Form.Item {...tailFormItemLayout}>
        <Button type="dashed" onClick={addArticleField} icon="plus">
          {t('orders.form.addArticle.addOrderButton')}
        </Button>
      </Form.Item>
      <Form.Item style={{ marginTop: 50 }} {...tailFormItemLayout}>
        <Button loading={isLoading} type="primary" htmlType="submit">
          {t(`pvs.form.button.${id ? 'edit' : 'create'}`)}
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          type="danger"
          onClick={() => {
            history.goBack();
          }}
        >
          {t('pvs.form.button.cancel')}
        </Button>
      </Form.Item>
    </>
  );
};

Buttons.propTypes = {
  addArticleField: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
};

export default Buttons;
