import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import EditAndCreateContractFormBody from './EditAndCreateContractFormBody';
import { useStateWithLocalStorage } from '../../utils';
import f_steps from './steps';
import {
  createContractUtil,
  doneUtil,
  getCompanyNamesUtil,
  getContractUtil,
  getEnumsUtil,
  handleSubmitUtil,
  setEndDateUtil,
  setFieldsValueUtil,
  updateContractUtil
} from './utils';

const EditAndCreateContractForm = ({
  values,
  id,
  amendment,
  purpose,
  history,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldValue,
    setFieldsValue,
    setFields
  }
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [enums, setEnums] = useState(null);
  const [contract, setContract] = useState({});
  const [companies, setCompanies] = useState(null);
  const [newCompanyType, setNewCompanyType] = useState(null);
  const [refinancers, setRefinancers] = useState([]);
  const [end_date, setEndDate] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [leasers, setLeasers] = useState([]);
  const [clients, setClients] = useState([]);
  const [companyName, setCompanyName] = useState(null);
  const [disabling, setDisabling] = useState({ amountEUADisable: true });
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const user = useStateWithLocalStorage('user');
  const VAT = getFieldValue('VAT');
  const totalAmount = getFieldValue('total_amount_excluding_taxes');
  const startDate = contract.start_date;
  const duration = getFieldValue('duration');
  const EUA = contract.EUA || getFieldValue('EUA');

  const getContract = () => {
    getContractUtil(dispatchAPI, setContract, setLoading, id);
  };

  useEffect(() => {
    setFieldsValueUtil(VAT, totalAmount, setFieldsValue);
  }, [VAT, totalAmount]);

  useEffect(() => {
    setEndDateUtil(duration, startDate, setEndDate);
  }, [duration, startDate]);

  useEffect(() => {
    setDisabling({ amountEUADisable: !EUA });
  }, [EUA]);

  const createContract = useCallback(
    (v) => {
      createContractUtil(
        v,
        end_date,
        history,
        dispatchAPI,
        setLoading,
        setFieldsValue,
        setFields,
        t
      );
    },
    [dispatchAPI, end_date, history, setFields, setFieldsValue, t]
  );

  const updateContract = (v) => {
    updateContractUtil(
      v,
      end_date,
      history,
      dispatchAPI,
      setLoading,
      t,
      amendment,
      contract,
      id,
      purpose
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitUtil(
      validateFieldsAndScroll,
      setConfirmLoading,
      setLoading,
      updateContract,
      id,
      contract,
      createContract
    );
  };

  const getEnums = () => {
    getEnumsUtil(dispatchAPI, setEnums);
  };

  const handleCompanyCreated = async (name) => {
    setVisible(false);
    await getCompanyNamesUtil(
      dispatchAPI,
      setCompanies,
      setRefinancers,
      setSuppliers,
      setLeasers,
      setClients
    );
    setCompanyName({ name, type: newCompanyType });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      (async () => {
        await getContract();
      })();
    }
    (async () => {
      await getEnums();
      await getCompanyNamesUtil(
        dispatchAPI,
        setCompanies,
        setRefinancers,
        setSuppliers,
        setLeasers,
        setClients
      );
    })();
  }, []);

  const done = () => {
    doneUtil(
      validateFieldsAndScroll,
      contract,
      end_date,
      setIsVisible,
      setContract
    );
  };

  useEffect(() => {
    const [field] = Object.values(values);
    if (field) {
      const { name, value } = field;
      setContract({ ...contract, [name]: value });
    }
  }, [values]);

  const steps = f_steps(
    enums,
    contract,
    suppliers,
    refinancers,
    leasers,
    companyName,
    t,
    amendment,
    disabling,
    getFieldDecorator,
    setNewCompanyType,
    setVisible
  );

  return (
    <EditAndCreateContractFormBody
      isVisible={isVisible}
      handleSubmit={handleSubmit}
      confirmLoading={confirmLoading}
      setIsVisible={setIsVisible}
      companies={companies}
      contract={contract}
      setVisible={setVisible}
      newCompanyType={newCompanyType}
      visible={visible}
      handleCompanyCreated={handleCompanyCreated}
      isLoading={isLoading}
      user={user}
      steps={steps}
      clients={clients}
      done={done}
      getFieldDecorator={getFieldDecorator}
    />
  );
};

EditAndCreateContractForm.defaultProps = {
  id: null,
  history: null
};

EditAndCreateContractForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired
  }).isRequired,
  id: PropTypes.string,
  amendment: PropTypes.bool,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  values: PropTypes.shape({}),
  purpose: PropTypes.string
};

EditAndCreateContractForm.defaultProps = {
  amendment: false,
  values: {},
  purpose: null
};

const WrappedEditAndCreateContractAntForm = Form.create({
  name: 'contracts',
  onFieldsChange: (props, changedFields) => {
    props.onChange(changedFields);
  }
})(EditAndCreateContractForm);

export default WrappedEditAndCreateContractAntForm;
