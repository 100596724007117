/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import numeral from 'numeral';
import orias from '../../assets/images/orias.png';
import halfGlove from '../../assets/images/halfglove.png';
import { styles } from './utils';
import HeaderInvoiceExport from './HeaderInvoiceExport';
import TableHeaderForInvoiceExport from './TableHeaderForInvoiceExport';
import TableBodyForInvoiceExport from './TableBodyForInvoiceExport';
import TableFooterForInvoiceExport from './TableFooterForInvoiceExport';
import FooterInvoiceExport from './FooterInvoiceExport';

const ShowInvoiceExport = ({ invoice, contract }) => {
  const [articleRowArray, setarticleRowArray] = useState(Array.from(Array(7)));
  const [state, setstate] = useState(false);
  useEffect(() => {
    if (invoice && invoice.articles) {
      const temp = new Array(7);
      invoice.articles.forEach((element, index, array) => {
        temp[index] = element;
        if (index === array.length - 1) {
          setarticleRowArray([...temp]);
          setstate(true);
        }
      });
    }
  }, []);

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.pageContent}>
          <Image
            src={halfGlove}
            style={{
              height: undefined,
              width: undefined,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0
            }}
          />

          <View style={styles.pageTable}>
            <HeaderInvoiceExport invoice={invoice} contract={contract} />
            <View style={{ flex: 10 }}>
              <View style={{ marginBottom: 10, marginTop: 10 }}>
                <TableHeaderForInvoiceExport />
                {(articleRowArray || Array.from(Array(7))).map(
                  (article, index) => {
                    return (
                      <TableBodyForInvoiceExport
                        article={article}
                        index={index}
                      />
                    );
                  }
                )}

                <View
                  style={{
                    flex: 1,
                    alignSelf: 'stretch',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      flex: 2,
                      justifyContent: 'flex-end',
                      borderLeftWidth: 1,
                      borderTopWidth: 0,
                      borderBottomWidth: 1
                    }}
                  >
                    <Text style={styles.tableContent} />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'flex-end',
                      borderLeftWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 1
                    }}
                  >
                    <Text style={styles.tableContent} />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'flex-end',
                      borderLeftWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 1
                    }}
                  >
                    <Text style={styles.tableContent} />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'flex-end',
                      borderLeftWidth: 0.5,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0.5
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 9,
                        textAlign: 'center'
                      }}
                    >
                      Total HT
                    </Text>
                  </View>

                  <View
                    style={{
                      flex: 2,
                      justifyContent: 'flex-end',
                      borderLeftWidth: 0.5,
                      borderRightWidth: 1,
                      borderTopWidth: 0,
                      borderBottomWidth: 0.5
                    }}
                  >
                    <Text style={styles.tableContentRight}>
                      {numeral(invoice.total_excluding_taxes || 0).format(
                        '0,0.00'
                      )}
                    </Text>
                  </View>
                </View>
                <TableFooterForInvoiceExport invoice={invoice} />
                <View
                  style={{
                    flex: 1,
                    alignSelf: 'stretch',
                    flexDirection: 'row'
                  }}
                >
                  <View style={{ flex: 2, justifyContent: 'flex-end' }}>
                    <Image
                      style={{
                        width: '126,4cm',
                        height: '38,1cm'
                      }}
                      src={orias}
                    />
                  </View>

                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Text
                      style={{
                        textAlign: 'left',
                        color: 'green',
                        fontSize: 11
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>

          <FooterInvoiceExport />
        </View>
      </Page>
    </Document>
  );
};

ShowInvoiceExport.Default = {
  invoice: {
    articles: [],
    total_excluding_taxes: 0,
    vat: 0,
    sales: 0
  }
};

ShowInvoiceExport.propTypes = {
  invoice: PropTypes.shape({
    number: PropTypes.string,
    label: PropTypes.string,
    from: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        additional: PropTypes.string,
        postal_code: PropTypes.string,
        city: PropTypes.string
      })
    }),
    to: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        additional: PropTypes.string,
        postal_code: PropTypes.string,
        city: PropTypes.string
      })
    }),
    articles: PropTypes.array,
    total_excluding_taxes: PropTypes.number,
    VAT: PropTypes.number,
    sales: PropTypes.number,
    payment_date: PropTypes.string,
    emission_date: PropTypes.string,
    total: PropTypes.number
  }).isRequired,
  contract: PropTypes.shape({}).isRequired
};

export default ShowInvoiceExport;
