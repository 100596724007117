import React, { useEffect, useState } from 'react';
import {
  Icon,
  Modal,
  Steps,
  Typography,
  Select,
  message,
  Button,
  DatePicker,
  Upload,
  Tooltip,
  notification
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const { Title } = Typography;

export const RawInvoiceModal = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  const { dispatchAPI, token } = useAuthContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [companiesIsLoading, setCompaniesIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState();
  const [provider, setProvider] = useState();
  const [month, setMonth] = useState();
  const [files, setFiles] = useState({});

  const fetchCompanies = async () => {
    setCompaniesIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
      setCompanies(
        data.map(({ _id, name, type: companyType }) => ({
          _id,
          name,
          type: companyType
        }))
      );
    } catch (e) {
      message.error(e.message);
    }
    setCompaniesIsLoading(false);
  };

  useEffect(() => {
    if (visible) {
      (async () => {
        await fetchCompanies();
      })();
    }
  }, [visible]);

  const beforeUpload = (fileList, fileType) => {
    setFiles({ ...files, [fileType]: [...fileList] });
    return false;
  };

  const onRemoveFile = (file, fileType) => {
    const newFileList = files[fileType].filter((f) => f.uid !== file.uid);
    if (newFileList.length) {
      setFiles({
        ...files,
        [fileType]: [...newFileList]
      });
    } else {
      setFiles({
        ...files,
        [fileType]: undefined
      });
    }
  };

  const steps = [
    {
      title: 'Client',
      icon: <Icon type="user" />,
      content: (
        <>
          <Title level={4}>À qui sont addressées ces factures?</Title>
          <Select
            loading={companiesIsLoading}
            value={company}
            onChange={(value) => setCompany(value)}
            style={{ width: 200, marginTop: 16 }}
          >
            {companies
              .filter(({ type: companyType }) => companyType.includes('CLIENT'))
              .map(({ _id, name }) => (
                <Select.Option key={_id} value={_id}>
                  {name}
                </Select.Option>
              ))}
          </Select>
        </>
      )
    },
    {
      title: 'Fournisseur',
      icon: <Icon type="phone" />,
      content: (
        <>
          <Title level={4}>Quel est le fournisseur?</Title>
          <Select
            value={provider}
            onChange={(value) => {
              setProvider(value);
              setFiles({});
            }}
            style={{ width: 200, marginTop: 16 }}
          >
            {['Bouygues', 'Orange', 'SFR'].map((p) => (
              <Select.Option key={p} value={p}>
                {p}
              </Select.Option>
            ))}
          </Select>
        </>
      )
    },
    {
      title: 'Période',
      icon: <Icon type="calendar" />,
      content: (
        <>
          <Title level={4}>Quel est le mois concerné?</Title>
          <DatePicker.MonthPicker
            allowClear={false}
            value={month}
            onChange={(date) => setMonth(date)}
          />
        </>
      )
    },
    {
      title: 'Factures',
      icon: <Icon type="euro" />,
      content: (
        <>
          <Title level={4}>Import des factures</Title>
          {provider === 'SFR' && (
            <Upload
              beforeUpload={(_, fileList) => beforeUpload(fileList, 'invoices')}
              accept=".txt,.csv"
              name="invoices"
              multiple
              fileList={files.invoices || []}
              onRemove={(file) => onRemoveFile(file, 'invoices')}
            >
              <Tooltip title="XXXXXXXXXX_XXXXXXXXXXXX_3.csv">
                <Button icon="upload">Factures</Button>
              </Tooltip>
            </Upload>
          )}
          {provider === 'Bouygues' && (
            <>
              <div style={{ marginBottom: 16 }}>
                <Upload
                  beforeUpload={(_, fileList) =>
                    beforeUpload(fileList, 'detailed_invoices')
                  }
                  accept=".txt,.csv"
                  name="detailed_invoices"
                  multiple
                  fileList={files.detailed_invoices || []}
                  onRemove={(file) => onRemoveFile(file, 'detailed_invoices')}
                >
                  <Tooltip title="facture_utilisateurs_liste_YYYYMM.csv">
                    <Button icon="upload">Factures détaillées</Button>
                  </Tooltip>
                </Upload>
              </div>
              <div style={{ marginBottom: 16 }}>
                <Upload
                  beforeUpload={(_, fileList) =>
                    beforeUpload(fileList, 'global_invoices')
                  }
                  accept=".txt,.csv"
                  name="global_invoices"
                  multiple
                  fileList={files.global_invoices || []}
                  onRemove={(file) => onRemoveFile(file, 'global_invoices')}
                >
                  <Tooltip title="Facture_List_YYYYMM.csv">
                    <Button icon="upload">Factures globales</Button>
                  </Tooltip>
                </Upload>
              </div>
              <div>
                <Upload
                  beforeUpload={(_, fileList) =>
                    beforeUpload(fileList, 'fleet')
                  }
                  accept=".txt,.csv"
                  name="fleet"
                  multiple
                  fileList={files.fleet || []}
                  onRemove={(file) => onRemoveFile(file, 'fleet')}
                >
                  <Tooltip title="Bouygues.Parc.MM.YYYY.csv">
                    <Button icon="upload">Flottes Telecom</Button>
                  </Tooltip>
                </Upload>
              </div>
            </>
          )}
          {provider === 'Orange' && (
            <>
              <div style={{ marginBottom: 16 }}>
                <Upload
                  beforeUpload={(_, fileList) =>
                    beforeUpload(fileList, 'detailed_invoices')
                  }
                  accept=".txt,.csv"
                  name="detailed_invoices"
                  multiple
                  fileList={files.detailed_invoices || []}
                  onRemove={(file) => onRemoveFile(file, 'detailed_invoices')}
                >
                  <Tooltip title="DonneesPersoFacturesMMMMMobile.csv &amp; DonneesPersoFacturesMMMMTousDomaines.csv">
                    <Button icon="upload">Factures détaillées</Button>
                  </Tooltip>
                </Upload>
              </div>
              <div>
                <Upload
                  beforeUpload={(_, fileList) =>
                    beforeUpload(fileList, 'fleet')
                  }
                  accept=".txt,.csv"
                  name="fleet"
                  multiple
                  fileList={files.fleet || []}
                  onRemove={(file) => onRemoveFile(file, 'fleet')}
                >
                  <Tooltip title="DonneesPersoParcMMMMMobile.csv &amp; DonneesPersoParcMMMMTousDomaines.csv">
                    <Button icon="upload">Factures globales</Button>
                  </Tooltip>
                </Upload>
              </div>
            </>
          )}
        </>
      )
    }
  ];

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const reset = () => {
    setCompany(undefined);
    setProvider(undefined);
    setMonth(undefined);
    setFiles({});
    setCurrentStep(0);
  };

  const upload = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('provider', provider);
    formData.append('month', month.format('YYYY-MM'));
    formData.append('company', company);
    formData.append('import_url', process.env.REACT_APP_API_URL);
    Object.entries(files).forEach(([fileType, fls]) => {
      fls.forEach((file) => {
        formData.append(fileType, file);
      });
    });
    try {
      const result = await fetch(
        `${process.env.REACT_APP_AGGREGATOR_URL}/aggregate`,
        {
          method: 'POST',
          body: formData,
          headers: new Headers({ Authorization: token })
        }
      );
      if (result.ok) {
        reset();
        onCancel();
        notification.open({
          description: t(`notification.IMPORT_PROGRESS`),
          message: 'Import',
          key: 'import',
          duration: 0,
          icon: <Icon type="loading" spin style={{ color: '#1890ff' }} />
        });
      } else {
        message.error('400');
      }
    } catch (e) {
      message.error(e.message);
    }
    setIsLoading(false);
  };

  const isNextDisabled = () => {
    if (currentStep === 0 && !company) return true;
    if (currentStep === 1 && !provider) return true;
    if (currentStep === 2 && !month) return true;
    return false;
  };

  const isDoneDisabled = () => {
    if (provider === 'SFR' && !files.invoices) return true;
    if (
      provider === 'Bouygues' &&
      (!files.global_invoices || !files.detailed_invoices || !files.fleet)
    )
      return true;
    if (provider === 'Orange' && (!files.detailed_invoices || !files.fleet))
      return true;
    return false;
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        reset();
        onCancel();
      }}
      title="Import factures Telecom brutes"
      width={600}
      footer={
        <div>
          {currentStep > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={prev}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={next} disabled={isNextDisabled()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button
              loading={isLoading}
              type="primary"
              onClick={() => upload()}
              disabled={isDoneDisabled()}
            >
              Done
            </Button>
          )}
        </div>
      }
    >
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Steps.Step icon={item.icon} key={item.title} title={item.title} />
        ))}
      </Steps>
      <div style={{ margin: 24 }}>{steps[currentStep].content}</div>
    </Modal>
  );
};

RawInvoiceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};
