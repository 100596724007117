import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, InputNumber, Button } from 'antd';
import PropTypes from 'prop-types';

const AmountManagerForm = ({
  invoice,
  getFieldDecorator,
  tailFormItemLayout,
  addArticleField,
  invoiceIsLoading,
  type
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item {...tailFormItemLayout}>
        <Button type="dashed" onClick={addArticleField} icon="plus">
          {t('invoices.form.addArticle.addInvoiceButton')}
        </Button>
      </Form.Item>
      <Form.Item label={t('invoices.form.total_quantity')}>
        {getFieldDecorator('total_quantity', {
          initialValue: invoice && invoice.total_quantity,
          rules: [
            {
              type: 'number' || 'float',
              message: t(`global.form.typeMessage`)
            },
            {
              required: true,
              message: t(`global.form.requiredMessage`)
            }
          ]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label={t('invoices.form.total_excluding_taxes')}>
        {getFieldDecorator('total_excluding_taxes', {
          initialValue: invoice && invoice.total_excluding_taxes,
          rules: [
            {
              type: 'number' || 'float',
              message: t(`global.form.typeMessage`)
            },
            {
              required: true,
              message: t(`global.form.requiredMessage`)
            }
          ]
        })(<InputNumber style={{ width: 150 }} />)}
        {` €`}
      </Form.Item>
      <Form.Item label={t('invoices.form.sales')}>
        {getFieldDecorator('sales', {
          initialValue: invoice && invoice.sales,
          rules: [
            {
              type: 'number' || 'float',
              message: t(`global.form.typeMessage`)
            }
          ]
        })(<InputNumber style={{ width: 150 }} />)}
        {` €`}
      </Form.Item>
      <Form.Item label={t('invoices.form.VAT')}>
        {getFieldDecorator('VAT', {
          initialValue: invoice && invoice.VAT,
          rules: [
            {
              type: 'number' || 'float',
              message: t(`global.form.typeMessage`)
            },
            {
              required: true,
              message: t(`global.form.requiredMessage`)
            }
          ]
        })(<InputNumber style={{ width: 150 }} />)}
        {` €`}
      </Form.Item>
      <Form.Item label={t('invoices.form.total')}>
        {getFieldDecorator('total', {
          initialValue: invoice && invoice.total,
          rules: [
            {
              type: 'number' || 'float',
              message: t(`global.form.typeMessage`)
            },
            {
              required: true,
              message: t(`global.form.requiredMessage`)
            }
          ]
        })(<InputNumber style={{ width: 150 }} />)}
        {` €`}
      </Form.Item>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={invoiceIsLoading}>
          {t('invoices.form.submitButton')}
        </Button>
        <Link to={`/invoices/${type}`}>
          <Button type="danger" style={{ margin: '0 10px' }}>
            {t('invoices.form.cancelButton')}
          </Button>
        </Link>
      </Form.Item>
    </>
  );
};

AmountManagerForm.propTypes = {
  invoice: PropTypes.shape({
    total_quantity: PropTypes.number,
    total: PropTypes.number,
    total_excluding_taxes: PropTypes.number,
    VAT: PropTypes.number,
    sales: PropTypes.number
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  tailFormItemLayout: PropTypes.shape({}).isRequired,
  addArticleField: PropTypes.func.isRequired,
  invoiceIsLoading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
};

const WrappedAmountManagerForm = Form.create({
  name: 'amountManagerForm'
})(AmountManagerForm);

export default WrappedAmountManagerForm;
