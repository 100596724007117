import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Input,
  message,
  Row,
  Spin,
  Typography,
  Upload
} from 'antd';

import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const { Paragraph } = Typography;

const Theme = ({
  configs,
  setConfigs,
  getConfig,
  configId,
  loading,
  setLoading,
  logoIsLoading,
  urlLogo,
  sidebar_color
}) => {
  const { t } = useTranslation();

  const { dispatchAPI } = useAuthContext();

  const handleColorChange = (e) => {
    setConfigs({ ...configs, sidebar_color: `${e.target.value}` });
  };

  const srcToFile = (src, fileName, mimeType) => {
    return fetch(src)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], fileName, { type: mimeType });
      })
      .then(async (file) => {
        const formData = new FormData();
        formData.append('logo', file);
        try {
          const result = await dispatchAPI('PATCH', {
            url: `client-configs/${configId}`,
            body: formData
          });
          if (result.status === 200) {
            message.success(t('companies.configClient.logoUploadSuccess'));
            await getConfig();
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      });
  };
  const resizeImage = (image, maxWidth, maxHeight, quality) => {
    const canvas = document.createElement('canvas');

    let { width } = image;
    let { height } = image;

    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    }
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, width, height);
    return canvas.toDataURL('image/png', quality);
  };

  const uploadLogo = (file) => {
    setLoading({ ...loading, logoIsLoading: true });
    let resizedDataUrl;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const dataUrl = event.target.result;
      const image = new Image();
      image.src = dataUrl;
      image.onload = async () => {
        resizedDataUrl = resizeImage(image, 100, 50, 0.7);
        await srcToFile(resizedDataUrl, 'logo.jpeg', 'image/jpeg');
      };
    };
    return false;
  };
  return (
    <>
      <Row style={{ marginTop: 16 }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {configs.logo ? (
          logoIsLoading ? (
            <Spin />
          ) : (
            <div>
              <img src={urlLogo} alt="Company logo" />
            </div>
          )
        ) : logoIsLoading ? (
          <Spin />
        ) : (
          <Icon type="file-image" style={{ fontSize: 36 }} />
        )}
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Upload beforeUpload={uploadLogo} showUploadList={false}>
          <Button size="small">
            <Icon type="upload" />
            {t('companies.configClient.uploadButton.logo')}
          </Button>
        </Upload>
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Paragraph strong>{t('companies.configClient.colorChoice')}</Paragraph>
        <Input
          addonBefore="#"
          style={{ width: 120, float: 'left' }}
          value={sidebar_color}
          onChange={handleColorChange}
        />
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: `#${sidebar_color}`,
            float: 'left',
            marginLeft: 16,
            marginTop: 5
          }}
        />
      </Row>
    </>
  );
};
Theme.propTypes = {
  configs: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.instanceOf(Object).isRequired,
  urlLogo: PropTypes.instanceOf(Object).isRequired,
  setLoading: PropTypes.func.isRequired,
  setConfigs: PropTypes.func.isRequired,
  getConfig: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  sidebar_color: PropTypes.string.isRequired,
  logoIsLoading: PropTypes.bool.isRequired
};
export default Theme;
