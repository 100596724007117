import React from 'react';
import { Tag } from 'antd';
import { categoryTypesColors, invoiceTypesColors } from '../../utils';

export default (product, productType, invoiceType, invoiceTypes) => {
  const typeOptions = invoiceTypes.map((type) => ({
    value: type._id,
    text: (
      <>
        <Tag color={categoryTypesColors[type.category]}>{type.category}</Tag>
        <Tag color={invoiceTypesColors[type.label]}>{type.label}</Tag>
      </>
    )
  }));

  return [
    {
      label: 'invoice_type',
      input: 'select',
      options: typeOptions,
      required: true,
      initialValue:
        productType && productType.invoice_type && productType.invoice_type._id
    },
    {
      label: 'label',
      required: true,
      initialValue: productType && productType.label
    }
  ];
};
