import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';

const { Option } = Select;

const BasePVForm = ({
  pv,
  getFieldDecorator,
  isOrdersLoading,
  orders,
  getOrders,
  setSelectedOrder,
  deselectOrder,
  allInvoices,
  deselectInvoices,
  setSelectedInvoices
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item label={t('pvs.fields.number')}>
        {getFieldDecorator('number', {
          rules: [
            { required: true, message: t('global.form.requiredMessage') }
          ],
          initialValue: pv && pv.number
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('pvs.fields.signingDate')}>
        {getFieldDecorator('signing_date', {
          initialValue: pv && moment(pv.signing_date)
        })(<DatePicker />)}
      </Form.Item>
      <Form.Item label={t('pvs.fields.order')}>
        {getFieldDecorator('order_number', {
          rules: [
            { required: true, message: t('global.form.requiredMessage') }
          ],
          initialValue: pv && !isOrdersLoading && pv.order_number
        })(
          <Select
            allowClear
            notFoundContent={null}
            loading={isOrdersLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            mode="multiple"
            onSearch={(value) => getOrders(value)}
            onSelect={(value) => setSelectedOrder(value)}
            onDeselect={(value) => deselectOrder(value)}
          >
            {orders.map(({ _id, order_number }) => (
              <Option value={_id} key={order_number}>
                {order_number}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('pvs.fields.invoices')}>
        {getFieldDecorator('invoices', {
          rules: [
            { required: true, message: t('global.form.requiredMessage') }
          ],
          initialValue: pv && !isOrdersLoading && pv.invoices
        })(
          <Select
            mode="multiple"
            notFoundContent={null}
            showArrow={false}
            filterOption={false}
            defaultActiveFirstOption={false}
            onSelect={(value) => setSelectedInvoices(value)}
            onDeselect={(value) => deselectInvoices(value)}
          >
            {allInvoices.map(({ _id, number }) => (
              <Option value={_id} key={number}>
                {number}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </>
  );
};

BasePVForm.propTypes = {
  pv: PropTypes.shape({
    number: PropTypes.string,
    signing_date: PropTypes.string,
    order_number: PropTypes.arrayOf(PropTypes.string),
    invoices: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  isOrdersLoading: PropTypes.bool.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      order_number: PropTypes.string
    })
  ).isRequired,
  getOrders: PropTypes.func.isRequired,
  setSelectedOrder: PropTypes.func.isRequired,
  deselectOrder: PropTypes.func.isRequired,
  allInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      number: PropTypes.string
    })
  ).isRequired,
  deselectInvoices: PropTypes.func.isRequired,
  setSelectedInvoices: PropTypes.func.isRequired
};

export default BasePVForm;
