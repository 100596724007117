import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Icon, Row, List, Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PeopleAssets = ({ areModelsLoading, assets, models, showModal }) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const [extra, setExtra] = useState({
    visible: false
  });
  const extraButtons = (asset) => (
    <>
      <Link to={`/assets/${asset.type}/show/${asset._id}`}>
        <Icon style={{ fontSize: 18, marginRight: 20 }} type="eye" />
      </Link>
    </>
  );
  return (
    <Skeleton
      active
      loading={areModelsLoading}
      paragraph={{
        rows: 6,
        width: ['100%', '50%', '100%', '50%', '100%', '50%']
      }}
    >
      {models.map((name) => {
        const { assetsLinked } = assets || {};
        return (
          <Row key={name}>
            <List
              header={
                <>
                  <Text strong style={{ fontSize: 20 }}>
                    {t(`people.showPeople.assetList.${name}`)}
                  </Text>
                  <Button
                    type="dashed"
                    style={{ float: 'right' }}
                    icon="plus"
                    onClick={() => showModal(name)}
                  >
                    {t(`people.showPeople.addAssetButton.${name}`)}
                  </Button>
                </>
              }
              dataSource={(assetsLinked || []).filter(
                (asset) => asset.type === name
              )}
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 4
              }}
              renderItem={({
                _id,
                type,
                description,
                phone_Number,
                ...asset
              }) => (
                <List.Item>
                  <Card
                    style={{ marginTop: 10, height: 150 }}
                    onMouseEnter={() => {
                      setExtra({ id: _id, visible: true });
                    }}
                    extra={
                      extra.visible &&
                      _id === extra.id &&
                      extraButtons({ _id, type, asset })
                    }
                    onMouseLeave={() => {
                      setExtra({ id: null, visible: false });
                    }}
                    title={
                      type === 'Telecom' ? `0${phone_Number}` : description
                    }
                  >
                    {type === 'Telecom' ? (
                      asset.telecomInvoices[asset.telecomInvoices.length - 1]
                        .phone_contract_name
                    ) : (
                      <p>
                        {asset.serial_number}
                        <br />
                        {asset.EasyVista_request_serial_number}
                      </p>
                    )}
                  </Card>
                </List.Item>
              )}
            />
          </Row>
        );
      })}
    </Skeleton>
  );
};

PeopleAssets.propTypes = {
  areModelsLoading: PropTypes.bool.isRequired,
  assets: PropTypes.shape({}).isRequired,
  models: PropTypes.arrayOf().isRequired,
  showModal: PropTypes.func.isRequired
};

export default PeopleAssets;
