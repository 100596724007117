import React from 'react';
import {
  Button,
  Divider,
  Icon,
  Popconfirm,
  Skeleton,
  Table,
  Tag,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ContentCustom } from '../../components';
import { invoiceTypesColors, categoryTypesColors } from '../../utils';

const { Column } = Table;
const iconSize = '18px';

const ProductType = ({
  isLoading,
  productTypes,
  pagination1,
  setPagination1,
  editProductType,
  del
}) => {
  const { t } = useTranslation();
  return (
    <ContentCustom marginTop={8}>
      <Typography.Title level={4}>
        {t('catalog.list.productTypes.title')}
      </Typography.Title>
      <Skeleton
        active
        title={false}
        loading={isLoading}
        paragraph={{
          rows: 8,
          width: [
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%'
          ]
        }}
      >
        <Table
          rowClassName="rowStyle"
          dataSource={productTypes}
          loading={isLoading}
          pagination={pagination1}
          onChange={(page) => {
            setPagination1(page);
          }}
          rowKey="_id"
          scroll={{ x: 800 }}
        >
          <Column
            title={t('catalog.list.productTypes.typeName')}
            dataIndex="label"
            align="center"
            sorter={(a, b) => a.label.localeCompare(b.label)}
            sortDirections={['ascend', 'descend']}
            render={(label) => <Typography>{label}</Typography>}
          />
          <Column
            title={t('catalog.list.productTypes.invoiceType')}
            dataIndex="invoice_type.label"
            align="center"
            sorter={(a, b) =>
              a.invoice_type.label.localeCompare(b.invoice_type.label)
            }
            sortDirections={['ascend', 'descend']}
            render={(label) => (
              <Tag color={invoiceTypesColors[label]}>{label}</Tag>
            )}
          />
          <Column
            title={t('catalog.list.productTypes.category')}
            dataIndex="invoice_type.category"
            align="center"
            sorter={(a, b) =>
              a.invoice_type.category.localeCompare(b.invoice_type.category)
            }
            sortDirections={['ascend', 'descend']}
            render={(label) => (
              <Tag color={categoryTypesColors[label]}>{label}</Tag>
            )}
          />
          <Column
            title={t('users.list.column.actions')}
            key="action"
            fixed="right"
            render={({ _id }) => (
              <span>
                <Button type="link" onClick={() => editProductType(_id)}>
                  <Icon type="edit" style={{ fontSize: iconSize }} />
                </Button>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('catalog.list.column.action.delete.title')}
                  okText={t('catalog.list.column.action.delete.ok')}
                  cancelText={t('catalog.list.column.action.delete.cancel')}
                  onConfirm={() => del(_id, 'producttypes')}
                  icon={<Icon type="warning" />}
                >
                  <Icon
                    style={{ color: 'red', fontSize: iconSize }}
                    type="delete"
                  />
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      </Skeleton>
    </ContentCustom>
  );
};

ProductType.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  productTypes: PropTypes.arrayOf().isRequired,
  pagination1: PropTypes.shape({}).isRequired,
  setPagination1: PropTypes.func.isRequired,
  editProductType: PropTypes.func.isRequired,
  del: PropTypes.func.isRequired
};

export default ProductType;
