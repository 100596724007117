import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography, Card, Row, Col } from 'antd';

const { Title } = Typography;

const PartiesTab = ({ partiesData }) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(null);
  const [tabList, setTabList] = useState(null);
  const [contentList] = useState({});

  const onTabChange = (activeKey) => {
    setKey(activeKey);
  };

  useEffect(() => {
    if (partiesData) {
      setTabList(
        partiesData.suppliers.map((supplier) => ({
          key: supplier.name,
          tab: supplier.name
        }))
      );
      setKey(partiesData.suppliers[0].name);
      partiesData.suppliers.forEach(({ _id, name, address }) => {
        contentList[name] = (
          <>
            <Title level={4}>
              <Link to={`/companies/supplier/show/${_id}`}>{`${name}`}</Link>
            </Title>
            <Typography>
              {`${address.number || '-'} ${address.street || '-'}`}
              <br />
              {address.additional}
              {address.additional && <br />}
              {`${address.postal_code || '-'} ${address.city || '-'}`}
              <br />
              {address.country || '-'}
            </Typography>
          </>
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partiesData]);

  return (
    <Row gutter={[32, 8]} type="flex" justify="space-around">
      {partiesData &&
        [
          { ...partiesData.customer, tp: 'CLIENT' },
          { ...partiesData.leaser, tp: 'LEASER' }
        ].map(({ _id, name, address, tp }) => (
          <Col md={8} xs={24} key={name}>
            <Card
              title={t(`relatedItems.tabs.${tp}`)}
              style={{ height: '100%' }}
            >
              <Title level={4}>
                <Link to={`/companies/${tp.toLowerCase()}/show/${_id}`}>
                  {`${name}`}
                </Link>
              </Title>
              <Typography>
                {`${address.number || '-'} ${address.street || '-'}`}
                <br />
                {address.additional}
                {address.additional && <br />}
                {`${address.postal_code || '-'} ${address.city || '-'}`}
                <br />
                {address.country || '-'}
              </Typography>
            </Card>
          </Col>
        ))}
      {partiesData && key && contentList && tabList && (
        <Col md={8} xs={24}>
          <Card
            title={t(`relatedItems.tabs.SUPPLIERS`)}
            style={{ height: '100%' }}
            tabList={tabList}
            activeTabKey={key}
            onTabChange={(tabKey) => {
              onTabChange(tabKey);
            }}
          >
            {contentList[key]}
          </Card>
        </Col>
      )}
    </Row>
  );
};

PartiesTab.defaultProps = {
  partiesData: null
};

PartiesTab.propTypes = {
  partiesData: PropTypes.shape({
    customer: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        additional: PropTypes.string,
        postal_code: PropTypes.string
      }),
      contact: PropTypes.shape({
        civility: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        phone_number: PropTypes.shape({
          country_code: PropTypes.string,
          number: PropTypes.string
        })
      })
    }),
    suppliers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        group: PropTypes.string,
        address: PropTypes.shape({
          number: PropTypes.string,
          street: PropTypes.string,
          additional: PropTypes.string,
          postal_code: PropTypes.string
        }),
        contact: PropTypes.shape({
          civility: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          phone_number: PropTypes.shape({
            country_code: PropTypes.string,
            number: PropTypes.string
          })
        })
      })
    ).isRequired,
    leaser: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        additional: PropTypes.string,
        postal_code: PropTypes.string
      }),
      contact: PropTypes.shape({
        civility: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        phone_number: PropTypes.shape({
          country_code: PropTypes.string,
          number: PropTypes.string
        })
      })
    })
  })
};

export default PartiesTab;
