import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import { assetsStatusColor } from '../../utils';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/assets/enums' });
      setStatuses(data.status);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return {
    IT: [
      {
        title: t('assets.list.column.serial_number'),
        dataIndex: 'serial_number',
        sorter: true
      },
      {
        title: t('assets.list.column.EasyVista_request_serial_number'),
        dataIndex: 'EasyVista_request_serial_number',
        sorter: true
      },
      {
        title: t('assets.list.column.genre'),
        dataIndex: 'genre',
        sorter: true
      },
      {
        title: t('assets.list.column.brand'),
        dataIndex: 'brand',
        sorter: true
      },
      {
        title: t('assets.list.column.status'),
        dataIndex: 'status',
        sorter: true,
        filters: statuses.map((enumValue) => ({
          text: t(`assets.tags.${enumValue}`),
          value: enumValue
        })),
        render: (status) =>
          status && (
            <Tag color={assetsStatusColor[status]}>
              {t(`assets.tags.${status}`)}
            </Tag>
          )
      }
    ],
    Telecom: [
      {
        title: t('assets.list.column.phone_Number'),
        dataIndex: 'phone_Number',
        sorter: true,
        render: (phoneNumber) =>
          phoneNumber &&
          `${(phoneNumber[0] === '0' ? phoneNumber : `0${phoneNumber}`).replace(
            /(.{2})/g,
            '$1 '
          )}`
      },
      {
        title: t('assets.list.column.status'),
        dataIndex: 'status',
        sorter: true,
        render: (status) =>
          status && (
            <Tag color={assetsStatusColor[status]}>
              {t(`assets.tags.${status}`)}
            </Tag>
          )
      },
      {
        dataIndex: 'telecomInvoices',
        render: (invoices) => {
          if (invoices && invoices.length) {
            const lastInvoice = invoices[invoices.length - 1];
            if (
              lastInvoice &&
              moment(lastInvoice.date).diff(moment(), 'months') < 2
            ) {
              if (
                lastInvoice.overage_data_amount ||
                lastInvoice.overage_data_international_amount ||
                lastInvoice.overage_messages_amount ||
                lastInvoice.overage_messages_international_amount ||
                lastInvoice.overage_messages_special_amount ||
                lastInvoice.overage_voice_amount ||
                lastInvoice.overage_voice_international_amount ||
                lastInvoice.overage_voice_special_amount
              ) {
                return (
                  <Tag color="red">
                    {t('assets.showAsset.phoneContract.overages')}
                  </Tag>
                );
              }
            }
          }
          return '';
        }
      }
    ]
  };
};

export default useColumns;
