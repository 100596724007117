import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';

const BreadCrumbs = ({ routes }) => {
  function itemRender(route, params, routesList) {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={route.path}>{route.display}</span>
    ) : (
      <Link
        key={route.path}
        to={{ pathname: route.path, state: route.state, search: route.search }}
      >
        {route.display}
      </Link>
    );
  }
  return (
    <Breadcrumb
      style={{
        marginBottom: 8,
        background: '#fefefe',
        width: 'fit-content',
        marginLeft: -50,
        padding: '10px 15px 10px 50px',
        borderRadius: '0 50px 50px 0'
      }}
      routes={routes}
      itemRender={itemRender}
    />
  );
};

BreadCrumbs.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default BreadCrumbs;
