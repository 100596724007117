import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Typography, Row, Steps, Menu, Select } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import '../../routes/assets/style.css';
import allsteps from './allSteps';
import SchemaName from './SchemaName';

const { Paragraph } = Typography;
const { Step } = Steps;
const { Option } = Select;

const ImportModal = ({
  loading,
  visible,
  handleCancel,
  headers,
  base,
  upload,
  steps,
  current,
  next,
  prev,
  collection
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [connectedHeaders, setConnectedHeaders] = useState({});
  const [tmpConnection, setTempConnection] = useState({});
  const [refModalVisible, setRefModalVisible] = useState(false);
  const [schemaList, setSchemaList] = useState([]);
  const [schemaName, setSchemaName] = useState(null);
  const [schema, setSchema] = useState([]);
  const [ref, setRef] = useState(null);
  const [headerToRef, setHeaderToRef] = useState(null);

  const connectHeaders = () => {
    setConnectedHeaders({
      ...connectedHeaders,
      [tmpConnection.fileHeader]: tmpConnection.dbHeader
    });
    setTempConnection({});
  };

  const handleDelete = (header) => {
    // eslint-disable-next-line no-unused-vars
    const { [header]: _, ...newData } = connectedHeaders;
    setConnectedHeaders(newData);
  };

  const openRefModal = (header) => {
    setHeaderToRef(header);
    setRefModalVisible(true);
  };

  const deleteRef = (header) => {
    setConnectedHeaders({ ...connectedHeaders, [header[0]]: header[1].field });
  };

  const menu = (header) => (
    <Menu>
      <Menu.Item key="1" onClick={() => openRefModal(header)}>
        {t('import.headersMenu.addRef')}
      </Menu.Item>
      {header[1].field && (
        <Menu.Item key="3" onClick={() => deleteRef(header)}>
          {t('import.headersMenu.deleteRef')}
        </Menu.Item>
      )}
      <Menu.Item key="2" onClick={() => handleDelete(header[0])}>
        {t('import.headersMenu.unlink')}
      </Menu.Item>
    </Menu>
  );

  const cancel = () => {
    setRefModalVisible(false);
  };

  const getSchema = async (value) => {
    try {
      const { data } = await dispatchAPI('GET', { url: `models/${value}` });
      setSchema(Object.keys(data));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleSchemaNameChange = async (value) => {
    setSchemaName(value);
    await getSchema(value);
  };

  const handleRefChange = (value) => {
    setRef(value);
  };

  const getSchemaList = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'models/names' });
      setSchemaList(
        data.filter(
          (value) => !['Adminconfig', 'Note', 'File', 'Deal'].includes(value)
        )
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const connectRefToHeader = () => {
    setConnectedHeaders({
      ...connectedHeaders,
      [headerToRef[0]]: {
        collection: schemaName,
        collection_field: ref,
        field: headerToRef[1].field ? headerToRef[1].field : headerToRef[1]
      }
    });
    setRefModalVisible(false);
    setRef(null);
    setHeaderToRef(null);
    setSchemaName(null);
  };

  useEffect(() => {
    (async () => {
      await getSchemaList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allSteps = allsteps(
    base,
    collection,
    connectHeaders,
    connectedHeaders,
    headers,
    menu,
    setTempConnection,
    setConnectedHeaders,
    steps,
    t,
    tmpConnection
  );

  const cancelMatching = () => {
    setConnectedHeaders({});
    handleCancel();
  };

  return (
    <Modal
      width={current === allSteps.length - 1 ? '90%' : 600}
      title={t('import.modalTitle')}
      visible={visible}
      onCancel={cancelMatching}
      zIndex={10}
      footer={
        <div>
          {current > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={prev}>
              Previous
            </Button>
          )}
          {current < allSteps.length - 1 && (
            <Button type="primary" onClick={next}>
              Next
            </Button>
          )}
          {current === allSteps.length - 1 && (
            <Button
              loading={loading}
              type="primary"
              onClick={() => upload(connectedHeaders)}
            >
              Done
            </Button>
          )}
        </div>
      }
    >
      <Modal
        visible={refModalVisible}
        onCancel={cancel}
        onOk={connectRefToHeader}
      >
        <Row>
          <Paragraph strong>{t('import.refModal.chooseSchema')}</Paragraph>
          <Select
            style={{ width: 200 }}
            value={schemaName}
            onChange={handleSchemaNameChange}
          >
            {schemaList.map((name) => (
              <Option key={name} value={name}>
                {t(`import.schemaList.${name}`)}
              </Option>
            ))}
          </Select>
        </Row>
        <SchemaName
          schemaName={schemaName}
          handleRefChange={handleRefChange}
          schema={schema}
          reference={ref}
        />
      </Modal>
      <Steps current={current}>
        {allSteps.map((item) => (
          <Step icon={item.icon} key={item.title} title={item.title} />
        ))}
      </Steps>
      <div style={{ margin: 24 }}>{allSteps[current].content}</div>
    </Modal>
  );
};

ImportModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  base: PropTypes.arrayOf(PropTypes.string),
  handleCancel: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  current: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  collection: PropTypes.string
};

ImportModal.defaultProps = {
  base: null,
  collection: null
};

export default ImportModal;
