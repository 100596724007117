import React from 'react';
import numeral from 'numeral';
import { Tag } from 'antd';
import moment from 'moment';
import {
  contractPeriodicityColor,
  contractTypeColor,
  contractStatusColor
} from '../../utils';

export default (
  enums,
  contract,
  supplierList,
  refinancers,
  leasers,
  companyName,
  t,
  amendment,
  disabling
) => {
  const {
    suppliers,
    leaser,
    refinancer,
    number,
    amendment_number,
    type,
    periodicity,
    extension,
    periodicity_extension,
    start_date,
    duration,
    linear,
    EUA,
    amount_EUA,
    termination_delay,
    rent,
    VAT,
    total_amount_excluding_taxes,
    total_amount,
    annual_total_amount,
    sinister_cost,
    leasetic_sinister_cost,
    rent_coefficient,
    cession_rate,
    client_rate,
    client_coefficient,
    status,
    residual_value,
    renewal_volume,
    renewal_available,
    renewal_consumed
  } = contract || {};

  const types = enums.type.map((element) => ({
    value: element,
    text: (
      <Tag color={contractTypeColor[element]}>
        {t(`contracts.form.options.type.${element}`)}
      </Tag>
    )
  }));

  const periodicities = enums.periodicity.map((element) => ({
    value: element,
    text: (
      <Tag color={contractPeriodicityColor[element]}>
        {t(`contracts.form.options.periodicity.${element}`)}
      </Tag>
    )
  }));

  const statuses = enums.status.map((element) => ({
    value: element,
    text: (
      <Tag color={contractStatusColor[element]}>
        {t(`contracts.form.options.status.${element}`)}
      </Tag>
    )
  }));

  return [
    {
      label: 'suppliers',
      initialValue:
        companyName && companyName.type.includes('SUPPLIER')
          ? supplierList.filter((c) => c.name === companyName.name)[0].key
          : suppliers,
      required: true,
      input: 'select',
      type: 'array',
      mode: 'multiple',
      options: supplierList,
      extra: true,
      typeExtra: 'addSupplier'
    },
    {
      label: 'leaser',
      initialValue:
        companyName && companyName.type.includes('LEASER')
          ? leasers.filter((c) => c.name === companyName.name)[0].key
          : leaser,
      required: true,
      input: 'select',
      options: leasers,
      extra: true,
      typeExtra: 'addLeaser'
    },
    {
      label: 'refinancer',
      initialValue:
        companyName && companyName.type.includes('REFINANCER')
          ? refinancers.filter((c) => c.name === companyName.name)[0].key
          : refinancer,
      required: true,
      input: 'select',
      options: refinancers
      // extra: true,
      // typeExtra: 'addRefinancer'
    },
    {
      label: 'number',
      initialValue: number,
      type: 'string',
      required: true,
      disabled: amendment
    },
    {
      label: 'amendment_number',
      type: 'string',
      initialValue: amendment_number,
      required: amendment,
      disabled: !amendment
    },
    {
      label: 'type',
      initialValue: type,
      input: 'select',
      required: true,
      options: types
    },
    {
      label: 'periodicity',
      initialValue: periodicity,
      input: 'select',
      required: true,
      options: periodicities
    },
    {
      label: 'extension',
      type: 'boolean',
      input: 'radio',
      initialValue: !!periodicity_extension || extension || false,
      buttons: [
        {
          value: true,
          text: 'Yes'
        },
        {
          value: false,
          text: 'No'
        }
      ],
      required: false
    },
    {
      label: 'periodicity_extension',
      initialValue: periodicity_extension,
      input: 'select',
      options: periodicities,
      disabled: disabling.periodicityExtensionDisable
    },
    {
      label: 'start_date',
      input: 'datePicker',
      config: {
        initialValue: start_date && moment(start_date),
        rules: [
          { type: 'object', required: true, message: 'Please select time!' }
        ]
      }
    },
    {
      label: 'duration',
      type: 'number',
      initialValue: duration,
      input: 'number',
      min: 0,
      required: true,
      extraSuffix: ` ${t('contracts.form.month')}`
    },

    {
      label: 'linear',
      type: 'boolean',
      initialValue: linear,
      input: 'radio',
      buttons: [
        {
          value: true,
          text: 'Yes'
        },
        {
          value: false,
          text: 'No'
        }
      ],
      required: false
    },
    {
      label: 'EUA',
      type: 'boolean',
      initialValue: EUA || false,
      input: 'radio',
      buttons: [
        {
          value: true,
          text: 'Yes'
        },
        {
          value: false,
          text: 'No'
        }
      ],
      required: true
    },
    {
      label: 'amount_EUA',
      type: 'number' || 'float',
      initialValue: amount_EUA,
      input: 'number',
      min: 0,
      required: false,
      formatter: (value) => `${numeral(value).format('0,0')}`,
      extraSuffix: ` €`,
      width: '70%',
      disabled: disabling.amountEUADisable
    },
    {
      label: 'termination_delay',
      type: 'number',
      initialValue: termination_delay,
      input: 'number',
      required: true,
      min: 0,
      extraSuffix: ` ${t('contracts.form.month')}`
    },
    {
      label: 'residual_value',
      type: 'number' || 'float',
      initialValue: residual_value,
      input: 'number',
      required: false,
      min: 0,
      formatter: (value) => `${numeral(value).format('0,0')}`,
      extraSuffix: ` €`
    },
    {
      label: 'cession_rate',
      type: 'number' || 'float',
      initialValue: cession_rate,
      input: 'number',
      required: false,
      min: 0,
      max: 100,
      extraSuffix: ` %`
    },
    {
      label: 'client_rate',
      type: 'number' || 'float',
      initialValue: client_rate,
      input: 'number',
      required: false,
      extraSuffix: ` %`,
      min: 0,
      max: 100
    },
    {
      label: 'client_coefficient',
      type: 'number' || 'float',
      initialValue: client_coefficient,
      input: 'number',
      required: false,
      extraSuffix: ` %`,
      min: 0,
      max: 100
    },
    {
      label: 'status',
      type: 'string',
      initialValue: status,
      input: 'select',
      options: statuses
    },
    {
      label: 'rent',
      type: 'number' || 'float',
      initialValue: rent,
      input: 'number',
      required: false,
      extraSuffix: ` €`,
      min: 0,
      width: '50%'
    },
    {
      label: 'VAT',
      type: 'number' || 'float',
      initialValue: VAT,
      input: 'number',
      required: false,
      min: 0,
      max: 100,
      extraSuffix: ` %`,
      width: '30%'
    },
    {
      label: 'total_amount_excluding_taxes',
      type: 'number' || 'float',
      initialValue: total_amount_excluding_taxes,
      input: 'number',
      required: false,
      min: 0,
      extraSuffix: ` €`,
      width: '70%'
    },
    {
      label: 'total_amount',
      type: 'number' || 'float',
      initialValue: total_amount,
      input: 'number',
      required: false,
      extraSuffix: ` €`,
      disabled: true,
      min: 0,
      width: '70%'
    },
    {
      label: 'rent_coefficient',
      type: 'number' || 'float',
      initialValue: rent_coefficient,
      input: 'number',
      required: false,
      min: 0,
      max: 100,
      extraSuffix: ` %`
    },
    {
      label: 'leasetic_sinister_cost',
      type: 'number' || 'float',
      initialValue: leasetic_sinister_cost,
      input: 'number',
      required: false,
      min: 0,
      extraSuffix: ` €`
    },
    {
      label: 'sinister_cost',
      type: 'number' || 'float',
      initialValue: sinister_cost,
      input: 'number',
      required: false,
      min: 0,
      extraSuffix: ` €`
    },
    {
      label: 'annual_total_amount',
      type: 'number' || 'float',
      initialValue: annual_total_amount,
      input: 'number',
      required: false,
      min: 0,
      width: '70%',
      extraSuffix: ` €`
    },
    {
      label: 'renewal_volume',
      type: 'number' || 'float',
      initialValue: renewal_volume,
      input: 'number',
      required: false,
      min: 0,
      width: '70%',
      extraSuffix: ` %`
    },
    {
      label: 'renewal_available',
      type: 'number' || 'float',
      initialValue: renewal_available,
      input: 'number',
      required: false,
      min: 0,
      width: '70%',
      extraSuffix: ` €`
    },
    {
      label: 'renewal_consumed',
      type: 'number' || 'float',
      initialValue: renewal_consumed,
      input: 'number',
      required: false,
      min: 0,
      width: '70%',
      extraSuffix: ` €`
    }
  ];
};
