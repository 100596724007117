import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataSet from '@antv/data-set';
import { Chart, Geom, Tooltip, Label } from 'bizcharts';
import { Empty, Icon } from 'antd';

const SquareHierarchyChart = ({ chartsElements }) => {
  const { DataView } = DataSet;
  const { t } = useTranslation();

  const data = {
    name: 'root',
    children: [...(chartsElements.data ? chartsElements.data : [])]
  };

  const dv = new DataView();
  dv.source(data, {
    type: 'hierarchy'
  }).transform({
    field: 'value',
    type: 'hierarchy.treemap',
    tile: 'treemapResquarify',
    as: ['x', 'y']
  });

  const nodes = dv.getAllNodes();
  nodes.map((node) => {
    node.name = !node.data.name ? 'À renseigner' : node.data.name;
    node.value = node.data.value;
    return node;
  });

  const scale = {
    value: {
      nice: false
    }
  };

  return (
    <Chart
      data={chartsElements.data && chartsElements.data.length ? nodes : []}
      forceFit
      height={400}
      scale={scale}
      padding={[20, 20, 10, 20]}
      placeholder={
        <Empty
          style={{ height: '100%' }}
          description={t('home.nodata')}
          imageStyle={{ height: '90%' }}
          image={
            <Icon
              style={{ fontSize: 350, color: '#E0E0E0' }}
              type="bar-chart"
            />
          }
        />
      }
    >
      <Tooltip showTitle={false} />
      <Geom
        type="polygon"
        position="x*y"
        color="name"
        tooltip={[
          'name*value',
          (name, value) => {
            return {
              name,
              value
            };
          }
        ]}
        style={{
          lineWidth: 1,
          stroke: '#fff'
        }}
      >
        <Label
          content="name*value"
          offset={0}
          textStyle={{
            textBaseline: 'middle',
            fill: '#fff',
            fontWeight: 700
          }}
          formatter={(val, { point: { value } }) => {
            if (val !== 'root' && value > 100) {
              return val;
            }
          }}
        />
      </Geom>
    </Chart>
  );
};

SquareHierarchyChart.propTypes = {
  chartsElements: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

SquareHierarchyChart.defaultProps = {
  chartsElements: {}
};

export default SquareHierarchyChart;
