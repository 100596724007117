/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message, Button, Form, Spin, Drawer, Icon } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import InputGenerator from '../../components/inputGenerator';
import invoiceFields from './fields';
import EditAndCreateCompanyForm from '../companies/EditAndCreateCompanyForm';
import AddArticleForm from './AddArticleForm';
import AmountManagerForm from './AmountManagerForm';
import { ContentCustom } from '../../components';
import {
  addingNewCompany,
  articlesSummer,
  createInvoiceUtil,
  defaultCalculatedValues,
  defaultLoading,
  getContractsUtil,
  getInvoiceEnumsUtil,
  getInvoiceUtil,
  getOrdersUtil,
  getSettingsUtil,
  handleSubmitUtil,
  makeArticle,
  setLoadings,
  spinStyle,
  updateInvoiceUtil
} from './utils';

const EditAndCreateInvoiceForm = ({
  id,
  type,
  preFilledInfo,
  history,
  formItemLayout,
  tailFormItemLayout,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    getFieldValue,
    getFieldProps,
    getFieldsValue
  }
}) => {
  const { dispatchAPI } = useAuthContext();
  const [loading, setLoading] = useState(defaultLoading);
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [leasers, setLeasers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [enums, setEnums] = useState({ type: [] });
  const [newCompanyType, setNewCompanyType] = useState(null);
  const [visible, setVisible] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [articles, setArticles] = useState([makeArticle(0)]);
  const [calculatedValues, setCalculatedValues] = useState(
    defaultCalculatedValues
  );
  const { seller, total, buyer, assets } = preFilledInfo || {};
  const from = getFieldValue('from');
  const to = getFieldValue('to');
  const leaser = getFieldValue('leaser');
  const order = getFieldValue('order_number');

  useEffect(() => {
    setInvoice({ ...invoice, from, to, leaser });
  }, [leaser, to, from]);

  const getInvoice = () =>
    getInvoiceUtil(loading, setLoading, dispatchAPI, id, setInvoice);

  const getInvoiceEnums = () =>
    getInvoiceEnumsUtil(setLoading, loading, dispatchAPI, setEnums);

  const createInvoice = (values) =>
    createInvoiceUtil(values, type, dispatchAPI, history, t);
  const updateInvoice = (values) =>
    updateInvoiceUtil(values, dispatchAPI, history, t, id);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitUtil(
      validateFieldsAndScroll,
      id,
      updateInvoice,
      createInvoice,
      t
    );
  };

  const getCompanyNames = async () => {
    setLoading({ ...loading, companiesAreLoading: true });
    try {
      const result = await dispatchAPI('GET', {
        url: '/companies'
      });
      setCompanies(
        result.data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
      );
      setLeasers(result.data.filter((c) => c.type.includes('LEASER')));
    } catch (e) {
      message.error(e.message);
    }
    setLoading({ ...loading, companiesAreLoading: false });
  };

  const getContracts = () =>
    getContractsUtil(setLoading, loading, dispatchAPI, to, setContracts);

  const getOrders = () =>
    getOrdersUtil(loading, setLoading, dispatchAPI, setOrders);

  const getSettings = () =>
    getSettingsUtil(loading, setLoading, dispatchAPI, setCatalog, type);

  useEffect(() => {
    if (id) {
      (async () => {
        await getInvoice();
      })();
    }
    (async () => {
      await getInvoiceEnums();
      await getCompanyNames();
      await getContracts();
      await getOrders();
      await getSettings();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to]);

  useEffect(() => {
    if (invoice && invoice.articles && invoice.articles.length) {
      const tmp = [];
      invoice.articles.forEach((opt, idx) => {
        tmp.push(makeArticle(idx));
      });
      setArticles([...tmp]);
    }
  }, [invoice]);

  const addArticleField = () => {
    const tmp = articles;
    tmp.push(makeArticle(tmp.length));
    setArticles([...tmp]);
  };

  const sumUpArticles = useCallback(
    (fieldsValue = getFieldsValue()) => {
      articlesSummer(fieldsValue, setFieldsValue);
    },
    [setFieldsValue, articles, getFieldsValue]
  );

  useEffect(() => {
    const values = getFieldsValue();
    if (values.addArticle) sumUpArticles();
  }, [calculatedValues, sumUpArticles, articles]);

  const deleteArticle = (key) => {
    const tmp = articles.filter(({ articleId }) => articleId !== key);
    setArticles([...tmp]);
  };

  const { invoiceIsLoading } = loading;

  useEffect(() => {
    if (seller && buyer && total && assets && companies.length) {
      setFieldsValue({ to: buyer, from: seller, total });
      const tmp = [];
      assets.forEach((opt, idx) => {
        tmp.push(makeArticle(idx));
      });
      setArticles([...tmp]);
    }
  }, [seller, buyer, total, assets, companies]);

  const buttonAddCompany = (tpe) => (
    <Button
      type="primary"
      onClick={() => addingNewCompany(tpe, setNewCompanyType, setVisible)}
    >
      <Icon type="plus" />
      {t(`contracts.${tpe}`)}
    </Button>
  );

  const handleCompanyCreated = async (name) => {
    setVisible(false);
    await getCompanyNames();
    setCompanyName({ name, type: newCompanyType });
    if (newCompanyType === ['SUPPLIER'])
      setInvoice({ ...invoice, from: invoice.from.push() });
  };

  return (
    <ContentCustom>
      <Drawer
        title={t(`companies.form.${newCompanyType}`)}
        width={720}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <EditAndCreateCompanyForm
          title={newCompanyType}
          initialType={newCompanyType}
          drawer
          onSuccess={handleCompanyCreated}
        />
      </Drawer>
      {invoiceIsLoading ? (
        <Spin style={spinStyle} />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Form {...formItemLayout} onSubmit={handleSubmit}>
          <InputGenerator
            title="invoices"
            fields={invoiceFields(
              setLoadings(loading),
              invoice,
              enums.type,
              companies,
              leasers,
              contracts,
              orders,
              catalog,
              t,
              companyName,
              order
            )}
            extra={buttonAddCompany}
            getFieldDecorator={getFieldDecorator}
          />
          <AddArticleForm
            articles={articles}
            invoice={invoice}
            getFieldDecorator={getFieldDecorator}
            getFieldProps={getFieldProps}
            calculatedValues={calculatedValues}
            setCalculatedValues={setCalculatedValues}
            catalog={catalog}
            deleteArticle={deleteArticle}
          />
          <AmountManagerForm
            invoice={invoice}
            getFieldDecorator={getFieldDecorator}
            tailFormItemLayout={tailFormItemLayout}
            addArticleField={addArticleField}
            invoiceIsLoading={invoiceIsLoading}
            type={type}
          />
        </Form>
      )}
    </ContentCustom>
  );
};

EditAndCreateInvoiceForm.defaultProps = {
  initialType: null,
  id: null,
  history: null,
  seller: null,
  buyer: null,
  total: null
};

EditAndCreateInvoiceForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldProps: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  initialType: PropTypes.string,
  id: PropTypes.string,
  preFilledInfo: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  seller: PropTypes.string,
  buyer: PropTypes.string,
  total: PropTypes.number,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  formItemLayout: PropTypes.shape({}).isRequired,
  tailFormItemLayout: PropTypes.shape({}).isRequired
};

const WrappedEditAndCreateInvoiceAntForm = Form.create({
  name: 'invoices'
})(EditAndCreateInvoiceForm);

export default WrappedEditAndCreateInvoiceAntForm;
