import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

const HeaderDropdown = ({ children, overlayMenu }) => {
  return (
    <Dropdown className="dropdown-container-wrapper" overlay={overlayMenu}>
      {children}
    </Dropdown>
  );
};

HeaderDropdown.propTypes = {
  children: PropTypes.element.isRequired,
  overlayMenu: PropTypes.element.isRequired
};

export default HeaderDropdown;
