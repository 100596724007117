import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniDashDisplayContainer from './displayContainers/miniDisplayContainer';
import Indicator from './miniDashs/Indicator';
import DashDisplayContainer from './displayContainers/DashDisplayContainer';
import ChartDash from './dashs/ChartDash';

const GeneralDashboard = () => {
  const { t } = useTranslation();

  return (
    <div style={{ margin: '24px' }}>
      <>
        <DashDisplayContainer>
          <ChartDash
            title={t('home.dash.insuranceCompensation.title')}
            purpose={{
              resource: 'IT',
              endPoint: 'insurance_compensation'
            }}
          />
        </DashDisplayContainer>
        <MiniDashDisplayContainer>
          <Indicator
            title={t('home.miniDash.refusedSinister.title')}
            purpose={{ resource: 'IT', endPoint: 'rate_sinister_refused' }}
          />
        </MiniDashDisplayContainer>
      </>
    </div>
  );
};

export default GeneralDashboard;
