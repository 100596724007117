import React from 'react';
import { Table, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;
const { Column } = Table;
const PaymentInvoices = ({
  handleChange,
  invoices,
  isTableLoading,
  pagination
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <>
      <Title level={4} style={{ marginTop: 35 }}>
        {t('payments.showPayment.invoicesTitle')}
      </Title>
      <Table
        dataSource={invoices}
        onRow={({ _id }) => ({
          onDoubleClick: () => history.push(`/invoices/supplier/show/${_id}`)
        })}
        loading={isTableLoading}
        pagination={pagination}
        onChange={handleChange}
        rowKey="_id"
      >
        <Column
          title={t(`invoices.list.column.number`)}
          dataIndex="number"
          align="center"
        />
        <Column
          title={t(`invoices.list.column.order_number`)}
          dataIndex="order_number.order_number"
          align="center"
        />
        <Column
          title={t(`invoices.list.column.emission_date`)}
          dataIndex="emission_date"
          align="center"
          render={(emission_date) =>
            emission_date ? moment(emission_date).format('LL') : '-'
          }
        />
        <Column
          title={t(`invoices.list.column.total_excluding_taxes`)}
          dataIndex="total_excluding_taxes"
          align="center"
          render={(total_excluding_taxes) => `${total_excluding_taxes} €`}
        />
      </Table>
    </>
  );
};

PaymentInvoices.propTypes = {
  handleChange: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}).isRequired
};

export default PaymentInvoices;
