import { message } from 'antd';

export const createUserUtil = async (
  values,
  dispatchAPI,
  history,
  setFields,
  setLoading,
  t
) => {
  const body = { ...values };
  try {
    await dispatchAPI('POST', { url: '/users', body });
    history.goBack();
  } catch (e) {
    message.error(`${t('global.form.error.message')}`);
    const fieldsInvalid = e.response.data.error.fields;
    Object.entries(fieldsInvalid).forEach(([field, value]) => {
      setFields({
        [field]: {
          value: values[field],
          errors: [
            new Error(
              (value.kind === 'required' && t('global.form.error.required')) ||
                (value.kind === 'unique' && t('global.form.error.unique')) ||
                t('global.form.error.invalid')
            )
          ]
        }
      });
    });
  }
  setLoading(false);
};
export const updateUserUtil = async (
  values,
  dispatchAPI,
  history,
  id,
  setFields,
  setLoading,
  t
) => {
  const body = { ...values };
  try {
    await dispatchAPI('PATCH', { url: `/users/${id}`, body });
    history.goBack();
  } catch (e) {
    message.error(`${t('global.form.error.message')}`);
    const fieldsInvalid = e.response.data.error.fields;
    Object.entries(fieldsInvalid).forEach(([field, value]) => {
      setFields({
        [field]: {
          value: values[field],
          errors: [
            new Error(
              (value.kind === 'required' && t('global.form.error.required')) ||
                (value.kind === 'unique' && t('global.form.error.unique')) ||
                t('global.form.error.invalid')
            )
          ]
        }
      });
    });
  }
  setLoading(false);
};
