/* eslint-disable no-console */
import { StyleSheet } from '@react-pdf/renderer';
import uuid from 'uuid/v1';
import { message } from 'antd';
import moment from 'moment';

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  pageContent: { borderWidth: 2, flex: 1, padding: 15 },

  pageTable: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },

  title: {
    fontSize: 24,
    textAlign: 'left'
  },
  sender: {
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 5
  },
  receiver: {
    fontSize: 12,
    textAlign: 'right'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey'
  },
  tableHeader: {
    flex: 3,
    alignSelf: 'flex-end',
    textAlign: 'left',
    borderWidth: 1,
    justifyContent: 'flex-end'
  },
  tableContent: {
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 1
  },
  tableContentRight: {
    fontSize: 12,
    textAlign: 'right',
    marginBottom: 5
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
});

export const makeArticle = (value) => {
  return {
    articleLabel: `addArticle.articles.articleLabel.${value}`,
    quantityLabel: `addArticle.articles.quantityLabel.${value}`,
    priceLabel: `addArticle.articles.priceLabel.${value}`,
    vatLabel: `addArticle.articles.vatLabel.${value}`,
    discountLabel: `addArticle.articles.discountLabel.${value}`,
    descriptionLabel: `addArticle.articles.descriptionLabel.${value}`,
    receptionDateLabel: `addArticle.articles.receptionDateLabel.${value}`,
    articleId: uuid()
  };
};
export const buttonStyle = {
  height: '100%',
  width: '90%',
  borderRadius: '10px 0 0 10px'
};

export const defaultCalculatedValues = {
  article: null,
  quantity: 0,
  price: 0,
  vat: 20,
  discount: 0
};
export const defaultLoading = {
  invoiceIsLoading: false,
  companiesAreLoading: false,
  labelsAreLoading: false,
  typesAreLoading: false,
  contractsAreLoading: false,
  catalogIsLoading: false
};
export const setLoadings = (loading) => {
  const {
    labelsAreLoading,
    typesAreLoading,
    companiesAreLoading,
    contractsAreLoading,
    ordersAreLoading
  } = loading;
  return {
    labels: labelsAreLoading,
    types: typesAreLoading,
    companies: companiesAreLoading,
    contracts: contractsAreLoading,
    orders: ordersAreLoading
  };
};
export const defaultDescriptionLabel = (invoice, idx) => {
  return {
    initialValue:
      invoice &&
      invoice.articles &&
      invoice.articles[idx] &&
      invoice.articles[idx].description
  };
};
export const defaultReceptionDateLabel = (invoice, idx) => {
  return {
    initialValue:
      invoice &&
      invoice.articles &&
      invoice.articles[idx] &&
      invoice.articles[idx].reception_date &&
      moment(invoice.articles[idx].reception_date)
  };
};
export const handleSubmitUtil = (
  validateFieldsAndScroll,
  id,
  updateInvoice,
  createInvoice,
  t
) => {
  validateFieldsAndScroll(async (err, values) => {
    if (!err) {
      const { addArticle, ...rest } = values;
      const artcls = [];
      if (addArticle) {
        const {
          articleLabel,
          priceLabel,
          vatLabel,
          discountLabel,
          descriptionLabel,
          quantityLabel,
          receptionDateLabel
        } = addArticle.articles;
        for (let i = 0; i < articleLabel.length; i += 1) {
          artcls.push({
            catalog_label: articleLabel[i],
            unit_price: priceLabel[i],
            VAT: vatLabel[i],
            discount: discountLabel[i],
            description: descriptionLabel[i],
            quantity: quantityLabel[i],
            reception_date: receptionDateLabel[i]
          });
        }
        const invoiceValues = {
          ...rest,
          articles: artcls
        };
        if (id) {
          await updateInvoice(invoiceValues);
        } else if (!id) {
          await createInvoice(invoiceValues);
        }
      } else {
        message.error(t('invoices.form.noArticles'));
      }
    }
  });
};
export const articlesSummer = (fieldsValue, setFieldsValue) => {
  if (fieldsValue.addArticle) {
    const articlesTmp = fieldsValue.addArticle.articles;
    const { quantityLabel, priceLabel, vatLabel, discountLabel } = articlesTmp;
    const qty = Object.values(articlesTmp)[1].reduce((tt, num) => tt + num);
    let price = 0;
    let vat = 0;
    let discount = 0;
    quantityLabel.forEach((art, idx) => {
      price += art * priceLabel[idx];
      discount +=
        priceLabel[idx] *
        quantityLabel[idx] *
        ((discountLabel[idx] || 0) / 100);
      vat +=
        // price
        (priceLabel[idx] * quantityLabel[idx] -
          // discount
          priceLabel[idx] *
            quantityLabel[idx] *
            ((discountLabel[idx] || 0) / 100)) *
        // vat ratio
        (vatLabel[idx] / 100);
    });
    setFieldsValue({
      total_quantity: qty || 0,
      total_excluding_taxes: parseFloat(price.toFixed(2)) || 0,
      VAT: parseFloat(vat.toFixed(2)) || 0,
      sales: parseFloat(discount.toFixed(2)) || 0,
      total: parseFloat((price + vat).toFixed(2)) || 0
    });
  }
};
export const spinStyle = {
  textAlign: 'center',
  borderRadius: '4px',
  marginBottom: '20px',
  padding: '30px 50px',
  margin: '20px 0',
  width: '100%',
  height: '100%'
};

export const getInvoiceUtil = async (
  loading,
  setLoading,
  dispatchAPI,
  id,
  setInvoice
) => {
  setLoading({ ...loading, invoiceIsLoading: true });
  try {
    const result = await dispatchAPI('GET', {
      url: `/invoices/${id}`
    });
    setInvoice(result.data);
  } catch (e) {
    if (e.request) {
      message.error('Network error. Please check your Internet connexion.');
    } else {
      message.error('Oops, something bad happens.');
    }
  }
  setLoading({ ...loading, invoiceIsLoading: false });
};

export const getInvoiceEnumsUtil = async (
  setLoading,
  loading,
  dispatchAPI,
  setEnums
) => {
  setLoading({ ...loading, typesAreLoading: true });
  try {
    const { data } = await dispatchAPI('GET', { url: '/invoices/enums' });
    setEnums(data);
  } catch (e) {
    console.error(e);
  }
  setLoading({ ...loading, typesAreLoading: false });
};

export const createInvoiceUtil = async (
  values,
  type,
  dispatchAPI,
  history,
  t
) => {
  const body = {
    ...values,
    invoice_type: type.toUpperCase()
  };
  try {
    await dispatchAPI('POST', { url: '/invoices', body });
    message.success(t('invoices.successCreation'));
    history.goBack();
  } catch (e) {
    message.error(`${t('global.form.error.message')}`);
  }
};

export const updateInvoiceUtil = async (
  values,
  dispatchAPI,
  history,
  t,
  id
) => {
  const body = {
    ...values
  };
  try {
    await dispatchAPI('PATCH', {
      url: `/invoices/${id}`,
      body
    });
    message.success(t('invoices.successEdit'));
    history.goBack();
  } catch (e) {
    message.error(`${t('global.form.error.message')}`);
  }
};

export const getContractsUtil = async (
  setLoading,
  loading,
  dispatchAPI,
  to,
  setContracts
) => {
  setLoading({ ...loading, contractsAreLoading: true });
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/contracts?${to ? `&customer=${to}` : ''}`
    });
    const signed_contracts = data.filter(
      (contract) => contract.status === 'SIGNED'
    );
    setContracts(signed_contracts);
  } catch (e) {
    console.error(e);
  }
  setLoading({ ...loading, contractsAreLoading: false });
};

export const getOrdersUtil = async (
  loading,
  setLoading,
  dispatchAPI,
  setOrders
) => {
  setLoading({ ...loading, ordersAreLoading: true });
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/orders'
    });
    setOrders(data);
  } catch (e) {
    console.error(e);
  }
};

export const getSettingsUtil = async (
  loading,
  setLoading,
  dispatchAPI,
  setCatalog,
  type
) => {
  setLoading({ ...loading, catalogIsLoading: true });
  const category = type === 'supplier' ? 'SUPPLIERINVOICE' : 'CLIENTINVOICE';
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/products?category=${category}&populate=type.invoice_type`
    });
    const products = data
      ? data.sort((a, b) => a.name.localeCompare(b.name))
      : [];
    setCatalog(products);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  setLoading({ ...loading, catalogIsLoading: false });
};

export const addingNewCompany = (
  companyType,
  setNewCompanyType,
  setVisible
) => {
  switch (companyType) {
    case 'addClient':
      setNewCompanyType(['CLIENT']);
      break;
    case 'addSupplier':
      setNewCompanyType(['SUPPLIER']);
      break;
    case 'addLeaser':
      setNewCompanyType(['LEASER']);
      break;
    default:
      break;
  }
  setVisible(true);
};

export const skeletonStyle = {
  marginTop: 16,
  borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
  paddingLeft: 4,
  marginLeft: -4
};
