/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
import { message } from 'antd';

export const noLabelLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 14, offset: 10 },
    xl: { span: 18, offset: 8 }
  }
};

export const rule = (t) => {
  return [
    {
      required: true,
      message: t(`global.form.requiredMessage`)
    }
  ];
};

export const defaultPagination = {
  hideOnSinglePage: true,
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true
};
export const setSiteAddress = (site) => {
  const {
    number,
    street,
    additional,
    postal_code,
    city,
    country
  } = site.address;
  return {
    site: {
      name: site.name,
      address: {
        number,
        street,
        additional,
        postal_code,
        city,
        country
      }
    }
  };
};
export const setPeople = (people) => {
  const { first_name, last_name, email, phone_number } = people;
  return {
    contact: {
      name: `${first_name} ${last_name}`,
      email,
      phone_number
    }
  };
};
export const setContact = (people) => {
  const { email, phone_number } = people;
  return {
    peopleId: '',
    contact: {
      email,
      phone_number
    }
  };
};
export const handleSubmitUtil = (
  validateFieldsAndScroll,
  setIsLoading,
  dispatchAPI,
  id,
  assets,
  history,
  t,
  files
) => {
  validateFieldsAndScroll(async (err, values) => {
    const date = new Date(Date.now());
    const ticket_number = `${date.getFullYear() %
      2000}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
    const formData = new FormData();
    files.forEach((f) => formData.append('files', f));
    formData.append(
      'body',
      JSON.stringify({ ticket_number, ...values, assets })
    );
    if (!err) {
      setIsLoading(true);
      try {
        await dispatchAPI(id ? 'PATCH' : 'POST', {
          url: `/tickets${id ? `/${id}` : ''}`,
          body: id ? { ...values, assets } : formData
        });
        message.success(t(`tickets.success.${id ? 'edit' : 'create'}`));
        history.goBack();
      } catch (e) {
        const phone_number = values.contact.phone_number.number;
        if (phone_number.length !== 9 || isNaN(phone_number))
          message.error(t('tickets.phone_number_error'));
        else message.error(t('tickets.error'));
      }
      setIsLoading(false);
    }
  });
};

export const getAssetsUtil = async (
  page,
  filters,
  selectMany,
  dispatchAPI,
  setAssets,
  company,
  setAllAssets,
  setPagination
) => {
  const { pageSize, current } = page;
  const {
    city,
    status,
    contract,
    serial_number,
    EasyVista_request_serial_number
  } = filters;
  let citySearchUrl;
  if (city && city.length) citySearchUrl = `city=${city[0]}&`;

  let serial_numberSearchUrl;
  if (serial_number && serial_number.length)
    serial_numberSearchUrl = `serial_number=${serial_number[0]}&`;

  let EasyVista_request_serial_numberSearchUrl;
  if (EasyVista_request_serial_number && EasyVista_request_serial_number.length)
    EasyVista_request_serial_numberSearchUrl = `EasyVista_request_serial_number=${
      EasyVista_request_serial_number[0]
    }&`;

  try {
    let result;
    if (selectMany) {
      result = await dispatchAPI('GET', {
        url: `/assets?type=IT&${citySearchUrl || ''}${
          status ? `status=${status}&` : ''
        }${serial_numberSearchUrl ||
          ''}${EasyVista_request_serial_numberSearchUrl || ''}${
          contract ? `contract=${contract}&` : ''
        }populate=contract,tickets,people`
      });
      const assetsId = Object.values(result.data).map(({ _id }) => _id);
      setAssets(assetsId);
    } else {
      result = await dispatchAPI('GET', {
        url: `/assets?type=IT&populate=contract,tickets,people&${citySearchUrl ||
          ''}${status ? `status=${status}&` : ''}${
          company ? `company=${company}&` : ''
        }${EasyVista_request_serial_numberSearchUrl ||
          ''}${serial_numberSearchUrl || ''}${
          contract ? `contract=${contract}&` : ''
        }limit=${pageSize}&skip=${(current - 1) * pageSize}`
      });
    }
    setAllAssets(result.data.map((asset) => ({ ...asset, key: asset._id })));
    setPagination({
      ...page,
      total: parseInt(result.headers['x-total-count'], 10)
    });
  } catch (e) {
    console.error(e);
  }
};
export const getTicketsEnumsUtil = async (dispatchAPI, setTicketsEnums) => {
  try {
    const result = await dispatchAPI('GET', { url: '/tickets/enums' });
    setTicketsEnums(result.data);
  } catch (e) {
    console.error(e);
  }
};
export const getCompanyNamesUtil = async (
  setIsCompaniesLoading,
  dispatchAPI,
  setCompanyNames,
  companyNames
) => {
  setIsCompaniesLoading(true);
  try {
    const { data } = await dispatchAPI('GET', { url: '/companies' });
    setCompanyNames([
      ...companyNames,
      ...data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
    ]);
  } catch (e) {
    console.error(e);
  }
  setIsCompaniesLoading(false);
};
export const getSiteAddressUtil = async (id, dispatchAPI, company) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/companies/${company}/site`
    });
    return data.filter((s) => s._id === id);
  } catch (e) {
    return e;
  }
};

export const getCompanySitesUtil = async (
  searchValue,
  setIsSitesLoading,
  company,
  dispatchAPI,
  setCompanySites
) => {
  setIsSitesLoading(true);
  if (company)
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${company}/site${
          searchValue ? `/search/${searchValue}` : ''
        }?limit=5`
      });

      setCompanySites(data);
    } catch (e) {
      console.error(e);
    }
  setIsSitesLoading(false);
};

export const handleMultipleSelectUtil = async (
  keys,
  setIsTableLoading,
  dispatchAPI,
  setAssets,
  allAssets,
  serialNumber,
  setSerialNumber
) => {
  setIsTableLoading(true);
  try {
    if (keys.length) {
      const { data } = await dispatchAPI('GET', {
        url: `/assets?type=IT&value=${keys}&fields=_id`
      });
      const b = data.map((a) => a._id);
      const keysInAllAssets = allAssets.filter((asset) =>
        b.includes(asset._id)
      );
      let keysInAllAssetsToSerial = keysInAllAssets.map(
        (asset) => asset.serial_number
      );
      if (keys.length > serialNumber.length) {
        keysInAllAssetsToSerial = keysInAllAssetsToSerial.filter(
          (asset) => !serialNumber.includes(asset)
        );
        setSerialNumber([...serialNumber, ...keysInAllAssetsToSerial]);
      } else {
        const allAssetsSerial = allAssets.map((asset) => asset.serial_number);
        let serialNumberInOtherPage = serialNumber.filter(
          (asset) => !allAssetsSerial.includes(asset)
        );
        const deletedSerialInOtherPage = serialNumber.filter(
          (asset) => !keys.includes(asset)
        );
        serialNumberInOtherPage = serialNumberInOtherPage.filter(
          (asset) => asset !== deletedSerialInOtherPage[0]
        );
        setSerialNumber([
          ...serialNumberInOtherPage,
          ...keysInAllAssetsToSerial
        ]);
      }
      setAssets(data.map((a) => a._id));
    } else {
      setSerialNumber([]);
      setAssets([]);
    }
  } catch (e) {
    console.error(e);
  }
  setIsTableLoading(false);
};
export const getTicketUtil = async (
  setFetchingTicket,
  id,
  dispatchAPI,
  setTicket,
  setAssets,
  setCompany
) => {
  setFetchingTicket(true);
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/tickets/${id}?populate=incident_user`
    });
    setTicket(data);
    setAssets(data.assets);
    setCompany(data.company);
  } catch (e) {
    console.error(e);
  }
  setFetchingTicket(false);
};

export const getAssetsEnumsUtil = async (setAssetsEnums, dispatchAPI) => {
  try {
    const result = await dispatchAPI('GET', { url: '/assets/enums' });
    setAssetsEnums(result.data);
  } catch (e) {
    console.error(e);
  }
};

export const getCompanyPeopleUtil = async (
  searchValue,
  setIsPeopleLoading,
  dispatchAPI,
  company,
  setCompanyPeople
) => {
  setIsPeopleLoading(true);
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/people/${
        searchValue ? `search/${searchValue}` : ''
      }?limit=5&company=${company}`
    });

    setCompanyPeople(data);
  } catch (e) {
    console.error(e);
  }
  setIsPeopleLoading(false);
};

export const getPeopleDetailsUtil = async (id, dispatchAPI) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/people/${id}`
    });
    return data;
  } catch (e) {
    return e;
  }
};
