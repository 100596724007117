/* eslint-disable no-console */
import React from 'react';
import { Button, Card, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EditAndCreateFormModal from './Edit&CreateFormModal';
import ProductFields from './ProductFields';
import ProductTypeFields from './ProductTypeFields';
import InvoiceTypeFields from './InvoiceTypeFields';

const ModalsForListCatalog = ({
  visible,
  visible1,
  visible2,
  setVisible,
  setVisible1,
  setVisible2,
  endPoint,
  setEndPoint,
  purpose,
  invoiceTypes,
  invoiceTypeId,
  invoiceTypeEnums,
  getCatalog,
  productId,
  productTypes,
  productEnums,
  productTypeId
}) => {
  const { t } = useTranslation();

  const endPointChange = (value) => setEndPoint(value);

  const showModal = () => setVisible(true);
  const showModal1 = () => setVisible1(true);
  const showModal2 = () => setVisible2(true);
  const handleCancel = () => setVisible(false);
  const handleCancel1 = () => setVisible1(false);
  const handleCancel2 = () => setVisible2(false);
  return (
    <Card title={t('settings.title')}>
      <Row type="flex" justify="space-between">
        <Radio.Group
          value={endPoint}
          onChange={(e) => endPointChange(e.target.value)}
        >
          <Radio.Button value="product">Liste de Produits</Radio.Button>
          <Radio.Button value="producttype">
            Liste de types de produit
          </Radio.Button>
          <Radio.Button value="invoicetype">
            Liste de types de facture
          </Radio.Button>
        </Radio.Group>
        {endPoint === 'product' && (
          <Button type="primary" onClick={showModal}>
            {t('catalog.list.ordersProduct.createProduct')}
          </Button>
        )}
        <EditAndCreateFormModal
          title={t('catalog.list.ordersProduct.createProduct')}
          visible={visible}
          purpose={purpose}
          productId={productId}
          invoiceTypes={invoiceTypes}
          productTypes={productTypes}
          productEnums={productEnums}
          endpoint={endPoint}
          fields={ProductFields}
          handleCancel={handleCancel}
          getCatalog={getCatalog}
        />
        {endPoint === 'producttype' && (
          <Button type="primary" onClick={showModal1}>
            {t('catalog.list.productTypes.create')}
          </Button>
        )}
        <EditAndCreateFormModal
          title={t('catalog.list.productTypes.create')}
          visible={visible1}
          productTypeId={productTypeId}
          purpose={purpose}
          invoiceTypes={invoiceTypes}
          endpoint={endPoint}
          fields={ProductTypeFields}
          handleCancel={handleCancel1}
          getCatalog={getCatalog}
        />
        {endPoint === 'invoicetype' && (
          <Button type="primary" onClick={showModal2}>
            {t('catalog.list.invoiceTypes.create')}
          </Button>
        )}
        <EditAndCreateFormModal
          title={t('catalog.list.invoiceTypes.create')}
          visible={visible2}
          invoiceTypeId={invoiceTypeId}
          purpose={purpose}
          invoiceTypeEnums={invoiceTypeEnums}
          endpoint={endPoint}
          fields={InvoiceTypeFields}
          handleCancel={handleCancel2}
          getCatalog={getCatalog}
        />
      </Row>
    </Card>
  );
};

ModalsForListCatalog.propTypes = {
  visible: PropTypes.bool.isRequired,
  visible1: PropTypes.bool.isRequired,
  visible2: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  setVisible1: PropTypes.func.isRequired,
  setVisible2: PropTypes.func.isRequired,
  endPoint: PropTypes.string.isRequired,
  setEndPoint: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  invoiceTypes: PropTypes.arrayOf().isRequired,
  invoiceTypeId: PropTypes.string.isRequired,
  invoiceTypeEnums: PropTypes.arrayOf().isRequired,
  getCatalog: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  productTypes: PropTypes.arrayOf().isRequired,
  productEnums: PropTypes.arrayOf().isRequired,
  productTypeId: PropTypes.string.isRequired
};

export default ModalsForListCatalog;
