/* eslint-disable no-console */
import { message } from 'antd';
import moment from 'moment';

export const handleSubmitUtil = (
  validateFieldsAndScroll,
  dispatchAPI,
  history,
  id,
  t
) => {
  const createOrder = async (values) => {
    const body = { ...values };
    try {
      await dispatchAPI('POST', { url: '/orders', body });
      message.success(t('orders.successCreation'));
      history.goBack();
    } catch (e) {
      if (body.order_number)
        message.error(`${t('orders.form.order_number_error')}`);
      else message.error(`${t('global.form.error.message')}`);
    }
  };
  const updateOrder = async (values) => {
    const body = {
      ...values
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/orders/${id}`,
        body
      });
      message.success(t('orders.successEdit'));
      history.goBack();
    } catch (e) {
      if (body.order_number)
        message.error(`${t('orders.form.order_number_error')}`);
      else message.error(`${t('global.form.error.message')}`);
    }
  };

  validateFieldsAndScroll(async (err, values) => {
    if (!err) {
      const { addArticle, ...rest } = values;
      const artcls = [];
      if (addArticle) {
        const {
          articleLabel,
          priceLabel,
          vatLabel,
          discountLabel,
          descriptionLabel,
          quantityLabel,
          receptionDateLabel
        } = addArticle.articles;
        for (let i = 0; i < articleLabel.length; i += 1) {
          artcls.push({
            catalog_label: articleLabel[i],
            unit_price: priceLabel[i],
            VAT: vatLabel[i],
            discount: discountLabel[i],
            description: descriptionLabel[i],
            quantity: quantityLabel[i],
            reception_date: receptionDateLabel[i]
          });
        }
        const orderValues = {
          ...rest,
          articles: artcls
        };
        if (id) {
          await updateOrder(orderValues);
        } else if (!id) {
          await createOrder(orderValues);
        }
      } else {
        message.error(t('orders.form.noArticles'));
      }
    }
  });
};
export const articlesSummer = (fieldsValue, setFieldsValue) => {
  if (fieldsValue.addArticle) {
    const articlesTmp = fieldsValue.addArticle.articles;
    const { quantityLabel, priceLabel, vatLabel, discountLabel } = articlesTmp;
    const qty = Object.values(articlesTmp)[1].reduce((tt, num) => tt + num);
    let price = 0;
    let vat = 0;
    let discount = 0;
    quantityLabel.forEach((art, idx) => {
      price += art * priceLabel[idx];
      discount +=
        priceLabel[idx] *
        quantityLabel[idx] *
        ((discountLabel[idx] || 0) / 100);
      vat +=
        // price
        (priceLabel[idx] * quantityLabel[idx] -
          // discount
          priceLabel[idx] *
            quantityLabel[idx] *
            ((discountLabel[idx] || 0) / 100)) *
        // vat ratio
        (vatLabel[idx] / 100);
    });
    setFieldsValue({
      total_quantity: qty || 0,
      total_ht_amount: parseFloat(price.toFixed(2)) || 0,
      tva_amount: parseFloat(vat.toFixed(2)) || 0,
      total_discount: parseFloat(discount.toFixed(2)) || 0,
      ttc_amount: parseFloat((price + vat).toFixed(2)) || 0
    });
  }
};

export const defaultLoading = {
  orderIsLoading: false,
  companiesAreLoading: false,
  labelsAreLoading: false,
  contractsAreLoading: false,
  catalogIsLoading: false
};
export const setLoadings = (loading) => {
  const {
    labelsAreLoading,
    typesAreLoading,
    companiesAreLoading,
    contractsAreLoading
  } = loading;
  return {
    labels: labelsAreLoading,
    types: typesAreLoading,
    companies: companiesAreLoading,
    contracts: contractsAreLoading
  };
};

export const buttonStyle = {
  height: '100%',
  width: '90%',
  borderRadius: '10px 0 0 10px'
};

export const rule = (t) => {
  return [
    {
      required: true,
      message: t(`global.form.requiredMessage`)
    }
  ];
};
export const rules = (t) => {
  return [
    {
      type: 'number' || 'float',
      message: t(`global.form.typeMessage`)
    },
    {
      required: true,
      message: t(`global.form.requiredMessage`)
    }
  ];
};
export const defaultDescriptionLabel = (order, idx) => {
  return {
    initialValue:
      order &&
      order.articles &&
      order.articles[idx] &&
      order.articles[idx].description
  };
};
export const defaultReceptionDateLabel = (order, idx) => {
  return {
    initialValue:
      order &&
      order.articles &&
      order.articles[idx] &&
      order.articles[idx].reception_date &&
      moment(order.articles[idx].reception_date)
  };
};

export const getOrderUtil = async (
  setLoading,
  loading,
  dispatchAPI,
  id,
  setOrder
) => {
  setLoading({ ...loading, orderIsLoading: true });
  try {
    const result = await dispatchAPI('GET', {
      url: `/orders/${id}`
    });
    setOrder(result.data);
  } catch (e) {
    if (e.request) {
      message.error('Network error. Please check your Internet connexion.');
    } else {
      message.error('Oops, something bad happens.');
    }
  }
  setLoading({ ...loading, orderIsLoading: false });
};

export const getOrderEnumsUtil = async (
  setLoading,
  loading,
  dispatchAPI,
  setEnums
) => {
  setLoading({ ...loading, typesAreLoading: true });
  try {
    const { data } = await dispatchAPI('GET', { url: '/orders/enums' });
    setEnums(data);
  } catch (e) {
    console.error(e);
  }
  setLoading({ ...loading, typesAreLoading: false });
};

export const getCompanyNamesUtil = async (
  setLoading,
  loading,
  dispatchAPI,
  setCompanies,
  setLeasers
) => {
  setLoading({ ...loading, companiesAreLoading: true });
  try {
    const result = await dispatchAPI('GET', {
      url: '/companies'
    });
    setCompanies(
      result.data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
    );
    setLeasers(result.data.filter((c) => c.type.includes('LEASER')));
  } catch (e) {
    message.error(e.message);
  }
  setLoading({ ...loading, companiesAreLoading: false });
};

export const getContractsUtil = async (
  setLoading,
  loading,
  dispatchAPI,
  client,
  setContracts
) => {
  setLoading({ ...loading, contractsAreLoading: true });
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/contracts?${client ? `&customer=${client}` : ''}`
    });
    setContracts(data);
  } catch (e) {
    console.error(e);
  }
  setLoading({ ...loading, contractsAreLoading: false });
};

export const getSettingsUtil = async (
  setLoading,
  loading,
  dispatchAPI,
  setCatalog
) => {
  setLoading({ ...loading, catalogIsLoading: true });
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/products?category=SUPPLIERORDER&populate=type, type.invoice_type'
    });
    const products = data
      ? data.sort((a, b) => a.name.localeCompare(b.name))
      : [];
    setCatalog(products);
  } catch (e) {
    console.error(e);
  }
  setLoading({ ...loading, catalogIsLoading: false });
};

export const addingNewCompany = (
  companyType,
  setNewCompanyType,
  setVisible
) => {
  switch (companyType) {
    case 'addClient':
      setNewCompanyType(['CLIENT']);
      break;
    case 'addSupplier':
      setNewCompanyType(['SUPPLIER']);
      break;
    case 'addLeaser':
      setNewCompanyType(['LEASER']);
      break;
    default:
      break;
  }
  setVisible(true);
};
