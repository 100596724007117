import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Avatar, message, Row, Skeleton, Popconfirm, Icon, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import DescriptionList from '../../components/descriptionList/DescriptionList';
import list from './informationList';
import { ContentCustom, PageHeaderCustom } from '../../components';

const ShowUser = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [user, setUser] = useState({
    address: {},
    img: {},
    first_name: [],
    last_name: []
  });

  const getUser = async () => {
    setLoading(true);
    try {
      const result = await dispatchAPI('GET', {
        url: `/users/${id}?populate=company`
      });
      setUser(result.data);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
    setLoading(false);
  };

  const deleteUser = async () => {
    await dispatchAPI('DELETE', { url: `/users/${id}` });
    history.goBack();
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await getUser();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('users.showUser.title')}
        extra={
          <>
            <Popconfirm
              title={t('users.list.column.action.delete.title')}
              okText={t('users.list.column.action.delete.ok')}
              cancelText={t('users.list.column.action.delete.cancel')}
              onConfirm={() => deleteUser()}
              icon={<Icon type="warning" />}
            >
              <Button
                type="danger"
                icon="delete"
                style={{ float: 'right', margin: '0 4px' }}
              >
                {t('users.showUser.deleteButton')}
              </Button>
            </Popconfirm>
            <Link to={`/users/edit/${id}`} style={{ float: 'right' }}>
              <Button type="primary" icon="edit">
                {t('users.showUser.editButton')}
              </Button>
            </Link>
          </>
        }
      />
      <ContentCustom>
        <Row style={{ marginBottom: 24 }}>
          <Skeleton
            loading={isLoading}
            title={0}
            paragraph={0}
            avatar={{ size: 64 }}
            active
          >
            <Avatar
              size={64}
              src={user && user.img && user.img.data ? user.img.data : ''}
            >
              {user && `${user.first_name[0]}${user.last_name[0]}`}
            </Avatar>
          </Skeleton>
        </Row>
        <Row>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={list(user, t)} />
          </Skeleton>
        </Row>
      </ContentCustom>
    </>
  );
};

export default ShowUser;
