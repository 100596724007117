/* eslint-disable no-console */
import React from 'react';
import { DatePicker, Typography, Icon, Row, Tooltip, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import ExtraOptions from './ExtraOptions';
import DatePickerFooter from './DatePickerFooter';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const FilterForResourceLandingLayout = ({
  optionsDisplay,
  setOptionsDisplay,
  filter,
  setFilter,
  handleReset,
  rangeDates,
  onDateChange,
  dateField,
  setDateField,
  type
}) => {
  const { t } = useTranslation();
  const range = rangeDates;

  return (
    <>
      <Row type="flex" justify="start" style={{ margin: '16px 0' }}>
        <Tooltip title="Options" placement="right" mouseEnterDelay={1}>
          <Icon
            style={{ fontSize: 20 }}
            type={optionsDisplay ? 'up' : 'filter'}
            onClick={() => setOptionsDisplay(!optionsDisplay)}
          />
        </Tooltip>
      </Row>
      <Row
        style={{ display: optionsDisplay ? 'contents' : 'none' }}
        gutter={36}
      >
        <ExtraOptions
          filter={filter}
          type={type}
          handleFilterChange={(key, value) =>
            setFilter({ ...filter, [key]: value })
          }
          reset={handleReset}
        />
        <Row
          style={{ margin: '10px 0' }}
          gutter={16}
          type="flex"
          justify="space-between"
        >
          <Col span={8}>
            <Row>
              <Text strong style={{ marginBottom: '10px' }}>
                {t('header.menu.filteredTab.dateRange')}
              </Text>
            </Row>
            <Row>
              <RangePicker
                style={{ width: '100%' }}
                value={
                  range.length > 0
                    ? [moment(rangeDates[0]), moment(rangeDates[1])]
                    : []
                }
                onChange={onDateChange}
                allowClear
                renderExtraFooter={() => (
                  <DatePickerFooter
                    dateField={dateField}
                    handleDateFieldChange={(value) => setDateField(value)}
                  />
                )}
              />
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  );
};

FilterForResourceLandingLayout.propTypes = {
  optionsDisplay: PropTypes.shape({}).isRequired,
  setOptionsDisplay: PropTypes.func.isRequired,
  filter: PropTypes.shape({}).isRequired,
  setFilter: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  rangeDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDateChange: PropTypes.func.isRequired,
  dateField: PropTypes.string.isRequired,
  setDateField: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};
export default FilterForResourceLandingLayout;
