const headers = [
  {
    label: 'name',
    key: 'name'
  },
  {
    label: 'group',
    key: 'group'
  },
  {
    label: 'created_at',
    key: 'created_at'
  },
  {
    label: 'address.number',
    key: 'address.number'
  },
  {
    label: 'address.street',
    key: 'address.street'
  },
  {
    label: 'address.additional',
    key: 'address.additional'
  },
  {
    label: 'address.postal_code',
    key: 'address.postal_code'
  },
  {
    label: 'address.city',
    key: 'address.city'
  },
  {
    label: 'address.state',
    key: 'address.state'
  },
  {
    label: 'address.country',
    key: 'address.country'
  },
  {
    label: 'siren',
    key: 'siren'
  },
  {
    label: 'APE',
    key: 'APE'
  },
  {
    label: 'capital',
    key: 'capital'
  },
  {
    label: 'legal_status',
    key: 'legal_status'
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'contact.email',
    key: 'contact.email'
  },
  {
    label: 'contact.civility',
    key: 'contact.civility'
  },
  {
    label: 'contact.first_name',
    key: 'contact.first_name'
  },
  {
    label: 'contact.last_name',
    key: 'contact.last_name'
  },
  {
    label: 'contact.address.number',
    key: 'contact.address.number'
  },
  {
    label: 'contact.address.street',
    key: 'contact.address.street'
  },
  {
    label: 'contact.address.additional',
    key: 'contact.address.additional'
  },
  {
    label: 'contact.address.postal_code',
    key: 'contact.address.postal_code'
  },
  {
    label: 'contact.address.city',
    key: 'contact.address.city'
  },
  {
    label: 'contact.address.state',
    key: 'contact.address.state'
  },
  {
    label: 'contact.address.country',
    key: 'contact.address.country'
  },
  {
    label: 'contact.phone_number.country_code',
    key: 'contact.phone_number.country_code'
  },
  {
    label: 'contact.phone_number.number',
    key: 'contact.phone_number.number'
  },
  {
    label: 'contact.phone_number2.country_code',
    key: 'contact.phone_number2.country_code'
  },
  {
    label: 'contact.phone_number2.number',
    key: 'contact.phone_number2.number'
  },
  {
    label: 'contact.function',
    key: 'contact.function'
  },
  {
    label: 'number',
    key: 'number'
  },
  {
    label: 'convention.number',
    key: 'convention.number'
  },
  {
    label: 'convention.date',
    key: 'convention.date'
  },
  {
    label: 'payment_delay',
    key: 'payment_delay'
  }
];

export default headers;
