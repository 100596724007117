import React from 'react';
import {
  Button,
  Divider,
  Icon,
  Popconfirm,
  Row,
  Skeleton,
  Table,
  Tag,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { ContentCustom } from '../../components';
import { invoiceTypesColors, productsTypesColors } from '../../utils';

const { Column } = Table;
const iconSize = '18px';

const ClientInvoicesProducts = ({
  isLoading,
  clientSupplierProducts,
  pagination3,
  setPagination3,
  editProduct,
  del
}) => {
  const { t } = useTranslation();
  return (
    <ContentCustom marginTop={8}>
      <Row type="flex" justify="space-between">
        <Typography.Title level={4}>
          {t('catalog.list.clientInvoiceProduct.title')}
        </Typography.Title>
      </Row>
      <Skeleton
        active
        title={false}
        loading={isLoading}
        paragraph={{
          rows: 8,
          width: [
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%'
          ]
        }}
      >
        <Table
          rowClassName="rowStyle"
          dataSource={clientSupplierProducts}
          loading={isLoading}
          pagination={pagination3}
          onChange={(page) => {
            setPagination3(page);
          }}
          rowKey="_id"
          scroll={{ x: 800 }}
        >
          <Column
            title={t('catalog.list.clientInvoiceProduct.productName')}
            dataIndex="name"
            align="center"
            sorter={(a, b) => a.name.localeCompare(b.name)}
            sortDirections={['ascend', 'descend']}
            render={(name) => <Typography>{name}</Typography>}
          />
          <Column
            title={t('catalog.list.clientInvoiceProduct.productType')}
            dataIndex="type.label"
            align="center"
            sorter={(a, b) => a.type.label.localeCompare(b.type.label)}
            sortDirections={['ascend', 'descend']}
            render={(label) => (
              <Tag color={productsTypesColors[label]}>{label}</Tag>
            )}
          />
          <Column
            title={t('catalog.list.clientInvoiceProduct.invoiceType')}
            dataIndex="type.invoice_type.label"
            align="center"
            sorter={(a, b) =>
              a.invoice_type.label.localeCompare(b.invoice_type.label)
            }
            sortDirections={['ascend', 'descend']}
            render={(label) => (
              <Tag color={invoiceTypesColors[label]}>{label}</Tag>
            )}
          />
          <Column
            title={t('catalog.list.clientInvoiceProduct.productPrice')}
            dataIndex="price"
            align="center"
            sorter={(a, b) => a.price - b.price}
            sortDirections={['ascend', 'descend']}
            render={(price) =>
              price ? `${numeral(price).format('0,0.00')} €` : '-'
            }
          />
          <Column
            title={t('users.list.column.actions')}
            key="action"
            fixed="right"
            render={({ _id }) => (
              <span>
                <Button type="link" onClick={() => editProduct(_id)}>
                  <Icon type="edit" style={{ fontSize: iconSize }} />
                </Button>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('catalog.list.column.action.delete.title')}
                  okText={t('catalog.list.column.action.delete.ok')}
                  cancelText={t('catalog.list.column.action.delete.cancel')}
                  onConfirm={() => del(_id, 'products')}
                  icon={<Icon type="warning" />}
                >
                  <Icon
                    style={{ color: 'red', fontSize: iconSize }}
                    type="delete"
                  />
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      </Skeleton>
    </ContentCustom>
  );
};

ClientInvoicesProducts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  clientSupplierProducts: PropTypes.arrayOf().isRequired,
  pagination3: PropTypes.shape({}).isRequired,
  setPagination3: PropTypes.func.isRequired,
  editProduct: PropTypes.func.isRequired,
  del: PropTypes.func.isRequired
};

export default ClientInvoicesProducts;
