import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Form, message, Spin } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import peopleFields from './fields';
import InputGenerator from '../../components/inputGenerator';
import { formItemLayout, tailFormItemLayout } from '../../utils/formLayouts';
import { ContentCustom } from '../../components';

const CreateAndEditPeopleForm = ({
  id,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    setFields
  }
}) => {
  const history = useHistory();
  const { dispatchAPI, isLoading } = useAuthContext();
  const { configs } = useConfigsContext();
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);

  const getPeople = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: `/people/${id}`
      });
      const { id_number, first_name, last_name, company, site } = result.data;
      setFieldsValue({
        id_number,
        first_name,
        last_name,
        company,
        site
      });
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
  };

  const getCompanyNames = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: '/companies'
      });
      result.data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
      setCompanies(
        // eslint-disable-next-line no-underscore-dangle
        result.data.map((company) => ({
          ...company,
          key: company._id,
          value: company._id,
          text: `${company.name}`
        }))
      );
    } catch (e) {
      message.error(e.message);
    }
  };

  const messageError = (error, values) => {
    message.error(`${t('global.form.error.message')}`);
    const fieldsInvalid = error.response.data.error.fields;
    Object.entries(fieldsInvalid).forEach(([field, value]) => {
      setFields({
        [field]: {
          value: values[field],
          errors: [
            new Error(
              (value.kind === 'required' && t('global.form.error.required')) ||
                (value.kind === 'unique' && t('global.form.error.unique')) ||
                t('global.form.error.invalid')
            )
          ]
        }
      });
    });
  };

  const createPeople = async (values) => {
    const body = { ...values };
    try {
      await dispatchAPI('POST', { url: '/people', body });
      history.goBack();
    } catch (e) {
      messageError(e, values);
    }
  };

  const updatePeople = async (values) => {
    const body = { ...values };
    try {
      await dispatchAPI('PATCH', {
        url: `/people/${id}`,
        body
      });
      getPeople();
    } catch (e) {
      messageError(e, values);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (id) {
          await updatePeople(values);
        } else if (!id) {
          await createPeople(values);
        }
      }
    });
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (id) {
      getPeople();
    }
    getCompanyNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (configs && companies && !isLoading) {
      setFieldsValue({ company: configs.client });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs, isLoading, companies]);

  return (
    <ContentCustom>
      {isLoading ? (
        <Spin
          style={{
            textAlign: 'center',
            borderRadius: '4px',
            marginBottom: '20px',
            padding: '30px 50px',
            margin: '20px 0',
            width: '100%',
            height: '100%'
          }}
        />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Form {...formItemLayout} onSubmit={handleSubmit}>
          <InputGenerator
            title="people"
            fields={peopleFields(companies, !!configs)}
            getFieldDecorator={getFieldDecorator}
          />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {t('users.editcreate.form.submitButton')}
            </Button>
            <Button
              type="danger"
              onClick={() => goBack()}
              style={{ margin: '0 10px' }}
            >
              {t('users.editcreate.form.cancel')}
            </Button>
          </Form.Item>
        </Form>
      )}
    </ContentCustom>
  );
};

CreateAndEditPeopleForm.defaultProps = {
  id: null
};

CreateAndEditPeopleForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired
  }).isRequired,
  id: PropTypes.string
};

const WrappedCreateAndEditPeopleAntForm = Form.create({ name: 'register' })(
  CreateAndEditPeopleForm
);

export default WrappedCreateAndEditPeopleAntForm;
