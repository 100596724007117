import React from 'react';
import { Button, Icon, Input, Checkbox, Divider, Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const SiteForm = ({
  ticketType,
  ticket,
  getFieldDecorator,
  isSitesLoading,
  getCompanySites,
  companySites,
  company,
  siteId,
  noLabelLayout
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: ticketType === 'COLLECTION' ? 'initial' : 'none'
      }}
    >
      <Divider orientation="left">
        {t('tickets.fields.divider.address')}
      </Divider>
      <Form.Item
        label={t('tickets.fields.site')}
        extra={
          !company && (
            <>
              <Icon
                type="warning"
                style={{
                  fontSize: '16px',
                  color: '#fadb14'
                }}
              />
              <Button type="link" style={{ fontSize: 12 }}>
                Choisissez une companie avant !
              </Button>
            </>
          )
        }
      >
        {getFieldDecorator('siteId')(
          <Select
            allowClear
            notFoundContent={null}
            loading={isSitesLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => getCompanySites(value)}
          >
            {companySites.map(({ _id, name }) => (
              <Option value={_id} key={_id}>
                {name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('tickets.fields.siteName')}>
        {getFieldDecorator('site.name', {
          rules: [
            {
              required: ticketType === 'COLLECTION',
              message: t('global.form.requiredMessage')
            }
          ],
          initialValue: ticket && ticket.site && ticket.site.name
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('tickets.fields.siteNumber')}>
        {getFieldDecorator('site.address.number', {
          rules: [
            {
              required: ticketType === 'COLLECTION',
              message: t('global.form.requiredMessage')
            }
          ],
          initialValue:
            ticket &&
            ticket.site &&
            ticket.site.address &&
            ticket.site.address.number
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('tickets.fields.siteStreet')}>
        {getFieldDecorator('site.address.street', {
          rules: [
            {
              required: ticketType === 'COLLECTION',
              message: t('global.form.requiredMessage')
            }
          ],
          initialValue:
            ticket &&
            ticket.site &&
            ticket.site.address &&
            ticket.site.address.street
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('tickets.fields.siteAdditional')}>
        {getFieldDecorator('site.address.additional', {
          initialValue:
            ticket &&
            ticket.site &&
            ticket.site.address &&
            ticket.site.address.additional
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('tickets.fields.sitePostalCode')}>
        {getFieldDecorator('site.address.postal_code', {
          rules: [
            {
              required: ticketType === 'COLLECTION',
              message: t('global.form.requiredMessage')
            }
          ],
          initialValue:
            ticket &&
            ticket.site &&
            ticket.site.address &&
            ticket.site.address.postal_code
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('tickets.fields.siteCity')}>
        {getFieldDecorator('site.address.city', {
          rules: [
            {
              required: ticketType === 'COLLECTION',
              message: t('global.form.requiredMessage')
            }
          ],
          initialValue:
            ticket &&
            ticket.site &&
            ticket.site.address &&
            ticket.site.address.city
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('tickets.fields.siteCountry')}>
        {getFieldDecorator('site.address.country', {
          rules: [
            {
              required: ticketType === 'COLLECTION',
              message: t('global.form.requiredMessage')
            }
          ],
          initialValue:
            ticket &&
            ticket.site &&
            ticket.site.address &&
            ticket.site.address.country
        })(<Input />)}
      </Form.Item>
      <Form.Item {...noLabelLayout}>
        {getFieldDecorator('createUpdateSite', {
          valuePropName: 'checked'
        })(
          <Checkbox>
            {t(`tickets.fields.${siteId ? 'update' : 'create'}`)}
          </Checkbox>
        )}
      </Form.Item>
    </div>
  );
};

SiteForm.propTypes = {
  ticketType: PropTypes.string.isRequired,
  ticket: PropTypes.shape({
    site: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.shape({
        country: PropTypes.string,
        city: PropTypes.string,
        postal_code: PropTypes.string,
        additional: PropTypes.string,
        street: PropTypes.string,
        number: PropTypes.string
      })
    })
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  isSitesLoading: PropTypes.bool.isRequired,
  getCompanySites: PropTypes.bool.isRequired,
  companySites: PropTypes.arrayOf().isRequired,
  company: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  noLabelLayout: PropTypes.shape({}).isRequired
};

export default SiteForm;
