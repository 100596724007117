import React from 'react';
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from './login';
import Home from './home';
import ListUsers from './users/ListUsers';
import EditAndCreateUser from './users/EditAndCreateUser';
import ShowUser from './users/ShowUser';
import ListContracts from './contracts/ListContracts';
import EditAndCreateContract from './contracts/EditAndCreateContract';
import ShowContract from './contracts/ShowContract';
import ListModels from './models/ListModels';
import AssetsRouter from './assets/AssetsRouter';
import ShowModel from './models/ShowModel';
import ListPeople from './people/ListPeople';
import Exception from '../components/Exceptions/Exception';
import useAuthContext from '../contexts/AuthContext';
import CreateAndEditPeople from './people/CreateAndEditPeople';
import ShowPeople from './people/ShowPeople';
import ListTickets from './tickets/ListTickets';
import EditAndCreateTicket from './tickets/Edit&Create';
import ShowTicket from './tickets/ShowTicket';
import ConfigClient from './configClient';
import ListOrders from './Orders/ListOrders';
import EditAndCreateOrder from './Orders/Edit&Create';
import ShowOrder from './Orders/ShowOrder';
import ListPayment from './payments/ListPayment';
import ListNotifications from './notifications/ListNotifications';
import ShowNotification from './notifications/ShowNotification';
import EditAndCreatePayment from './payments/Edit&Create';

import ShowPV from './pvs/ShowPV';
import { BasicLayout } from '../layouts';
import CompaniesRouter from './companies/CompaniesRouter';
import InvoicesRouter from './invoices/InvoicesRouter';
import ListPVs from './pvs/ListPVs';
import EditAndCreatePV from './pvs/Edit&Create';

import ShowPayment from './payments/ShowPayment';
import ListCatalog from './settings/ListCatalog';

export const PrivateRoute = ({ component: Component, ...restProps }) => {
  const location = useLocation();
  const authContext = useAuthContext();
  const splitPath = location.pathname.split('/');
  let path = '/';
  if (splitPath[1]) path = `/${splitPath[1]}`;
  if (splitPath[2] && !['show', 'edit', 'create'].includes(splitPath[2]))
    path = `/${splitPath[1]}/${splitPath[2]}`;

  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps}
      render={(props) =>
        authContext.isValid ? (
          <BasicLayout path={path}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...props} />
          </BasicLayout>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.arrayOf(PropTypes.string),
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

PrivateRoute.defaultProps = {
  location: { pathname: '/' }
};

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login/:token" component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Login} />
        <PrivateRoute exact path="/users" component={ListUsers} />
        <PrivateRoute exact path="/contracts" component={ListContracts} />
        <PrivateRoute exact path="/models" component={ListModels} />
        <PrivateRoute path="/companies" component={CompaniesRouter} />
        <PrivateRoute path="/invoices" component={InvoicesRouter} />
        <PrivateRoute
          exact
          path="/users/create"
          component={EditAndCreateUser}
        />
        <PrivateRoute
          exact
          path="/contracts/create"
          component={EditAndCreateContract}
        />
        <PrivateRoute
          exact
          path="/people/create"
          component={CreateAndEditPeople}
        />
        <PrivateRoute path="/users/edit/:id" component={EditAndCreateUser} />
        <PrivateRoute
          path="/contracts/edit/:id"
          component={EditAndCreateContract}
        />
        <PrivateRoute path="/people/edit/:id" component={CreateAndEditPeople} />
        <PrivateRoute path="/users/show/:id" component={ShowUser} />
        <PrivateRoute path="/contracts/show/:id" component={ShowContract} />
        <PrivateRoute path="/models/show/:id" component={ShowModel} />
        <PrivateRoute path="/people/show/:id" component={ShowPeople} />
        <PrivateRoute path="/tickets/show/:id" component={ShowTicket} />
        <PrivateRoute path="/assets" component={AssetsRouter} />
        <PrivateRoute exact path="/people" component={ListPeople} />
        <PrivateRoute exact path="/client-settings" component={ConfigClient} />
        <PrivateRoute exact path="/products" component={ListCatalog} />
        <PrivateRoute
          exact
          path="/tickets/edit/:id"
          component={EditAndCreateTicket}
        />
        <PrivateRoute exact path="/tickets" component={ListTickets} />
        <PrivateRoute
          exact
          path="/tickets/create"
          component={EditAndCreateTicket}
        />
        <PrivateRoute exact path="/orders" component={ListOrders} />
        <PrivateRoute
          exact
          path="/orders/create"
          component={EditAndCreateOrder}
        />
        <PrivateRoute
          exact
          path="/orders/edit/:id"
          component={EditAndCreateOrder}
        />
        <PrivateRoute
          exact
          path="/payments/create"
          component={EditAndCreatePayment}
        />
        <PrivateRoute
          exact
          path="/payments/edit/:id"
          component={EditAndCreatePayment}
        />
        <PrivateRoute exact path="/orders/show/:id" component={ShowOrder} />
        <PrivateRoute exact path="/payments" component={ListPayment} />
        <PrivateRoute exact path="/payments/show/:id" component={ShowPayment} />
        <PrivateRoute
          exact
          path="/notifications"
          component={ListNotifications}
        />
        <PrivateRoute
          exact
          path="/notifications/show/:id"
          component={ShowNotification}
        />
        <PrivateRoute exact path="/pvs" component={ListPVs} />
        <PrivateRoute exact path="/pvs/create" component={EditAndCreatePV} />
        <PrivateRoute exact path="/pvs/edit/:id" component={EditAndCreatePV} />
        <PrivateRoute exact path="/pvs/show/:id" component={ShowPV} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute component={Exception} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
