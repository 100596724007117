/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { View, Text } from '@react-pdf/renderer';

const FooterInvoiceExport = () => {
  return (
    <View>
      <Text style={{ fontSize: 10, marginBottom: 10, color: '#615A22' }}>
        Veuillez libeller tous les chèques à l’ordre de LEASETIC SAS. Pour toute
        question concernant cette facture, contactez Emmanuel ROUSSEAU, 06 81 27
        21 48, emmanuel.rousseau@leasetic.com Inscrit au Registre Unique des
        Intermédiaires en Opération de Banque de l&apos;ORIAS sous le N°17005324
      </Text>
      <Text style={{ fontSize: 10, marginBottom: 5, color: '#615A22' }}>
        Banque Populaire IBAN : FR76 1780 7000 7675 5211 5795 474 CCBPFRPPTLS
      </Text>
      <Text style={{ fontSize: 10, marginBottom: 5, color: '#615A22' }}>
        Caisse d&apos;Épargne IBAN : FR76 1313 5000 8008 0047 4514 447
        CEPAFRPP313
      </Text>

      <Text style={{ fontSize: 10, color: '#615A22' }}>Code NAF 7733Z</Text>

      <Text style={{ fontSize: 13, fontWeight: 'bolder', color: '#615A22' }}>
        NOUS VOUS REMERCIONS DE VOTRE CONFIANCE.
      </Text>
    </View>
  );
};

export default FooterInvoiceExport;
