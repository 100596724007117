import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, Input, message, Icon } from 'antd';
import useAuthContext from '../../contexts/AuthContext';

const ForgotPwdForm = ({
  switchForm,
  form: { getFieldDecorator, validateFields }
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setLoading] = useState(false);

  const askForPwdReset = async (email) => {
    setLoading(true);
    try {
      await dispatchAPI('GET', { url: `reset-pwd/${email}` });
      message.success(t('login.resetPwdEmailSent', { email }));
    } catch (e) {
      message.error(t('login.resetPwdEmailError'));
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        await askForPwdReset(values.username.toLowerCase());
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [{ required: true, message: t('login.usernameMissing') }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={t('login.username')}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.resetPwd')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          block
          style={{ width: '100%' }}
          onClick={() => switchForm('login')}
        >
          {t('backButton')}
        </Button>
      </Form.Item>
    </Form>
  );
};

ForgotPwdForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired,
  switchForm: PropTypes.func.isRequired
};

const WrappedForgotPwdForm = Form.create({ name: 'forgotPwd' })(ForgotPwdForm);

export default WrappedForgotPwdForm;
