/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Divider, Icon, Table, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';

const { Column } = Table;
const iconSize = '18px';

const SuppliersTab = ({ company }) => {
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const getSuppliers = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?to=${company}&invoice_type=SUPPLIER&populate=from`
      });
      const supplierList = {};
      data.forEach((invoice) => {
        if (!supplierList[invoice.from.name]) {
          supplierList[invoice.from.name] = invoice.from;
        }
      });
      setSuppliers(Object.values(supplierList));
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getSuppliers();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPagination({ ...pagination, total: suppliers.length });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliers]);

  const suppliersNameOptions =
    suppliers &&
    suppliers.map(({ _id, name }) => ({
      value: name,
      text: name
    }));

  const suppliersGroupOptions =
    suppliers &&
    suppliers.map(({ _id, group }) => ({
      value: group,
      text: group
    }));

  return (
    <Table
      loading={isLoading}
      dataSource={suppliers.map(({ _id, ...supplier }) => ({
        ...supplier,
        key: _id
      }))}
      pagination={pagination}
      rowClassName="rowStyle"
      onRow={(record) => ({
        onDoubleClick: () =>
          history.push(`/companies/supplier/show/${record.key}`)
      })}
    >
      <Column
        title={t('companies.list.column.legal_status')}
        key="legalStatus"
        sorter={(a, b) => a.legal_status.localeCompare(b.legal_status)}
        sortDirections={['ascend', 'descend']}
        render={(supplier) => <Typography>{supplier.legal_status}</Typography>}
      />
      <Column
        title={t('companies.list.column.name')}
        key="name"
        filters={suppliersNameOptions}
        filterMultiple
        onFilter={(value, record) => record.name.indexOf(value) === 0}
        render={(supplier) => <Typography>{`${supplier.name}`}</Typography>}
      />
      <Column
        title={t('companies.list.column.group')}
        key="group"
        filters={suppliersGroupOptions}
        filterMultiple
        onFilter={(value, record) => record.group.indexOf(value) === 0}
        render={(supplier) => <Typography>{supplier.group}</Typography>}
      />
      <Column
        title={t('users.list.column.actions')}
        key="action"
        render={(record) => (
          <span>
            <Link to={`/companies/supplier/show/${record.key}`}>
              <Icon type="eye" style={{ fontSize: iconSize }} />
            </Link>
            <Divider type="vertical" />
            <Link to={`/companies/supplier/edit/${record.key}`}>
              <Icon type="edit" style={{ fontSize: iconSize }} />
            </Link>
          </span>
        )}
      />
    </Table>
  );
};

SuppliersTab.propTypes = {
  company: PropTypes.string
};

SuppliersTab.defaultProps = {
  company: null
};

export default SuppliersTab;
