import moment from 'moment';
import numeral from 'numeral';

const chartsElementsReducer = (state, action) => {
  const { data, timeScale, t } = action.payload;

  const tmpData = [];
  const monthList = [];
  const fieldList = [];
  const startingMonth = moment(timeScale[0]);
  // eslint-disable-next-line prefer-destructuring
  const endingMonth = timeScale[1];
  while (startingMonth <= endingMonth) {
    monthList.push(startingMonth.format('YYYY-MM')); // Set list of months readable by moment
    fieldList.push(startingMonth.format('MM-YYYY')); // Set Chart fields
    startingMonth.add(1, 'month');
  }

  switch (action.type) {
    case 'insurance_compensation':
      if (data.count) {
        monthList.forEach((month) => {
          const d = data.count.find(({ _id }) => _id === month);
          tmpData.push({
            month: moment(month).format('MM-YYYY'),
            count: (d || {}).count
          });
        });
      }
      return {
        data: tmpData,
        fields: ['count'],
        type: 'fold',
        key: 'count',
        value: 'count',
        xAxis: 'month',
        yAxis: 'count',
        position: 'month*count',
        cols: {
          count: {
            formatter: (v) => `${numeral(v).format('0,0')}`
          }
        },
        customTooltip: [
          'count',
          (c) => ({
            name: t(`home.dash.legends.assetQuantity`),
            value: `${numeral(c).format('0,0')}`
          })
        ],
        geomType: 'intervalStack'
      };
    default:
      return {};
  }
};

export default chartsElementsReducer;
