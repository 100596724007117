import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EditAndCreateCompanyForm from './EditAndCreateCompanyForm';
import { PageHeaderCustom } from '../../components';

const EditAndCreateCompany = ({ purpose }) => {
  const { id, type } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t(`companies.${purpose}`)} />
      <EditAndCreateCompanyForm
        title={type}
        initialType={[type.toUpperCase()]}
        id={id}
      />
    </>
  );
};

EditAndCreateCompany.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default EditAndCreateCompany;
