export const ITAssetsHeaders = {
  'Adresse IP': 'ip_address',
  Avenant: {
    collection: 'Contract',
    collection_field: 'number',
    field: 'contract'
  },
  'CO2 kg/an': 'co2_year',
  CPU: 'cpu',
  'Code produit': 'product_code',
  'Consommation énergétique kWh/an': 'power_consumption',
  Constructeur: 'brand',
  'Coûts de rétrologistique': 'retrologistic_cost',
  "Date d'entrée": 'entry_date',
  'Date de la Commande': 'order_date',
  'Date de livraison': 'delivery_date',
  'Date de sortie': 'exit_date',
  'Date prévisionnelle de remplacement': 'replacement_date',
  Filiale: {
    collection: 'Subsidiary',
    collection_field: 'name',
    field: 'subsidiary'
  },
  'Impact carbone Fabrication (kg CO2)': 'carbon_footprint',
  Label: 'description',
  Matricule: {
    collection: 'People',
    collection_field: 'id_number',
    field: 'people'
  },
  'Montant Remboursement Assurance': 'insurance_refund',
  'Montant de la valorisation': 'valorisation_amount',
  'Motif de sortie': 'exit_reason',
  'Mémoire Disque': 'hdd',
  'N° EasyVista': 'EasyVista_request_serial_number',
  'N° de Commande': 'order_number',
  'N° de PV': 'pv_number',
  'N° de série': 'serial_number',
  "N° d'immobilisation": 'master_record_number',
  OS: 'os',
  'Prix unitaire': 'unit_price',
  Propriétaire: {
    collection: 'Company',
    collection_field: 'name',
    field: 'owner'
  },
  RAM: 'ram',
  Site: { collection: 'Site', collection_field: 'name', field: 'site' },
  Statut: 'status',
  Type: 'genre',
  'âge (mois)': 'age',
  Loyer: 'rent',
  'Poids Recyclage': 'recycling',
  'Grade Réemploi': 're_use',
  Dons: 'donations',
  'Date Audit': 'audit_date',
  Réparation: 'repair',
  'Montant Réparation': 'repair_amount',
  'Valeur Résiduelle Nette Comptable': 'net_book_value',
};

export const TelecomAssetsHeaders = {
  'N° de Ligne': 'phone_Number',
  Matricule: {
    collection: 'People',
    collection_field: 'id_number',
    field: 'people'
  },
  Filiale: {
    collection: 'Subsidiary',
    collection_field: 'name',
    field: 'subsidiary'
  }
};

export const PeopleHeaders = {
  'E-mail': 'email',
  Prénom: 'first_name',
  Nom: 'last_name',
  Matricule: 'id_number',
  'Date sortie': 'exit_date',
  Ctrt: 'contract_type',
  'Emploi type': 'job',
  'Famille emploi': 'job_type',
  'Filière métier': 'job_category',
  Filiale: {
    collection: 'Subsidiary',
    collection_field: 'name',
    field: 'subsidiary'
  }
};

export const TelecomInvoiceHeaders = {
  Opérateur: 'provider',
  'Date Facture': 'date',
  Forfait: {
    collection: 'PhoneContract',
    collection_field: 'name',
    field: 'phone_contract'
  },
  'N° ligne': {
    collection: 'Telecom',
    collection_field: 'phone_Number',
    field: 'asset'
  },
  'N° compte': 'contract_number',
  'N°Facture': 'number',
  'Hors Forfait SMS/MMS': 'overage_messages_count',
  'Hors Forfait Voix (s)': 'overage_voice_seconds',
  'Hors forfait Data (ko)': 'overage_data_ko',
  'Dépassement Voix(s) N° Spéciaux': 'overage_voice_special_seconds',
  'Dépassement Voix (s) étranger': 'overage_voice_international_seconds',
  'Dépassement Data (Ko) étranger': 'overage_data_international_ko',
  'Montant Total Net': 'amount',
  'Montant Net Dépassement Data étranger': 'overage_data_international_amount',
  'Montant Net Dépassement SMS/MMS N° Spéciaux':
    'overage_messages_special_amount',
  'Montant Net Dépassement SMS/MMS étranger':
    'overage_messages_international_amount',
  'Montant Net Dépassement Voix N° Spéciaux': 'overage_voice_special_amount',
  'Montant Net Dépassement Voix étranger': 'overage_voice_international_amount',
  'Montant Net Hors Forfait Voix': 'overage_voice_amount',
  'Montant Net Hors forfait SMS/MMS': 'overage_messages_amount',
  'Montant net Hors forfait Data': 'overage_data_amount',
  'Dépassement SMS/MMS N° Spéciaux': 'overage_messages_special_count',
  'Dépassement SMS/MMS étranger': 'overage_messages_international_count',
  'Consommation Totale Data (ko)': 'consumption_data_ko',
  'Consommation voix totale (s)': 'consumption_voice_seconds',
  'Consommation totale SMS/MMS': 'consumption_messages_count',
  'Montant Total Remises': 'credits_amount'
};
