import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import ShowCompany from './ShowCompany';
import ListCompanies from './ListCompanies';
import EditAndCreateCompany from './EditAndCreateCompany';

const CompaniesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:type/show/:id`} component={ShowCompany} />
      <Route
        path={`${path}/:type/edit/:id`}
        render={() => <EditAndCreateCompany purpose="edit" />}
      />
      <Route
        path={`${path}/:type/create`}
        render={() => <EditAndCreateCompany purpose="create" />}
      />
      <Route path={`${path}/:type`} component={ListCompanies} />
      <Route path={path} render={() => <Redirect to="/companies/client" />} />
    </Switch>
  );
};

export default CompaniesRouter;
