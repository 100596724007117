import React, { useEffect, useState } from 'react';
import { Button, Icon, Popconfirm, Spin, Typography } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import ReportsTable from './ReportsTable';
import TicketInformation from './TicketInformation';
import TicketAssets from './TicketAssets';

const { Title } = Typography;

const ShowTicket = () => {
  const { id } = useParams();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const { t } = useTranslation();
  const [ticket, setTicket] = useState({});
  const [badgeCount, setBadgeCount] = useState(0);
  const [assetsEnumValues, setAssetsEnums] = useState([]);
  const [assets, setAssets] = useState(null);

  const getTicket = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/tickets/${id}?populate=assets,files,audit_report,erasure_report,destruction_report`
    });
    setTicket({
      ...data,
      erasure_report: data.erasure_report.map((report) => ({
        ...report,
        type: 'ERASURE'
      })),
      destruction_report: data.destruction_report.map((report) => ({
        ...report,
        type: 'DESTRUCTION'
      }))
    });
    let fileCount = 0;
    if (data.files && data.files.length) {
      fileCount += data.files.length;
    }
    if (data.audit_report) fileCount += 1;
    setBadgeCount(fileCount);
    const tmp = [];
    // eslint-disable-next-line no-underscore-dangle
    data.assets.forEach((asset) => tmp.push({ ...asset, key: asset._id }));
    setAssets(tmp);
    setIsTableLoading(false);
  };

  const deleteTicket = async () => {
    await dispatchAPI('DELETE', { url: `/tickets/${id}` });
    history.goBack();
  };

  const getAssetsEnums = async () => {
    const result = await dispatchAPI('GET', { url: '/assets/enums' });
    setAssetsEnums(result.data);
  };

  useEffect(() => {
    setIsTableLoading(true);
    getTicket();
    getAssetsEnums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    ticket_number,
    type,
    company,
    erasure_report,
    destruction_report,
    status
  } = ticket;

  const setInvoice = () => {
    let total = 0;
    assets.forEach(({ contract }) => {
      total += contract && contract.sinister_cost ? contract.sinister_cost : 0;
    });
    history.push({
      pathname: '/invoices/client/create',
      state: {
        type: 'client',
        preFilledInfo: {
          seller: '5dcbfd7b88a97f13f0e34a6d',
          buyer: company,
          total,
          assets
        }
      }
    });
  };

  return (
    <>
      <PageHeaderCustom
        title={t('tickets.showTicket.title')}
        extra={
          <div>
            {ticket_number && (
              <span style={{ paddingRight: '100px' }}>{ticket_number}</span>
            )}
            {['THEFT', 'LOST', 'DAMAGE'].includes(type) && (
              <Button
                style={{ margin: '0 4px' }}
                type="primary"
                onClick={setInvoice}
              >
                {t('tickets.showTicket.invoiceButton')}
              </Button>
            )}
            {(type !== 'COLLECTION' ||
              ['OPEN', 'WAITING'].includes(status)) && (
              <Link to={`/tickets/edit/${id}`}>
                <Button type="primary" icon="edit">
                  {t('tickets.showTicket.editButton')}
                </Button>
              </Link>
            )}
            <Popconfirm
              title={t('tickets.showTicket.delete.title')}
              okText={t('tickets.showTicket.delete.ok')}
              cancelText={t('tickets.showTicket.delete.cancel')}
              onConfirm={deleteTicket}
              icon={<Icon type="warning" />}
            >
              <Button type="danger" icon="delete" style={{ margin: '0 4px' }}>
                {t('tickets.showTicket.deleteButton')}
              </Button>
            </Popconfirm>
          </div>
        }
      />
      {isTableLoading ? (
        <Spin
          style={{
            textAlign: 'center',
            borderRadius: '4px',
            marginBottom: '20px',
            padding: '30px 50px',
            margin: '20px 0',
            width: '100%',
            height: '100%'
          }}
        />
      ) : (
        <div>
          <TicketInformation
            id={id}
            ticket={ticket}
            badgeCount={badgeCount}
            getTicket={getTicket}
          />
          <TicketAssets
            isTableLoading={isTableLoading}
            assets={assets}
            assetsEnumValues={assetsEnumValues}
          />
          {(erasure_report || []).length ||
          (destruction_report || []).length ? (
            <ContentCustom marginTop={8}>
              <Title level={4}>{t('tickets.showTicket.reportsTitle')}</Title>
              <ReportsTable
                reports={[
                  ...(erasure_report || []),
                  ...(destruction_report || [])
                ]}
              />
            </ContentCustom>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

export default ShowTicket;
