import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

const { Content } = Layout;

const ContentCustom = ({ children, padding, marginTop }) => {
  return (
    <Content
      style={{
        padding: padding ? 24 : 0,
        marginTop: marginTop || 2,
        background: '#fff',
        overflow: 'hidden'
      }}
    >
      {children}
    </Content>
  );
};

ContentCustom.propTypes = {
  marginTop: PropTypes.number,
  padding: PropTypes.bool
};

ContentCustom.defaultProps = {
  marginTop: null,
  padding: true
};

export default ContentCustom;
