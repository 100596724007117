/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import { formItemLayout } from '../../utils/formLayouts';
import PaymentInformationForm from './PaymentInformationForm';
import PaymentList from './PaymentList';

const PaymentForm = ({
  form: { getFieldDecorator, validateFieldsAndScroll }
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [payment, setPayment] = useState({});
  const [allInvoices, setAllInvoices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [, setIsCompaniesLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState({ type: [] });
  const [filteredInfo, setFilteredInfo] = useState({
    type: null
  });
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });
  const [, setPaymentIsLoading] = useState(false);

  const getCompanies = useCallback(async () => {
    setIsCompaniesLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(
        data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
      );
    } catch (e) {
      console.error(e);
    }
    setIsCompaniesLoading(false);
  }, []);

  const getInvoiceEnums = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/invoices/enums' });
      setEnums(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const getInvoices = useCallback(
    async (page = pagination, filters = filteredInfo, selectMany) => {
      const { number, from, to, type, total, order_number } = filters;
      try {
        let result;
        if (selectMany) {
          result = await dispatchAPI('GET', {
            url: `/invoices?!payment_date&${type ? `type=${type}&` : ''}
            ${number ? `number=${number}&` : ''}
            ${to ? `to=${to}&` : ''}${from ? `from=${from}&` : ''}
            ${total ? `total=${total}&` : ''}
            ${order_number ? `order_number=${order_number}&` : ''}
            populate=from,contract,to`
          });
          const invoicesId = Object.values(result.data).map(({ _id }) => _id);
          setAllInvoices(invoicesId);
        } else {
          result = await dispatchAPI('GET', {
            url: `/invoices?!payment_date&populate=from,contract,to`
          });
        }
        setAllInvoices(
          result.data.map((invoice) => ({ ...invoice, key: invoice._id }))
        );
        setPagination({
          ...page,
          total: parseInt(result.headers['x-total-count'], 10)
        });
      } catch (e) {
        console.error(e);
      }
      setIsTableLoading(false);
    },
    []
  );

  const handleChange = async (page, filters) => {
    setPagination({ ...pagination, ...page });
    setIsTableLoading(true);
    setFilteredInfo(filters);
    await getInvoices(page, filters);
  };

  const getPayment = useCallback(async () => {
    setPaymentIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/payments/${id}?populate=invoices`
      });
      setPayment(data);
      const tmp = data.invoices.map((invoice) => ({
        ...invoice,
        key: invoice._id
      }));
      setInvoices(tmp);
      setAllInvoices(...allInvoices, tmp);
    } catch (e) {
      console.error(e);
    }
    setPaymentIsLoading(false);
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        await getPayment();
      })();
    }
    (async () => {
      await getInvoiceEnums();
      await getInvoices();
      await getCompanies();
    })();
  }, [getCompanies, getInvoiceEnums, getInvoices, getPayment, id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (invoices.length) {
      validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          setIsLoading(true);
          try {
            await dispatchAPI(id ? 'PATCH' : 'POST', {
              url: `/payments${id ? `/${id}` : ''}`,
              body: { ...values, invoices }
            });
            message.success(t(`payments.success.${id ? 'edit' : 'create'}`));
            history.goBack();
          } catch (e) {
            message.error(t('payments.error'));
          }
          setIsLoading(false);
        }
      });
    } else {
      message.error(t('payments.fields.error.noInvoices'));
    }
  };

  const selectInvoices = (keys) => {
    setInvoices(keys);
  };

  const selectAllInvoices = async (selected) => {
    if (selected) {
      setIsTableLoading(true);
      await getInvoices(pagination, filteredInfo, selected);
    } else {
      setInvoices([]);
    }
  };

  const handleSearch = (field, selectedKeys, confirm) => {
    setFilteredInfo({ ...filteredInfo, [field]: selectedKeys[0] });
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <PaymentInformationForm
        companies={companies}
        payment={payment}
        getFieldDecorator={getFieldDecorator}
      />
      <PaymentList
        allInvoices={allInvoices}
        isLoading={isLoading}
        isTableLoading={isTableLoading}
        handleChange={handleChange}
        handleReset={handleReset}
        handleSearch={handleSearch}
        pagination={pagination}
        invoices={invoices}
        selectInvoices={selectInvoices}
        selectAllInvoices={selectAllInvoices}
        enums={enums}
        filteredInfo={filteredInfo}
        id={id}
      />
    </Form>
  );
};

PaymentForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func,
    getFieldProps: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired
  }).isRequired
};

export default Form.create({ name: 'payment_form' })(PaymentForm);
