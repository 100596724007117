/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Form, Spin, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import { formItemLayout } from '../../utils/formLayouts';
import { ContentCustom } from '../../components';
import AddArticleForm from './AddArticleForm';
import {
  defaultCalculatedValues,
  defaultLoading,
  getInvoicesUtil,
  getOrdersUtil,
  handleSubmitUtil,
  makeArticle,
  setPVNumberUtil
} from './utils';
import BasePVForm from './BasePVForm';
import Buttons from './Buttons';

const { Title } = Typography;

const PVForm = ({
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    getFieldProps
  }
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [catalog, setCatalog] = useState([]);
  const [loading, setLoading] = useState(defaultLoading);
  const [fetchingPV, setFetchingPV] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [invoicesArticles, setInvoicesArticles] = useState([]);
  const [pv, setPV] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [articles, setArticles] = useState([makeArticle(0)]);
  const [calculatedValues, setCalculatedValues] = useState(
    defaultCalculatedValues
  );

  const getSettings = async () => {
    setLoading({ ...loading, catalogIsLoading: true });
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/products?category=SUPPLIERORDER&populate=type.invoice_type`
      });
      const products = data
        ? data.sort((a, b) => a.name.localeCompare(b.name))
        : [];
      console.log(products);
      setCatalog(products);
    } catch (e) {
      console.error(e);
    }
    setLoading({ ...loading, catalogIsLoading: false });
  };

  const getOrders = (searchValue) => {
    getOrdersUtil(searchValue, setIsOrdersLoading, dispatchAPI, setOrders);
  };

  const getPV = async () => {
    setFetchingPV(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/pvs/${id}`
      });
      setPV(data);
      if (data && data.articles && data.articles.length) {
        const tmp = [];
        data.articles.forEach((opt, idx) => {
          tmp.push(makeArticle(idx));
        });
        setArticles([...tmp]);
      }
      setFetchingPV(false);
    } catch (e) {
      console.error(e);
    }
  };

  const setSelectedOrder = (value) => {
    const found = orders.find((ord) => ord._id === value);
    setOrder([...order, found._id]);
    setRefresh(!refresh);
  };

  const deselectOrder = (value) => {
    const index = order.indexOf(value);
    if (index > -1) order.splice(index, 1);
    setOrder(order);
    setRefresh(!refresh);
  };

  const getInvoices = () => {
    getInvoicesUtil(dispatchAPI, order, setAllInvoices);
  };

  useEffect(() => {
    if (order.length > 0) {
      (async () => {
        await getInvoices();
      })();
    }
  }, [refresh]);

  const setSelectedInvoices = (value) => {
    const found = allInvoices.filter((invoice) => invoice._id === value);
    invoices.push(found[0]);
    setInvoices(invoices);
    let tab = [];
    invoices.map(
      (inv) =>
        (tab = tab.concat(
          inv.articles.map((art) => ({ ...art, invoiceId: inv._id }))
        ))
    );
    setInvoicesArticles(tab);
    setRefresh(!refresh);
  };

  const deselectInvoices = (value) => {
    const index = invoices.findIndex((item) => item._id === value);
    if (index > -1) invoices.splice(index, 1);
    setInvoices(invoices);
    let tab = [];
    invoices.map((inv) => (tab = tab.concat(inv.articles)));
    setInvoicesArticles(tab);
    setRefresh(!refresh);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitUtil(validateFieldsAndScroll, id, dispatchAPI, history, t);
  };

  const setPVNumber = () => {
    setPVNumberUtil(dispatchAPI, setFieldsValue);
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await getOrders();
        await getPV();
        await getInvoices();
      })();
    } else {
      (async () => {
        await setPVNumber();
      })();
    }
    (async () => {
      await getSettings();
    })();
  }, []);

  useEffect(() => {
    let tab = [];
    if (id && pv) {
      tab = pv.articles;
    } else {
      invoices.map((inv) => {
        tab = tab.concat(inv.articles);
      });
    }
    if (tab.length > 0) {
      const tmp = [];
      tab.forEach((opt, idx) => {
        tmp.push(makeArticle(idx));
      });
      setArticles([...tmp]);
    }
  }, [refresh, pv]);

  const addArticleField = () => {
    const tmp = articles;
    tmp.push(makeArticle(tmp.length));
    setArticles([...tmp]);
  };

  const deleteArticle = (key) => {
    const tmp = articles.filter(({ articleId }) => articleId !== key);
    setArticles([...tmp]);
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <ContentCustom>
        <Title level={2}>{t(`pvs.create.pvInfo`)}</Title>
        <Spin spinning={fetchingPV}>
          <BasePVForm
            pv={pv}
            getFieldDecorator={getFieldDecorator}
            isOrdersLoading={isOrdersLoading}
            orders={orders}
            getOrders={getOrders}
            setSelectedOrder={setSelectedOrder}
            deselectOrder={deselectOrder}
            allInvoices={allInvoices}
            deselectInvoices={deselectInvoices}
            setSelectedInvoices={setSelectedInvoices}
          />
          <AddArticleForm
            articles={articles}
            id={id}
            pv={pv}
            invoices={invoices}
            invoicesArticles={invoicesArticles}
            getFieldDecorator={getFieldDecorator}
            getFieldProps={getFieldProps}
            calculatedValues={calculatedValues}
            setCalculatedValues={setCalculatedValues}
            catalog={catalog}
            deleteArticle={deleteArticle}
          />
          <Buttons
            addArticleField={addArticleField}
            isLoading={isLoading}
            id={id}
          />
        </Spin>
      </ContentCustom>
    </Form>
  );
};

PVForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func,
    getFieldProps: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired
  }).isRequired
};

export default Form.create({ name: 'pv_form' })(PVForm);
