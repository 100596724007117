import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import wipIcon from '../../assets/images/wip.png';

const InProgress = ({ height, width }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2,
        backgroundColor: 'rgba(255,255,255,0.8)'
      }}
    >
      <div
        style={{
          height,
          width,
          position: 'absolute',
          top: `calc(50% - ${height / 2}px)`,
          left: `calc(50% - ${width / 2}px)`,
          textAlign: 'center'
        }}
      >
        <img src={wipIcon} alt="wip" style={{ height }} />
        <br />
        <Typography.Text strong>Mise à jour en cours</Typography.Text>
      </div>
    </div>
  );
};

InProgress.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default InProgress;
