import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { styles } from './utils';

const TableBodyForInvoiceExport = ({ index, article }) => {
  return (
    <View
      key={`row - ${index - 1}`}
      style={{
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'row',
        borderLeftWidth: 0.5,
        borderRightWidth: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0
      }}
    >
      <View
        key={`colLabelText - ${index - 1}`}
        style={{
          flex: 2,
          justifyContent: 'flex-end',
          borderTopWidth: 0,
          borderBottomWidth: 0.5
        }}
      >
        <Text
          key={`labelText - ${index - 1}`}
          style={{
            ...styles.tableContent,
            flex: 1,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          {article ? article.catalog_label : ` `}
        </Text>
      </View>

      <View
        key={`colDiscount - ${index - 1}`}
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          borderTopWidth: 0,
          borderLeftWidth: 0.5,
          borderLeftStyle: 'dashed',
          borderBottomWidth: 0.5
        }}
      >
        <Text
          key={`discountText - ${index - 1}`}
          style={{
            ...styles.tableContent,
            textAlign: 'center',
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          {article ? article.discount : ` `}
        </Text>
      </View>

      <View
        key={`colQuantityText - ${index - 1}`}
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          borderTopWidth: 0,
          borderLeftWidth: 0.5,
          borderLeftStyle: 'dashed',
          borderBottomWidth: 0.5
        }}
      >
        <Text
          key={`quantityText - ${index - 1}`}
          style={{
            ...styles.tableContent,
            textAlign: 'center',

            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          {article ? article.quantity : ` `}
        </Text>
      </View>

      <View
        key={`colUnit_price - ${index - 1}`}
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          borderLeftWidth: 0.5,
          borderLeftStyle: 'dashed',
          borderTopWidth: 0,
          borderBottomWidth: 0.5
        }}
      >
        <Text
          key={`unit_price - ${index - 1}`}
          style={{
            ...styles.tableContent,
            textAlign: 'center',

            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          {article ? `${numeral(article.unit_price).format('0,0.00')}€` : ` `}
        </Text>
      </View>

      <View
        key={`colAmount - ${index - 1}`}
        style={{
          flex: 2,
          justifyContent: 'flex-end',
          borderLeftWidth: 0.5,
          borderTopWidth: 0,
          borderBottomWidth: 0.5
        }}
      >
        <Text
          key={`amountValue - ${index - 1}`}
          style={styles.tableContentRight}
        >
          {article ? `${numeral(article.unit_price).format('0,0.00')}€` : ` `}
        </Text>
      </View>
    </View>
  );
};

TableBodyForInvoiceExport.propTypes = {
  index: PropTypes.number.isRequired,
  article: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    unit_price: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    catalog_label: PropTypes.string.isRequired
  }).isRequired
};
export default TableBodyForInvoiceExport;
