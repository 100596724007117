import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const { Column } = Table;

const AssetsTab = ({ assetsData }) => {
  const history = useHistory();
  const { isLoading } = useAuthContext();
  const { t } = useTranslation();
  const [assets, setAssets] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const handlePageChange = (page) => {
    setPagination(page);
  };

  useEffect(() => {
    if (assetsData && assetsData.length) {
      setAssets(assetsData.map((asset) => ({ ...asset, key: asset._id })));
      setPagination({ ...pagination, total: assetsData.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsData]);

  return (
    <Table
      dataSource={assets}
      loading={isLoading}
      onChange={handlePageChange}
      pagination={pagination}
      rowClassName="rowStyle"
      onRow={(record) => ({
        onClick: () =>
          history.push({
            pathname: `/assets/show`,
            state: { model: { name: record.type }, assetId: record.key }
          })
      })}
    >
      <Column
        title={t('assets.list.column.analytic_code')}
        key="analyticCode"
        render={(asset) => <Typography>{asset.analytic_code}</Typography>}
      />
      <Column
        title={t('assets.list.column.status')}
        key="status"
        render={(asset) => <Typography>{asset.status}</Typography>}
      />
      <Column
        title={t('assets.list.column.type')}
        key="type"
        render={(asset) => <Typography>{asset.type}</Typography>}
      />
    </Table>
  );
};

AssetsTab.defaultProps = {
  assetsData: null
};

AssetsTab.propTypes = {
  assetsData: PropTypes.arrayOf(PropTypes.shape({}))
};

export default AssetsTab;
