import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Tag
} from 'antd';
import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import './inputGenerator.css';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '40px'
};

const InputGenerator = ({
  title,
  fields,
  getFieldDecorator,
  extra,
  translation
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {fields.map(
        ({
          input,
          label,
          config,
          type,
          disabled,
          min,
          max,
          loading,
          required,
          parser,
          formatter,
          initialValue,
          help,
          extraRules,
          defaultChecked,
          ...item
        }) =>
          ((input === 'rangePicker' || input === 'datePicker') && (
            <Form.Item
              label={translation ? t(`${title}.form.${label}`) : label}
              key={label}
            >
              {getFieldDecorator(label, config)(
                (input === 'rangePicker' && <RangePicker />) ||
                  (input === 'datePicker' && <DatePicker />)
              )}
            </Form.Item>
          )) || (
            <Form.Item
              label={translation ? t(`${title}.form.${label}`) : label}
              key={label}
              help={help}
            >
              {getFieldDecorator(label, {
                initialValue,
                rules: [
                  {
                    type: type || 'string',
                    message: translation ? t(`global.form.typeMessage`) : label
                  },
                  {
                    required,
                    message: translation
                      ? t(`global.form.requiredMessage`)
                      : label
                  },
                  ...(extraRules || [])
                ]
              })(
                (input === 'number' && (
                  <InputNumber
                    style={{ width: item.width }}
                    disabled={disabled}
                    min={min}
                    max={max}
                    parser={parser}
                    formatter={formatter}
                  />
                )) ||
                  (input === 'radio' && item.buttons && (
                    <Radio.Group>
                      {item.buttons.map((button) => (
                        <Radio.Button value={button.value} key={button.value}>
                          {button.text}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  )) ||
                  (input === 'radio' && item.list && (
                    <Radio.Group>
                      {item.list.map((li) => (
                        <Radio
                          style={radioStyle}
                          value={li.value}
                          key={li.value}
                        >
                          {li.text}
                        </Radio>
                      ))}
                    </Radio.Group>
                  )) ||
                  (input === 'switch' && (
                    <Switch defaultChecked={defaultChecked} />
                  )) ||
                  (input === 'select' && (
                    <Select
                      loading={loading}
                      disabled={disabled}
                      mode={item.mode}
                    >
                      {item.options.map(({ value, text, color }) => (
                        <Option value={value} key={value}>
                          {color ? (
                            <Tag color={color}>{text.toUpperCase()}</Tag>
                          ) : (
                            text
                          )}
                        </Option>
                      ))}
                    </Select>
                  )) ||
                  (input === 'textArea' && <TextArea rows={item.rows} />) || (
                    <Input
                      disabled={disabled}
                      addonBefore={item.addOn}
                      addonAfter={item.addAfter}
                    />
                  )
              )}
              {(item.extra && extra(item.typeExtra)) || ''}
              {(item.extraSuffix && item.extraSuffix) || ''}
            </Form.Item>
          )
      )}
    </div>
  );
};

InputGenerator.defaultProps = {
  title: null,
  extra: null,
  translation: true
};

InputGenerator.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(
    shape({
      label: PropTypes.string.isRequired,
      required: PropTypes.bool,
      input: PropTypes.string,
      buttons: PropTypes.arrayOf(shape({}))
    })
  ).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  extra: PropTypes.func,
  translation: PropTypes.bool
};

export default InputGenerator;
