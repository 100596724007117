import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useStateWithSessionStorage } from '../../utils';

const InvoicesContext = createContext({});

export const InvoicesContextProvider = ({ children }) => {
  const [rangeDates, setRangeDates] = useState([]);
  const [dateField, setDateField] = useState('emission_date');
  const [filter, setFilter] = useStateWithSessionStorage('invoicesFilter', {
    paid: null,
    order: null,
    due: null
  });

  return (
    <InvoicesContext.Provider
      value={{
        rangeDates,
        setRangeDates,
        filter,
        setFilter,
        dateField,
        setDateField
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};

InvoicesContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(InvoicesContext);
