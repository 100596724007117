import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Table,
  Typography,
  Tag,
  Skeleton,
  Popconfirm,
  Icon,
  Divider
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { contractTypeColor } from '../../utils';

const { Column } = Table;
const ContractAmendments = ({ contract, deleteContract, isLoading }) => {
  const { t } = useTranslation();
  const iconSize = '18px';

  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });
  const history = useHistory();
  return (
    <Skeleton
      active
      title={false}
      loading={isLoading}
      paragraph={{
        rows: 8,
        width: ['100%', '100%', '100%', '100%', '100%', '100%', '100%', '100%']
      }}
    >
      <Table
        rowClassName="rowStyle"
        dataSource={contract.amendments}
        loading={isLoading}
        pagination={pagination}
        onRow={({ _id }) => ({
          onDoubleClick: () => history.push(`/contracts/show/${_id}`)
        })}
        onChange={(page) => {
          setPagination(page);
        }}
        rowKey="_id"
      >
        <Column
          title={t('contracts.list.column.amendmentNumber')}
          dataIndex="amendment_number"
          sorter={(a, b) => a.amendment_number - b.amendment_number}
          sortDirections={['ascend', 'descend']}
        />
        <Column
          title={t('contracts.list.column.type')}
          dataIndex="type"
          sorter={(a, b) => a.type.localeCompare(b.type)}
          sortDirections={['ascend', 'descend']}
          render={(type) => (
            <Tag color={contractTypeColor[type]}>
              {t(`contracts.form.options.type.${type}`).toUpperCase()}
            </Tag>
          )}
        />
        <Column
          title={t('contracts.list.column.dates')}
          key="dates"
          sorter={(a, b) =>
            moment(a.start_date).unix() - moment(b.start_date).unix()
          }
          sortDirections={['ascend', 'descend']}
          render={({ start_date, end_date }) => (
            <Typography>
              {`${moment(start_date).format('LL') || '-'} - ${moment(
                end_date
              ).format('LL') || '-'}`}
            </Typography>
          )}
        />
        <Column
          title={t('users.list.column.actions')}
          key="action"
          render={({ _id }) => (
            <span>
              <Link to={`/contracts/show/${_id}`}>
                <Icon type="eye" style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/contracts/edit/${_id}`,
                  state: {
                    amendment: true,
                    purpose: 'edit'
                  }
                }}
              >
                <Icon type="edit" style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title={t('contracts.list.column.action.delete.title')}
                okText={t('contracts.list.column.action.delete.ok')}
                cancelText={t('contracts.list.column.action.delete.cancel')}
                onConfirm={() => deleteContract(_id, true)}
                icon={<Icon type="warning" />}
              >
                <Icon
                  style={{ color: 'red', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </span>
          )}
        />
      </Table>
    </Skeleton>
  );
};

ContractAmendments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  contract: PropTypes.shape({
    amendments: PropTypes
  }).isRequired,
  deleteContract: PropTypes.func.isRequired
};

export default ContractAmendments;
