import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Typography } from 'antd';

const DescriptionList = ({ title, data, bordered, skipEmpty = false }) => {
  return (
    <div>
      <Descriptions title={title || ''} bordered={bordered}>
        {data.map(
          (item, idx) =>
            (item.content !== '-' || !skipEmpty) && (
              // eslint-disable-next-line react/no-array-index-key
              <Descriptions.Item label={item.label} span={item.span} key={idx}>
                <Typography style={{ fontWeight: 700 }}>
                  {item.content}
                </Typography>
              </Descriptions.Item>
            )
        )}
      </Descriptions>
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bordered: PropTypes.bool,
  skipEmpty: PropTypes.bool
};

DescriptionList.defaultProps = {
  title: null,
  bordered: false,
  skipEmpty: false
};

export default DescriptionList;
