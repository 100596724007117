import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Table } from 'antd';
import numeral from 'numeral';
import moment from 'moment';
import InvoiceModal from './InvoiceModal';

const { Column } = Table;

const TelecomInvoicesTable = ({ data }) => {
  const { t } = useTranslation();
  const [invoiceId, setInvoiceId] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleOpenModal = (id) => {
    setInvoiceId(id);
    setVisible(true);
  };

  return (
    <Card
      title={t('assets.showAsset.invoicesList.title')}
      style={{ marginTop: 16 }}
    >
      <InvoiceModal id={invoiceId} visible={visible} setVisible={setVisible} />
      <Table
        dataSource={data
          .map(({ _id, ...invoice }) => ({
            ...invoice,
            key: _id
          }))
          .sort((a, b) => moment(b.date).diff(moment(a.date)))}
        rowClassName="rowStyle"
        onRow={({ key }) => ({
          onClick: () => handleOpenModal(key)
        })}
      >
        <Column
          title={t('assets.showAsset.invoicesList.number')}
          dataIndex="number"
        />
        <Column
          title={t('assets.showAsset.invoicesList.phoneContractName')}
          dataIndex="phone_contract_name"
        />
        <Column
          title={t('assets.showAsset.invoicesList.date')}
          dataIndex="date"
          render={(date) => (date ? moment(date).format('ll') : '-')}
        />
        <Column
          title={t('assets.showAsset.invoicesList.amount')}
          dataIndex="amount"
          align="right"
          render={(amount) => `${numeral(amount).format('0,0.00')}€`}
        />
      </Table>
    </Card>
  );
};

TelecomInvoicesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

TelecomInvoicesTable.defaultProps = {
  data: []
};

export default TelecomInvoicesTable;
