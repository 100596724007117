/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  message,
  Button,
  Icon,
  Popconfirm,
  Row,
  Typography,
  Skeleton,
  Descriptions
} from 'antd';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import PVArticles from './PVArticles';
import PVInvoices from './PVInvoices';
import PVOrders from './PVOrders';
import UploadDrawer from '../../components/uploadDrawer';

const { Title } = Typography;

const ShowPV = () => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const isTableLoading = false;
  const [pv, setPV] = useState({});
  const [badgeCount, setBadgeCount] = useState(0);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const getPV = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/pvs/${id}?populate=files,invoices.from order_number ,order_number.client supplier contract owner`
      });
      if (data.files.length) {
        setBadgeCount(data.files.length);
      } else setBadgeCount(0);
      setPV(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const deletePV = async () => {
    await dispatchAPI('DELETE', {
      url: `/pvs/${id}`
    });
    history.goBack();
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getPV();
    }
  }, [id]);

  const { number, signing_date } = pv;

  const sendSigningRequest = () => {
    pv.order_number.map(async (order) => {
      try {
        const notification = {
          title: `Notification: Signature de PV`,
          description: `Vous avez reçu une nouvelle demande de signature de PV pour votre commande numéro ${order.order_number}`,
          type: `PV`,
          company_receiver: order.client._id
        };
        await dispatchAPI('POST', {
          url: `/notifications`,
          body: notification
        });
        message.success('Votre demande est envoyée');
      } catch (e) {
        console.error(e);
      }
    });
  };

  const downloadPV = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/pvs/download/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `PV_${pv.number}.pdf`;
      a.click();
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error)
        message.error(e.response.data.error);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('pvs.showPV.title')}
        extra={
          <div>
            {pv && !isLoading && (
              <Popconfirm
                title={t('datatable.column.action.download.title')}
                okText={t('datatable.column.action.download.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.download.cancel')}
                onConfirm={downloadPV}
                icon={<Icon type="warning" />}
              >
                <Button shape="circle" icon="download" type="primary" />
              </Popconfirm>
            )}
            <Popconfirm
              title={t('pvs.action.sendRequest.title')}
              okText={t('pvs.action.sendRequest.ok')}
              cancelText={t('pvs.action.sendRequest.cancel')}
              onConfirm={() => sendSigningRequest()}
              icon={<Icon type="check-circle" />}
            >
              <Button icon="to-top" style={{ margin: '0 4px' }}>
                {t('pvs.showPV.sendSigningRequest')}
              </Button>
            </Popconfirm>
            <Link to={`/pvs/edit/${id}`}>
              <Button type="primary" icon="edit">
                {t('pvs.showPV.editButton')}
              </Button>
            </Link>
            <Popconfirm
              title={t('pvs.action.delete.title')}
              okText={t('pvs.action.delete.ok')}
              cancelText={t('pvs.action.delete.cancel')}
              onConfirm={() => deletePV()}
              icon={<Icon type="warning" />}
            >
              <Button type="danger" icon="delete" style={{ margin: '0 4px' }}>
                {t('pvs.showPV.deleteButton')}
              </Button>
            </Popconfirm>
          </div>
        }
      />
      <ContentCustom padding={false}>
        <div
          style={{
            padding: 24,
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            width: '100%'
          }}
        >
          <Row>
            <Row type="flex" justify="space-between">
              <div>
                <Skeleton
                  active
                  loading={isLoading}
                  paragraph={{ rows: 1, width: 150 }}
                  title={false}
                >
                  <Title level={3}>
                    {t('pvs.showPV.number')}
                    {number}
                  </Title>
                </Skeleton>
              </div>
            </Row>
            <Descriptions column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
              <Descriptions.Item label={t('pvs.showPV.signingDate')} span={1}>
                {signing_date ? moment(signing_date).format('LL') : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Row>
          <PVOrders isTableLoading={isTableLoading} pv={pv} />
          <PVInvoices isTableLoading={isTableLoading} pv={pv} />
          <PVArticles isTableLoading={isTableLoading} pv={pv} />
          <UploadDrawer
            badgeCount={badgeCount}
            getResource={getPV}
            resourceFiles={pv.files}
            resourceId={id}
            resourceName="pvs"
            topPosition={100}
          />
        </div>
      </ContentCustom>
    </>
  );
};

export default ShowPV;
