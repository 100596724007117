import React from 'react';
import { Button, Modal } from 'antd';

const MyFallbackComponent = () => {
  return (
    <div>
      <Modal
        title="Erreur"
        visible
        closable={false}
        footer={
          <Button type="primary" onClick={() => window.location.reload()}>
            Ok
          </Button>
        }
      >
        <p>Une erreur vient de se produire.</p>
        <p>
          Un rapport détaillé vient d'être envoyé à notre équipe afin que nous
          puissions améliorer notre logiciel.
        </p>
        <p>Si l'erreur persiste, n'hésitez pas à nous contacter directement.</p>
        <p>En vous remerciant pour votre compréhension</p>
      </Modal>
    </div>
  );
};

export default MyFallbackComponent;
