/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Spin,
  Card,
  Button,
  Icon,
  Popconfirm,
  Typography,
  Tag,
  Tooltip
} from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const getTagModelType = (type) => {
  let color = '';

  switch (type) {
    case 'Boolean':
      color = 'magenta';
      break;
    case 'Number':
      color = 'geekblue';
      break;
    case 'Date':
      color = 'orange';
      break;
    case 'String':
      color = 'green';
      break;
    default:
      color = '';
  }
  return <Tag color={color}>{type}</Tag>;
};

const ShowModel = ({ match, history }) => {
  const { dispatchAPI, isLoading } = useAuthContext();
  const { t } = useTranslation();
  const [baseAsset, setBaseAsset] = useState(null);
  const [model, setModel] = useState(null);
  const [assetCount, setAssetCount] = useState(0);

  const getModel = async () => {
    try {
      let result = await dispatchAPI('GET', {
        url: `/assets/models?_id=${match.params.id}`
      });
      const assetsResult = await dispatchAPI('GET', {
        url: `/assets/${result.data[0].name}`
      });
      setAssetCount(parseInt(assetsResult.headers['x-total-count'], 10));
      setModel(result.data[0]);
      result = await dispatchAPI('GET', {
        url: `/assets/models/base-asset`
      });
      setBaseAsset(result.data);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteAsset = async (id) => {
    await dispatchAPI('DELETE', { url: `/assets/models/${id}` });
    history.push('../../models');
  };

  useEffect(() => {
    if (match.params.id) {
      getModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Link to="/models">
        <Button type="link" style={{ marginBottom: '10px' }} icon="left">
          {t('models.backToList')}
        </Button>
      </Link>
      <Card>
        {isLoading ? (
          <Spin
            style={{
              textAlign: 'center',
              borderRadius: '4px',
              marginBottom: '20px',
              padding: '30px 50px',
              margin: '20px 0',
              width: '100%',
              height: '100%'
            }}
          />
        ) : (
          <div>
            {assetCount > 0 ? (
              <Tooltip title={t('models.delete.tooltip')}>
                <Button
                  type="danger"
                  disabled
                  icon="delete"
                  style={{ float: 'right', margin: '0 4px' }}
                >
                  {t('models.deleteButton')}
                </Button>
              </Tooltip>
            ) : (
              <Popconfirm
                title={t('models.confirmDelete')}
                okText={t('models.confirmDeleteOk')}
                cancelText={t('models.cancelDelete')}
                onConfirm={() => deleteAsset(match.params.id)}
                icon={<Icon type="warning" />}
              >
                <Button
                  type="danger"
                  icon="delete"
                  style={{ float: 'right', margin: '0 4px' }}
                >
                  {t('models.deleteButton')}
                </Button>
              </Popconfirm>
            )}
            <Link to={`../edit/${match.params.id}`} style={{ float: 'right' }}>
              <Button type="primary" icon="edit">
                {t('models.editButton')}
              </Button>
            </Link>
            <Descriptions
              title={(model && model.name) || ''}
              bordered
              column={{ xl: 1, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              {baseAsset &&
                model &&
                Object.entries(model)
                  .filter(
                    ([key]) =>
                      !baseAsset[key] && key !== 'name' && key !== '_id'
                  )
                  .map(([key, value]) => (
                    <Descriptions.Item
                      key={key}
                      label={
                        <Typography>
                          {key}
                          <span style={{ color: 'red' }}>
                            {value.required ? '*' : ''}
                          </span>
                        </Typography>
                      }
                    >
                      {getTagModelType(value.type)}
                      {value.enum ? `values: [${value.enum.join(', ')}]` : ''}
                    </Descriptions.Item>
                  ))}
            </Descriptions>
          </div>
        )}
      </Card>
    </>
  );
};

ShowModel.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

export default ShowModel;
