const headers = [
  {
    label: 'number',
    key: 'number'
  },
  {
    label: 'recipient',
    key: 'recipient.name'
  },
  {
    label: 'nbrInvoices',
    key: 'invoices.length'
  },
  {
    label: 'paymentDate',
    key: 'payment_date'
  }
];
export default headers;
