/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Icon, Modal, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';

const ExportButton = ({ fileName, dataName, url, headers }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [dataCSV, setDataCSV] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url
      });
      if (data.length) {
        setDataCSV(
          data.map(
            ({
              emission_date,
              payment_date,
              pv_date,
              due_date,
              suppliers,
              start_date,
              end_date,
              ...d
            }) => {
              if (dataName === 'invoices')
                return {
                  ...d,
                  emission_date: emission_date
                    ? moment(emission_date).format('LL')
                    : '',
                  payment_date: payment_date
                    ? moment(payment_date).format('LL')
                    : '',
                  pv_date: pv_date ? moment(pv_date).format('LL') : '',
                  due_date: due_date ? moment(due_date).format('LL') : ''
                };
              if (dataName === 'contracts')
                return {
                  ...d,
                  suppliers: suppliers ? suppliers.map(({ name }) => name) : '',
                  start_date: start_date ? moment(start_date).format('LL') : '',
                  end_date: end_date ? moment(end_date).format('LL') : ''
                };
              if (dataName === 'people') {
                const { exit_date, subsidiary } = d;
                return {
                  ...d,
                  exit_date: exit_date
                    ? moment(exit_date).format('DD/MM/YYYY')
                    : '',
                  subsidiary: subsidiary ? subsidiary.name : ''
                };
              }
              if (dataName === 'assets') {
                const { type } = d;
                if (type === 'Telecom') {
                  const { people, subsidiary } = d;
                  return {
                    ...d,
                    people: people ? people.id_number : '',
                    subsidiary: subsidiary ? subsidiary.name : ''
                  };
                }
                if (type === 'IT') {
                  const {
                    subsidiary,
                    site,
                    owner,
                    people,
                    contract,
                    order_date,
                    invoice_date,
                    delivery_date,
                    entry_date,
                    exit_date,
                    replacement_date
                  } = d;
                  return {
                    ...d,
                    subsidiary: subsidiary ? subsidiary.name : 'À renseigner',
                    site: site ? site.name : 'À renseigner',
                    owner: owner ? owner.name : '',
                    people: people ? people.id_number : '',
                    contract: contract
                      ? `${contract.number}-${contract.amendment_number}`
                      : '',
                    order_date: order_date
                      ? moment(order_date).format('DD/MM/YYYY')
                      : '',
                    invoice_date: invoice_date
                      ? moment(invoice_date).format('DD/MM/YYYY')
                      : '',
                    delivery_date: delivery_date
                      ? moment(delivery_date).format('DD/MM/YYYY')
                      : '',
                    entry_date: entry_date
                      ? moment(entry_date).format('DD/MM/YYYY')
                      : '',
                    exit_date: exit_date
                      ? moment(exit_date).format('DD/MM/YYYY')
                      : '',
                    replacement_date: replacement_date
                      ? moment(replacement_date).format('DD/MM/YYYY')
                      : ''
                  };
                }
              }
              return { ...d };
            }
          )
        );
      } else setDataCSV('Pas de donnée');
    } catch (e) {
      console.error(e);
      setDataCSV('Pas de donnée');
    }
  };

  useEffect(() => {
    setDataCSV([]);
  }, [dataName, url, headers]);

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        visible={visible}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>
          Nous préparons votre fichier. Merci de patienter.
        </Typography.Text>
      </Modal>
      <CSVLink
        asyncOnClick
        onClick={(e, done) => {
          if (!dataCSV.length) {
            setVisible(true);
            e.persist();
            e.preventDefault();
            fetchData().then(() => {
              setVisible(false);
              e.target.click();
              done(false);
            });
          } else done();
        }}
        filename={fileName}
        data={dataCSV}
        headers={(headers || []).map(({ label, key }) => ({
          label: t(`${dataName}.form.${label}`),
          key: key || label
        }))}
      >
        <Icon type="download" />
        {` ${t(`${dataName}.downloadButton`)}`}
      </CSVLink>
    </>
  );
};

ExportButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default ExportButton;
