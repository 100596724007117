import React from 'react';
import { Button, Input } from 'antd';
import PropTypes from 'prop-types';

const FilterDropdown = ({
  setSearchInput,
  selectedKeys,
  setSelectedKeys,
  handleSearch,
  handleReset,
  clearFilters,
  confirm,
  placeholder,
  refSearch
}) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          setSearchInput(node);
        }}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(refSearch, selectedKeys, confirm)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(refSearch, selectedKeys, confirm)}
        icon="search"
        size="small"
        style={{ width: 110, marginRight: 8 }}
      >
        Rechercher
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  );
};

FilterDropdown.propTypes = {
  setSearchInput: PropTypes.func.isRequired,
  selectedKeys: PropTypes.arrayOf().isRequired,
  setSelectedKeys: PropTypes.arrayOf().isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  refSearch: PropTypes.string.isRequired
};

export default FilterDropdown;
