/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Menu,
  Tree,
  Typography,
  Modal,
  Input,
  Spin,
  message,
  Icon,
  Button
} from 'antd';
import { renderTreeNodes } from './renderTreeNodesForSubsidiaries';
import useAuthContext from '../../contexts/AuthContext';

const Subsidiaries = ({ companyId }) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [parent, setParent] = useState(null);
  const [subToEdit, setSubToEdit] = useState(null);
  const [newSubName, setNewSubName] = useState(null);
  const [tempNode, setTempNode] = useState(null);
  const [modalPurpose, setModalPurpose] = useState(null);
  const [visible, setVisible] = useState(false);
  const [keysSelected, setKeysSelected] = useState([]);

  const reset = () => {
    setParent(null);
    setNewSubName(null);
    setVisible(false);
    setSubToEdit(null);
    setModalPurpose(null);
    setTempNode(null);
    setKeysSelected([]);
  };

  const fetchSubsidiaries = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/subsidiaries/tree-organization?company=${companyId}`
      });
      if (data) setSubsidiaries([data]);
      else setSubsidiaries([]);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSubsidiaries();
  }, [fetchSubsidiaries]);

  const createSubsidiary = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/subsidiaries/`,
        body: { parent, company: companyId, name: newSubName }
      });
      reset();
      fetchSubsidiaries();
    } catch (e) {
      console.error(e);
    }
  };

  const modifySubsidiary = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/subsidiaries/${subToEdit}`,
        body: { company: companyId, name: newSubName }
      });
      reset();
      fetchSubsidiaries();
    } catch (e) {
      console.error(e);
    }
  };

  const deleteSubsidiary = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/subsidiaries/${id}` });
      reset();
      fetchSubsidiaries();
    } catch (e) {
      if (e.response && e.response.data.error.description === 'ERR_CHILDREN')
        message.error(
          'Impossible de supprimer une filiale ayant des sous-filiales'
        );
      else console.error(e);
    }
  };

  const cancelNewSub = () => {
    reset();
  };

  const manageSubsidiaries = async (action, { key, title }) => {
    switch (action) {
      case 'addInitial':
        setModalPurpose('create');
        setVisible(true);
        break;
      case 'add':
        setParent(key);
        setModalPurpose('create');
        setVisible(true);
        break;
      case 'edit':
        setNewSubName(title);
        setSubToEdit(key);
        setModalPurpose('edit');
        setVisible(true);
        break;
      case 'delete':
        await deleteSubsidiary(key);
        break;
      default:
        break;
    }
  };

  const contextMenu = (subsidiary) => (
    <Menu onClick={(item) => manageSubsidiaries(item.key, subsidiary)}>
      <Menu.Item key="add">
        <Icon type="plus" />
        {` Ajouter une filiale`}
      </Menu.Item>
      <Menu.Item key="edit">
        <Icon type="edit" />
        {` Modifier`}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="delete">
        <Icon type="delete" />
        {` Supprimer`}
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      style={{ marginTop: 24 }}
      bodyStyle={{
        maxHeight: 400,
        overflowY: 'auto'
      }}
      title="Filiales"
      extra={
        <>
          <div style={{ marginRight: 8, width: 250, float: 'left' }}>
            <Typography.Text type="secondary">
              {`Veuillez séléctionner une filiale pour lancer l'intéraction ou utiliser le clic droit`}
            </Typography.Text>
          </div>
          <Button
            disabled={!tempNode}
            type="primary"
            icon="plus"
            style={{ margin: 3 }}
            onClick={() => manageSubsidiaries('add', tempNode)}
          />
          <Button
            disabled={!tempNode}
            icon="edit"
            style={{ margin: 3 }}
            onClick={() => manageSubsidiaries('edit', tempNode)}
          />
          <Button
            disabled={!tempNode}
            type="danger"
            icon="delete"
            style={{ margin: 3 }}
            onClick={() => manageSubsidiaries('delete', tempNode)}
          />
        </>
      }
    >
      <Modal
        style={{ top: '40%' }}
        visible={visible}
        onCancel={cancelNewSub}
        onOk={
          modalPurpose === 'create'
            ? () => createSubsidiary()
            : modifySubsidiary
        }
        closable={false}
      >
        <Input
          placeholder={parent ? 'Nom de la filiale' : 'Maison mère'}
          value={newSubName}
          onChange={(e) => setNewSubName(e.target.value)}
        />
      </Modal>
      <Spin spinning={isLoading} size="large">
        {subsidiaries.length ? (
          <Tree
            selectedKeys={keysSelected}
            onSelect={(
              selectedKeys,
              {
                node: {
                  props: { dataRef }
                }
              }
            ) => {
              if (selectedKeys.length) {
                setTempNode(dataRef);
                setKeysSelected(selectedKeys);
              } else {
                setTempNode(null);
                setKeysSelected([]);
              }
            }}
            defaultExpandAll
          >
            {renderTreeNodes(subsidiaries, contextMenu)}
          </Tree>
        ) : (
          <Button
            type="primary"
            onClick={() => manageSubsidiaries('addInitial', {})}
          >
            Générer une organisation
          </Button>
        )}
      </Spin>
    </Card>
  );
};

Subsidiaries.propTypes = {
  companyId: PropTypes.string.isRequired
};

export default Subsidiaries;
