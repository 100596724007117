import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './utils';

const TableHeaderForInvoiceExport = () => {
  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'row',
        backgroundColor: '#fafafa'
      }}
    >
      <View
        style={{
          ...styles.tableHeader,
          flex: 2,
          paddingTop: 5,
          paddingBottom: 8,
          borderLeftWidth: 1,
          borderRightWidth: 0,
          borderTopWidth: 1,
          borderBottomWidth: 0.5
        }}
      >
        <Text style={{ fontSize: 14 }}>DESCRIPTION</Text>
      </View>

      <View
        style={{
          ...styles.tableHeader,
          flex: 1,
          paddingTop: 5,
          paddingBottom: 8,
          borderLeftWidth: 1,
          borderRightWidth: 0,
          borderTopWidth: 1,
          borderBottomWidth: 0.5
        }}
      >
        <Text style={{ fontSize: 14, textAlign: 'center' }}>Remise</Text>
      </View>

      <View
        style={{
          ...styles.tableHeader,
          flex: 1,
          paddingTop: 5,
          paddingBottom: 8,
          borderLeftWidth: 1,
          borderRightWidth: 0,
          borderTopWidth: 1,
          borderBottomWidth: 0.5
        }}
      >
        <Text style={{ fontSize: 14, textAlign: 'center' }}>Qté</Text>
      </View>

      <View
        style={{
          ...styles.tableHeader,
          flex: 1,
          paddingTop: 5,
          paddingBottom: 8,
          borderLeftWidth: 1,
          borderRightWidth: 0,
          borderTopWidth: 1,
          borderBottomWidth: 0.5
        }}
      >
        <Text style={{ fontSize: 14, textAlign: 'center' }}>PU</Text>
      </View>

      <View
        style={{
          ...styles.tableHeader,
          flex: 2,
          paddingTop: 5,
          paddingBottom: 8,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderTopWidth: 1,
          borderBottomWidth: 0.5
        }}
      >
        <Text style={{ fontSize: 14, textAlign: 'center' }}>MONTANT HT</Text>
      </View>
    </View>
  );
};

export default TableHeaderForInvoiceExport;
