import React from 'react';
import { Icon, Upload, Menu } from 'antd';

import exportHeaders from './exportHeaders';
import ExportButton from '../../components/exportButton/ExportButton';

const MenuComponent = (
  configs,
  type,
  filter,
  datesUrl,
  assetsProps,
  invoicesProps,
  t,
  setRawInvoicesModalVisible
) => {
  return (
    <Menu>
      <Menu.Item>
        <ExportButton
          dataName="assets"
          headers={exportHeaders[type]}
          fileName={`MCR-assets-${type}.csv`}
          url={`/assets/${type}?populate=contract,people,owner,phone_contract${
            configs ? `&company=${configs.client}` : ''
          }${filter.status || ''}${filter.overage || ''}${datesUrl || ''}`}
        />
      </Menu.Item>
      <Menu.Item>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Upload {...assetsProps} showUploadList={false}>
          <Icon type="upload" />
          {` ${t('assets.uploadButton')}`}
        </Upload>
      </Menu.Item>
      {type === 'Telecom' && (
        <Menu.Item onClick={() => setRawInvoicesModalVisible(true)}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Icon type="upload" />
          {` ${t('assets.rawFileUploadButton')}`}
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MenuComponent;
