import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Card, Divider, Icon, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import useAuthContext from '../../contexts/AuthContext';
import Theme from '../../components/configCLient/Theme';
import Settings from '../../components/configCLient/Settings';
import RACICard from '../../components/configCLient/RACICard';
import PhoneCard from '../../components/configCLient/PhoneCard';
import Subsidiaries from '../../components/configCLient/Subsidiaries';
import BreadCrumbs from '../../components/breadCrumbs';
import DisplayableModulesCard from '../../components/configCLient/DisplayableModulesCard';

const { Title } = Typography;

const ConfigClient = ({ location }) => {
  const { configId, companyId, companyName, type } = location.state || {};
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState({
    configIsLoading: true,
    logoIsLoading: false
  });
  const [configs, setConfigs] = useState({
    logo: null,
    article: { label_invoices: null },
    raci: { it: [], telecom: [], cars: [], real_estate: [] },
    sidebar_color: '001529'
  });
  const [urlLogo, setUrlLogo] = useState(null);

  const getLogo = async (id) => {
    setLoading({ ...loading, logoIsLoading: true });
    try {
      const result = await dispatchAPI('GET', {
        responseType: 'blob',
        url: `/files/${id}`
      });
      setUrlLogo(URL.createObjectURL(result.data));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setLoading({ ...loading, logoIsLoading: false });
  };

  const getConfig = async () => {
    setLoading({ ...loading, configIsLoading: true });
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/client-configs/${configId}?populate=phone_contracts`
      });
      setConfigs({
        ...configs,
        ...data,
        sidebar_color:
          data.sidebar_color && data.sidebar_color.replace(/^#/, '')
      });
      if (data.logo) await getLogo(data.logo);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setLoading({ ...loading, configIsLoading: false });
  };

  const handleConfigChange = (config, value) => {
    if (config === 'label_invoices')
      setConfigs({
        ...configs,
        article: { ...configs.article, label_invoices: value }
      });
    else
      setConfigs({
        ...configs,
        [config]: value
      });
  };

  const updateConfig = async () => {
    setLoading({ ...loading, configIsLoading: true });
    try {
      await dispatchAPI('PATCH', {
        url: `client-configs/${configId}`,
        body: { ...configs, sidebar_color: `#${configs.sidebar_color}` }
      });
      await getConfig();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    setLoading({ ...loading, configIsLoading: true });
    if (!configId) history.push('/companies');
    else {
      (async () => {
        await getConfig();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = [
    {
      path: '/',
      breadcrumbName: 'home',
      display: <Icon type="home" />
    },
    {
      path: '/companies',
      breadcrumbName: 'companies',
      state: { type },
      display: (
        <>
          <Icon type="shop" />
          {` ${t('companies.list.title')}`}
        </>
      )
    },
    {
      path: '/companies/show',
      breadcrumbName: companyName,
      state: { companyId, type },
      display: companyName
    },
    {
      path: '/',
      breadcrumbName: 'details',
      display: t('config')
    }
  ];

  const { raci, sidebar_color } = configs;
  const { configIsLoading, logoIsLoading } = loading;

  return (
    <>
      <BreadCrumbs routes={routes} />
      <Button
        type="link"
        style={{ marginBottom: '10px' }}
        icon="left"
        onClick={() => history.goBack()}
      >
        {t('backButton')}
      </Button>
      {configIsLoading ? (
        <Spin
          style={{
            textAlign: 'center',
            borderRadius: '4px',
            marginBottom: '20px',
            padding: '30px 50px',
            margin: '20px 0',
            width: '100%',
            height: '100%'
          }}
        />
      ) : (
        <>
          <Card>
            <Title level={3}>
              {`${t('companies.configClient.title')} - ${companyName}`}
            </Title>
            <Divider orientation="left">
              {t('companies.configClient.theme.title')}
            </Divider>
            <Theme
              configs={configs}
              urlLogo={urlLogo}
              logoIsLoading={logoIsLoading}
              sidebar_color={sidebar_color}
              configId={configId}
              loading={loading}
              setLoading={setLoading}
              setConfigs={setConfigs}
              getConfig={getConfig}
            />
            <Divider orientation="left">
              {t('companies.configClient.settings.title')}
            </Divider>
            <Settings
              configs={configs}
              handleConfigChange={handleConfigChange}
            />
            <Row style={{ textAlign: 'center', marginTop: 24 }}>
              <Button type="primary" onClick={updateConfig}>
                {t('companies.configClient.saveButton')}
              </Button>
            </Row>
          </Card>
          <Subsidiaries companyId={companyId} />
          <PhoneCard configId={configId} />
          <RACICard
            configId={configId}
            raci={raci}
            companyId={companyId}
            getConfig={getConfig}
          />
          <DisplayableModulesCard configId={configId} getConfig={getConfig} />
        </>
      )}
    </>
  );
};

ConfigClient.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      configId: PropTypes.string,
      companyId: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default ConfigClient;
