/* eslint-disable no-underscore-dangle */
import React, { useContext, createContext } from 'react';
import { useStateWithSessionStorage } from '../utils';

const ConfigsContext = createContext({});

export const ConfigsContextProvider = ({ children }) => {
  const [configs, setConfigs] = useStateWithSessionStorage('configs', null);
  const [modif, setModif] = useStateWithSessionStorage('modif');
  const dispatchConfigs = (action) => {
    switch (action.type) {
      case 'SET':
        setConfigs({
          client: action.id,
          contracts: action.contracts,
          configs: action.configs
        });
        break;
      case 'MODIF':
        setModif({
          modif: action.modif
        });
        break;
      case 'ADMIN':
        setConfigs(null);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('Unhandled action.');
    }
  };

  return (
    <ConfigsContext.Provider value={{ configs, modif, dispatchConfigs }}>
      {children}
    </ConfigsContext.Provider>
  );
};

export default () => useContext(ConfigsContext);
