import React from 'react';
import { Dropdown, Tree, Typography } from 'antd';

const { TreeNode } = Tree;

export const renderTreeNodes = (data, contextMenu) =>
  data.map((item) => {
    if (item.children) {
      return (
        <TreeNode
          title={
            <Dropdown
              overlay={() => contextMenu(item)}
              trigger={['contextMenu']}
            >
              <Typography href="">{item.title}</Typography>
            </Dropdown>
          }
          key={item.key}
          dataRef={item}
        >
          {renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        title={
          <Dropdown overlay={() => contextMenu(item)} trigger={['contextMenu']}>
            <Typography href="">{item.title}</Typography>
          </Dropdown>
        }
        key={item.key}
        dataRef={item}
      />
    );
  });
