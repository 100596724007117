import React from 'react';
import { Icon, Row, Skeleton, Table, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;
const { Column } = Table;
const PVOrders = ({ isTableLoading, pv }) => {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <Row style={{ marginTop: 50 }}>
      <Title level={4}>{t('pvs.showPV.ordersList')}</Title>
      <Skeleton
        active
        title={false}
        loading={isTableLoading}
        paragraph={{
          rows: 8,
          width: [
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%'
          ]
        }}
      >
        <Table
          dataSource={(pv.order_number || []).map(({ _id, ...order }) => ({
            ...order,
            key: _id
          }))}
          loading={isTableLoading}
          pagination={false}
          onRow={({ key }) => ({
            onDoubleClick: () => history.push(`/orders/show/${key}`)
          })}
          expandIcon={(expandProps) => {
            if (expandProps.record.description) {
              if (expandProps.expanded) {
                return (
                  <Icon
                    onClick={(e) => {
                      expandProps.onExpand(expandProps.record, e);
                    }}
                    role="button"
                    type="minus-square"
                  />
                );
              }
              return (
                <Icon
                  onClick={(e) => {
                    expandProps.onExpand(expandProps.record, e);
                  }}
                  role="button"
                  type="plus-square"
                />
              );
            }
            return false;
          }}
          expandedRowRender={(record) =>
            record.description ? record.description : 'Pas de description'
          }
        >
          <Column
            title={t('pvs.showPV.order_number')}
            dataIndex="order_number"
          />
          <Column
            title={t('orders.showOrder.supplier')}
            dataIndex="supplier.name"
            align="right"
          />
          <Column
            title={t('orders.showOrder.client')}
            dataIndex="client.name"
            align="right"
          />
          <Column
            title={t('orders.showOrder.owner')}
            dataIndex="owner.name"
            align="right"
          />
          <Column
            title={t('pvs.showPV.amendment_number')}
            dataIndex="contract.number"
            align="right"
          />
        </Table>
      </Skeleton>
    </Row>
  );
};

PVOrders.propTypes = {
  isTableLoading: PropTypes.bool.isRequired,
  pv: PropTypes.shape({
    order_number: PropTypes.string
  }).isRequired
};

export default PVOrders;
