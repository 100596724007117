import React from 'react';
import { Icon, Skeleton, Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';

const { Column } = Table;

const InvoiceTable = ({ invoice, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Skeleton
      active
      title={false}
      loading={isLoading}
      paragraph={{
        rows: 8,
        width: ['100%', '100%', '100%', '100%', '100%', '100%', '100%', '100%']
      }}
    >
      <Table
        dataSource={(invoice.articles || []).map(({ _id, ...article }) => ({
          ...article,
          key: _id
        }))}
        loading={isLoading}
        pagination={false}
        expandIcon={(expandProps) => {
          if (expandProps.record.description) {
            if (expandProps.expanded) {
              return (
                <Icon
                  onClick={(e) => {
                    expandProps.onExpand(expandProps.record, e);
                  }}
                  role="button"
                  type="minus-square"
                />
              );
            }
            return (
              <Icon
                onClick={(e) => {
                  expandProps.onExpand(expandProps.record, e);
                }}
                role="button"
                type="plus-square"
              />
            );
          }
          return false;
        }}
        expandedRowRender={(record) =>
          record.description ? record.description : 'Pas de description'
        }
      >
        <Column
          title={t('invoices.showInvoice.articlesTable.label')}
          dataIndex="catalog_label"
        />
        <Column
          title={t('invoices.showInvoice.articlesTable.quantity')}
          dataIndex="quantity"
          align="right"
        />
        <Column
          title={t('invoices.showInvoice.articlesTable.unitPrice')}
          dataIndex="unit_price"
          align="right"
          render={(price) => `${numeral(price).format('0,0.00')} €`}
        />
        <Column
          title={t('invoices.showInvoice.articlesTable.discount')}
          dataIndex="discount"
          align="right"
          render={(discount) => (discount ? `${discount}%` : 'N/A')}
        />
        <Column
          title={t('invoices.showInvoice.articlesTable.VAT')}
          dataIndex="VAT"
          align="right"
          render={(vat) => `${vat}%`}
        />
        <Column
          title={t('invoices.showInvoice.articlesTable.subTotal')}
          key="subTotal"
          align="right"
          render={({ quantity, unit_price }) =>
            `${numeral(quantity * unit_price).format('0,0.00')} €`
          }
        />
        <Column
          title={t('invoices.showInvoice.articlesTable.receptionDate')}
          dataIndex="reception_date"
          align="right"
          render={(receptionDate) =>
            receptionDate && moment(receptionDate).format('LL')
          }
        />
      </Table>
    </Skeleton>
  );
};

InvoiceTable.propTypes = {
  invoice: PropTypes.shape({
    articles: PropTypes.arrayOf()
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default InvoiceTable;
