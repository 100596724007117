/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Icon,
  Popconfirm,
  Row,
  Skeleton,
  Table,
  Typography
} from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import AddSiteModal from './AddSiteModal';
import { ContentCustom } from '../../components';

const { Column } = Table;

const SiteTable = ({ companyId }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [sites, setSites] = useState([]);
  const [siteToEdit, setSiteToEdit] = useState({ info: null });
  const [isModalLoading, setIsModalLoading] = useState(false);

  const getSites = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${companyId}/site`
      });
      setSites(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const updateSiteModal = (index) => {
    setVisible(true);
    setSiteToEdit({
      info: sites.filter(({ _id }) => _id === index),
      index
    });
  };

  const createSite = async (values) => {
    const body = { ...values, type: 'Point' };
    try {
      await dispatchAPI('POST', { url: `/companies/${companyId}/site`, body });
      await getSites();
    } catch (e) {
      console.error(e);
    }
    setVisible(false);
    setIsModalLoading(false);
  };

  const deleteSite = async (index) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/companies/${index}/site`
      });
      await getSites();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const updateSite = async (values, _id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/companies/${_id}/site`,
        body: values
      });
      setSiteToEdit({ info: null, index: null });
      await getSites();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setVisible(false);
    setIsModalLoading(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async (values, { _id } = {}) => {
    setIsModalLoading(true);
    if (!siteToEdit.info) {
      await createSite(values);
    } else {
      await updateSite(values, _id);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setSiteToEdit({ info: null, index: null });
  };

  useEffect(() => {
    if (companyId) {
      getSites();
    }
  }, []);

  return (
    <ContentCustom marginTop={8}>
      <AddSiteModal
        site={siteToEdit.info}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        updateSite={updateSite}
        isLoading={isModalLoading}
      />
      <Row type="flex" justify="space-between" style={{ marginBottom: 8 }}>
        <Typography.Title level={4}>
          {t('companies.showCompany.siteList.title')}
        </Typography.Title>
        <Button onClick={showModal} type="primary">
          {t('companies.showCompany.siteList.addSite')}
        </Button>
      </Row>
      <Skeleton
        loading={loading}
        active
        title={0}
        paragraph={{
          rows: 8,
          width: [
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%'
          ]
        }}
      >
        <Table
          dataSource={sites.map(({ _id, ...site }) => ({ ...site, key: _id }))}
          scroll={{ x: 800 }}
        >
          <Column
            title={t('companies.list.column.siteName')}
            dataIndex="name"
            sorter={(a, b) => a.name.localeCompare(b.name)}
            sortDirections={['ascend', 'descend']}
          />
          <Column
            title={t('companies.list.column.address.title')}
            dataIndex="address"
            sorter={(a, b) =>
              a.address &&
              b.address &&
              a.address.street &&
              b.address.street &&
              a.address.street.localeCompare(b.address.street)
            }
            sortDirections={['ascend', 'descend']}
            render={(address) =>
              address
                ? `${address.number} ${address.street}, ${address.postal_code} ${address.city}`
                : 'N/R'
            }
          />
          <Column
            title={t('users.list.column.actions')}
            fixed="right"
            render={({ key }) => (
              <span>
                <Button
                  type="link"
                  icon="edit"
                  onClick={() => updateSiteModal(key)}
                />
                <Divider type="vertical" />
                <Popconfirm
                  title={t('companies.sites.action.delete.title')}
                  okText={t('companies.sites.action.delete.ok')}
                  cancelText={t('companies.sites.action.delete.cancel')}
                  onConfirm={() => deleteSite(key)}
                  icon={<Icon type="warning" />}
                >
                  <Button size="small" type="danger" icon="delete" />
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      </Skeleton>
    </ContentCustom>
  );
};

SiteTable.propTypes = {
  companyId: PropTypes.string.isRequired
};

export default SiteTable;
