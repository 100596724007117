import numeral from 'numeral';

const indicatorsElementsReducer = (state, action) => {
  const { data } = action.payload;

  switch (action.type) {
    case 'rate_sinister_refused':
      return {
        number1:
          data.rate !== null
            ? `${numeral(data.rate).format('0,0[.]00')}%`
            : null,
        trend1: data.trend && data.trend.rate,
        invertedTrendColor: true
      };
    default:
      return {};
  }
};

export default indicatorsElementsReducer;
