/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Layout, Menu, Icon, Row } from 'antd';
import moment from 'moment';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import useAuthContext from '../contexts/AuthContext';
import useConfigsContext from '../contexts/ConfigsContext';
import SelectView from '../components/selectView';
import './BasicLayout.css';
import miniLogo from '../assets/images/favicon-196.png';
import colibrisBlancLogo from '../assets/images/ColibrisBlanc.png';
import colibrisLogo from '../assets/images/colibris blanc small.png';
import Preload from '../components/preload/Preload';
import BasicLayoutMenu from './BasicLayoutMenu';
import FallbackComponent from './FallbackComponent';
import UpdateAlert from '../components/updateAlert/UpdateAlert';

const { Content, Footer, Sider } = Layout;

const assetsIcons = {
  IT: 'laptop',
  Telecom: 'mobile',
  Car: 'car',
  RealEstate: 'home'
};

const BasicLayout = ({ children, path }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { configs } = useConfigsContext();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );
  const [assetsSubMenu, setAssetsSubMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const mapAssetsSubMenu = (models) => {
    if (!models) return null;
    const disabledAsset = { Car: true, RealEstate: true, Software: true };

    return Object.entries(models).map(([modelName]) => (
      // eslint-disable-next-line no-underscore-dangle
      <Menu.Item
        key={`/assets/${modelName}`}
        disabled={disabledAsset[modelName]}
      >
        <NavLink to={`/assets/${modelName}`} className="nav-text">
          {assetsIcons[modelName] && <Icon type={assetsIcons[modelName]} />}
          <span>{t(`${modelName}.title`)}</span>
        </NavLink>
      </Menu.Item>
    ));
  };

  const getAssetsName = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/assets/models'
      });
      setAssetsSubMenu(data);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getAssetsName();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (size.width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [size]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('orientationchange', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  useEffect(() => {
    if (configs) {
      if (configs.configs && configs.configs.sidebar_color)
        document.documentElement.style.setProperty(
          '--main-color',
          configs.configs.sidebar_color
        );
    } else
      document.documentElement.style.setProperty('--main-color', '#078c8c');
  }, [configs]);

  return isLoading && history.action !== 'PUSH' ? (
    <Preload />
  ) : (
    <Layout style={{ minHeight: '100vh' }}>
      <UpdateAlert />
      <Sider
        className="container-sider"
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <div className="logo">
          <Link to="/">
            <img src={collapsed ? miniLogo : colibrisLogo} alt="Logo" />
          </Link>
        </div>
        <Row type="flex" justify="space-around">
          {!collapsed && <SelectView />}
          <Link to="/products">
            <Icon type="setting" style={{ color: '#d0d0d0', fontSize: 20 }} />
          </Link>
        </Row>
        <BasicLayoutMenu
          path={path}
          setCollapsed={setCollapsed}
          size={size}
          assetsSubMenu={assetsSubMenu}
          mapAssetsSubMenu={mapAssetsSubMenu}
        />
        <div className="colibrisBlancLogo">
          <img src={colibrisBlancLogo} alt="logo Leasetic" />
        </div>
      </Sider>
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <ErrorBoundary FallbackComponent={FallbackComponent}>
          <Content className="content">{children}</Content>
        </ErrorBoundary>
        <Footer className="footer">
          {`${t('global.title')} ©${moment().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
          <p>{`Version: ${process.env.REACT_APP_APP_VERSION}`}</p>
        </Footer>
      </Layout>
    </Layout>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired
};

export default BasicLayout;
