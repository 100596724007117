/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'react-router-dom';
import { Descriptions, Row, Skeleton, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const { Title } = Typography;
const OrderDetails = ({ invoices, isLoading, order }) => {
  const { t } = useTranslation();
  const {
    supplier,
    client,
    owner,
    contract,
    order_number,
    object,
    order_date,
    payment_date,
    pv_number,
    pv_signing_date,
    type
  } = order;
  return (
    <Row>
      <Row type="flex" justify="space-between">
        <div>
          <Skeleton
            active
            loading={isLoading}
            paragraph={{ rows: 1, width: 150 }}
            title={false}
          >
            <Title level={3}>
              {t('orders.showOrder.order_number')}
              {order_number}
            </Title>
          </Skeleton>
        </div>
      </Row>
      <Descriptions column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label={t('orders.showOrder.object')} span={2}>
          {object || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.showOrder.creation_date')} span={1}>
          {moment(order_date).format('LL') || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.showOrder.type')} span={2}>
          {type || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.showOrder.payment_date')} span={1}>
          {payment_date ? moment(payment_date).format('LL') : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.showOrder.contract')} span={2}>
          {(contract && `${contract.number}-${contract.amendment_number}`) ||
            '-'}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('orders.showOrder.pv_signing_date')}
          span={1}
        >
          {pv_signing_date ? moment(pv_signing_date).format('LL') : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.showOrder.pv_number')} span={3}>
          {pv_number || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.showOrder.supplier')} span={1}>
          <Tag color="green">{(supplier && supplier.name) || '-'}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.showOrder.client')} span={1}>
          <Tag color="orange">{(client && client.name) || '-'}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.showOrder.owner')} span={1}>
          <Tag color="blue">{(owner && owner.name) || '-'}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('orders.showOrder.InvoicesList')} span={1}>
          {invoices.length !== 0
            ? invoices.map((invoice, key) => (
                <Tag key={key} color="#108ee9">
                  <Link to={`/invoices/supplier/show/${invoice._id}`}>
                    {invoice.number}
                  </Link>
                </Tag>
              ))
            : 'N/A'}
        </Descriptions.Item>
      </Descriptions>
    </Row>
  );
};

OrderDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  order: PropTypes.shape({
    supplier: PropTypes.shape({
      name: PropTypes.string
    }),
    client: PropTypes.shape({
      name: PropTypes.string
    }),
    owner: PropTypes.shape({
      name: PropTypes.string
    }),
    contract: PropTypes.shape({
      number: PropTypes.string,
      amendment_number: PropTypes.string
    }),
    order_number: PropTypes.number,
    object: PropTypes.string,
    order_date: PropTypes.instanceOf(Date),
    payment_date: PropTypes.instanceOf(Date),
    pv_number: PropTypes.number,
    pv_signing_date: PropTypes.instanceOf(Date),
    type: PropTypes.string
  }).isRequired
};
export default OrderDetails;
