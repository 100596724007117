import { Tag } from 'antd';
import React from 'react';
import numeral from 'numeral';
import { companyTypeColor } from '../../utils';

export default (enums, initialType, t) => {
  const { type, legal_status } = enums;
  const types = type.map((element) => ({
    value: element,
    text: (
      <Tag color={companyTypeColor[element]}>
        {t(`companies.form.options.${element}`)}
      </Tag>
    )
  }));
  const legal_statuses = legal_status.map((element) => ({
    value: element,
    text: element
  }));
  return [
    {
      label: 'type',
      required: true,
      initialValue: initialType || '',
      input: 'select',
      type: 'array',
      mode: 'multiple',
      options: types
    },
    {
      label: 'legal_status',
      required: true,
      input: 'select',
      options: legal_statuses
    },
    {
      label: 'name',
      required: true
    },
    {
      label: 'group',
      required: true
    },
    {
      label: 'address.number',
      required: true
    },
    {
      label: 'address.street',
      required: true
    },
    {
      label: 'address.additional',
      required: false
    },
    {
      label: 'address.postal_code',
      required: true
    },
    {
      label: 'address.city',
      required: true
    },
    {
      label: 'address.country',
      required: true
    },
    {
      label: 'siren',
      required: true,
      extraRules: [{ len: 9, message: t(`global.form.typeMessage`) }]
    },
    {
      label: 'APE',
      required: false
    },
    {
      label: 'capital',
      required: false,
      input: 'number',
      type: 'number',
      width: '100%',
      formatter: (value) => `${numeral(value).format('0,0')}`
    }
  ];
};
