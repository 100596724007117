import React from 'react';
import {
  Button,
  Divider,
  Icon,
  Popconfirm,
  Row,
  Skeleton,
  Table,
  Typography
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Column } = Table;
const CompanyContacts = ({
  contactList,
  deleteContact,
  isLoading,
  showModal,
  updateContactModal
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 8 }}>
        <Typography.Title level={4}>
          {t('companies.showCompany.contactList.title')}
        </Typography.Title>
        <Button onClick={showModal} type="primary">
          {t('companies.contacts.addContact')}
        </Button>
      </Row>
      <Skeleton
        loading={isLoading}
        active
        title={0}
        paragraph={{
          rows: 8,
          width: [
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%'
          ]
        }}
      >
        <Table
          dataSource={
            contactList &&
            contactList.map((contact, idx) => ({ ...contact, key: idx }))
          }
          scroll={{ x: 900 }}
        >
          <Column
            title={t('companies.showCompany.contactList.column.contact')}
            key="contact"
            render={(contact) => `${contact.first_name} ${contact.last_name}`}
          />
          <Column
            title={t('companies.showCompany.contactList.column.function')}
            key="function"
            render={(contact) => contact.function}
          />
          <Column
            title={t('companies.showCompany.contactList.column.phoneNumber')}
            dataIndex="phone_number"
            render={(phone_number) =>
              phone_number
                ? `${phone_number.country_code} ${phone_number.number.slice(
                    0,
                    1
                  )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}`
                : '-'
            }
          />
          <Column
            title={t('companies.showCompany.contactList.column.email')}
            dataIndex="email"
            render={(email) => email}
          />
          <Column
            title={t('users.list.column.actions')}
            fixed="right"
            render={({ key }) => (
              <span>
                <Button
                  type="link"
                  icon="edit"
                  onClick={() => updateContactModal(key)}
                />
                <Divider type="vertical" />
                <Popconfirm
                  title={t('companies.contacts.action.delete.title')}
                  okText={t('companies.contacts.action.delete.ok')}
                  cancelText={t('companies.contacts.action.delete.cancel')}
                  onConfirm={() => deleteContact(key)}
                  icon={<Icon type="warning" />}
                >
                  <Button size="small" type="danger" icon="delete" />
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      </Skeleton>
    </>
  );
};

CompanyContacts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  contactList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteContact: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  updateContactModal: PropTypes.func.isRequired
};

export default CompanyContacts;
