import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  message,
  Typography,
  Skeleton,
  Popconfirm,
  Icon,
  Button,
  Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import RelatedItems from './RelatedItems';
import DescriptionList from '../../components/descriptionList/DescriptionList';
import list from './informationList';
import UploadDrawer from '../../components/uploadDrawer';
import { ContentCustom, PageHeaderCustom } from '../../components';
import LOETable from './LOETable';
import ContractAmendments from './ContractAmendments';

const ShowContract = () => {
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { contractList } = list;
  const [contract, setContract] = useState([]);
  const [invoices, setInvoices] = useState(null);
  const [parties, setParties] = useState(null);
  const [badgeCount, setBadgeCount] = useState(0);
  const amendment = !!contract.amendment_number;

  const getContract = async (contractId = id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/${contractId}?populate=assets,suppliers,customer,leaser,files,amendments,invoices`
      });
      setContract(data);
      setInvoices(data.invoices);
      setParties({
        ...parties,
        customer: data.customer,
        leaser: data.leaser,
        suppliers: data.suppliers
      });
      if (data.files && data.files.length) {
        setBadgeCount(data.files.length);
      } else setBadgeCount(0);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
    setIsLoading(false);
  };

  const deleteContract = async (contractId = id, amdt) => {
    try {
      await dispatchAPI('DELETE', { url: `/contracts/${contractId}` });
      if (amdt) await getContract();
      else history.goBack();
    } catch (e) {
      message.error('Oops, something bad happens.');
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      (async () => {
        await getContract();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const financialContract = ['CAP', 'CAPéTic', 'CLASSIC', 'CADRE'];

  return (
    <>
      <PageHeaderCustom
        title={t('contracts.showContract.title')}
        extra={
          <>
            <Popconfirm
              title={t('contracts.confirmDelete')}
              okText={t('contracts.confirmDeleteOk')}
              cancelText={t('contracts.cancelDelete')}
              onConfirm={() => deleteContract()}
              icon={<Icon type="warning" />}
            >
              <Button
                type="danger"
                icon="delete"
                style={{ float: 'right', margin: '0 4px' }}
              >
                {t('contracts.showContract.deleteButton')}
              </Button>
            </Popconfirm>
            <Link
              to={{
                pathname: `/contracts/edit/${id}`,
                state: {
                  amendment: !!contract.amendment_number,
                  purpose: 'edit'
                }
              }}
              style={{ float: 'right' }}
            >
              <Button type="primary" icon="edit">
                {t('contracts.showContract.editButton')}
              </Button>
            </Link>
          </>
        }
      />
      <ContentCustom padding={false}>
        <div
          style={{
            padding: 24,
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            width: '100%'
          }}
        >
          <Skeleton loading={isLoading} paragraph={{ rows: 9 }} active>
            <DescriptionList data={contractList(contract, t)} />
          </Skeleton>
          <UploadDrawer
            badgeCount={badgeCount}
            getResource={getContract}
            resourceFiles={contract.files}
            resourceId={id}
            resourceName="contracts"
          />
        </div>
      </ContentCustom>
      {!amendment && (
        <ContentCustom marginTop={8}>
          <Row type="flex" justify="space-between">
            <Typography.Title level={4}>
              {t('contracts.showContract.amendmentList')}
            </Typography.Title>
            <Link
              to={{
                pathname: '/contracts/create',
                state: { contractId: id, amendment: true, purpose: 'create' }
              }}
            >
              <Button type="primary">
                {t('contracts.amendments.addAmendment')}
              </Button>
            </Link>
          </Row>
          <ContractAmendments
            contract={contract}
            deleteContract={deleteContract}
            isLoading={isLoading}
          />
        </ContentCustom>
      )}
      {contract && financialContract.includes(contract.type) && (
        <LOETable contract={contract} />
      )}
      <RelatedItems
        history={history}
        contractId={id}
        invoices={invoices}
        parties={parties}
      />
    </>
  );
};

export default ShowContract;
