import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import ListAssets from './ListAssets';
import ShowAsset from './ShowAsset';

const AssetsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:type/show/:id`} component={ShowAsset} />
      <Route path={`${path}/:type`} component={ListAssets} />
      <Route path={path} render={() => <Redirect to="/assets/IT" />} />
    </Switch>
  );
};

export default AssetsRouter;
