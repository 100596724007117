/* eslint-disable no-console */
import uuid from 'uuid/v1';
import { message } from 'antd';

export const defaultDescriptionLabel = (
  id,
  pv,
  invoices,
  invoicesArticles,
  idx
) => {
  return {
    initialValue: id
      ? pv && pv.articles && pv.articles[idx] && pv.articles[idx].description
      : invoices &&
        invoicesArticles &&
        invoicesArticles[idx] &&
        invoicesArticles[idx].description
  };
};

export const rule = (t) => {
  return [
    {
      required: true,
      message: t(`global.form.requiredMessage`)
    }
  ];
};

export const buttonStyle = {
  height: '100%',
  width: '90%',
  borderRadius: '10px 0 0 10px'
};

export const makeArticle = (value) => {
  return {
    articleLabel: `addArticle.articles.articleLabel.${value}`,
    quantityLabel: `addArticle.articles.quantityLabel.${value}`,
    priceLabel: `addArticle.articles.priceLabel.${value}`,
    vatLabel: `addArticle.articles.vatLabel.${value}`,
    discountLabel: `addArticle.articles.discountLabel.${value}`,
    descriptionLabel: `addArticle.articles.descriptionLabel.${value}`,
    articleId: uuid()
  };
};

export const defaultLoading = {
  labelsAreLoading: false,
  catalogIsLoading: false
};

export const defaultCalculatedValues = {
  article: null,
  quantity: 0,
  price: 0,
  vat: 20,
  discount: 0
};

export const handleSubmitUtil = (
  validateFieldsAndScroll,
  id,
  dispatchAPI,
  history,
  t
) => {
  const createPV = async (values) => {
    const body = { ...values };
    try {
      await dispatchAPI('POST', { url: '/pvs', body });
      message.success(t('pvs.successCreation'));
      history.goBack();
    } catch (e) {
      message.error(`${t('global.form.error.message')}`);
    }
  };

  const updatePV = async (values) => {
    const body = {
      ...values
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/pvs/${id}`,
        body
      });
      message.success(t('pvs.successEdit'));
      history.goBack();
    } catch (e) {
      message.error(`${t('global.form.error.message')}`);
    }
  };
  validateFieldsAndScroll(async (err, values) => {
    if (!err) {
      const { addArticle, ...rest } = values;
      const artcls = [];
      if (addArticle) {
        const {
          articleLabel,
          priceLabel,
          vatLabel,
          discountLabel,
          descriptionLabel,
          quantityLabel
        } = addArticle.articles;
        for (let i = 0; i < articleLabel.length; i += 1) {
          artcls.push({
            catalog_label: articleLabel[i],
            unit_price: priceLabel[i],
            VAT: vatLabel[i],
            discount: discountLabel[i],
            description: descriptionLabel[i],
            quantity: quantityLabel[i]
          });
        }
        const pvValues = {
          ...rest,
          articles: artcls
        };
        if (id) {
          await updatePV(pvValues);
        } else if (!id) {
          await createPV(pvValues);
        }
      } else {
        message.error(t('orders.form.noArticles'));
      }
    }
  });
};

export const setPVNumberUtil = async (dispatchAPI, setFieldsValue) => {
  try {
    const date = new Date();
    const year = date.getFullYear();
    const { data } = await dispatchAPI('GET', { url: `/pvs/lastOne` });
    let number = '0001';
    if (data) {
      const parts = data.number.split('-');
      number = Number(parts[1]) + 1;
    }
    const defaultFill = '0000';
    const formatedNumber = (defaultFill + number).substr(String(number).length);
    const pvNumber = `${year}-${formatedNumber}`;
    setFieldsValue({ number: pvNumber });
  } catch (e) {
    console.error(e);
  }
};

export const getInvoicesUtil = async (dispatchAPI, order, setAllInvoices) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/invoices?order_number=${order}`
    });
    setAllInvoices(data);
  } catch (e) {
    console.error(e);
  }
};

export const getOrdersUtil = async (
  searchValue,
  setIsOrdersLoading,
  dispatchAPI,
  setOrders
) => {
  setIsOrdersLoading(true);
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/orders/${
        searchValue ? `/search/${searchValue}` : ''
      }?populate=supplier,client,owner,contract`
    });
    setOrders(data);
    setIsOrdersLoading(false);
  } catch (e) {
    console.error(e);
  }
};
