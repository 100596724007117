import { useState, useEffect, useReducer } from 'react';

// TODO: Switch la local storage if "Rember me" is checked
export const useStateWithSessionStorage = (storageKey, defaultValue = null) => {
  const [value, setValue] = useState(
    JSON.parse(sessionStorage.getItem(storageKey)) || defaultValue
  );

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);
  return [value, setValue];
};

export const useStateWithLocalStorage = (storageKey) => {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(storageKey))
  );

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);
  return [value, setValue];
};

export const useReducerWithSessionStorage = (
  storageKey,
  reducer,
  defaultValue
) => {
  const [value, dispatch] = useReducer(
    reducer,
    JSON.parse(sessionStorage.getItem(storageKey)) || defaultValue
  );

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);
  return [value, dispatch];
};

export const useStateWithDynamicStorage = (useLocalStorage, storageKey) => {
  const [value, setValue] = useState(
    JSON.parse(
      useLocalStorage
        ? localStorage.getItem(storageKey)
        : sessionStorage.getItem(storageKey)
    )
  );

  useEffect(() => {
    if (useLocalStorage) {
      localStorage.setItem(storageKey, JSON.stringify(value));
      sessionStorage.removeItem(storageKey);
    } else {
      sessionStorage.setItem(storageKey, JSON.stringify(value));
      localStorage.removeItem(storageKey);
    }
  }, [value, storageKey, useLocalStorage]);
  return [value, setValue];
};

export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = () => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  });

  const [WindowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return WindowSize;
};

export function chunk(array, size) {
  return array.reduce((chunks, item, i) => {
    if (i % size === 0) {
      chunks.push([item]);
    } else {
      chunks[chunks.length - 1].push(item);
    }
    return chunks;
  }, []);
}

export const invoiceTypeColors = {
  CESSION: 'green',
  BROKERAGE: 'magenta',
  CONSULTING: 'geekblue',
  BUILD: 'volcano',
  RUN: 'orange',
  FINANCIAL_PORTAGE: 'cyan',
  CREDIT: 'blue'
};

export const notificationTypeColors = {
  ORDERS: 'cyan',
  PV: 'volcano'
};

export const notificationStatusColors = {
  OPEN: 'green',
  CLOSED: 'red'
};

export const contractTypeColor = {
  CLASSIC: 'green',
  CAP: 'magenta',
  MASTER_AGREEMENT: 'geekblue',
  CAPéTic: 'volcano',
  MCR_IT: 'orange',
  MCR_TELECOM: 'orange',
  MCR_REAL_ESTATE: 'orange',
  MCR_SOFTWARE_LICENSES: 'orange',
  MCR_TRAVEL_EXPENSES: 'orange',
  MCR_CLOUD: 'orange',
  MCR_VEHICLE_FLEET: 'orange',
  MCR_PRINT_SERVICES: 'orange',
  MCR_INTERM: 'orange',
  MCR_CSR: 'orange',
  CONSULTING: 'cyan',
  CADRE: 'blue',
  SERVICES_CONTRACT: 'gold',
  SERVICES_TELECOM: 'gold',
  SERVICES_ASSURANCE: 'gold',
  SERVICES_MAINTENANCE: 'gold'
};

export const contractStatusColor = {
  SIGNED: 'green',
  YIELDED: 'magenta'
};

export const contractPeriodicityColor = {
  MONTHLY: 'green',
  QUARTERLY: 'magenta',
  SEMESTER: 'geekblue',
  YEARLY: 'volcano'
};

export const userRoleColor = {
  ADMIN: 'volcano',
  'SUPER-USER': 'geekblue',
  'SUPER-ADMIN': 'purple',
  USER: 'green'
};

export const companyTypeColor = {
  LEASER: 'green',
  CLIENT: 'magenta',
  SUPPLIER: 'geekblue',
  REFINANCER: 'volcano',
  PROSPECT: 'blue'
};

export const assetsStatusColor = {
  'EN STOCK': 'green',
  'STOCK SORTIE': 'red',
  STOCK_OUT: 'red',
  'STOCK ENTREE': 'geekblue',
  AFFECTE: 'volcano',
  'A DEPOLLUER': 'red',
  SINISTER: 'red',
  USED: 'blue',
  null: 'grey'
};

export const assetsStateColor = {
  UNKNOWN: 'red',
  BROKEN: 'green',
  GOOD: 'geekblue',
  VERY_GOOD: 'volcano'
};

export const ticketTypeColors = {
  COLLECTION: 'green',
  THEFT: 'volcano',
  BREAKDOWN: 'orange',
  DAMAGE: 'red',
  DELIVERY: 'blue',
  LOST: 'cyan'
};

export const ticketStatusColors = {
  OPEN: 'blue',
  WAITING: 'green',
  IN_PROGRESS: 'orange',
  CLOSED: 'red',
  AUDITED: 'cyan',
  REFUSED_BY_INSURER: 'volcano',
  DELETED: 'red'
};

export const ticketPriorityColors = {
  NORMAL: 'green',
  HIGH: 'orange',
  URGENT: 'red'
};

export const providerColors = {
  BOUYGUES: 'cyan',
  ORANGE: 'orange',
  SFR: 'red'
};

export const reportTypes = {
  DESTRUCTION: 'red',
  ERASURE: 'orange'
};

export const invoiceTypesColors = {
  FournisseurIT: 'red',
  ServicesLEASETIC: 'orange',
  FournisseurRetrologistique: 'cyan',
  FournisseurAssurance: 'volcano'
};

export const productsTypesColors = {
  Computer: 'blue',
  Display: 'geekblue',
  Services: 'magenta',
  Accessories: 'green',
  Packaging: 'purple'
};

export const categoryTypesColors = {
  SUPPLIERORDER: 'gray',
  SUPPLIERINVOICE: 'blue',
  CLIENTINVOICE: 'red'
};

export const LOEStatusColors = {
  EXPIRED: 'red',
  VALID: 'green',
  ENATTENTE: 'orange'
};
