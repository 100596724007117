import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, Input, message, Icon } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import usePwdPattern from '../../utils/pwdPattern';

const ChangePwdForm = ({
  token,
  switchForm,
  form: { getFieldDecorator, validateFields, getFieldValue }
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setLoading] = useState(false);

  const changePwd = async (body) => {
    setLoading(true);
    try {
      await dispatchAPI('POST', { url: `reset-pwd/${token}`, body });
      message.success(t('login.passwordChanged'));
      switchForm('login');
      history.push('/login');
    } catch (e) {
      if (e.response && e.response.data && e.response.data.description) {
        message.error(e.response.data.description);
      } else {
        message.error(t('login.wrongToken'));
      }
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        await changePwd(values);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [
            { required: true, message: t('login.pwdMissing') },
            ...usePwdPattern()
          ],
          hasFeedback: true
        })(
          <Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={t('login.password')}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('confirm', {
          rules: [
            { required: true, message: t('login.pwdMissing') },
            {
              validator: (_, value, callback) => {
                if (getFieldValue('password') !== value) {
                  callback('login.pwdNotMatching');
                }
                callback();
              }
            }
          ],
          hasFeedback: true
        })(
          <Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={t('login.confirmPassword')}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.resetPwd')}
        </Button>
      </Form.Item>
    </Form>
  );
};

ChangePwdForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired,
  token: PropTypes.string,
  switchForm: PropTypes.func.isRequired
};

ChangePwdForm.defaultProps = {
  token: null
};

const WrappedChangePwdForm = Form.create({ name: 'changePwd' })(ChangePwdForm);

export default WrappedChangePwdForm;
