import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ExtraButtonsCompany = ({ company, deleteCompany, id, type }) => {
  const { t } = useTranslation();
  const { client_config, name } = company;
  return (
    <>
      {(company.type || []).includes('CLIENT') && (
        <Link
          to={{
            pathname: '/client-settings',
            // eslint-disable-next-line no-underscore-dangle
            state: {
              configId: client_config,
              companyId: id,
              companyName: name,
              type
            }
          }}
        >
          <Button icon="setting" />
        </Link>
      )}
      <Popconfirm
        title={t('companies.confirmDelete')}
        okText={t('companies.confirmDeleteOk')}
        cancelText={t('companies.cancelDelete')}
        onConfirm={() => deleteCompany()}
        icon={<Icon type="warning" />}
      >
        <Button
          type="danger"
          icon="delete"
          style={{ float: 'right', margin: '0 4px' }}
        >
          {t('companies.showCompany.deleteButton')}
        </Button>
      </Popconfirm>
      <Link to={`/companies/${type}/edit/${id}`} style={{ float: 'right' }}>
        <Button type="primary" icon="edit">
          {t('companies.showCompany.editButton')}
        </Button>
      </Link>
    </>
  );
};

ExtraButtonsCompany.propTypes = {
  deleteCompany: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
    client_config: PropTypes.string,
    type: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  type: PropTypes.string.isRequired
};

export default ExtraButtonsCompany;
