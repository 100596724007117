import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, message } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import action from './actionColumn';

const DataTable = ({
  resourceName,
  path,
  columns,
  customActionColumn,
  populate,
  style,
  extraQuery,
  forceRefresh,
  linkState
}) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { dispatchConfigs } = useConfigsContext();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const fetchData = useCallback(
    async (page = pagination) => {
      setIsLoading(true);

      const searchURL = searchValue ? `/search/${searchValue}` : null;

      let sortingParameter;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters.replaceAll('__', '&')}`;

      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}${searchURL || ''}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`
        });
        setPagination({
          ...page,
          total: parseInt(headers['x-total-count'], 10)
        });
        setResources(data.map(({ _id, ...d }) => ({ ...d, key: _id })));
      } catch (e) {
        message.error(e.message);
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line
    [
      searchValue,
      currentPage,
      pageSize,
      currentSorter,
      currentFilters,
      forceRefresh,
      extraQuery
    ]
  );
  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
      await fetchData();
      if (resourceName === 'companies')
        dispatchConfigs({
          type: 'MODIF',
          modif: true
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handlePageChange = (page, filters, sorters = {}) => {
    let sortingParameter;
    if (sorters) {
      if (!sorters.order) {
        sortingParameter = null;
      } else if (sorters.order === 'descend') {
        sortingParameter = `&s=-${sorters.columnKey}`;
      } else {
        sortingParameter = `&s=${sorters.columnKey}`;
      }
    }
    let filterParameter = '';
    Object.entries(filters || {}).forEach((el) => {
      if (el[1].length) filterParameter += `${el[0]}=${[...el[1]]}__`;
    });
    history.push({
      pathname,
      search: `?p=${page.current}&pS=${page.pageSize}${sortingParameter || ''}${
        filterParameter ? `&f=${filterParameter}` : ''
      }${searchValue ? `&k=${searchValue}` : ''}`
    });
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const actionColumn = action(path, pathname, linkState, t, deleteResource);

  const adjustedColumns = columns.map((col) => {
    let order;
    let orderKey;
    if (currentSorter) {
      order = 'ascend';
      orderKey = currentSorter;
    }
    if (currentSorter && currentSorter.charAt(0) === '-') {
      order = 'descend';
      orderKey = currentSorter.substring(1);
    }
    const filters = {};
    if (currentFilters) {
      const filtersList = currentFilters.split('__');
      filtersList.forEach((f) => {
        if (f.split('=').length) {
          const [key, values] = f.split('=');
          filters[key] = values;
        }
      });
    }

    return {
      ...col,
      ...(col.dataIndex === orderKey || col.key === orderKey
        ? {
            sortOrder: order
          }
        : { sortOrder: false }),
      ...(filters[col.dataIndex] || filters[col.key]
        ? { filteredValue: filters[col.dataIndex || col.key].split(',') }
        : { filteredValue: [] })
    };
  });

  return (
    <>
      <Table
        style={style}
        rowClassName="rowStyle"
        onRow={({ key }) => ({
          onDoubleClick: () =>
            history.push({
              pathname: `${path || pathname}/show/${key}`
            })
        })}
        dataSource={resources}
        loading={isLoading}
        onChange={handlePageChange}
        pagination={{ ...pagination, current: currentPage, pageSize }}
        scroll={{ x: 1500 }}
        columns={
          customActionColumn
            ? adjustedColumns
            : [...adjustedColumns, ...actionColumn]
        }
      />
      <style>
        {`
          .rowStyle {
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};

DataTable.propTypes = {
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      dataIndex: PropTypes.string,
      render: PropTypes.func,
      sorter: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  ),
  customActionColumn: PropTypes.bool,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  linkState: PropTypes.shape({})
};

DataTable.defaultProps = {
  path: null,
  columns: [],
  customActionColumn: false,
  populate: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  linkState: null
};

export default DataTable;
