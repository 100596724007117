/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Spin, Drawer, Icon, message } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import InputGenerator from '../../components/inputGenerator';
import orderFields from './fields';
import EditAndCreateCompanyForm from '../companies/EditAndCreateCompanyForm';
import AddArticleForm from './AddArticleForm';
import AmountManagerForm from './AmountManagerForm';
import { ContentCustom } from '../../components';
import {
  defaultCalculatedValues,
  makeArticle,
  spinStyle
} from '../invoices/utils';
import {
  addingNewCompany,
  articlesSummer,
  defaultLoading,
  getContractsUtil,
  getOrderEnumsUtil,
  getOrderUtil,
  getSettingsUtil,
  handleSubmitUtil,
  setLoadings
} from './utils';

const EditAndCreateOrderForm = ({
  id,
  preFilledInfo,
  history,
  formItemLayout,
  tailFormItemLayout,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    getFieldValue,
    getFieldProps,
    getFieldsValue
  }
}) => {
  const { dispatchAPI } = useAuthContext();
  const [loading, setLoading] = useState(defaultLoading);
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [leasers, setLeasers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [order, setOrder] = useState(null);
  const [enums, setEnums] = useState({ type: [] });
  const [newCompanyType, setNewCompanyType] = useState(null);
  const [visible, setVisible] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [articles, setArticles] = useState([makeArticle(0)]);
  const [calculatedValues, setCalculatedValues] = useState(
    defaultCalculatedValues
  );
  const { seller, total, buyer, assets } = preFilledInfo || {};
  const supplier = getFieldValue('supplier');
  const client = getFieldValue('client');
  const owner = getFieldValue('owner');

  useEffect(() => {
    setOrder({ ...order, supplier, client, owner });
  }, [owner, client, supplier]);

  const getOrder = () =>
    getOrderUtil(setLoading, loading, dispatchAPI, id, setOrder);

  const getOrderEnums = () =>
    getOrderEnumsUtil(setLoading, loading, dispatchAPI, setEnums);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitUtil(validateFieldsAndScroll, dispatchAPI, history, id, t);
  };

  const getCompanyNames = async () => {
    setLoading({ ...loading, companiesAreLoading: true });
    try {
      const result = await dispatchAPI('GET', {
        url: '/companies'
      });
      setCompanies(
        result.data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
      );
      setLeasers(result.data.filter((c) => c.type.includes('LEASER')));
    } catch (e) {
      message.error(e.message);
    }
    setLoading({ ...loading, companiesAreLoading: false });
  };

  const getContracts = () => {
    getContractsUtil(setLoading, loading, dispatchAPI, client, setContracts);
  };

  const getSettings = () =>
    getSettingsUtil(setLoading, loading, dispatchAPI, setCatalog);

  useEffect(() => {
    if (id) {
      (async () => {
        await getOrder();
      })();
    }
    (async () => {
      await getOrderEnums();
      await getCompanyNames();
      getContracts();
      await getSettings();
    })();
  }, [client]);

  useEffect(() => {
    if (order && order.articles && order.articles.length) {
      const tmp = [];
      order.articles.forEach((opt, idx) => {
        tmp.push(makeArticle(idx));
      });
      setArticles([...tmp]);
    }
  }, [order]);

  const addArticleField = () => {
    const tmp = articles;
    tmp.push(makeArticle(tmp.length));
    setArticles([...tmp]);
  };

  const sumUpArticles = useCallback(
    (fieldsValue = getFieldsValue()) => {
      if (fieldsValue.addArticle) {
        articlesSummer(fieldsValue, setFieldsValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldsValue, articles, getFieldsValue]
  );

  useEffect(() => {
    const values = getFieldsValue();
    if (values.addArticle) sumUpArticles();
  }, [calculatedValues, sumUpArticles, articles]);

  const deleteArticle = (key) => {
    const tmp = articles.filter(({ articleId }) => articleId !== key);
    setArticles([...tmp]);
  };

  const { orderIsLoading } = loading;

  useEffect(() => {
    if (seller && buyer && total && assets && companies.length) {
      setFieldsValue({ to: buyer, from: seller, total });
      const tmp = [];
      assets.forEach((opt, idx) => {
        tmp.push(makeArticle(idx));
      });
      setArticles([...tmp]);
    }
  }, [seller, buyer, total, assets, companies]);

  const buttonAddCompany = (tpe) => (
    <Button
      type="primary"
      onClick={() => addingNewCompany(tpe, setNewCompanyType, setVisible)}
    >
      <Icon type="plus" />
      {t(`contracts.${tpe}`)}
    </Button>
  );

  const handleCompanyCreated = async (name) => {
    setVisible(false);
    await getCompanyNames();
    setCompanyName({ name, type: newCompanyType });
    if (newCompanyType === ['SUPPLIER'])
      setOrder({ ...order, supplier: order.supplier.push() });
  };

  return (
    <ContentCustom>
      <Drawer
        title={t(`companies.form.${newCompanyType}`)}
        width={720}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <EditAndCreateCompanyForm
          title={newCompanyType}
          initialType={newCompanyType}
          drawer
          onSuccess={handleCompanyCreated}
        />
      </Drawer>
      {orderIsLoading ? (
        <Spin style={spinStyle} />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Form {...formItemLayout} onSubmit={handleSubmit}>
          <InputGenerator
            title="orders"
            fields={orderFields(
              setLoadings(loading),
              order,
              enums.type,
              companies,
              leasers,
              contracts,
              catalog,
              t,
              companyName
            )}
            extra={buttonAddCompany}
            getFieldDecorator={getFieldDecorator}
          />
          <AddArticleForm
            articles={articles}
            order={order}
            getFieldDecorator={getFieldDecorator}
            getFieldProps={getFieldProps}
            calculatedValues={calculatedValues}
            setCalculatedValues={setCalculatedValues}
            catalog={catalog}
            deleteArticle={deleteArticle}
          />
          <AmountManagerForm
            invoice={order}
            getFieldDecorator={getFieldDecorator}
            tailFormItemLayout={tailFormItemLayout}
            addArticleField={addArticleField}
          />
        </Form>
      )}
    </ContentCustom>
  );
};

EditAndCreateOrderForm.defaultProps = {
  initialType: null,
  id: null,
  history: null,
  seller: null,
  buyer: null,
  total: null,
  preFilledInfo: {}
};

EditAndCreateOrderForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldProps: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  initialType: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  seller: PropTypes.string,
  buyer: PropTypes.string,
  total: PropTypes.number,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  formItemLayout: PropTypes.shape({}).isRequired,
  tailFormItemLayout: PropTypes.shape({}).isRequired,
  preFilledInfo: PropTypes.shape({})
};

const WrappedEditAndCreateOrderAntForm = Form.create({
  name: 'orders'
})(EditAndCreateOrderForm);

export default WrappedEditAndCreateOrderAntForm;
