/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import { formItemLayout } from '../../utils/formLayouts';
import AssetsPart from './AssetsPart';
import BaseTicketForm from './BaseTicketForm';
import {
  defaultPagination,
  getAssetsEnumsUtil,
  getAssetsUtil,
  getCompanyNamesUtil,
  getCompanyPeopleUtil,
  getCompanySitesUtil,
  getPeopleDetailsUtil,
  getSiteAddressUtil,
  getTicketsEnumsUtil,
  getTicketUtil,
  handleMultipleSelectUtil,
  handleSubmitUtil,
  setContact,
  setPeople,
  setSiteAddress
} from './utils';

const TicketForm = ({
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldValue,
    setFieldsValue
  }
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { configs } = useConfigsContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isSitesLoading, setIsSitesLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [companySites, setCompanySites] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isPeopleLoading, setIsPeopleLoading] = useState(false);
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(true);
  const [companyPeople, setCompanyPeople] = useState([]);
  const [ticket, setTicket] = useState(null);
  const [fetchingTicket, setFetchingTicket] = useState(false);
  const [modificationContactFields, setModificationContactFields] = useState(
    false
  );
  const [assets, setAssets] = useState([]);
  const [ticketsEnumValues, setTicketsEnums] = useState(null);
  const [assetsEnumValues, setAssetsEnums] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [allAssets, setAllAssets] = useState([]);
  const [files, setFiles] = useState([]);
  const [serialNumber, setSerialNumber] = useState([]);
  const ticketType = getFieldValue('type');
  const siteId = getFieldValue('siteId');
  const peopleId = getFieldValue('peopleId');
  const contactName = getFieldValue('contact.name');
  const [pagination, setPagination] = useState(defaultPagination);
  const [filteredInfo, setFilteredInfo] = useState({ status: null });

  const getTicketsEnums = () => {
    getTicketsEnumsUtil(dispatchAPI, setTicketsEnums);
  };
  const getCompanyNames = () => {
    getCompanyNamesUtil(
      setIsCompaniesLoading,
      dispatchAPI,
      setCompanyNames,
      companyNames
    );
  };

  const getAssetsEnums = () => {
    getAssetsEnumsUtil(setAssetsEnums, dispatchAPI);
  };

  const getAssets = (page = pagination, filters = filteredInfo, selectMany) => {
    getAssetsUtil(
      page,
      filters,
      selectMany,
      dispatchAPI,
      setAssets,
      company,
      setAllAssets,
      setPagination
    );
    setIsTableLoading(false);
  };

  const getSiteAddress = (id) => {
    return getSiteAddressUtil(id, dispatchAPI, company);
  };

  useEffect(() => {
    if (siteId) {
      setIsSitesLoading(true);
      (async () => {
        const site = (await getSiteAddress(siteId))[0];
        setFieldsValue(setSiteAddress(site));
      })();
      setIsSitesLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const handleChange = async (page, filters) => {
    setPagination({ ...pagination, ...page });
    setIsTableLoading(true);
    setFilteredInfo(filters);
    await getAssets(page, filters);
  };

  const getTicket = () =>
    getTicketUtil(
      setFetchingTicket,
      id,
      dispatchAPI,
      setTicket,
      setAssets,
      setCompany
    );

  const getCompanySites = (searchValue) =>
    getCompanySitesUtil(
      searchValue,
      setIsSitesLoading,
      company,
      dispatchAPI,
      setCompanySites
    );

  const getCompanyPeople = (searchValue) =>
    getCompanyPeopleUtil(
      searchValue,
      setIsPeopleLoading,
      dispatchAPI,
      company,
      setCompanyPeople
    );

  const getPeopleDetails = (id) => {
    return getPeopleDetailsUtil(id, dispatchAPI);
  };
  const getAssetsSelected = async () => {
    if (id && serialNumber.length === 0) {
      try {
        const resultForModification = await dispatchAPI('GET', {
          url: `/tickets/${id}?populate=assets`
        });
        const Result = resultForModification.data.assets.filter((asset) =>
          assets.includes(asset._id)
        );
        setSerialNumber(Result.map((asset) => asset.serial_number));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };
  useEffect(() => {
    if ((!id && peopleId) || (modificationContactFields && peopleId)) {
      setIsPeopleLoading(true);
      (async () => {
        const people = await getPeopleDetails(peopleId);
        setFieldsValue(setPeople(people));
      })();
      setIsPeopleLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleId]);
  useEffect(() => {
    if (contactName) setModificationContactFields(true);
    if (
      (!id && contactName && !peopleId) ||
      (modificationContactFields && !peopleId)
    ) {
      setIsPeopleLoading(true);
      (async () => {
        const people = await getPeopleDetails(contactName);
        setFieldsValue(setContact(people));
      })();
      setIsPeopleLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactName]);

  useEffect(() => {
    if (id) {
      (async () => {
        await getTicket();
      })();
    }
    (async () => {
      await getTicketsEnums();
      await getAssetsEnums();
      await getCompanyNames();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (id) {
      (async () => {
        await getAssetsSelected();
      })();
    }
  }, [assets]);

  useEffect(() => {
    if (company)
      (async () => {
        await getAssets();
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (
      company &&
      ticket &&
      ticket.incident_user &&
      ticket.incident_user.last_name
    ) {
      (async () => {
        await getCompanyPeople(ticket.incident_user.last_name);
      })();
    }
  }, [ticket, company]);

  useEffect(() => {
    if (configs) {
      setFieldsValue({ company: configs.client });
      setCompany(configs.client);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (assets.length) {
      handleSubmitUtil(
        validateFieldsAndScroll,
        setIsLoading,
        dispatchAPI,
        id,
        assets,
        history,
        t,
        files
      );
    } else {
      message.error(t('tickets.fields.error.noAssets'));
    }
  };

  const selectAssets = (keys) => {
    setAssets(keys);

    const keysInAllAssets = allAssets.filter((asset) =>
      keys.includes(asset._id)
    );
    let keysInAllAssetsToSerial = keysInAllAssets.map((b) => b.serial_number);
    if (keys.length > serialNumber.length) {
      keysInAllAssetsToSerial = keysInAllAssetsToSerial.filter(
        (b) => !serialNumber.includes(b)
      );
      setSerialNumber([...serialNumber, ...keysInAllAssetsToSerial]);
    } else {
      const allAssetsSerial = allAssets.map((asset) => asset.serial_number);
      const serialNumberInOtherPage = serialNumber.filter(
        (b) => !allAssetsSerial.includes(b)
      );
      setSerialNumber([...serialNumberInOtherPage, ...keysInAllAssetsToSerial]);
    }
  };

  const selectAllAssets = async (selected) => {
    if (selected) {
      setIsTableLoading(true);
      await getAssets(pagination, filteredInfo, selected);
    } else {
      setAssets([]);
    }
  };

  const handleSearch = (field, selectedKeys, confirm) => {
    setFilteredInfo({ ...filteredInfo, [field]: selectedKeys[0] });
    confirm();
  };

  const handleMultipleSelect = (keys) => {
    handleMultipleSelectUtil(
      keys,
      setIsTableLoading,
      dispatchAPI,
      setAssets,
      allAssets,
      serialNumber,
      setSerialNumber
    );
  };
  useEffect(() => {
    setSerialNumber(serialNumber);
  }, [serialNumber]);

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <BaseTicketForm
        fetchingTicket={fetchingTicket}
        isCompaniesLoading={isCompaniesLoading}
        setCompany={setCompany}
        companyNames={companyNames}
        ticketType={ticketType}
        ticketsEnumValues={ticketsEnumValues}
        ticket={ticket}
        getFieldDecorator={getFieldDecorator}
        isSitesLoading={isSitesLoading}
        getCompanySites={getCompanySites}
        companySites={companySites}
        company={company}
        siteId={siteId}
        isPeopleLoading={isPeopleLoading}
        getCompanyPeople={getCompanyPeople}
        companyPeople={companyPeople}
        peopleId={peopleId}
        setFieldsValue={setFieldsValue}
        files={files}
        setFiles={setFiles}
        id={id}
      />
      <AssetsPart
        id={id}
        handleMultipleSelect={handleMultipleSelect}
        handleChange={handleChange}
        handleSearch={handleSearch}
        allAssets={allAssets}
        isTableLoading={isTableLoading}
        pagination={pagination}
        assets={assets}
        selectAllAssets={selectAllAssets}
        assetsEnumValues={assetsEnumValues}
        filteredInfo={filteredInfo}
        isLoading={isLoading}
        selectAssets={selectAssets}
        serialNumber={serialNumber}
      />
    </Form>
  );
};

TicketForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func
  }).isRequired
};

export default Form.create({ name: 'ticket_form' })(TicketForm);
