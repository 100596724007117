const headers = [
  {
    label: 'order_number',
    key: 'order_number'
  },
  {
    label: 'order_date',
    key: 'order_date'
  },
  {
    label: 'object',
    key: 'object'
  },
  {
    label: 'reception_date',
    key: 'reception_date'
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'pv_number',
    key: 'pv_number'
  },
  {
    label: 'pv_signing_date',
    key: 'pv_signing_date'
  },
  {
    label: 'checkout_amount',
    key: 'checkout_amount'
  },
  {
    label: 'tva_amount',
    key: 'tva_amount'
  },
  {
    label: 'total_ht_amount',
    key: 'total_ht_amount'
  },
  {
    label: 'ttc_amount',
    key: 'ttc_amount'
  }
];

export default headers;
