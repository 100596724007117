import React from 'react';
import { Row, Typography, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;
const { Option } = Select;

const SchemaName = ({ schemaName, handleRefChange, schema, reference }) => {
  const { t } = useTranslation();

  return (
    <Row style={{ marginTop: 16 }}>
      {schemaName && (
        <>
          <Paragraph strong>{t('import.refModal.chooseRef')}</Paragraph>
          <Select
            style={{ width: 250 }}
            onChange={handleRefChange}
            value={reference}
          >
            {schema.map((opt) => (
              <Option key={opt} value={opt}>
                {t(
                  `${
                    // eslint-disable-next-line no-nested-ternary
                    [
                      'IT',
                      'Telecom',
                      'PhoneContract',
                      'Phonecontract',
                      'RealEstate',
                      'Car'
                    ].includes(schemaName)
                      ? 'assets'
                      : ['Company', 'Subsidiary'].includes(schemaName)
                      ? schemaName.toLowerCase().replace('y', 'ies')
                      : `${schemaName.toLowerCase()}s`
                  }.list.column.${opt}`
                )}
              </Option>
            ))}
          </Select>
        </>
      )}
    </Row>
  );
};

SchemaName.propTypes = {
  schemaName: PropTypes.string.isRequired,
  handleRefChange: PropTypes.func.isRequired,
  schema: PropTypes.arrayOf(PropTypes.string).isRequired,
  reference: PropTypes.string.isRequired
};

export default SchemaName;
