export default (
  user,
  companies,
  roles,
  companyName,
  selectedCompany,
  addOn,
  t,
  clientView
) => {
  const {
    civility,
    first_name,
    last_name,
    company,
    site,
    email,
    role,
    function: position
  } = user;
  const selectedCompanyObj = companies.filter(
    (c) => c.key === selectedCompany
  )[0];

  return [
    {
      label: 'civility',
      required: true,
      input: 'radio',
      initialValue: civility,
      buttons: [
        {
          value: 'MISTER',
          text: t('users.editcreate.form.gender.male')
        },
        {
          value: 'MADAME',
          text: t('users.editcreate.form.gender.female')
        }
      ]
    },
    {
      label: 'first_name',
      required: true,
      initialValue: first_name
    },
    {
      label: 'last_name',
      required: true,
      initialValue: last_name
    },
    {
      label: 'role',
      required: true,
      input: 'select',
      options: roles,
      initialValue: role
    },
    {
      label: 'email',
      type: 'email',
      required: true,
      initialValue: email
    },
    {
      label: 'company',
      initialValue: companyName
        ? companies.filter((c) => c.name === companyName)[0].key
        : company,
      input: 'select',
      options: companies,
      disabled: clientView,
      extra: true
    },

    {
      label: 'site',
      required: false,
      input: 'select',
      initialValue: site,
      options:
        (selectedCompanyObj &&
          selectedCompanyObj.sites &&
          selectedCompanyObj.sites.map((singleSite) => ({
            ...singleSite,
            key: singleSite._id,
            value: singleSite._id,
            text: singleSite.name
          }))) ||
        []
    },
    {
      label: 'function',
      required: false,
      initialValue: position
    }
  ];
};
