import React from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { useParams } from 'react-router-dom';
import headers from './headers';
import { ResourceLandingLayout } from '../../layouts';
import useConfigsContext from '../../contexts/ConfigsContext';

const ListCompanies = () => {
  const { type } = useParams();
  const { t } = useTranslation();
  const { configs } = useConfigsContext();

  return (
    <ResourceLandingLayout
      tradKey={type}
      headers={headers}
      extraQuery={`type=${type.toUpperCase()}${
        configs ? `&contracts=${configs.contracts}&_id!=${configs.client}` : ''
      }`}
      resourceName="companies"
      exportUrl={`companies?type=${type.toUpperCase()}`}
      columns={[
        {
          title: t('companies.list.column.name'),
          dataIndex: 'name',
          sorter: true
        },
        {
          title: t('companies.list.column.group'),
          dataIndex: 'group',
          sorter: true
        },
        {
          title: t('companies.list.column.siren'),
          dataIndex: 'siren',
          sorter: true,
          render: (siren) => (siren ? numeral(siren).format('siren') : '-')
        },
        {
          title: t('companies.list.column.APE'),
          dataIndex: 'APE',
          sorter: true
        }
      ]}
    />
  );
};

export default ListCompanies;
