import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import { useStateWithSessionStorage } from '../../utils';

const { Option } = Select;

const SelectView = () => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { modif, dispatchConfigs } = useConfigsContext();
  const [configs] = useStateWithSessionStorage('configs', null);
  const [client, setClient] = useState(configs ? configs.client : 'admin');
  const defaultCompany = [{ _id: 'admin', name: 'Administrateur' }];
  const [companies, setCompanies] = useState([]);
  const fetchCompanies = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/companies?type=CLIENT'
    });
    setCompanies([
      ...defaultCompany,
      ...data.sort((a, b) => a.name.localeCompare(b.name))
    ]);
    setIsLoading(false);
  };

  const handleChange = async (value) => {
    setIsLoading(true);
    setClient(value);
    if (value === 'admin') {
      dispatchConfigs({ type: 'ADMIN' });
    } else {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/companies/${value}?populate=client_config`
        });
        dispatchConfigs({
          type: 'SET',
          id: value,
          contracts: data.contracts,
          configs: data.client_config
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modif]);

  return (
    <Select
      size="small"
      onChange={handleChange}
      loading={isLoading}
      style={{ width: '70%', marginLeft: 10 }}
      value={client}
    >
      {companies.map(({ _id, name }) => (
        <Option key={_id} value={_id}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectView;
