import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Select, Button } from 'antd';
import InputGenerator from '../../components/inputGenerator';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 12 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const AddContactModal = ({
  contact,
  visible,
  updateContact,
  handleOk,
  handleCancel,
  isLoading,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    resetFields,
    setFieldsValue
  }
}) => {
  const { t } = useTranslation();

  const prefixSelector = getFieldDecorator(
    'addContact.phone_number.country_code',
    {
      initialValue: '+33'
    }
  )(
    <Select style={{ width: 70 }}>
      <Option value="+33">+33</Option>
    </Select>
  );
  const prefixSelector2 = getFieldDecorator(
    'addContact.phone_number2.country_code',
    {
      initialValue: '+33'
    }
  )(
    <Select style={{ width: 70 }}>
      <Option value="+33">+33</Option>
    </Select>
  );

  const fields = [
    {
      label: 'addContact.civility',
      required: true,
      input: 'radio',
      buttons: [
        {
          value: 'MISTER',
          text: t('companies.form.contact.gender.male')
        },
        {
          value: 'MADAME',
          text: t('companies.form.contact.gender.female')
        }
      ],
      initialValue: contact && contact[0].civility
    },
    {
      label: 'addContact.first_name',
      required: true,
      initialValue: contact && contact[0].first_name
    },
    {
      label: 'addContact.last_name',
      required: true,
      initialValue: contact && contact[0].last_name
    },
    {
      label: 'addContact.function',
      initialValue: contact && contact[0].function
    },
    {
      label: 'addContact.email',
      initialValue: contact && contact[0].email,
      required: true
    },
    {
      label: 'addContact.phone_number.number',
      required: true,
      addOn: prefixSelector,
      initialValue:
        contact && contact[0].phone_number && contact[0].phone_number.number,
      extraRules: [
        {
          transform: (value) => {
            if (value)
              setFieldsValue({
                'addContact.phone_number.number': value
                  .replace(/^0/, '')
                  .replace(/ /g, '')
              });
          }
        }
      ]
    },
    {
      label: 'addContact.phone_number2.number',
      addOn: prefixSelector2,
      initialValue:
        contact && contact[0].phone_number2 && contact[0].phone_number2.number,
      extraRules: [
        {
          transform: (value) => {
            if (value)
              setFieldsValue({
                'addContact.phone_number2.number': value
                  .replace(/^0/, '')
                  .replace(/ /g, '')
              });
          }
        }
      ]
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (contact) {
          await updateContact(values);
          resetFields();
        } else {
          await handleOk(values);
          resetFields();
        }
      }
    });
  };

  const cancel = () => {
    resetFields();
    handleCancel();
  };

  return (
    <Modal
      width={800}
      title={
        contact
          ? t('companies.contacts.editContact')
          : t('companies.contacts.addContact')
      }
      visible={visible}
      onCancel={cancel}
      footer={null}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <InputGenerator
          title="companies"
          fields={fields}
          getFieldDecorator={getFieldDecorator}
        />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('users.editcreate.form.submitButton')}
          </Button>
          <Button type="danger" onClick={cancel} style={{ margin: '0 10px' }}>
            {t('users.editcreate.form.cancel')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddContactModal.propTypes = {
  contact: PropTypes.arrayOf(
    PropTypes.shape({
      civility: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      function: PropTypes.string,
      phone_number: PropTypes.shape({
        country_code: PropTypes.string,
        number: PropTypes.string
      }),
      phone_number2: PropTypes.shape({
        country_code: PropTypes.string,
        number: PropTypes.string
      })
    })
  ),
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};

AddContactModal.defaultProps = {
  contact: null
};

const WrappedAddContactAntForm = Form.create({ name: 'addContact' })(
  AddContactModal
);

export default WrappedAddContactAntForm;
