import React from 'react';
import { Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ResourceLandingLayout } from '../../layouts';
import headers from './headers';

const ListPayment = () => {
  const { t } = useTranslation();
  const populates = ['invoices', 'recipient'];

  return (
    <ResourceLandingLayout
      resourceName="payments"
      populate={populates}
      headers={headers}
      columns={[
        {
          title: t('payments.list.column.number'),
          dataIndex: 'number',
          sorter: true
        },
        {
          title: t('payments.list.column.invoices'),
          dataIndex: 'invoices',
          sorter: true,
          render: (invoices) => (
            <Typography.Text>{invoices.length}</Typography.Text>
          )
        },
        {
          title: t('payments.list.column.recipient'),
          dataIndex: 'recipient',
          sorter: true,
          render: (recipient) => <Tag color="red">{recipient.name}</Tag>
        },
        {
          title: t('payments.list.column.paymentDate'),
          dataIndex: 'payment_date',
          sorter: true,
          render: (payment_date) => (
            <Typography>
              {(payment_date && moment(payment_date).format('LL')) || '-'}
            </Typography>
          )
        }
      ]}
    />
  );
};

export default ListPayment;
