import React, { useEffect, useState } from 'react';
import { Divider, Icon, message, Table, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';

const { Column } = Table;
const iconSize = '18px';

const PeopleTab = ({ company }) => {
  const { dispatchAPI, isLoading } = useAuthContext();
  const history = useHistory();
  const { t } = useTranslation();
  const [people, setPeople] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const getPeople = async (page = pagination) => {
    try {
      const result = await dispatchAPI('GET', {
        url: `/people?limit=${page.pageSize}&skip=${(page.current - 1) *
          page.pageSize}&company=${company}`
      });
      setPeople(result.data.map((person) => ({ ...person, key: person._id })));
      setPagination({
        ...page,
        total: parseInt(result.headers['x-total-count'], 10)
      });
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
  };

  const handlePageChange = async (page) => {
    await getPeople(page);
  };

  useEffect(() => {
    if (company) {
      getPeople();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <Table
      dataSource={people}
      onChange={handlePageChange}
      pagination={pagination}
      loading={isLoading}
      rowClassName="rowStyle"
      onRow={(record) => ({
        onDoubleClick: () => history.push(`/people/show/${record.key}`)
      })}
    >
      <Column
        title={t('people.list.column.first_name')}
        key="firstName"
        sorter={(a, b) => a.first_name.localeCompare(b.first_name)}
        sortDirections={['ascend', 'descend']}
        render={(person) => <Typography>{`${person.first_name}`}</Typography>}
      />
      <Column
        title={t('people.list.column.last_name')}
        key="lastName"
        sorter={(a, b) => a.last_name.localeCompare(b.last_name)}
        sortDirections={['ascend', 'descend']}
        render={(person) => <Typography>{person.last_name}</Typography>}
      />
      <Column
        title={t('people.list.column.site')}
        key="site"
        sorter={(a, b) => a.site.localeCompare(b.site)}
        sortDirections={['ascend', 'descend']}
        render={(person) => <Typography>{person.site}</Typography>}
      />
      <Column
        title={t('users.list.column.actions')}
        key="action"
        render={(person) => (
          <span>
            <Link
              to={{
                pathname: `/people/show/${person.key}`
              }}
            >
              <Icon type="eye" style={{ fontSize: iconSize }} />
            </Link>
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/people/edit/${person.key}`
              }}
            >
              <Icon type="edit" style={{ fontSize: iconSize }} />
            </Link>
          </span>
        )}
      />
    </Table>
  );
};

PeopleTab.propTypes = {
  company: PropTypes.string
};

PeopleTab.defaultProps = {
  company: null
};

export default PeopleTab;
