import React from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DatePickerFooter = ({
  dateField,
  handleDateFieldChange,
  disabledPVDateRange,
  disabledReceptionDateRange
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Radio.Group
        buttonStyle="solid"
        size="small"
        value={dateField}
        onChange={(e) => handleDateFieldChange(e.target.value)}
      >
        <Radio.Button value="order_date">
          {t(`header.menu.filteredTab.creationDateOrders`)}
        </Radio.Button>
        <Radio.Button value="pv_signing_date" disabled={disabledPVDateRange}>
          {t(`header.menu.filteredTab.pv_signing_date`)}
        </Radio.Button>
        <Radio.Button
          value="reception_date"
          disabled={disabledReceptionDateRange}
        >
          {t(`header.menu.filteredTab.receptionDate`)}
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

DatePickerFooter.propTypes = {
  dateField: PropTypes.string.isRequired,
  handleDateFieldChange: PropTypes.func.isRequired,
  disabledPVDateRange: PropTypes.bool.isRequired,
  disabledReceptionDateRange: PropTypes.bool.isRequired
};

export default DatePickerFooter;
