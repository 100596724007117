/* eslint-disable no-console */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';
import {
  Icon,
  message,
  notification,
  Progress,
  Button,
  Modal,
  Typography,
  Spin
} from 'antd';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useStateWithDynamicStorage, useStateWithLocalStorage } from '../utils';

const AuthContext = createContext({});
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

let socket;

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [rememberMe, setRememberMe] = useStateWithLocalStorage('remember_me');
  const [user, setUser] = useStateWithDynamicStorage(rememberMe, 'user');
  const [token, setToken] = useStateWithDynamicStorage(rememberMe, 'token');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openNotification = (
    data,
    options,
    type = 'open',
    description = t(`notification.${data}`)
  ) => {
    notification[type]({
      description,
      ...options
    });
  };

  useEffect(() => {
    socket = io(`${process.env.REACT_APP_API_URL}?token=${token}`);
    socket.on('import', (status, data) => {
      switch (status) {
        case 'PARSING_PENDING':
        case 'PARSING_DONE':
          openNotification(status, {
            message: 'Import',
            key: 'import',
            duration: 0,
            icon: <Icon type="loading" spin style={{ color: '#1890ff' }} />
          });
          break;
        case 'IMPORT_PROGRESS':
          openNotification(status, {
            message: 'Import',
            key: 'import',
            duration: 0,
            icon:
              data === 100 ? (
                <Spin spinning style={{ width: 35 }} />
              ) : (
                <Progress type="circle" percent={data} width={35} />
              )
          });
          break;
        case 'IMPORT_DONE':
          socket.emit(status, 'ROGER');
          if (!data.errorFeedback || data.errorFeedback.numberOfError === 0)
            openNotification(status, {
              message: 'Import',
              key: 'import',
              icon: <Progress type="circle" percent={100} width={35} />
            });
          else
            openNotification(
              status,
              {
                message: `Import avec ${data.errorFeedback.numberOfError} erreurs`,
                key: 'import',
                duration: 0,
                description: (
                  <Button
                    type="link"
                    onClick={() => {
                      Modal.error({
                        title: `Errors: ${data.errorFeedback.numberOfError}`,
                        width: 1000,
                        content: (
                          <div style={{ height: 700, overflow: 'auto' }}>
                            <ul>
                              {data.errorFeedback.failedRows.map(
                                ({ errors, row }) => (
                                  <li key={row}>
                                    <Typography.Text strong>
                                      {`Line ${row}:`}
                                    </Typography.Text>
                                    <ul>
                                      {errors.map((err) => (
                                        <li key={err}>{err}</li>
                                      ))}
                                    </ul>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )
                      });
                      notification.destroy();
                    }}
                  >
                    Voir les erreurs
                  </Button>
                )
              },
              'warning'
            );
          break;
        case 'PARSING_ERROR':
        case 'PARSING_ERROR_COMMA':
          socket.emit(status, 'ROGER');
          openNotification(
            status,
            {
              message: 'Import',
              key: 'import',
              duration: 10
            },
            'error'
          );
          break;
        case 'IMPORT_ERROR':
          socket.emit(status, 'ROGER');
          break;
        default:
          break;
      }
    });
    socket.on('error', (data) => {
      console.error(data);
    });
  }, [token]);

  const setSession = (accessToken) => {
    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };

  const loginAPI = async (email, password, remember) => {
    try {
      const result = await axiosInstance.get('/login/admin', {
        auth: {
          username: email,
          password
        }
      });
      setUser(result.data.user);
      setRememberMe(remember);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const logout = () => {
    setSession(null);
    setUser(null);
  };

  const isTokenValid = () => {
    if (!token) {
      return false;
    }
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        message.warn('Session expired, please log in again.');
        setSession(null);
        return false;
      }
    } catch (e) {
      message.warn('Oops something went wrong, please log in again.');
      setSession(null);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  isTokenValid();

  const fetchAPI = async (
    url,
    method = 'GET',
    body = null,
    responseType = 'json'
  ) => {
    try {
      isTokenValid();
      setIsLoading(true);
      const result = await axiosInstance({
        url,
        method,
        responseType,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIsLoading(false);
      return result;
    } catch (e) {
      setIsLoading(false);
      return throw e;
    }
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password, options.rememberMe);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(options.url, 'GET', null, options.responseType);
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        return throw new Error('Unknown dispatchAPI type!');
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, token, isValid, dispatchAPI, isLoading, socket }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default () => useContext(AuthContext);
