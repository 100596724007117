import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ContractsTab from './ContractsTab';
import SuppliersTab from './SuppliersTab';
import AssetsTab from './AssetsTab';
import PeopleTab from './PeopleTab';

const RelatedItems = ({
  contracts,
  assets,
  companyType,
  company,
  isLoading
}) => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState('contracts');
  const [tabList, setTabList] = useState([]);
  const [contentList, setContentList] = useState({});

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (companyType) {
      if (companyType.includes('SUPPLIER')) {
        setTabList([
          {
            key: 'contracts',
            tab: t('contracts.list.title')
          }
        ]);
        setContentList({
          contracts: <ContractsTab contractsData={contracts && contracts} />
        });
      }
      if (companyType.includes('CLIENT')) {
        setTabList([
          {
            key: 'contracts',
            tab: t('contracts.list.title')
          },
          {
            key: 'suppliers',
            tab: t('SUPPLIER.list.title')
          },
          {
            key: 'people',
            tab: t('people.title')
          }
        ]);
        setContentList({
          contracts: <ContractsTab contractsData={contracts} />,
          suppliers: <SuppliersTab company={company} />,
          people: <PeopleTab company={company} />,
          assets: <AssetsTab assetsData={assets} />
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyType, assets]);

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 12 }}>
        <Typography.Title level={4}>
          {t('companies.showCompany.attachedElements.title')}
        </Typography.Title>
      </Row>
      <Skeleton
        loading={isLoading}
        active
        title={0}
        paragraph={{
          rows: 6,
          width: ['100%', '100%', '100%', '100%', '100%', '100%']
        }}
      >
        <Card
          tabList={tabList}
          activeTabKey={activeKey}
          onTabChange={(key) => {
            onTabChange(key);
          }}
        >
          {contentList[activeKey]}
        </Card>
      </Skeleton>
    </>
  );
};

RelatedItems.defaultProps = {
  contracts: null,
  assets: null,
  company: null
};

RelatedItems.propTypes = {
  contracts: PropTypes.arrayOf(PropTypes.shape({})),
  company: PropTypes.string,
  companyType: PropTypes.arrayOf(PropTypes.string).isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired
};

export default RelatedItems;
