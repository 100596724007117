import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Icon, Table, Typography, Dropdown, Menu, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';

const { Column } = Table;
const iconSize = '18px';

const InvoicesTab = ({ invoicesData }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const handlePageChange = (page) => {
    setPagination(page);
  };

  useEffect(() => {
    if (invoicesData && invoicesData.length) {
      setInvoices(
        // eslint-disable-next-line no-underscore-dangle
        invoicesData.map((invoice) => ({ ...invoice, key: invoice._id }))
      );
      setPagination({ ...pagination, total: invoicesData.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesData]);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to={{ pathname: '/invoices/create', search: `?type=supplier` }}>
          {t('invoices.addSupplierInvoice')}
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to={{ pathname: '/invoices/create', search: `?type=client` }}>
          {t('invoices.addClientInvoice')}
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: 5 }}>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="link" icon="plus" />
        </Dropdown>
      </div>
      <Table
        dataSource={invoices}
        onChange={handlePageChange}
        pagination={pagination}
        rowClassName="rowStyle"
        onRow={(record) => ({
          onDoubleClick: () =>
            history.push(
              `/invoices/${record.invoice_type.toLowerCase()}/show/${
                record.key
              }`
            )
        })}
      >
        <Column
          title={t('invoices.list.column.number')}
          key="number"
          render={(invoice) => <Typography>{invoice.number}</Typography>}
        />
        <Column
          title={t('invoices.list.column.payment_date')}
          key="payment_date"
          render={(invoice) => (
            <Typography>{moment(invoice.payment_date).format('LL')}</Typography>
          )}
        />
        <Column
          title={t('invoices.list.column.total')}
          key="total"
          render={(invoice) => (
            <Typography>
              {`${numeral(invoice.total).format('0,0.00')} €`}
            </Typography>
          )}
        />
        <Column
          title={t('users.list.column.actions')}
          key="action"
          render={(record) => (
            <span>
              <Link
                to={`/invoices/${record.invoice_type.toLowerCase()}/show/${
                  record.key
                }`}
              >
                <Icon type="eye" style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
              <Link
                to={`/invoices/${record.invoice_type.toLowerCase()}/edit/${
                  record.key
                }`}
              >
                <Icon type="edit" style={{ fontSize: iconSize }} />
              </Link>
            </span>
          )}
        />
      </Table>
    </>
  );
};

InvoicesTab.defaultProps = {
  invoicesData: null
};

InvoicesTab.propTypes = {
  invoicesData: PropTypes.arrayOf(PropTypes.shape({}))
};

export default InvoicesTab;
