/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { message, Button, Icon, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import InvoiceAmount from './InvoiceAmount';
import InvoiceDetails from './InvoiceDetails';
import InvoiceTable from './InvoiceTable';
import UploadDrawer from '../../components/uploadDrawer';
import { ContentCustom, PageHeaderCustom } from '../../components';

const ShowInvoice = () => {
  const { id, type } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState({
    files: []
  });
  const [badgeCount, setBadgeCount] = useState(0);
  const [isCompletedstate, setIsCompleted] = useState(false);
  const [order_number, setOrder_number] = useState();

  const getInvoice = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: `/invoices/${id}?populate=to,from,files,contract`
      });
      setInvoice(result.data);
      const checkForHexRegExp = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
      if (!checkForHexRegExp.test(result.data.order_number)) {
        setOrder_number(result.data.order_number);
      } else {
        const { data } = await dispatchAPI('GET', {
          url: `/orders?number=${result.data.order_number}`
        });
        setOrder_number(data.order_number);
      }
      if (result.data.files.length) {
        setBadgeCount(result.data.files.length);
      } else setBadgeCount(0);
      setLoading(false);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
      setLoading(false);
    }
  };

  const deleteInvoice = async () => {
    await dispatchAPI('DELETE', { url: `/invoices/${id}` });
    history.push({ pathname: '../../invoices', search: `?type=${type}` });
  };

  const displayDownload = () => {
    setIsCompleted(true);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getInvoice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {}, [isCompletedstate]);

  const prev = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?invoice_type=${type.toUpperCase()}&emission_date<${
          invoice.emission_date
        }&sort=-emission_date&limit=1`
      });
      if (data[0]) {
        const { _id } = data[0];
        history.push(`/invoices/${type}/show/${_id}`);
      } else {
        message.error(t('invoices.showInvoice.noPrev'));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const next = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?invoice_type=${type.toUpperCase()}&emission_date>${
          invoice.emission_date
        }&sort=emission_date&limit=1`
      });
      if (data[0]) {
        const { _id } = data[0];
        history.push(`/invoices/${type}/show/${_id}`);
      } else {
        message.error(t('invoices.showInvoice.noNext'));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('invoices.showInvoice.title')}
        extra={
          <div>
            <Link to={`/invoices/${type}/edit/${id}`}>
              <Button type="primary" icon="edit">
                {t('invoices.showInvoice.editButton')}
              </Button>
            </Link>
            <Popconfirm
              title={t('invoices.action.delete.title')}
              okText={t('invoices.action.delete.ok')}
              cancelText={t('invoices.action.delete.cancel')}
              onConfirm={() => deleteInvoice()}
              icon={<Icon type="warning" />}
            >
              <Button type="danger" icon="delete" style={{ margin: '0 4px' }}>
                {t('invoices.showInvoice.deleteButton')}
              </Button>
            </Popconfirm>
          </div>
        }
      />
      <ContentCustom padding={false}>
        <div
          style={{
            padding: 24,
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            width: '100%'
          }}
        >
          <InvoiceDetails
            displayDownload={displayDownload}
            invoice={invoice}
            isLoading={isLoading}
            next={next}
            order_number={order_number}
            prev={prev}
            type={type}
          />
          <InvoiceTable invoice={invoice} isLoading={isLoading} />
          <InvoiceAmount invoice={invoice} isLoading={isLoading} />
          <UploadDrawer
            badgeCount={badgeCount}
            getResource={getInvoice}
            resourceFiles={invoice.files}
            resourceId={id}
            resourceName="invoices"
            topPosition={100}
          />
        </div>
      </ContentCustom>
    </>
  );
};

export default ShowInvoice;
