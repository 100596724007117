import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CreateAndEditPeopleForm from './CreateAndEditPeopleForm';
import { PageHeaderCustom } from '../../components';

const CreateAndEditPeople = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom
        title={id ? t('people.edit.title') : t('people.create.title')}
      />
      <CreateAndEditPeopleForm id={id} />
    </>
  );
};

export default CreateAndEditPeople;
