import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button } from 'antd';
import InputGenerator from '../../components/inputGenerator';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 12 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const AddSiteModal = ({
  site,
  visible,
  handleOk,
  handleCancel,
  isLoading,
  form: { getFieldDecorator, validateFieldsAndScroll, resetFields }
}) => {
  const { t } = useTranslation();

  const fields = [
    {
      label: 'name',
      required: true,
      initialValue: site && site[0].name
    },
    {
      label: 'address.number',
      required: true,
      initialValue: site && site[0].address ? site[0].address.number : undefined
    },
    {
      label: 'address.street',
      required: true,
      initialValue: site && site[0].address ? site[0].address.street : undefined
    },
    {
      label: 'address.additional',
      required: false,
      initialValue:
        site && site[0].address ? site[0].address.additional : undefined
    },
    {
      label: 'address.postal_code',
      required: true,
      initialValue:
        site && site[0].address ? site[0].address.postal_code : undefined
    },
    {
      label: 'address.city',
      required: true,
      initialValue: site && site[0].address ? site[0].address.city : undefined
    },
    {
      label: 'address.country',
      required: true,
      initialValue:
        site && site[0].address ? site[0].address.country : undefined
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (site) {
          handleOk(values, site[0]);
          resetFields();
        } else {
          handleOk(values);
          resetFields();
        }
      }
    });
  };

  const cancel = () => {
    resetFields();
    handleCancel();
  };

  return (
    <Modal
      width={800}
      title={
        site ? t('companies.sites.editSite') : t('companies.sites.addSite')
      }
      visible={visible}
      onCancel={cancel}
      footer={null}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <InputGenerator
          title="companies"
          fields={fields}
          getFieldDecorator={getFieldDecorator}
        />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('users.editcreate.form.submitButton')}
          </Button>
          <Button
            type="danger"
            onClick={handleCancel}
            style={{ margin: '0 10px' }}
          >
            {t('users.editcreate.form.cancel')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddSiteModal.propTypes = {
  site: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        additional: PropTypes.string,
        postal_code: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string
      })
    })
  ),
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  updateSite: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};

AddSiteModal.defaultProps = {
  site: null
};

const WrappedAddContactAntForm = Form.create({ name: 'addSite' })(AddSiteModal);

export default WrappedAddContactAntForm;
