import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ResourceLandingLayout } from '../../layouts';

const ListPVs = () => {
  const { t } = useTranslation();

  return (
    <ResourceLandingLayout
      resourceName="pvs"
      populate="order_number"
      columns={[
        {
          title: t('pvs.list.column.number'),
          dataIndex: 'number',
          sorter: true
        },
        {
          title: t('pvs.list.column.orderNumber'),
          dataIndex: 'order_number',
          sorter: true,
          render: ({ order_number }) => order_number
        },
        {
          title: t('pvs.list.column.signingDate'),
          dataIndex: 'signing_date',
          sorter: true,
          render: (signing_date) => (
            <Typography>
              {(signing_date && moment(signing_date).format('LL')) || '-'}
            </Typography>
          )
        },
        {
          title: t('pvs.list.column.deliveryDate'),
          dataIndex: 'order_number',
          sorter: true,
          render: ({ reception_date }) => (
            <Typography>
              {(reception_date && moment(reception_date).format('LL')) || '-'}
            </Typography>
          )
        }
      ]}
    />
  );
};

export default ListPVs;
