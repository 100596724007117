const TelecomHeaders = [
  {
    label: 'phone_Number'
  },
  {
    label: 'status'
  },
  {
    label: 'people'
  },
  {
    label: 'subsidiary'
  },
  {
    label: 'contract.number'
  }
];

const ITHeaders = [
  {
    label: 'serial_number'
  },
  {
    label: 'EasyVista_request_serial_number'
  },
  {
    label: 'os'
  },
  {
    label: 'ram'
  },
  {
    label: 'cpu'
  },
  {
    label: 'hdd'
  },
  {
    label: 'ip_address'
  },
  {
    label: 'order_number'
  },
  {
    label: 'order_date'
  },
  {
    label: 'delivery_date'
  },
  {
    label: 'entry_date'
  },
  {
    label: 'replacement_date'
  },
  {
    label: 'exit_date'
  },
  {
    label: 'exit_reason'
  },
  {
    label: 'valorisation_amount'
  },
  {
    label: 'retrologistic_cost'
  },
  {
    label: 'insurance_refund'
  },
  {
    label: 'contract'
  },
  {
    label: 'pv_number'
  },
  {
    label: 'invoice_number'
  },
  {
    label: 'invoice_date'
  },
  {
    label: 'description'
  },
  {
    label: 'unit_price'
  },
  {
    label: 'owner'
  },
  {
    label: 'genre'
  },
  {
    label: 'brand'
  },
  {
    label: 'product_code'
  },
  {
    label: 'co2_year'
  },
  {
    label: 'power_consumption'
  },
  {
    label: 'carbon_footprint'
  },
  {
    label: 'age'
  },
  {
    label: 'people'
  },
  {
    label: 'status'
  },
  {
    label: 'site'
  },
  {
    label: 'subsidiary'
  },
  {
    label: 'city'
  },
  {
    label: 'donations'
  },
  {
    label: 're_use'
  },
  {
    label: 'recycling'
  },
  {
    label: 'rent'
  },
  {
    label: 'repair'
  },
  {
    label: 'repair_amount'
  },
  {
    label: 'replacement_date'
  },
  {
    label: 'audit_date'
  },
  {
    label: 'state'
  }
];

export default { Telecom: TelecomHeaders, IT: ITHeaders };
