import React, { useState } from 'react';
import { Spin, Row, message, Modal, Select, Typography, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import useInformation from './informationList';
import { DescriptionList } from '../../components';

const PeopleAssets = ({
  assets,
  assetsSelect,
  handleAssetChange,
  handleOk,
  handleCancel,
  handleModelChange,
  isMainLoading,
  model,
  models,
  person,
  visible
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const { Text } = Typography;
  const information = useInformation(person);
  const { dispatchAPI, isLoading } = useAuthContext();
  const [data, setData] = useState([]);
  const fetchAssets = async (value) => {
    try {
      const result = await dispatchAPI('GET', {
        url: `/assets?type=${model}&filter={"$text": {"$search":"${value}"}}&!people`
      });
      setData(result.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  return (
    <>
      <Modal
        title={t('people.showPeople.modal.title')}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row>
          <Text>{`${t('people.showPeople.modal.categorySelect')}:`}</Text>
        </Row>
        <Row>
          <Select
            value={model}
            style={{ width: '50%' }}
            onChange={handleModelChange}
          >
            {models.map((name) => (
              <Option value={name} key={name}>
                {name}
              </Option>
            ))}
          </Select>
        </Row>
        <Row style={assetsSelect}>
          <Row>
            <Text>{`${t('people.showPeople.modal.assetsSelect')}:`}</Text>
          </Row>
          <Row>
            <Select
              showArrow={false}
              showSearch
              value={assets.assetChosen}
              defaultActiveFirstOption={false}
              notFoundContent={isLoading ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={(value) => fetchAssets(value)}
              onChange={handleAssetChange}
              style={{ width: '100%' }}
            >
              {data.map(
                ({ _id, phone_Number, phone_contract, Genre, ...asset }) => (
                  <Option key={_id} value={_id}>
                    {model === 'Telecom'
                      ? `${phone_Number} ${phone_contract}`
                      : `${Genre} ${asset.serial_number}`}
                  </Option>
                )
              )}
            </Select>
          </Row>
        </Row>
      </Modal>
      <Skeleton
        active
        loading={isMainLoading}
        paragraph={{ width: ['50%', '50%', '50%'] }}
      >
        <DescriptionList
          title={`${person.first_name} ${
            person.last_name ? person.last_name : ''
          }`}
          data={information}
        />
      </Skeleton>
    </>
  );
};

PeopleAssets.propTypes = {
  assets: PropTypes.shape({
    assetChosen: PropTypes.string
  }).isRequired,
  assetsSelect: PropTypes.shape({}).isRequired,
  handleAssetChange: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleModelChange: PropTypes.func.isRequired,
  isMainLoading: PropTypes.bool.isRequired,
  model: PropTypes.string.isRequired,
  models: PropTypes.arrayOf(PropTypes.string).isRequired,
  person: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }).isRequired,
  visible: PropTypes.bool.isRequired
};

export default PeopleAssets;
