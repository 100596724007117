import React from 'react';
import { Col, Icon, Radio, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ExtraOptions = ({ filter, handleFilterChange, reset, type }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ marginTop: 8 }}>
      <Row>
        <Text strong style={{ marginBottom: 10, marginLeft: 8 }}>
          {t(`header.menu.filteredTab.invoicesFilter`)}
        </Text>
        <Icon style={{ marginLeft: 4 }} type="undo" onClick={reset} />
      </Row>
      <Row>
        <Col>
          <Radio.Group
            style={{ margin: 8 }}
            value={filter.status}
            onChange={(e) => handleFilterChange('status', e.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value="&status=STOCK ENTREE">
              {t(`header.menu.filteredTab.stockEntreeAssets`)}
            </Radio.Button>
            <Radio.Button value="&status=AFFECTE">
              {t(`header.menu.filteredTab.affecteAssets`)}
            </Radio.Button>
            <Radio.Button value="&status=A DEPOLLUER">
              {t(`header.menu.filteredTab.aDepolluerAssets`)}
            </Radio.Button>
            <Radio.Button value="&status=EN STOCK">
              {t(`header.menu.filteredTab.enStockAssets`)}
            </Radio.Button>
            <Radio.Button value="&status=STOCK SORTIE">
              {t(`header.menu.filteredTab.stockSortieAssets`)}
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {type === 'Telecom' && (
        <Row>
          <Col>
            <Radio.Group
              style={{ margin: 8 }}
              value={filter.overage}
              onChange={(e) => handleFilterChange('overage', e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value="&overage=true">
                {t(`header.menu.filteredTab.overage`)}
              </Radio.Button>
              <Radio.Button value="&overage=false">
                {t(`header.menu.filteredTab.non_overage`)}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      )}
    </Row>
  );
};

ExtraOptions.propTypes = {
  filter: PropTypes.shape({
    status: PropTypes.string,
    overage: PropTypes.string
  }),
  handleFilterChange: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

ExtraOptions.defaultProps = {
  filter: null
};

export default ExtraOptions;
