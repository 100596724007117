import React, { useEffect, useState } from 'react';
import { Typography, Icon, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import ImportModal from '../../components/ImportModal';
import { ResourceLandingLayout } from '../../layouts';
import exportHeaders from './exportHeaders';

const { Title } = Typography;
const { Option } = Select;

const ListPeople = () => {
  const { dispatchAPI } = useAuthContext();
  const { configs } = useConfigsContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState({
    peopleIsLoading: true,
    modalIsLoading: false,
    companiesIsLoading: false
  });
  const [visible, setVisible] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [headers, setHeaders] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [schema, setSchema] = useState([]);

  const getSchema = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/models/People' });
      setSchema(Object.keys(data).filter((v) => v !== 'last_update'));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(
        data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setLoading({ ...loading, companiesIsLoading: false });
  };

  const props = {
    name: 'file',
    beforeUpload: (file) => {
      setFileData(file);
      Papa.parse(file, {
        header: true,
        encoding: 'ISO-8859-1',
        delimiter: ';',
        complete: (results) => {
          try {
            setHeaders(results.meta.fields);
            setVisible(true);
          } catch (e) {
            message.error(e);
          }
        }
      });
      return false;
    }
  };

  const uploadPeople = async (connectedHeaders) => {
    setLoading({ ...loading, modalIsLoading: true });
    const formData = new FormData();
    formData.append('import', fileData);
    formData.append('collection', 'People');
    formData.append('extra', JSON.stringify({ company }));
    formData.append('fields', JSON.stringify(connectedHeaders));
    try {
      const result = await dispatchAPI('POST', {
        url: `/formats`,
        body: formData
      });
      if (result.status === 202) {
        setVisible(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    setLoading({ ...loading, modalIsLoading: false });
  };

  const onCancel = () => {
    setFileData(null);
    setCurrentStep(0);
    setCompany(null);
    setVisible(false);
  };

  const next = () => {
    if (currentStep === 0) {
      if (company) {
        setCurrentStep(currentStep + 1);
      } else {
        message.error('Veuillez-choisir un employeur');
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCompanyChange = (value) => {
    setCompany(value);
  };

  useEffect(() => {
    (async () => {
      await getSchema();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  const { modalIsLoading, companiesIsLoading } = loading;

  const steps = [
    {
      title: 'Employeur',
      icon: <Icon type="user" />,
      content: (
        <>
          <Title level={4}>Où Travaillent ces employés?</Title>
          <Select
            loading={companiesIsLoading}
            value={company}
            onChange={handleCompanyChange}
            style={{ width: 200, marginTop: 16 }}
          >
            {companies.map(({ _id, name }) => (
              <Option key={_id} value={_id}>
                {name}
              </Option>
            ))}
          </Select>
        </>
      )
    }
  ];

  useEffect(() => {
    if (visible) {
      setLoading({ ...loading, companiesIsLoading: true });
      (async () => {
        await getCompanies();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <ResourceLandingLayout
        resourceName="people"
        extraQuery={configs && `company=${configs.client}`}
        populate="company,subsidiary"
        uploadProps={props}
        headers={exportHeaders}
        withUpload
        columns={[
          {
            title: t('people.list.column.name'),
            key: 'last_name',
            sorter: true,
            render: (person) => (
              <Typography>
                {`${person.first_name || '-'} ${person.last_name || '-'}`}
              </Typography>
            )
          },
          {
            title: t('people.list.column.company'),
            dataIndex: 'company',
            sorter: true,
            render: (c) => (c ? c.name : '-')
          },
          {
            title: t('people.list.column.employeeNumber'),
            dataIndex: 'id_number',
            sorter: true
          },
          {
            title: t('people.list.column.job_type'),
            dataIndex: 'job_type',
            sorter: true
          }
        ]}
      />
      <ImportModal
        upload={uploadPeople}
        base={schema}
        loading={modalIsLoading}
        headers={headers}
        handleCancel={onCancel}
        current={currentStep}
        next={next}
        prev={prev}
        steps={steps}
        visible={visible}
        collection="People"
      />
    </>
  );
};

export default ListPeople;
