/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import ImportModal from '../../components/ImportModal';
import './assets.css';
import useColumns from './columns';
import { ResourceLandingLayout } from '../../layouts';
import FilterForResourceLandingLayout from './FilterForResourceLandingLayout';
import { useStateWithSessionStorage } from '../../utils';
import { RawInvoiceModal } from './RawInvoiceModal';
import f_steps from './steps';
import MenuComponent from './menu';
import {
  assetsPropsUtil,
  fetchCompaniesUtil,
  fetchHeadersUtil,
  invoicesPropsUtil,
  uploadUtil
} from './utils';

const ListAssets = () => {
  const { type } = useParams();
  const { [type]: tableColumns } = useColumns();
  const { dispatchAPI } = useAuthContext();
  const [companiesIsLoading, setCompaniesIsLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const { configs } = useConfigsContext();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [visible, setVisible] = useState(false);
  const [schemaHeaders, setSchemaHeaders] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [owner, setOwner] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [collection, setCollection] = useState(null);
  const [rawInvoicesModalVisible, setRawInvoicesModalVisible] = useState(false);
  const [resourceToImport, setResourceToImport] = useState('assets');
  const [ownerTitle, setOwnerTitle] = useState(null);
  const [optionsDisplay, setOptionsDisplay] = useState(false);
  const [rangeDates, setRangeDates] = useStateWithSessionStorage(
    'assetsRangeDates',
    []
  );
  const [dateField, setDateField] = useStateWithSessionStorage(
    'assetsDateField',
    'entry_date'
  );
  const [filter, setFilter] = useStateWithSessionStorage('assetsFilter', {
    status: undefined,
    overage: undefined
  });
  const datesUrl =
    rangeDates && rangeDates[0]
      ? `&${dateField}>=${rangeDates[0]}&${dateField}<=${rangeDates[1]}`
      : null;

  const fetchHeaders = () => {
    fetchHeadersUtil(dispatchAPI, setSchemaHeaders, type);
  };

  const upload = (connectedHeaders) => {
    uploadUtil(
      connectedHeaders,
      setIsModalLoading,
      resourceToImport,
      fileData,
      collection,
      owner,
      company,
      dispatchAPI,
      setVisible,
      setOwner,
      setCompany
    );
  };
  const handleCancel = () => {
    setVisible(false);
    setCurrentStep(0);
    setFileData(null);
    setOwner(null);
  };
  const fetchCompanies = () => {
    fetchCompaniesUtil(setCompaniesIsLoading, dispatchAPI, setCompanies);
  };

  const handleOwnerSelect = (value) => {
    setOwner(value);
  };

  useEffect(() => {
    fetchHeaders();
    fetchCompanies();
  }, [configs, type]);

  const next = () => setCurrentStep(currentStep + 1);

  const prev = () => setCurrentStep(currentStep - 1);

  const assetsProps = assetsPropsUtil(
    setResourceToImport,
    setCollection,
    setFileData,
    setHeaders,
    setVisible,
    type
  );

  const invoicesProps = invoicesPropsUtil(
    setResourceToImport,
    setCollection,
    setFileData,
    setHeaders,
    setVisible,
    setSchemaHeaders,
    dispatchAPI
  );

  const extraQuery = `type=${type}${
    configs ? `&company=${configs.client}` : ''
  }${filter.status || ''}${filter.overage || ''}${datesUrl || ''}`;

  useEffect(() => {
    if (owner) {
      setOwnerTitle(companies.filter((c) => c._id === owner));
    }
  }, [owner]);

  const handleCompanyChange = (value) => {
    setCompany(value);
  };

  const steps = f_steps(
    owner,
    ownerTitle,
    handleOwnerSelect,
    companies,
    companiesIsLoading,
    company,
    t,
    handleCompanyChange
  );

  const onDateChange = (date, dateString) => {
    setRangeDates(date.length > 0 ? dateString : []);
  };

  const handleReset = () => {
    setFilter({ status: undefined, overage: undefined });
    setRangeDates([]);
  };

  useEffect(() => {
    if (filter.status || filter.overage || rangeDates.length)
      setOptionsDisplay(true);
  }, []);

  useEffect(() => {
    handleReset();
  }, [type]);

  return (
    <>
      <ResourceLandingLayout
        customActionColumn
        customMenu={MenuComponent(
          configs,
          type,
          filter,
          datesUrl,
          assetsProps,
          invoicesProps,
          t,
          setRawInvoicesModalVisible
        )}
        withCreateButton={false}
        resourceName="assets"
        populate="telecomInvoices"
        path={`${type}`}
        tradKey={type}
        extraQuery={extraQuery}
        columns={tableColumns}
      >
        <RawInvoiceModal
          visible={rawInvoicesModalVisible}
          onCancel={() => setRawInvoicesModalVisible(false)}
        />
        <FilterForResourceLandingLayout
          optionsDisplay={optionsDisplay}
          setOptionsDisplay={setOptionsDisplay}
          filter={filter}
          setFilter={setFilter}
          handleReset={handleReset}
          rangeDates={rangeDates}
          onDateChange={onDateChange}
          dateField={dateField}
          setDateField={setDateField}
          type={type}
        />
      </ResourceLandingLayout>
      <ImportModal
        loading={isModalLoading}
        visible={visible}
        handleCancel={handleCancel}
        headers={headers}
        base={schemaHeaders}
        upload={upload}
        steps={steps[resourceToImport]}
        owner={owner}
        next={next}
        prev={prev}
        current={currentStep}
        collection={collection}
      />
    </>
  );
};

export default ListAssets;
