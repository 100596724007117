import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { styles } from './utils';

const TableFooterForInvoiceExport = ({ invoice }) => {
  return (
    <>
      <View
        style={{
          flex: 1,
          alignSelf: 'stretch',
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            flex: 3,
            justifyContent: 'flex-end',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          <Text
            style={{
              textAlign: 'left',
              color: 'green',
              fontSize: 11
            }}
          >
            Paiement à réception de facture
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          <Text style={styles.tableContent} />
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            borderLeftWidth: 1,
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0.5
          }}
        >
          <Text
            style={{
              fontSize: 9,
              textAlign: 'right'
            }}
          >
            TAUX de TVA
          </Text>
        </View>

        <View
          style={{
            flex: 2,
            alignSelf: 'stretch',
            borderLeftWidth: 0.5,
            borderRightWidth: 1,
            borderTopWidth: 0,
            borderBottomWidth: 0.5
          }}
        >
          <Text style={styles.tableContentRight}>
            {/* {numeral(invoice.VAT || 0).format('0,0.00')} */}
            20%
          </Text>
        </View>
      </View>
      <View
        style={{
          flex: 1,
          alignSelf: 'stretch',
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            flex: 2,
            justifyContent: 'flex-end',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          <Text style={styles.tableContent} />
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          <Text style={styles.tableContent} />
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          <Text style={styles.tableContent} />
        </View>

        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            borderLeftWidth: 1,
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0.5
          }}
        >
          <Text
            style={{
              fontSize: 9,
              textAlign: 'right'
            }}
          >
            TVA
          </Text>
        </View>

        <View
          style={{
            flex: 2,
            alignSelf: 'stretch',
            borderLeftWidth: 0.5,
            borderRightWidth: 1,
            borderTopWidth: 0,
            borderBottomWidth: 0.5
          }}
        >
          <Text style={styles.tableContentRight}>
            {numeral(invoice.VAT || 0).format('0,0.00')}
          </Text>
        </View>
      </View>
      <View
        style={{
          flex: 1,
          alignSelf: 'stretch',
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            flex: 2,
            justifyContent: 'flex-end',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          <Text style={styles.tableContent} />
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          <Text style={styles.tableContent} />
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          <Text style={styles.tableContent} />
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            borderLeftWidth: 1,
            borderTopWidth: 0,
            borderBottomWidth: 1
          }}
        >
          <Text style={{ fontSize: 9, textAlign: 'right' }}>Total TTC</Text>
        </View>

        <View
          style={{
            flex: 2,
            justifyContent: 'flex-end',
            borderLeftWidth: 0.5,
            borderRightWidth: 1,
            borderTopWidth: 0,
            borderBottomWidth: 1
          }}
        >
          <Text style={styles.tableContentRight}>
            {numeral(invoice.total || 0 + invoice.VAT || 0).format('0,0.00')}
          </Text>
        </View>
      </View>
    </>
  );
};

TableFooterForInvoiceExport.propTypes = {
  invoice: PropTypes.shape({
    total: PropTypes.number,
    VAT: PropTypes.number
  }).isRequired
};

export default TableFooterForInvoiceExport;
