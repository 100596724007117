import React from 'react';
import { Menu, Icon, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { SubMenu } = Menu;
const BasicLayoutMenu = ({
  path,
  setCollapsed,
  size,
  assetsSubMenu,
  mapAssetsSubMenu
}) => {
  const { t } = useTranslation();

  return (
    <Menu
      className="menu-sider"
      theme="dark"
      selectedKeys={[path]}
      defaultOpenKeys={[path.split('/')[1]]}
      mode="inline"
      onSelect={size.width < 992 ? () => setCollapsed(true) : () => {}}
    >
      <Menu.Item key="/">
        <NavLink to="/" className="nav-text">
          <Icon type="pie-chart" />
          <span>{t('dashboard.title')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/users">
        <NavLink to="/users" className="nav-text">
          <Icon type="user" />
          <span>{t('users.list.title')}</span>
        </NavLink>
      </Menu.Item>
      <SubMenu
        key="/companies"
        title={
          <span>
            <Icon type="shop" />
            <span>{t('companies.list.title')}</span>
          </span>
        }
      >
        <Menu.Item key="/companies/client">
          <NavLink to="/companies/client" className="nav-text">
            <span>{t('CLIENT.list.title')}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/companies/supplier">
          <NavLink to="/companies/supplier" className="nav-text">
            <span>{t('SUPPLIER.list.title')}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/companies/refinancer">
          <NavLink to="/companies/refinancer" className="nav-text">
            <span>{t('REFINANCER.list.title')}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/companies/leaser">
          <NavLink to="/companies/leaser" className="nav-text">
            <span>{t('LEASER.list.title')}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/companies/prospect">
          <NavLink to="/companies/prospect" className="nav-text">
            <span>{t('PROSPECT.list.title')}</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/contracts">
        <NavLink to="/contracts" className="nav-text">
          <Icon type="project" />
          <span>{t('contracts.list.title')}</span>
        </NavLink>
      </Menu.Item>
      <SubMenu
        key="/invoices"
        title={
          <span>
            <Icon type="euro" />
            <span>{t('invoices.list.title.general')}</span>
          </span>
        }
      >
        <Menu.Item key="/invoices/client">
          <NavLink to="/invoices/client" className="nav-text">
            <span>{t('clientInvoices.list.title')}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/invoices/supplier">
          <NavLink to="/invoices/supplier" className="nav-text">
            <span>{t('supplierInvoices.list.title')}</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/people">
        <NavLink to="/people" className="nav-text">
          <Icon type="team" />
          <span>{t('people.title')}</span>
        </NavLink>
      </Menu.Item>
      <SubMenu
        key="/assets"
        title={
          <span>
            <Icon type="desktop" />
            <span>Assets</span>
            {!assetsSubMenu && <Spin style={{ marginLeft: '20px' }} />}
          </span>
        }
        disabled={!assetsSubMenu}
      >
        {mapAssetsSubMenu(assetsSubMenu)}
      </SubMenu>
      <Menu.Item key="/tickets">
        <NavLink to="/tickets" className="nav-text">
          <Icon type="file-protect" />
          <span>{t('tickets.list.title')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/orders">
        <NavLink to="/orders" className="nav-text">
          <Icon type="file-unknown" />
          <span>{t('orders.list.title')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/payments">
        <NavLink to="/payments" className="nav-text">
          <Icon type="money-collect" />
          <span>{t('payments.list.title')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/pvs">
        <NavLink to="/pvs" className="nav-text">
          <Icon type="file-unknown" />
          <span>{t('pvs.list.title')}</span>
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

BasicLayoutMenu.propTypes = {
  size: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  setCollapsed: PropTypes.func.isRequired,
  assetsSubMenu: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  mapAssetsSubMenu: PropTypes.node.isRequired
};

export default BasicLayoutMenu;
