import React from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import { Card, Col, Row, Spin } from 'antd';
import InProgress from '../InProgress';

const MiniDashDisplayContainer = ({ children, xxlSpan }) => {
  return (
    <Row type="flex" justify="center" gutter={[16, 8]}>
      {(Array.isArray(children) ? children : [children]).map(
        (dashboard, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col xs={24} sm={12} xxl={xxlSpan} key={idx}>
            <Card
              style={{
                minHeight: '150px',
                height: '100%'
              }}
              bodyStyle={{ textAlign: 'center', padding: '5px 0' }}
            >
              {dashboard.props.inProgress && (
                <InProgress rotation={15} height={80} width={340} />
              )}
              <LazyLoad
                placeholder={
                  <Spin size="large" spinning>
                    <div style={{ height: 150, width: '100%' }} />
                  </Spin>
                }
              >
                {dashboard}
              </LazyLoad>
            </Card>
          </Col>
        )
      )}
    </Row>
  );
};

MiniDashDisplayContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]).isRequired,
  xxlSpan: PropTypes.number
};

MiniDashDisplayContainer.defaultProps = {
  xxlSpan: 6
};

export default MiniDashDisplayContainer;
