import React from 'react';
import { Col, Row, Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';

const InvoiceAmount = ({ invoice, isLoading }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row style={{ background: '#f0f0f0', marginTop: 50 }}>
        <Col xs={{ offset: 8 }} md={{ offset: 14 }}>
          <Skeleton active loading={isLoading} paragraph={0}>
            <Row style={{ borderBottom: '1px solid #e0e0e0' }}>
              <Col span={8} style={{ textAlign: 'left' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {`${t('invoices.showInvoice.subTotal')} `}
                </Typography.Text>
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {`${numeral(invoice.total_excluding_taxes || 0).format(
                    '0,0.00'
                  )}€`}
                </Typography.Text>
              </Col>
            </Row>
            <Row style={{ borderBottom: '1px solid #e0e0e0' }}>
              <Col span={8} style={{ textAlign: 'left' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {t('invoices.showInvoice.discount')}
                </Typography.Text>
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {`${numeral(invoice.sales || 0).format('0,0.00')}€`}
                </Typography.Text>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ textAlign: 'left' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {t('invoices.showInvoice.vat')}
                </Typography.Text>
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <Typography.Text style={{ fontSize: '16px' }}>
                  {`${numeral(invoice.VAT || 0).format('0,0.00')}€`}
                </Typography.Text>
              </Col>
            </Row>
          </Skeleton>
        </Col>
      </Row>
      <Row style={{ margin: '30px 0' }}>
        <Col xs={{ offset: 8 }} md={{ offset: 14 }}>
          <Skeleton active loading={isLoading} paragraph={0}>
            <Row type="flex" style={{ height: '100%' }}>
              <Col span={8} style={{ textAlign: 'left' }}>
                <Typography.Text strong style={{ fontSize: '18px' }}>
                  {t('invoices.showInvoice.totalDue', {
                    date: invoice.due_date
                      ? `${moment(invoice.due_date).format('L')}`
                      : ''
                  })}
                </Typography.Text>
              </Col>
              <Col
                span={16}
                style={{
                  textAlign: 'right',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end'
                }}
              >
                <Typography.Text strong style={{ fontSize: '18px' }}>
                  {`${numeral(invoice.total || 0 + invoice.VAT || 0).format(
                    '0,0.00'
                  )}€`}
                </Typography.Text>
              </Col>
            </Row>
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};

InvoiceAmount.propTypes = {
  invoice: PropTypes.shape({
    total: PropTypes.number,
    VAT: PropTypes.number,
    due_date: PropTypes.string,
    sales: PropTypes.number,
    total_excluding_taxes: PropTypes.number
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};
export default InvoiceAmount;
