import React, { useEffect, useState } from 'react';
import {
  Tag,
  Typography,
  Checkbox,
  Row,
  Tooltip,
  Icon,
  DatePicker,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import moment from 'moment';
import { ResourceLandingLayout } from '../../layouts';
import useConfigsContext from '../../contexts/ConfigsContext';
import headers from './headers';
import { invoiceTypeColors, useStateWithSessionStorage } from '../../utils';
import DatePickerFooter from './DatePickerFooter';
import ExtraOptions from './ExtraOptions';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ListOrders = () => {
  const { t } = useTranslation();
  const { configs } = useConfigsContext();
  const populates = ['supplier', 'client'];

  const [disabledPVDateRange, setDisabledPVDateRange] = useState(false);
  const [disabledReceptionDateRange, setDisabledReceptionDateRange] = useState(
    false
  );

  const [rangeDates, setRangeDates] = useStateWithSessionStorage(
    'ordersRangeDates',
    []
  );

  const [filter, setFilter] = useStateWithSessionStorage('ordersFilter', {
    type: null,
    paid: null,
    pv: null,
    order: null
  });

  const [dateField, setDateField] = useStateWithSessionStorage(
    'ordersDateField',
    'creation_date'
  );

  const [optionsDisplay, setOptionsDisplay] = useState(false);

  const onDateChange = (date, dateString) => {
    setRangeDates(date.length > 0 ? dateString : []);
  };

  const datesUrl =
    rangeDates && rangeDates[0]
      ? `${dateField}>=${rangeDates[0]}&${dateField}<=${rangeDates[1]}&`
      : null;

  const handleReset = () => {
    setFilter({ type: null, paid: null, pv: null, order: null });
    setRangeDates([]);
  };

  useEffect(() => {
    if (
      filter.paid ||
      filter.type ||
      filter.order ||
      filter.pv ||
      rangeDates.length
    )
      setOptionsDisplay(true);
    // eslint-disable-next-line
  }, []);

  const extraQuery = `${
    configs ? `&client=${configs.client}` : ''
  }${filter.paid || ''}${filter.type || ''}${filter.order || ''}${filter.pv ||
    ''}${datesUrl || ''}`;

  return (
    <ResourceLandingLayout
      resourceName="orders"
      populate={populates}
      headers={headers}
      extraQuery={extraQuery}
      exportUrl="orders"
      columns={[
        {
          title: t('orders.list.column.order_number'),
          dataIndex: 'order_number',
          sorter: true
        },
        {
          title: t('orders.list.column.type'),
          dataIndex: 'type',
          sorter: true,
          render: (tp) => (
            <Tag color={invoiceTypeColors[tp]}>
              {t(`invoices.tags.${tp}`).toUpperCase()}
            </Tag>
          )
        },
        {
          title: t('orders.list.column.supplier'),
          dataIndex: 'supplier',
          sorter: true,
          render: ({ name }) => name
        },
        {
          title: t('orders.list.column.to'),
          dataIndex: 'client',
          sorter: true,
          render: ({ name }) => name
        },
        {
          title: t('orders.list.column.total_ht'),
          sorter: true,
          dataIndex: 'total_ht_amount',
          align: 'right',
          render: (total) => `${numeral(total).format('0,0.00')} €`
        },
        {
          title: t('orders.list.column.tva_amount'),
          sorter: true,
          dataIndex: 'tva_amount',
          align: 'left',
          render: (total) => `${numeral(total).format('0,0.00')} €`
        },
        {
          title: t('orders.list.column.ttc_amount'),
          sorter: true,
          dataIndex: 'ttc_amount',
          render: (total) => `${numeral(total).format('0,0.00')} €`
        },
        {
          title: t('orders.list.column.total_ht_amount'),
          dataIndex: 'invoiced_amount',
          sorter: true,
          align: 'right',
          render: (total) => `${numeral(total).format('0,0.00')} €`
        },
        {
          title: t('orders.list.column.checkout_amount'),
          dataIndex: 'checkout_amount',
          sorter: true,
          align: 'right',
          render: (total) => `${numeral(total).format('0,0.00')} €`
        },
        {
          title: t('orders.list.column.created_at'),
          dataIndex: 'order_date',
          sorter: true,
          render: (order_date) => (
            <Typography>
              {(order_date && moment(order_date).format('LL')) || '-'}
            </Typography>
          )
        },
        {
          title: t('orders.list.column.pvSigned'),
          dataIndex: 'pv_signing_date',
          sorter: true,
          align: 'center',
          render: (pv_signing_date) => (
            <Checkbox defaultChecked={!!pv_signing_date} />
          )
        }
      ]}
    >
      <Row type="flex" justify="start" style={{ margin: '16px 0' }}>
        <Tooltip title="Options" placement="right" mouseEnterDelay={1}>
          <Icon
            style={{ fontSize: 20 }}
            type={optionsDisplay ? 'up' : 'filter'}
            onClick={() => setOptionsDisplay(!optionsDisplay)}
          />
        </Tooltip>
      </Row>
      <Row
        style={{ display: optionsDisplay ? 'contents' : 'none' }}
        gutter={36}
      >
        <ExtraOptions
          filter={filter}
          setDisabledPVDateRange={setDisabledPVDateRange}
          setDisabledReceptionDateRange={setDisabledReceptionDateRange}
          handleFilterChange={(key, value) =>
            setFilter({ ...filter, [key]: value })
          }
          reset={handleReset}
        />
        <Row
          style={{ margin: '10px 0' }}
          gutter={16}
          type="flex"
          justify="space-between"
        >
          <Col span={8}>
            <Row>
              <Text strong style={{ marginBottom: '10px' }}>
                {t('header.menu.filteredTab.dateRange')}
              </Text>
            </Row>
            <Row>
              <RangePicker
                style={{ width: '100%' }}
                value={
                  rangeDates.length > 0
                    ? [moment(rangeDates[0]), moment(rangeDates[1])]
                    : []
                }
                onChange={onDateChange}
                allowClear
                renderExtraFooter={() => (
                  <DatePickerFooter
                    dateField={dateField}
                    disabledPVDateRange={disabledPVDateRange}
                    disabledReceptionDateRange={disabledReceptionDateRange}
                    handleDateFieldChange={(value) => setDateField(value)}
                  />
                )}
              />
            </Row>
          </Col>
        </Row>
      </Row>
    </ResourceLandingLayout>
  );
};

export default ListOrders;
