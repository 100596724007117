import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Col,
  Drawer,
  Icon,
  message,
  Popconfirm,
  Row,
  Timeline,
  Upload
} from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const Index = ({
  badgeCount,
  resourceId,
  resourceFiles,
  resourceName,
  getResource,
  topPosition
}) => {
  const { dispatchAPI, token } = useAuthContext();
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_API_URL;
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [visible, setVisible] = useState(false);

  const afterUpload = async (status) => {
    if (status === 'done') {
      message.success(t('invoices.upload.success'));
      await getResource();
    }
    if (status === 'error') message.error(t('invoices.upload.error'));
  };

  const downloadResource = async (id, name) => {
    const result = await dispatchAPI('GET', {
      responseType: 'blob',
      url: `files/${id}`
    });
    const url = URL.createObjectURL(result.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  const deleteFile = async (id, ref) => {
    await dispatchAPI('DELETE', { url: `/${resourceName}/${id}/upload` });
    // eslint-disable-next-line no-unused-vars
    const { [ref]: _, ...rest } = uploadedFiles;
    setUploadedFiles(rest);
    await getResource();
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{
          position: 'absolute',
          padding: 5,
          right: 10,
          top: topPosition || '85%',
          borderRight: 'none',
          height: '40px',
          width: '40px'
        }}
      >
        <Badge
          offset={[-25, -15]}
          style={{ zIndex: 1 }}
          count={
            badgeCount === 0 ? (
              <Icon type="plus" style={{ color: 'gray', bold: 700 }} />
            ) : (
              badgeCount
            )
          }
        >
          <Icon type="file" style={{ fontSize: 16 }} />
        </Badge>
      </Button>
      <Drawer
        onClose={() => setVisible(false)}
        placement="right"
        zIndex={2}
        width={400}
        mask={false}
        getContainer={false}
        visible={visible}
        style={{ position: 'absolute' }}
        title={
          <>
            {`${t('contracts.showContract.attachedFiles.title')} `}
            <Upload
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              name={resourceName}
              action={`${baseUrl}/${resourceName}/${resourceId}/upload`}
              showUploadList={false}
              onChange={(e) => afterUpload(e.file.status)}
              headers={{
                Authorization: `Bearer ${token}`
              }}
            >
              <Button style={{ marginTop: 10 }}>
                <div style={{ width: 300 }}>
                  <Icon type="upload" style={{ marginRight: 5 }} />
                  {t('contracts.showContract.uploadDocument')}
                </div>
              </Button>
            </Upload>
          </>
        }
      >
        <Timeline>
          {resourceFiles &&
            resourceFiles.map(({ metadata, _id }) => (
              <Timeline.Item key={_id} color="green">
                <Row type="flex" style={{ height: '100%' }}>
                  <Col span={20}>
                    <a
                      style={{ height: 20, width: '80%' }}
                      onClick={() =>
                        downloadResource(_id, metadata.originalName)
                      }
                      type="link"
                    >
                      {metadata.originalName}
                    </a>
                  </Col>
                  <Col
                    span={4}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Popconfirm
                      title={t('invoices.list.column.action.delete.title')}
                      okText={t('invoices.list.column.action.delete.ok')}
                      cancelText={t(
                        'invoices.list.column.action.delete.cancel'
                      )}
                      onConfirm={() => deleteFile(_id)}
                      icon={<Icon type="warning" />}
                    >
                      <Icon
                        style={{ color: 'red', fontSize: 18 }}
                        type="delete"
                      />
                    </Popconfirm>
                  </Col>
                </Row>
              </Timeline.Item>
            ))}
        </Timeline>
      </Drawer>
    </>
  );
};

Index.propTypes = {
  badgeCount: PropTypes.number.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourceFiles: PropTypes.arrayOf(PropTypes.shape({})),
  resourceName: PropTypes.string.isRequired,
  getResource: PropTypes.func.isRequired,
  topPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Index.defaultProps = {
  topPosition: null,
  resourceFiles: []
};

export default Index;
