import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button } from 'antd';
import moment from 'moment';
import InputGenerator from '../../components/inputGenerator';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 12 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const AddLOEModal = ({
  LOE,
  visible,
  handleOk,
  handleCancel,
  isLoading,
  form: { getFieldDecorator, validateFieldsAndScroll, resetFields }
}) => {
  const { t } = useTranslation();

  const fields = [
    {
      label: 'number',
      required: true,
      initialValue: LOE && LOE.info && LOE.info[0].number
    },
    {
      label: 'amount',
      input: 'number',
      type: 'number' || 'float',
      required: true,
      initialValue: LOE && LOE.info && LOE.info[0].amount
    },
    {
      label: 'start_date',
      input: 'datePicker',
      config: {
        rules: [
          { type: 'object', required: true, message: 'Please select date!' }
        ],
        initialValue:
          LOE &&
          LOE.info &&
          LOE.info[0].start_date &&
          moment(LOE.info[0].start_date)
      }
    },
    {
      label: 'end_date',
      input: 'datePicker',
      config: {
        rules: [
          { type: 'object', required: true, message: 'Please select date!' }
        ],
        initialValue:
          LOE &&
          LOE.info &&
          LOE.info[0].end_date &&
          moment(LOE.info[0].end_date)
      }
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (LOE) {
          handleOk(values, LOE.index);
          resetFields();
        } else {
          handleOk(values);
          resetFields();
        }
      }
    });
  };

  const cancel = () => {
    resetFields();
    handleCancel();
  };

  return (
    <Modal
      width={800}
      title={
        LOE.info
          ? t('contracts.loeList.editLOE')
          : t('contracts.loeList.addLOE')
      }
      visible={visible}
      onCancel={cancel}
      footer={null}
    >
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <InputGenerator
          title="contracts"
          fields={fields}
          getFieldDecorator={getFieldDecorator}
        />
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('users.editcreate.form.submitButton')}
          </Button>
          <Button
            type="danger"
            onClick={handleCancel}
            style={{ margin: '0 10px' }}
          >
            {t('users.editcreate.form.cancel')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddLOEModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  updateSite: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  LOE: PropTypes.shape({
    info: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.number,
        amount: PropTypes.number,
        start_date: PropTypes.string,
        end_date: PropTypes.string
      })
    ),
    index: PropTypes.number.isRequired
  })
};

AddLOEModal.defaultProps = {
  LOE: null
};

const WrappedAddLOEAntForm = Form.create({ name: 'addLOE' })(AddLOEModal);

export default WrappedAddLOEAntForm;
