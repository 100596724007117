import React from 'react';
import { Button, Col, Row, Skeleton, Tag, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { invoiceTypeColors } from '../../utils';
import ShowInvoiceExport from './ShowInvoiceExport';
import { skeletonStyle } from './utils';

const { Title } = Typography;
const InvoiceDetails = ({
  displayDownload,
  invoice,
  isLoading,
  next,
  order_number,
  prev,
  type
}) => {
  const { t } = useTranslation();
  const {
    from,
    to,
    contract,
    emission_date,
    label,
    delivery_date,
    order_date,
    pv_number,
    pv_date,
    type: invoiceType
  } = invoice;
  return (
    <>
      <Row>
        <Row type="flex" justify="space-between">
          <div>
            <Skeleton
              active
              loading={isLoading}
              paragraph={{ rows: 1, width: 150 }}
              title={false}
            >
              <Title level={3}>
                {t('invoices.showInvoice.invoiceNumber', {
                  number: invoice.number
                })}
              </Title>
            </Skeleton>
          </div>
          <div>
            <Tooltip
              title={t('invoices.showInvoice.toolTip.prev')}
              placement="topRight"
            >
              <Button
                shape="circle"
                icon="left"
                onClick={prev}
                style={{ marginRight: 5 }}
              />
            </Tooltip>
            <Tooltip
              title={t('invoices.showInvoice.toolTip.next')}
              placement="topRight"
            >
              <Button
                shape="circle"
                icon="right"
                style={{ marginRight: 5 }}
                onClick={next}
              />
            </Tooltip>
            {type.toString().toUpperCase() !== 'SUPPLIER' &&
              (invoice && !isLoading && (
                <PDFDownloadLink
                  document={
                    <ShowInvoiceExport
                      isCompleted={displayDownload}
                      invoice={invoice}
                      contract={invoice.contract}
                    />
                  }
                  fileName={`${invoice.number}.pdf`}
                >
                  {({ loading }) => (
                    <Tooltip
                      title={t('invoices.showInvoice.toolTip.download')}
                      placement="topRight"
                    >
                      <Button
                        shape="circle"
                        icon="download"
                        loading={loading}
                      />
                    </Tooltip>
                  )}
                </PDFDownloadLink>
              ))}
          </div>
        </Row>
        <Col span={12}>
          <Skeleton active loading={isLoading}>
            <Row style={{ margin: '30px 0', fontWeight: 'bold' }}>
              {from && (
                <Typography style={{ fontSize: '18px' }}>
                  {`${from.name} - ${from.group}`}
                  <br />
                  {`${from.address.number} ${from.address.street}`}
                  <br />
                  {from.address.additional}
                  {from.address.additional && <br />}
                  {`${from.address.postal_code} ${from.address.city}`}
                </Typography>
              )}
            </Row>
          </Skeleton>
        </Col>
      </Row>
      <Row style={{ margin: '30px 0' }}>
        <Col offset={1} span={13}>
          <div>
            <Skeleton
              active
              loading={isLoading}
              title={{ width: '20%' }}
              paragraph={0}
            >
              <Typography.Text>
                {emission_date &&
                  t('invoices.showInvoice.emissionDate', {
                    date: moment(emission_date).format('LL')
                  })}
                <br />
                {label && <Typography.Text strong>{label}</Typography.Text>}
              </Typography.Text>
            </Skeleton>
          </div>
          <div style={skeletonStyle}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 3 }}>
              {contract && (
                <Typography.Text>
                  {t('invoices.showInvoice.contract', {
                    number: contract.amendment_number
                      ? `${contract.number}-${contract.amendment_number}`
                      : contract.number
                  })}
                  <br />
                </Typography.Text>
              )}
              {type && (
                <Typography.Text>
                  {`Type: `}
                  <Tag color={invoiceTypeColors[invoiceType]}>
                    {t(`invoices.tags.${invoiceType}`)}
                  </Tag>
                  <br />
                </Typography.Text>
              )}
              {order_number && (
                <Typography.Text>
                  {t('invoices.showInvoice.order_number')}
                  {order_number}
                  <br />
                </Typography.Text>
              )}
              {order_date && (
                <Typography.Text>
                  {t('invoices.showInvoice.order_date', {
                    order_date: moment(order_date).format('L')
                  })}
                  <br />
                </Typography.Text>
              )}
              {delivery_date && (
                <Typography.Text>
                  {t('invoices.showInvoice.delivery_date', {
                    delivery_date: moment(delivery_date).format('L')
                  })}
                  <br />
                </Typography.Text>
              )}
              {pv_number && (
                <Typography.Text>
                  {t('invoices.showInvoice.pv_number', { pv_number })}
                  <br />
                </Typography.Text>
              )}
              {pv_date && (
                <Typography.Text>
                  {t('invoices.showInvoice.pv_date', {
                    pv_date: moment(pv_date).format('L')
                  })}
                  <br />
                </Typography.Text>
              )}
            </Skeleton>
          </div>
        </Col>
        <Col span={10}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
            {to && (
              <Typography.Text style={{ fontSize: '18px' }}>
                {`${to.name} - ${to.group}`}
                <br />
                {`${to.address.number} ${to.address.street}`}
                <br />
                {to.address.additional}
                {to.address.additional && <br />}
                {`${to.address.postal_code} ${to.address.city}`}
              </Typography.Text>
            )}
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};

InvoiceDetails.propTypes = {
  displayDownload: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    from: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        additional: PropTypes.string,
        postal_code: PropTypes.string,
        city: PropTypes.string
      })
    }),
    to: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        additional: PropTypes.string,
        postal_code: PropTypes.string,
        city: PropTypes.string
      })
    }),
    contract: PropTypes.shape({
      amendment_number: PropTypes.string,
      number: PropTypes.string
    }),
    emission_date: PropTypes.string,
    label: PropTypes.string,
    delivery_date: PropTypes.string,
    order_date: PropTypes.string,
    pv_number: PropTypes.string,
    pv_date: PropTypes.string,
    type: PropTypes.string,
    number: PropTypes.string.isRequired
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  next: PropTypes.func.isRequired,
  order_number: PropTypes.string.isRequired,
  prev: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default InvoiceDetails;
