import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import ShowInvoice from './ShowInvoice';
import ListInvoices from './ListInvoices';
import EditAndCreateInvoice from './EditAndCreateInvoice';
import { InvoicesContextProvider } from './invoicesContext';

const InvoicesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <InvoicesContextProvider>
      <Switch>
        <Route path={`${path}/:type/show/:id`} component={ShowInvoice} />
        <Route
          path={`${path}/:type/edit/:id`}
          component={EditAndCreateInvoice}
        />
        <Route path={`${path}/:type/create`} component={EditAndCreateInvoice} />
        <Route path={`${path}/:type`} component={ListInvoices} />
        <Route path={path} render={() => <Redirect to="/invoices/client" />} />
      </Switch>
    </InvoicesContextProvider>
  );
};

export default InvoicesRouter;
