import React from 'react';
import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  Select,
  Tag,
  Spin,
  Typography,
  Upload
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import procedureSinistre from '../../assets/images/PROCEDURE SINISTRE DEF.pdf';
import ContactForm from './ContactForm';
import IncidentDetailsForm from './IncidentDetailsForm';
import { ContentCustom } from '../../components';
import SiteForm from './SiteForm';
import {
  ticketPriorityColors,
  ticketStatusColors,
  ticketTypeColors
} from '../../utils';
import { noLabelLayout, rule } from './utils';

const { Option } = Select;
const { Title } = Typography;

const BaseTicketForm = ({
  fetchingTicket,
  isCompaniesLoading,
  setCompany,
  companyNames,
  ticketType,
  ticketsEnumValues,
  ticket,
  getFieldDecorator,
  isSitesLoading,
  getCompanySites,
  companySites,
  company,
  siteId,
  isPeopleLoading,
  getCompanyPeople,
  companyPeople,
  peopleId,
  setFieldsValue,
  id,
  files,
  setFiles
}) => {
  const { t } = useTranslation();
  const prefixSelector = getFieldDecorator(
    'contact.phone_number.country_code',
    {
      initialValue: '+33'
    }
  )(
    <Select style={{ width: 70 }}>
      <Option value="+33">+33</Option>
    </Select>
  );
  return (
    <ContentCustom>
      <Title level={2}>{t(`tickets.create.ticketInfo`)}</Title>
      <Spin spinning={fetchingTicket}>
        <Form.Item label={t('tickets.fields.company')}>
          {getFieldDecorator('company', {
            rules: [{ required: true }],
            initialValue: ticket && !isCompaniesLoading && ticket.company
          })(
            <Select
              loading={isCompaniesLoading}
              onSelect={(value) => setCompany(value)}
            >
              {companyNames &&
                companyNames.map(({ name, _id }) => (
                  <Option value={_id} key={name}>
                    {name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('tickets.fields.label')}>
          {getFieldDecorator('label', {
            rules: rule(t),
            initialValue: ticket && ticket.label
          })(<Input />)}
        </Form.Item>
        <Form.Item
          label={t('tickets.fields.type')}
          extra={
            (ticketType === 'LOST' ||
              ticketType === 'THEFT' ||
              ticketType === 'DAMAGE') && (
              <>
                <Icon
                  type="warning"
                  style={{
                    fontSize: '16px',
                    marginRight: '10px',
                    color: '#fadb14'
                  }}
                />
                <a
                  style={{ fontSize: 12 }}
                  href={procedureSinistre}
                  download="procedure-sinistre.pdf"
                >
                  Procédure sinistre
                </a>
              </>
            )
          }
        >
          {getFieldDecorator('type', {
            rules: rule(t),
            initialValue: ticket && ticket.type
          })(
            <Select>
              {ticketsEnumValues &&
                ticketsEnumValues.type.map((type) => (
                  <Option value={type} key={type}>
                    <Tag color={ticketTypeColors[type]}>
                      {t(`tickets.options.${type}`)}
                    </Tag>
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('tickets.fields.priority')}>
          {getFieldDecorator('priority', {
            rules: rule(t),
            initialValue: ticket && ticket.priority
          })(
            <Select>
              {ticketsEnumValues &&
                ticketsEnumValues.priority.map((priority) => (
                  <Option value={priority} key={priority}>
                    <Tag color={ticketPriorityColors[priority]}>
                      {t(`tickets.options.${priority}`)}
                    </Tag>
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('tickets.fields.status')}>
          {getFieldDecorator('status', {
            rules: rule(t),
            initialValue: ticket && ticket.status
          })(
            <Select>
              {ticketsEnumValues &&
                ticketsEnumValues.status.map((status) => (
                  <Option value={status} key={status}>
                    <Tag color={ticketStatusColors[status]}>
                      {t(`tickets.options.${status}`)}
                    </Tag>
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={t('tickets.fields.readyDate')}
          style={{ display: ticketType === 'COLLECTION' ? 'block' : 'none' }}
        >
          {getFieldDecorator('ready_date', {
            rules: [
              {
                required: ticketType === 'COLLECTION',
                message: t('global.form.requiredMessage')
              },
              { type: 'object', message: t('global.form.typeMessage') }
            ],
            initialValue: ticket && moment(ticket.ready_date)
          })(<DatePicker />)}
        </Form.Item>
        {!id && (
          <Form.Item
            label={t('tickets.fields.documents')}
            style={{
              textAlign: 'right',
              marginTop: 9
            }}
          >
            <Upload
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              multiple
              name="files"
              maxCount={5}
              beforeUpload={(file) => {
                const newFile = [...files];
                newFile.push(file);
                setFiles(newFile);
                return false;
              }}
              onRemove={(doc) => {
                const index = files.indexOf(doc);
                const newFile = files.slice();
                newFile.splice(index, 1);
                setFiles(newFile);
              }}
            >
              <Button style={{ marginTop: 10 }}>
                <div style={{ width: 300 }}>
                  <Icon type="upload" style={{ marginRight: 5 }} />
                  {t('contracts.showContract.uploadDocument')}
                </div>
              </Button>
            </Upload>
          </Form.Item>
        )}
        <SiteForm
          ticketType={ticketType}
          ticket={ticket}
          getFieldDecorator={getFieldDecorator}
          isSitesLoading={isSitesLoading}
          getCompanySites={getCompanySites}
          companySites={companySites}
          company={company}
          siteId={siteId}
          noLabelLayout={noLabelLayout}
        />
        <IncidentDetailsForm
          ticketType={ticketType}
          ticket={ticket}
          getFieldDecorator={getFieldDecorator}
          isPeopleLoading={isPeopleLoading}
          getCompanyPeople={getCompanyPeople}
          companyPeople={companyPeople}
        />
        <ContactForm
          peopleId={peopleId}
          ticket={ticket}
          getFieldDecorator={getFieldDecorator}
          isPeopleLoading={isPeopleLoading}
          getCompanyPeople={getCompanyPeople}
          companyPeople={companyPeople}
          company={company}
          setFieldsValue={setFieldsValue}
          noLabelLayout={noLabelLayout}
          prefixSelector={prefixSelector}
        />
      </Spin>
    </ContentCustom>
  );
};

BaseTicketForm.propTypes = {
  ticket: PropTypes.shape({
    label: PropTypes.string,
    priority: PropTypes.string,
    ready_date: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    company: PropTypes.string
  }).isRequired,
  company: PropTypes.string.isRequired,
  fetchingTicket: PropTypes.func.isRequired,
  isCompaniesLoading: PropTypes.bool.isRequired,
  setCompany: PropTypes.func.isRequired,
  companyNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ticketType: PropTypes.string.isRequired,
  ticketsEnumValues: PropTypes.shape({
    type: PropTypes.arrayOf(PropTypes.string),
    priority: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  isSitesLoading: PropTypes.bool.isRequired,
  getCompanySites: PropTypes.func.isRequired,
  companySites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  siteId: PropTypes.string.isRequired,
  isPeopleLoading: PropTypes.bool.isRequired,
  getCompanyPeople: PropTypes.func.isRequired,
  companyPeople: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  peopleId: PropTypes.string.isRequired,
  setFieldsValue: PropTypes.func.isRequired
};

export default BaseTicketForm;
