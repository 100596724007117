import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { Typography, Tag, Row, Col, Tooltip, Icon, DatePicker } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { invoiceTypeColors } from '../../utils';
import ExtraOptions from './ExtraOptions';
import '../../layouts/TableLayout/style.css';
import { ResourceLandingLayout } from '../../layouts';
import DatePickerFooter from './DatePickerFooter';
import useInvoicesContext from './invoicesContext';
import headers from './headers';
import useConfigsContext from '../../contexts/ConfigsContext';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ListInvoices = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const {
    rangeDates,
    setRangeDates,
    filter,
    setFilter,
    dateField,
    setDateField
  } = useInvoicesContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const { configs } = useConfigsContext();
  const [optionsDisplay, setOptionsDisplay] = useState(false);
  const [disabledPaymentDateRange, setDisabledPaymentDateRange] = useState(
    false
  );
  const [disabledPayment, setDisabledPayment] = useState(false);

  const onDateChange = (date, dateString) => {
    setRangeDates(date.length > 0 ? dateString : []);
  };

  const datesUrl =
    rangeDates && rangeDates[0]
      ? encodeURI(
          `${dateField}>=${rangeDates[0]}&${dateField}<=${rangeDates[1]}&`
        )
      : null;

  const dueUrl = filter.due
    ? encodeURI(
        `due_date${
          filter.due === 'due'
            ? `<=${moment()}&`
            : `>${moment()}&due_date=null&`
        }`
      )
    : null;

  const exportUrl = keyword
    ? `invoices/search/${keyword}?${filter.paid || ''}${filter.order ||
        ''}${dueUrl || ''}${datesUrl ||
        ''}invoice_type=${type.toUpperCase()}&populate=from,to,contract`
    : `invoices?${filter.paid || ''}${filter.order || ''}${dueUrl ||
        ''}${datesUrl ||
        ''}invoice_type=${type.toUpperCase()}&populate=from,to,contract`;

  const handleReset = () => {
    setFilter({ paid: null, order: null, due: null });
    setRangeDates([]);
    setDisabledPaymentDateRange(false);
    setDisabledPayment(false);
  };

  useEffect(() => {
    if (
      filter.due ||
      filter.paid ||
      filter.order ||
      filter.due ||
      rangeDates.length
    )
      setOptionsDisplay(true);
    // eslint-disable-next-line
  }, []);

  return (
    <ResourceLandingLayout
      resourceName="invoices"
      populate="from"
      headers={headers}
      exportUrl={exportUrl}
      extraQuery={`${configs ? `to=${configs.client}&` : ''}${filter.paid ||
        ''}${filter.order || ''}${dueUrl || ''}${datesUrl ||
        ''}invoice_type=${type.toUpperCase()}
`}
      columns={[
        {
          title: t('invoices.list.column.number'),
          dataIndex: 'number',
          sorter: true
        },
        {
          title: t('invoices.list.column.label'),
          sorter: true,
          dataIndex: 'label'
        },
        {
          title: t('invoices.list.column.type'),
          dataIndex: 'type',
          sorter: true,
          render: (tp) => (
            <Tag color={invoiceTypeColors[tp]}>
              {t(`invoices.tags.${tp}`).toUpperCase()}
            </Tag>
          )
        },
        {
          title: t('invoices.list.column.from'),
          dataIndex: 'from',
          sorter: true,
          render: ({ name }) => name
        },
        {
          title: t('invoices.list.column.total_excluding_taxes'),
          dataIndex: 'total_excluding_taxes',
          sorter: true,
          align: 'right',
          render: (total) => `${numeral(total).format('0,0.00')} €`
        },
        {
          title: t('invoices.list.column.emission_date'),
          dataIndex: 'emission_date',
          sorter: true,
          render: (emission_date) => (
            <Typography>
              {(emission_date && moment(emission_date).format('LL')) || '-'}
            </Typography>
          )
        },
        {
          title: t('invoices.list.column.payment_date'),
          dataIndex: 'payment_date',
          sorter: true,
          render: (payment_date) =>
            payment_date ? moment(payment_date).format('LL') : '-'
        }
      ]}
    >
      <Row type="flex" justify="start" style={{ margin: '16px 0' }}>
        <Tooltip title="Options" placement="right" mouseEnterDelay={1}>
          <Icon
            style={{ fontSize: 20 }}
            type={optionsDisplay ? 'up' : 'filter'}
            onClick={() => setOptionsDisplay(!optionsDisplay)}
          />
        </Tooltip>
      </Row>
      <Row
        style={{ display: optionsDisplay ? 'contents' : 'none' }}
        gutter={36}
      >
        <ExtraOptions
          filter={filter}
          setDisabledPaymentDateRange={setDisabledPaymentDateRange}
          disabledPayment={disabledPayment}
          handleFilterChange={(key, value) =>
            setFilter({ ...filter, [key]: value })
          }
          reset={handleReset}
        />
        <Row
          style={{ margin: '10px 0' }}
          gutter={16}
          type="flex"
          justify="space-between"
        >
          <Col span={8}>
            <Row>
              <Text strong style={{ marginBottom: '10px' }}>
                {t('header.menu.filteredTab.dateRange')}
              </Text>
            </Row>
            <Row>
              <RangePicker
                style={{ width: '100%' }}
                value={
                  rangeDates.length > 0
                    ? [moment(rangeDates[0]), moment(rangeDates[1])]
                    : []
                }
                onChange={onDateChange}
                allowClear
                renderExtraFooter={() => (
                  <DatePickerFooter
                    dateField={dateField}
                    setDisabledPayment={setDisabledPayment}
                    disabledPaymentDateRange={disabledPaymentDateRange}
                    handleDateFieldChange={(value) => setDateField(value)}
                  />
                )}
              />
            </Row>
          </Col>
        </Row>
      </Row>
    </ResourceLandingLayout>
  );
};

export default ListInvoices;
