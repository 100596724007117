import React from 'react';
import moment from 'moment';
import { Tag, Alert, Typography } from 'antd';
import { contractTypeColor, invoiceTypeColors } from '../../utils';

const { Text } = Typography;

export default (
  loading,
  invoice,
  types,
  companies,
  leasers,
  contracts,
  orders,
  catalog,
  t,
  companyName,
  order
) => {
  const companiesOptions = companies.map(({ _id, name }) => ({
    value: _id,
    text: name
  }));

  const leasersOptions = leasers.map(({ _id, name }) => ({
    value: _id,
    text: name
  }));

  const contractsOptions = contracts.map((contract) => ({
    value: contract._id,
    text: (
      <>
        {`${contract.number}${
          contract.amendment_number ? `-${contract.amendment_number}` : ''
        } `}
        <Tag color={contractTypeColor[contract.type]}>{contract.type}</Tag>
        <Text disabled>{moment(contract.start_date).format('LL')}</Text>
      </>
    )
  }));

  const ordersOptions = orders.map(({ _id, order_number }) => ({
    value: _id,
    text: order_number
  }));

  const typeOptions = types.map((type) => ({
    value: type,
    text: (
      <Tag color={invoiceTypeColors[type]}>
        {t(`invoices.form.options.type.${type}`)}
      </Tag>
    )
  }));

  const orderDetails = order && orders.filter((ord) => ord._id === order);

  return [
    {
      label: 'number',
      required: true,
      initialValue: invoice && invoice.number
    },
    {
      label: 'from',
      initialValue:
        companyName && companyName.type.includes('SUPPLIER')
          ? companiesOptions.filter((c) => c.text === companyName.name)[0].value
          : (invoice || {}).from,
      required: true,
      input: 'select',
      options: companiesOptions,
      extra: true,
      typeExtra: 'addSupplier'
    },
    {
      label: 'to',
      input: 'select',
      options: companiesOptions,
      loading: loading.companies,
      required: true,
      initialValue:
        companyName && companyName.type.includes('CLIENT')
          ? companiesOptions.filter((c) => c.text === companyName.name)[0].value
          : (invoice || {}).to,
      extra: true,
      typeExtra: 'addClient'
    },
    {
      label: 'leaser',
      input: 'select',
      options: leasersOptions,
      loading: loading.companies,
      initialValue:
        companyName && companyName.type.includes('LEASER')
          ? companiesOptions.filter((c) => c.text === companyName.name)[0].value
          : (invoice || {}).leaser,
      extra: true,
      typeExtra: 'addLeaser'
    },
    {
      label: 'contract',
      input: 'select',
      options: contractsOptions,
      loading: loading.contracts,
      required: true,
      initialValue: invoice && invoice.contract
    },
    {
      label: 'type',
      input: 'select',
      options: typeOptions,
      loading: loading.types,
      required: true,
      initialValue: invoice && invoice.type
    },
    {
      label: 'label',
      required: true,
      initialValue: invoice && invoice.label
    },
    {
      label: 'emission_date',
      input: 'datePicker',
      config: {
        rules: [
          { type: 'object', required: false, message: 'Please select date!' }
        ],
        initialValue:
          invoice && invoice.emission_date && moment(invoice.emission_date)
      }
    },
    {
      label: 'due_date',
      input: 'datePicker',
      config: {
        rules: [
          { type: 'object', required: false, message: 'Please select date!' }
        ],
        initialValue: invoice && invoice.due_date && moment(invoice.due_date)
      }
    },
    {
      label: 'payment_date',
      input: 'datePicker',
      config: {
        rules: [
          { type: 'object', required: false, message: 'Please select date!' }
        ],
        initialValue:
          invoice && invoice.payment_date && moment(invoice.payment_date)
      }
    },
    {
      label: 'order_number',
      input: 'select',
      options: ordersOptions,
      loading: loading.orders,
      initialValue: invoice && invoice.order_number,
      help: orderDetails && (
        <Alert
          type="info"
          showIcon
          message={
            orderDetails[0] && (
              <>
                <h5>
                  {`Date de commande : `}
                  {orderDetails[0].order_date &&
                    moment(orderDetails[0].order_date).format('LL')}
                </h5>
                {orderDetails[0].reception_date && (
                  <h5>
                    {`Date de Livraison : `}
                    {moment(orderDetails[0].reception_date).format('LL')}
                  </h5>
                )}
                {orderDetails[0].pv_number && (
                  <h5>
                    {`Numéro de PV : `}
                    {orderDetails[0].pv_number}
                  </h5>
                )}
                {orderDetails[0].pv_signing_date && (
                  <h5>
                    {`Date de PV : `}
                    {moment(orderDetails[0].pv_signing_date).format('LL')}
                  </h5>
                )}
              </>
            )
          }
        />
      )
    },
    {
      label: 'comment',
      input: 'textArea',
      rows: 4,
      required: false,
      initialValue: invoice && invoice.comment
    }
  ];
};
