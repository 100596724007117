/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { message, Button, Icon, Row, Typography, Table, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import useAuthContext from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import OrderDetails from './OrderDetails';
import OrderAmount from './OrderAmount';
import UploadDrawer from '../../components/uploadDrawer';

const { Title } = Typography;
const { Column } = Table;

const ShowOrder = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const isTableLoading = false;
  const [invoices, setInvoices] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const isCompletedstate = false;
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [order, setOrder] = useState({
    files: []
  });

  const getOrderInvoices = async (orderNumber) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?order_number=${orderNumber}`
      });
      setInvoices(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getOrder = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: `/orders/${id}?populate=client,supplier,articles,files,contract,owner`
      });
      if (result.data.files.length) {
        setBadgeCount(result.data.files.length);
      } else setBadgeCount(0);
      setOrder(result.data);
      await getOrderInvoices(result.data._id);
      setLoading(false);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getOrder();
    }
  }, [id]);

  useEffect(() => {}, [isCompletedstate]);

  return (
    <>
      <PageHeaderCustom
        title={t('orders.showOrder.title')}
        extra={
          <div>
            <Link to={`/orders/edit/${id}`}>
              <Button type="primary" icon="edit">
                {t('orders.showOrder.editButton')}
              </Button>
            </Link>
          </div>
        }
      />
      <ContentCustom padding={false}>
        <div
          style={{
            padding: 24,
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            width: '100%'
          }}
        >
          <OrderDetails
            invoices={invoices}
            isLoading={isLoading}
            order={order}
          />
          <Row style={{ marginTop: 50 }}>
            <Title level={4}>{t('orders.showOrder.articlesTitle')}</Title>
            <Skeleton
              active
              title={false}
              loading={isTableLoading}
              paragraph={{
                rows: 8,
                width: [
                  '100%',
                  '100%',
                  '100%',
                  '100%',
                  '100%',
                  '100%',
                  '100%',
                  '100%'
                ]
              }}
            >
              <Table
                dataSource={(order.articles || []).map(
                  ({ _id, ...article }) => ({
                    ...article,
                    key: _id
                  })
                )}
                loading={isTableLoading}
                pagination={false}
                expandIcon={(expandProps) => {
                  if (expandProps.record.description) {
                    if (expandProps.expanded) {
                      return (
                        <Icon
                          onClick={(e) => {
                            expandProps.onExpand(expandProps.record, e);
                          }}
                          role="button"
                          type="minus-square"
                        />
                      );
                    }
                    return (
                      <Icon
                        onClick={(e) => {
                          expandProps.onExpand(expandProps.record, e);
                        }}
                        role="button"
                        type="plus-square"
                      />
                    );
                  }
                  return false;
                }}
                expandedRowRender={(record) =>
                  record.description ? record.description : 'Pas de description'
                }
              >
                <Column
                  title={t('orders.showOrder.articlesTable.label')}
                  dataIndex="catalog_label"
                />
                <Column
                  title={t('orders.showOrder.articlesTable.quantity')}
                  dataIndex="quantity"
                  align="right"
                />
                <Column
                  title={t('orders.showOrder.articlesTable.unitPrice')}
                  dataIndex="unit_price"
                  align="right"
                  render={(price) => `${numeral(price).format('0,0.00')} €`}
                />
                <Column
                  title={t('orders.showOrder.articlesTable.discount')}
                  dataIndex="discount"
                  align="right"
                  render={(discount) => (discount ? `${discount}%` : 'N/A')}
                />
                <Column
                  title={t('orders.showOrder.articlesTable.VAT')}
                  dataIndex="VAT"
                  align="right"
                  render={(vat) => `${vat}%`}
                />
                <Column
                  title={t('orders.showOrder.articlesTable.subTotal')}
                  key="subTotal"
                  align="right"
                  render={({ quantity, unit_price }) =>
                    `${numeral(quantity * unit_price).format('0,0.00')} €`
                  }
                />
                <Column
                  title={t('orders.showOrder.articlesTable.receptionDate')}
                  dataIndex="reception_date"
                  align="right"
                  render={(receptionDate) => moment(receptionDate).format('LL')}
                />
              </Table>
            </Skeleton>
            <OrderAmount
              isLoading={isLoading}
              isTableLoading={isTableLoading}
              order={order}
            />
          </Row>
          <UploadDrawer
            badgeCount={badgeCount}
            getResource={getOrder}
            resourceFiles={order.files}
            resourceId={id}
            resourceName="orders"
            topPosition={100}
          />
        </div>
      </ContentCustom>
    </>
  );
};

export default ShowOrder;
