import React from 'react';
import { Icon, Row, Skeleton, Table, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { invoiceTypeColors } from '../../utils';

const { Title } = Typography;
const { Column } = Table;
const PVInvoices = ({ isTableLoading, pv }) => {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <Row style={{ marginTop: 50 }}>
      <Title level={4}>{t('pvs.showPV.invoicesList')}</Title>
      <Skeleton
        active
        title={false}
        loading={isTableLoading}
        paragraph={{
          rows: 8,
          width: [
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%'
          ]
        }}
      >
        <Table
          dataSource={(pv.invoices || []).map(({ _id, ...invoice }) => ({
            ...invoice,
            key: _id
          }))}
          loading={isTableLoading}
          pagination={false}
          onRow={({ key }) => ({
            onDoubleClick: () => history.push(`/invoices/supplier/show/${key}`)
          })}
          expandIcon={(expandProps) => {
            if (expandProps.record.description) {
              if (expandProps.expanded) {
                return (
                  <Icon
                    onClick={(e) => {
                      expandProps.onExpand(expandProps.record, e);
                    }}
                    role="button"
                    type="minus-square"
                  />
                );
              }
              return (
                <Icon
                  onClick={(e) => {
                    expandProps.onExpand(expandProps.record, e);
                  }}
                  role="button"
                  type="plus-square"
                />
              );
            }
            return false;
          }}
          expandedRowRender={(record) =>
            record.description ? record.description : 'Pas de description'
          }
        >
          <Column title={t('invoices.list.column.number')} dataIndex="number" />
          <Column
            title={t('invoices.list.column.order_number')}
            dataIndex="order_number.order_number"
          />
          <Column
            title={t('invoices.list.column.label')}
            dataIndex="label"
            align="right"
          />
          <Column
            title={t('invoices.list.column.type')}
            dataIndex="type"
            align="right"
            render={(tp) => (
              <Tag color={invoiceTypeColors[tp]}>
                {t(`invoices.tags.${tp}`).toUpperCase()}
              </Tag>
            )}
          />
          <Column
            title={t('invoices.list.column.from')}
            dataIndex="from.name"
            align="right"
          />
          <Column
            title={t('invoices.list.column.total_excluding_taxes')}
            dataIndex="total_excluding_taxes"
            align="center"
            render={(total) => `${numeral(total).format('0,0.00')} €`}
          />
          <Column
            title={t('invoices.list.column.emission_date')}
            dataIndex="emission_date"
            align="center"
            render={(emission_date) => (
              <Typography>
                {(emission_date && moment(emission_date).format('LL')) || '-'}
              </Typography>
            )}
          />
          <Column
            title={t('invoices.list.column.payment_date')}
            dataIndex="payment_date"
            align="center"
            render={(payment_date) =>
              payment_date ? moment(payment_date).format('LL') : '-'
            }
          />
        </Table>
      </Skeleton>
    </Row>
  );
};

PVInvoices.propTypes = {
  isTableLoading: PropTypes.bool.isRequired,
  pv: PropTypes.shape({
    invoices: PropTypes.shape({})
  }).isRequired
};

export default PVInvoices;
