import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Radio, Typography } from 'antd';
import moment from 'moment';
import './timeScaling.css';
import useTelecomContext from '../../routes/home/context/FiltersContext';

const { Title } = Typography;

const TimeScaling = () => {
  const { timeScale, dispatchTime } = useTelecomContext();
  const { t } = useTranslation();
  const [buttonValue, setButtonValue] = useState('12months');

  const handleTimeScaleChoice = (initiator, value) => {
    dispatchTime({ initiator, payload: value });
    setButtonValue(value);
  };

  return (
    <>
      <Title style={{ textAlign: 'center' }} level={2}>
        {`${timeScale[0].format('MMMM YYYY')} - ${timeScale[1].format(
          'MMMM YYYY'
        )}`}
      </Title>
      <Row type="flex" justify="center">
        <Radio.Group
          value={buttonValue}
          buttonStyle="solid"
          onChange={(e) => handleTimeScaleChoice('buttons', e.target.value)}
          style={{ marginBottom: 16 }}
        >
          <Radio.Button value="lastYear">
            {moment()
              .subtract('1', 'year')
              .year()}
          </Radio.Button>
          <Radio.Button value="12months">
            {t('dashboard.timeScale.last12months')}
          </Radio.Button>
          <Radio.Button value="year">
            {t('dashboard.timeScale.year')}
          </Radio.Button>
          <Radio.Button value="s1">S1</Radio.Button>
          <Radio.Button value="s2">S2</Radio.Button>
          <Radio.Button value="q1">Q1</Radio.Button>
          <Radio.Button value="q2">Q2</Radio.Button>
          <Radio.Button value="q3">Q3</Radio.Button>
          <Radio.Button value="q4">Q4</Radio.Button>
        </Radio.Group>
      </Row>
    </>
  );
};

export default TimeScaling;
