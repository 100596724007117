import React, { useState } from 'react';
import { Button, Steps } from 'antd';
import PropTypes from 'prop-types';

const { Step } = Steps;

const AllSteps = ({ steps, t, done }) => {
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  return (
    <div>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        {steps[current].content}
      </div>
      <div>
        {current > 0 && (
          <Button style={{ marginRight: 8 }} onClick={() => prev()}>
            {t('contracts.form.previous')}
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            {t('contracts.form.next')}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => done()}>
            {t('contracts.form.done')}
          </Button>
        )}
      </div>
    </div>
  );
};

AllSteps.propTypes = {
  t: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string
    })
  ).isRequired,
  done: PropTypes.func.isRequired
};

export default AllSteps;
