import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

const EditAndCreateContractFormPreview = ({
  values,
  companies,
  translation
}) => {
  const previewField = (value, companiesArray) => {
    // TODO: fix this shit
    switch (true) {
      case !Array.isArray(value) &&
        !companiesArray.find((v) => v.value === value) &&
        value:
        return value.toString();

      case !!companiesArray.find((v) => v.value === value):
        return companiesArray.find((v) => v.value === value).text.toString();

      // case Array.isArray(value):
      //   return value.map(val =>
      //     companiesArray.find(com => com.value === val).text.toString()
      //   );
      case typeof value === 'number':
        return numeral(value).format('0,0');

      default:
        return value && value.toString();
    }
  };

  return (
    <>
      {Object.entries(values).map(([key, value]) => (
        <Row key={`Row - ${key}`}>
          <Col span={12} key={key}>
            {' '}
            {translation(`contracts.list.column.${key}`)}
          </Col>
          <Col span={12} key={`Key - ${key}`}>
            {' '}
            {previewField(value, companies)}
          </Col>
        </Row>
      ))}
    </>
  );
};

EditAndCreateContractFormPreview.defaultProps = {
  values: null
};

EditAndCreateContractFormPreview.propTypes = {
  values: PropTypes.shape({}),
  translation: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired
};

export default EditAndCreateContractFormPreview;
