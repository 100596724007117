export const spinStyle = {
  textAlign: 'center',
  borderRadius: '4px',
  marginBottom: '20px',
  padding: '30px 50px',
  margin: '20px 0',
  width: '100%',
  height: '100%'
};
export const defaultPagination = {
  hideOnSinglePage: true,
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true
};
