/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Layout, Icon, message, Menu, Button, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { withRouter, Link, useLocation } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import HeaderDropdwon from '../../components/HeaderDropdown/HeaderDropdown';
import './style.css';
import useLanguageContext from '../../contexts/LanguageContext';

const { Header } = Layout;

const HeaderLayout = ({ collapseSider, collapsed, collapseWidth }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { configs } = useConfigsContext();
  const { dispatch: dispatchLocale } = useLanguageContext();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [marginTrigger, setMarginTrigger] = useState(0);
  const [logo, setLogo] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [nbrNotifications, setNbrNotifications] = useState(0);

  const languages = {
    en_GB: { label: 'English', icon: '🇬🇧' },
    fr_FR: { label: 'Français', icon: '🇫🇷' },
    es_ES: { label: 'Español', icon: '🇪🇸' },
    de_DE: { label: 'Deutsche', icon: '🇩🇪' }
  };

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success('Logged out.');
    }
  };

  const onLanguageMenuClick = ({ key }) => {
    dispatchLocale({ type: 'SET_LOCALE', locale: key });
  };

  const getLogo = async (id) => {
    try {
      const result = await dispatchAPI('GET', {
        responseType: 'blob',
        url: `/files/${id}`
      });
      setLogo(URL.createObjectURL(result.data));
    } catch (e) {
      console.error(e);
    }
  };

  const getNbrNotifications = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/notifications?status=OPEN&!user_receiver`
      });
      setNbrNotifications(data.length);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getNbrNotifications();
    })();
  }, [pathname]);

  useEffect(() => {
    if (configs) {
      if (configs.configs && configs.configs.logo)
        getLogo(configs.configs.logo);
    } else {
      setLogo(null);
    }
  }, [configs]);

  const langMenu = (
    <Menu onClick={onLanguageMenuClick}>
      {Object.keys(languages).map((locale) => (
        <Menu.Item key={locale}>
          <span>{languages[locale].icon}</span>
          {` ${languages[locale].label}`}
        </Menu.Item>
      ))}
    </Menu>
  );

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      {window.innerWidth <= 992 && user && (
        <Menu.Item key="user">
          <Button type="link">
            <span className="full-name">
              {`${user.first_name} ${user.last_name}`}
            </span>
            <p className="role">{t(`users.tags.${user.role}`)}</p>
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="profile">
        <Icon type="user" />
        {` ${t('header.menu.user.profile')}`}
      </Menu.Item>
      <Menu.Item key="settings">
        <Icon type="setting" />
        {` ${t('header.menu.user.settings')}`}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <Icon type="logout" />
        {` ${t('header.menu.user.logout')}`}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (collapsed) {
      if (collapseWidth === 0) {
        setMarginTrigger(0);
      } else {
        setMarginTrigger(80);
      }
    } else {
      setMarginTrigger(256);
    }
  }, [collapsed, collapseWidth]);

  return (
    <Header
      style={{
        background: '#fff',
        boxShadow: '0 1px 4px rgba(0,21,41,.08)',
        padding: '0',
        zIndex: 8,
        width: '100%',
        position: 'fixed'
      }}
    >
      {window.innerWidth < 992 && (
        <Icon
          className="trigger"
          style={{
            // eslint-disable-next-line no-nested-ternary
            marginLeft: marginTrigger,
            zIndex: '10000',
            position: 'absolute'
          }}
          type={collapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={() => collapseSider(!collapsed)}
        />
      )}
      {logo && (
        <img
          style={{
            width: 100,
            maxHeight: 50,
            position: 'absolute',
            top: 10,
            left: '50%',
            marginLeft: '-50px'
          }}
          src={logo}
          alt="Company logo"
        />
      )}
      <div className="container">
        <span className="dropdown-container">
          <Badge count={nbrNotifications} style={{ fontSize: 10 }}>
            <Link to="/notifications">
              <Icon type="bell" style={{ fontSize: 20 }} />
{' '}
            </Link>
          </Badge>
        </span>
        <HeaderDropdwon overlayMenu={profileMenu}>
          {window.innerWidth <= 992 ? (
            <span className="dropdown-container">
              <Icon type="user" />
            </span>
          ) : (
            <span className="dropdown-container">
              <Avatar
                size="medium"
                icon={user && user.img ? '' : 'user'}
                src={user && user.img && user.img.data ? user.img.data : ''}
              >
                {user && `${user.first_name[0]}${user.last_name[0]}`}
              </Avatar>
              <Button type="link">
                <span className="full-name">
                  {`${user.first_name} ${user.last_name}`}
                </span>
                <p className="role">{t(`users.tags.${user.role}`)}</p>
              </Button>
            </span>
          )}
        </HeaderDropdwon>
        <HeaderDropdwon overlayMenu={langMenu}>
          <span className="dropdown-container">
            <Icon type="global" />
          </span>
        </HeaderDropdwon>
      </div>
    </Header>
  );
};

HeaderLayout.propTypes = {
  collapseSider: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapseWidth: PropTypes.number.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(HeaderLayout);
