/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, message } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputGenerator from '../../components/inputGenerator';
import useAuthContext from '../../contexts/AuthContext';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 12 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const EditAndCreateFormModal = ({
  visible,
  handleCancel,
  endpoint,
  fields,
  productId,
  productTypeId,
  invoiceTypeId,
  purpose,
  title,
  invoiceTypes,
  productTypes,
  productEnums,
  invoiceTypeEnums,
  getCatalog,
  form: { getFieldDecorator, validateFieldsAndScroll, resetFields }
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [product, setProduct] = useState({});
  const [productType, setProductType] = useState({});
  const [invoiceType, setInvoiceType] = useState({});

  const cancel = () => {
    resetFields();
    handleCancel();
  };

  const getProduct = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/products/${productId}?populate=type, type.invoice_type`
      });
      setProduct(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getProductType = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/producttypes/${productTypeId}?populate=invoice_type`
      });
      setProductType(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getInvoiceType = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoicetypes/${invoiceTypeId}`
      });
      setInvoiceType(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (productId) {
      (async () => {
        await getProduct();
      })();
    }
  }, [productId]);

  useEffect(() => {
    if (productTypeId) {
      (async () => {
        await getProductType();
      })();
    }
  }, [productTypeId]);

  useEffect(() => {
    if (invoiceTypeId) {
      (async () => {
        await getInvoiceType();
      })();
    }
  }, [invoiceTypeId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const body = {
          ...values
        };

        if (purpose === 'create') {
          switch (endpoint) {
            case 'product':
              await dispatchAPI('POST', {
                url: `/products`,
                body
              });
              message.success(t('products.create.success'));
              break;
            case 'producttype':
              await dispatchAPI('POST', {
                url: `/producttypes`,
                body
              });
              message.success(t('producttypes.create.success'));
              break;
            case 'invoicetype':
              await dispatchAPI('POST', {
                url: `/invoicetypes`,
                body
              });
              message.success(t('invoicetype.create.success'));
              break;
          }
        } else {
          switch (endpoint) {
            case 'product':
              await dispatchAPI('PATCH', {
                url: `/products/${productId}`,
                body
              });
              message.success(t('products.edit.success'));
              break;
            case 'producttype':
              await dispatchAPI('PATCH', {
                url: `/producttypes/${productTypeId}`,
                body
              });
              message.success(t('producttypes.edit.success'));
              break;
            case 'invoicetype':
              await dispatchAPI('PATCH', {
                url: `/invoicetypes/${invoiceTypeId}`,
                body
              });
              message.success(t('invoicetype.edit.success'));
              break;
          }
        }
        cancel();
        await getCatalog();
      }
    });
  };

  return (
    <Modal
      width={1000}
      title={title}
      visible={visible}
      onCancel={cancel}
      footer={false}
    >
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <InputGenerator
          title="catalog"
          fields={fields(
            product,
            productType,
            invoiceType,
            invoiceTypes,
            productTypes,
            productEnums,
            invoiceTypeEnums,
            t
          )}
          getFieldDecorator={getFieldDecorator}
        />
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t(`catalog.${purpose}.form.submitButton`)}
          </Button>
          <Button type="danger" onClick={cancel} style={{ margin: '0 10px' }}>
            {t(`catalog.${purpose}.form.cancel`)}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditAndCreateFormModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf().isRequired,
  productId: PropTypes.string.isRequired,
  productTypeId: PropTypes.string.isRequired,
  invoiceTypeId: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  invoiceTypes: PropTypes.arrayOf().isRequired,
  productTypes: PropTypes.arrayOf().isRequired,
  productEnums: PropTypes.arrayOf().isRequired,
  invoiceTypeEnums: PropTypes.arrayOf().isRequired,
  getCatalog: PropTypes.func.isRequired
};

const WrappedEditAndCreateForm = Form.create({
  name: 'editAndCreateCatalog'
})(EditAndCreateFormModal);

export default WrappedEditAndCreateForm;
