/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import { defaultPagination, spinStyle } from './utils';
import ModalsForListCatalog from './ModalsForListCatalog';
import OrderProducts from './OrderProducts';
import SupplierInvoicesProducts from './SupplierInvoicesProducts';
import ClientInvoicesProducts from './ClientInvoicesProducts';
import ProductType from './ProductType';
import InvoiceType from './InvoiceType';

const ListCatalog = () => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productEnums, setProductEnums] = useState([]);
  const [invoiceTypeEnums, setInvoiceTypeEnums] = useState([]);
  const [productId, setProductId] = useState(null);
  const [productTypeId, setProductTypeId] = useState(null);
  const [invoiceTypeId, setInvoiceTypeId] = useState(null);
  const [purpose, setPurpose] = useState('create');
  const [endPoint, setEndPoint] = useState('product');
  const [pagination1, setPagination1] = useState(defaultPagination);
  const [pagination2, setPagination2] = useState(defaultPagination);
  const [pagination3, setPagination3] = useState(defaultPagination);
  const [orderProducts, setOrderProducts] = useState([]);
  const [supplierInvoiceProducts, setSupplierInvoiceProducts] = useState([]);
  const [clientSupplierProducts, setClientSupplierProducts] = useState([]);

  const getInvoiceTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/invoicetypes'
      });
      setInvoiceTypes(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getProductTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/producttypes?populate=invoice_type'
      });
      setProductTypes(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getProductEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/products/enums'
      });
      setProductEnums(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getInvoiceTypeEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/invoicetypes/enums'
      });
      setInvoiceTypeEnums(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getOrdersProducts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/products?category=SUPPLIERORDER&populate=type, type.invoice_type'
      });
      setOrderProducts(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getSupplierInvoicesProducts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url:
          '/products?category=SUPPLIERINVOICE&populate=type, type.invoice_type'
      });
      setSupplierInvoiceProducts(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getClientInvoicesProducts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/products?category=CLIENTINVOICE&populate=type, type.invoice_type'
      });
      setClientSupplierProducts(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getCatalog = async () => {
    await getOrdersProducts();
    await getClientInvoicesProducts();
    await getSupplierInvoicesProducts();
    await getInvoiceTypes();
    await getProductTypes();
    await getProductEnums();
    await getInvoiceTypeEnums();
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getCatalog();
  }, []);

  const del = async (id, element) => {
    try {
      await dispatchAPI('DELETE', { url: `/${element}/${id}` });
      await getCatalog();
    } catch (e) {
      console.error(e);
    }
  };

  const editProduct = (id) => {
    setProductId(id);
    setPurpose('edit');
    setVisible(true);
  };

  const editProductType = (id) => {
    setProductTypeId(id);
    setPurpose('edit');
    setVisible1(true);
  };

  const editInvoiceType = (id) => {
    setInvoiceTypeId(id);
    setPurpose('edit');
    setVisible2(true);
  };

  return (
    <>
      {isLoading ? (
        <Spin style={spinStyle} />
      ) : (
        <>
          <ModalsForListCatalog
            visible={visible}
            visible1={visible1}
            visible2={visible2}
            setVisible={setVisible}
            setVisible1={setVisible1}
            setVisible2={setVisible2}
            endPoint={endPoint}
            setEndPoint={setEndPoint}
            purpose={purpose}
            invoiceTypes={invoiceTypes}
            invoiceTypeId={invoiceTypeId}
            invoiceTypeEnums={invoiceTypeEnums}
            getCatalog={getCatalog}
            productId={productId}
            productTypes={productTypes}
            productEnums={productEnums}
            productTypeId={productTypeId}
          />
          {endPoint === 'product' && (
            <>
              <OrderProducts
                isLoading={isLoading}
                orderProducts={orderProducts}
                pagination1={pagination1}
                setPagination1={setPagination1}
                editProduct={editProduct}
                del={del}
              />
              <SupplierInvoicesProducts
                isLoading={isLoading}
                supplierInvoiceProducts={supplierInvoiceProducts}
                pagination2={pagination2}
                setPagination2={setPagination2}
                editProduct={editProduct}
                del={del}
              />
              <ClientInvoicesProducts
                isLoading={isLoading}
                clientSupplierProducts={clientSupplierProducts}
                pagination3={pagination3}
                setPagination3={setPagination3}
                editProduct={editProduct}
                del={del}
              />
            </>
          )}
          {endPoint === 'producttype' && (
            <ProductType
              isLoading={isLoading}
              productTypes={productTypes}
              pagination1={pagination1}
              setPagination1={setPagination1}
              editProductType={editProductType}
              del={del}
            />
          )}
          {endPoint === 'invoicetype' && (
            <InvoiceType
              isLoading={isLoading}
              invoiceTypes={invoiceTypes}
              pagination1={pagination1}
              setPagination1={setPagination1}
              editInvoiceType={editInvoiceType}
              del={del}
            />
          )}
        </>
      )}
    </>
  );
};

export default ListCatalog;
