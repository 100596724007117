import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Icon, Row, Tag, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  ticketStatusColors,
  ticketPriorityColors,
  ticketTypeColors,
  useStateWithSessionStorage
} from '../../utils';
import { ResourceLandingLayout } from '../../layouts';
import useConfigsContext from '../../contexts/ConfigsContext';
import DatePickerFooter from './DatePickerFooter';
import ExtraOptions from './ExtraOptions';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ListTickets = () => {
  const { t } = useTranslation();
  const { configs } = useConfigsContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');

  const [rangeDates, setRangeDates] = useStateWithSessionStorage(
    'ticketsRangeDates',
    []
  );

  const [filter, setFilter] = useStateWithSessionStorage('ticketsFilter', {
    status: null,
    type: null,
    priority: null
  });

  const [dateField, setDateField] = useStateWithSessionStorage(
    'ticketsDateField',
    'creation_date'
  );

  const [optionsDisplay, setOptionsDisplay] = useState(false);

  const onDateChange = async (date, dateString) => {
    setRangeDates(date.length > 0 ? dateString : []);
  };

  const datesUrl =
    rangeDates && rangeDates[0]
      ? `&${dateField}>=${rangeDates[0]}&${dateField}<=${rangeDates[1]}`
      : null;

  const handleReset = () => {
    setFilter({ status: null, type: null, priority: null });
    setRangeDates([]);
  };

  useEffect(() => {
    if (filter.status || filter.type || filter.priority || rangeDates.length)
      setOptionsDisplay(true);
    // eslint-disable-next-line
  }, []);

  const extraQuery = `${
    configs ? `&company=${configs.client}` : ''
  }${filter.status || ''}${filter.type || ''}${filter.priority ||
    ''}${datesUrl || ''}`;

  return (
    <ResourceLandingLayout
      customActionColumn
      extraQuery={extraQuery}
      resourceName="tickets"
      columns={[
        {
          title: t('tickets.list.column.ticket_number'),
          dataIndex: 'ticket_number',
          sorter: true
        },
        {
          title: t('tickets.list.column.label'),
          dataIndex: 'label',
          sorter: true
        },
        {
          title: t('tickets.list.column.assets'),
          dataIndex: 'assets',
          sorter: true,
          render: (assets) => <Typography.Text>{assets.length}</Typography.Text>
        },
        {
          title: t('tickets.list.column.type'),
          dataIndex: 'type',
          sorter: true,
          render: (type) => (
            <Tag color={ticketTypeColors[type]}>
              {t(`tickets.options.${type}`)}
            </Tag>
          )
        },
        {
          title: t('tickets.list.column.status'),
          dataIndex: 'status',
          sorter: true,
          render: (status) => (
            <Tag color={ticketStatusColors[status]}>
              {t(`tickets.options.${status}`)}
            </Tag>
          )
        },
        {
          title: t('tickets.list.column.priority'),
          dataIndex: 'priority',
          sorter: true,
          render: (priority) => (
            <Tag color={ticketPriorityColors[priority]}>{priority}</Tag>
          )
        }
      ]}
    >
      <Row type="flex" justify="start" style={{ margin: '16px 0' }}>
        <Tooltip title="Options" placement="right" mouseEnterDelay={1}>
          <Icon
            style={{ fontSize: 20 }}
            type={optionsDisplay ? 'up' : 'filter'}
            onClick={() => setOptionsDisplay(!optionsDisplay)}
          />
        </Tooltip>
      </Row>
      <Row
        style={{ display: optionsDisplay ? 'contents' : 'none' }}
        gutter={36}
      >
        <ExtraOptions
          filter={filter}
          handleFilterChange={(key, value) =>
            setFilter({ ...filter, [key]: value })
          }
          reset={handleReset}
        />
        <Row
          style={{ margin: '10px 0' }}
          gutter={16}
          type="flex"
          justify="space-between"
        >
          <Col span={8}>
            <Row>
              <Text strong style={{ marginBottom: '10px' }}>
                {t('header.menu.filteredTab.dateRange')}
              </Text>
            </Row>
            <Row>
              <RangePicker
                style={{ width: '100%' }}
                value={
                  rangeDates.length > 0
                    ? [moment(rangeDates[0]), moment(rangeDates[1])]
                    : []
                }
                onChange={onDateChange}
                allowClear
                renderExtraFooter={() => (
                  <DatePickerFooter
                    dateField={dateField}
                    handleDateFieldChange={(value) => setDateField(value)}
                  />
                )}
              />
            </Row>
          </Col>
        </Row>
      </Row>
    </ResourceLandingLayout>
  );
};

export default ListTickets;
