import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { invoiceTypeColors } from '../../utils';

export default (
  loading,
  order,
  types,
  companies,
  leasers,
  contracts,
  catalog,
  t,
  companyName
) => {
  const companiesOptions = companies.map(({ _id, name }) => ({
    value: _id,
    text: name
  }));

  const leasersOptions = leasers.map(({ _id, name }) => ({
    value: _id,
    text: name
  }));

  const contractsOptions = contracts.map((contract) => ({
    value: contract._id,
    text: `${contract.number}${
      contract.amendment_number ? ` - ${contract.amendment_number}` : ''
    }`
  }));

  const typeOptions = types.map((type) => ({
    value: type,
    text: (
      <Tag color={invoiceTypeColors[type]}>
        {t(`orders.form.options.type.${type}`)}
      </Tag>
    )
  }));

  return [
    {
      label: 'order_number',
      required: true,
      initialValue: order && order.order_number
    },
    {
      label: 'order_date',
      required: true,
      input: 'datePicker',
      config: {
        rules: [
          { type: 'object', required: true, message: 'Please select date!' }
        ],
        initialValue: order && order.order_date && moment(order.order_date)
      }
    },
    {
      label: 'supplier',
      initialValue:
        companyName && companyName.type.includes('SUPPLIER')
          ? companiesOptions.filter((c) => c.text === companyName.name)[0].value
          : (order || {}).supplier,
      required: true,
      input: 'select',
      options: companiesOptions,
      extra: true,
      typeExtra: 'addSupplier'
    },
    {
      label: 'client',
      input: 'select',
      options: companiesOptions,
      loading: loading.companies,
      required: true,
      initialValue:
        companyName && companyName.type.includes('CLIENT')
          ? companiesOptions.filter((c) => c.text === companyName.name)[0].value
          : (order || {}).client,
      extra: true,
      typeExtra: 'addClient'
    },
    {
      label: 'owner',
      input: 'select',
      required: true,
      options: leasersOptions,
      loading: loading.companies,
      initialValue:
        companyName && companyName.type.includes('LEASER')
          ? companiesOptions.filter((c) => c.text === companyName.name)[0].value
          : (order || {}).owner,
      extra: true,
      typeExtra: 'addLeaser'
    },
    {
      label: 'contract',
      input: 'select',
      required: true,
      options: contractsOptions,
      loading: loading.contracts,
      initialValue: order && order.contract
    },
    {
      label: 'type',
      input: 'select',
      options: typeOptions,
      loading: loading.types,
      required: true,
      initialValue: order && order.type
    },
    {
      label: 'object',
      required: true,
      initialValue: order && order.object
    },
    {
      label: 'pv_number',
      required: false,
      initialValue: order && order.pv_number
    },
    {
      label: 'pv_signing_date',
      input: 'datePicker',
      config: {
        rules: [
          { type: 'object', required: false, message: 'Please select date!' }
        ],
        initialValue:
          order && order.pv_signing_date && moment(order.pv_signing_date)
      }
    },
    {
      label: 'comment',
      input: 'textArea',
      rows: 4,
      required: false,
      initialValue: order && order.comment
    }
  ];
};
