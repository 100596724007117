import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import EditAndCreateContractForm from './EditAndCreateContractForm';
import { formItemLayout, tailFormItemLayout } from '../../utils/formLayouts';
import { PageHeaderCustom } from '../../components';

const EditAndCreateContract = ({ location }) => {
  const { contractId, amendment, purpose } = location.state || {};
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const [values, setValues] = useState({});

  const onChange = (fields) => {
    setValues(fields);
  };

  return (
    <>
      <PageHeaderCustom
        title={
          id
            ? t(`contracts.edit.title${amendment ? '.amendment' : '.contract'}`)
            : t(
                `contracts.create.title${
                  amendment ? '.amendment' : '.contract'
                }`
              )
        }
      />
      <EditAndCreateContractForm
        id={id || contractId}
        amendment={amendment}
        purpose={purpose}
        formItemLayout={formItemLayout}
        tailFormItemLayout={tailFormItemLayout}
        history={history}
        onChange={onChange}
        values={values}
      />
    </>
  );
};

EditAndCreateContract.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      purpose: PropTypes.string,
      amendment: PropTypes.bool,
      contractId: PropTypes.string
    }).isRequired
  }).isRequired
};

export default EditAndCreateContract;
