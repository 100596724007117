import React from 'react';
import { Typography, Icon, Select } from 'antd';

const { Title } = Typography;
const { Option } = Select;

const f_steps = (
  owner,
  ownerTitle,
  handleOwnerSelect,
  companies,
  companiesIsLoading,
  company,
  t,
  handleCompanyChange
) => {
  return {
    assets: [
      {
        title: ownerTitle
          ? `${t('assets.importModal.firstStep.title')}: ${ownerTitle[0].name}`
          : t('assets.importModal.firstStep.title'),
        icon: <Icon type="user" />,
        content: (
          <>
            <Title level={4}>
              {t('assets.importModal.firstStep.contentTitle')}
            </Title>
            <Select
              value={owner}
              onChange={handleOwnerSelect}
              style={{ width: 200, marginTop: 16 }}
            >
              {companies
                .filter(({ type: companyType }) =>
                  companyType.includes('CLIENT')
                )
                .map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
            </Select>
          </>
        )
      }
    ],
    invoices: [
      {
        title: 'Client',
        icon: <Icon type="user" />,
        content: (
          <>
            <Title level={4}>À qui sont addressées ces factures?</Title>
            <Select
              loading={companiesIsLoading}
              value={company}
              onChange={handleCompanyChange}
              style={{ width: 200, marginTop: 16 }}
            >
              {companies
                .filter(({ type: companyType }) =>
                  companyType.includes('CLIENT')
                )
                .map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
            </Select>
          </>
        )
      }
    ],
    servicesAndCreditsInvoices: [
      {
        title: 'Client',
        icon: <Icon type="user" />,
        content: (
          <>
            <Title level={4}>À qui sont addressées ces factures?</Title>
            <Select
              loading={companiesIsLoading}
              value={company}
              onChange={handleCompanyChange}
              style={{ width: 200, marginTop: 16 }}
            >
              {companies
                .filter(({ type: companyType }) =>
                  companyType.includes('CLIENT')
                )
                .map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
            </Select>
          </>
        )
      }
    ]
  };
};

export default f_steps;
