/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Descriptions,
  Icon,
  Popconfirm,
  Spin,
  Tag,
  Typography,
  Col,
  Skeleton,
  Row
} from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import useAuthContext from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import UploadDrawer from '../../components/uploadDrawer';
import PaymentInvoices from './PaymentInvoices';
import PaymentOrders from './PaymentOrders';

const ShowPayment = () => {
  const { id } = useParams();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const isTableLoading = false;
  const { t } = useTranslation();
  const [payment, setPayment] = useState({});
  const [paymentIsLoading, setPaymentIsLoading] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const getPayment = async () => {
    setPaymentIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/payments/${id}?populate=invoices,recipient,files,invoices.order_number`
      });
      if (data.files.length) {
        setBadgeCount(data.files.length);
      } else setBadgeCount(0);
      setPayment(data);
      const tmp = [];
      let som = 0;
      data.invoices.map((invoice) => {
        tmp.push(invoice.order_number);
        som += invoice.total;
      });
      const newArray = [...tmp];
      newArray.map(({ _id }, index) => (newArray[index] = _id));
      const unique = [...new Set(newArray)];
      const result = [];
      unique.map((uni) => {
        result.push(tmp.find((order) => order._id === uni));
      });
      setOrders(result);
      setTotal(som);
    } catch (e) {
      console.error(e);
    }
    setPaymentIsLoading(false);
  };

  useEffect(() => {
    getPayment();
  }, []);

  const deletePayment = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/payments/${id}` });
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (page) => {
    setPagination(page);
  };

  const {
    number,
    comment,
    recipient,
    payment_date,
    created_at,
    invoices
  } = payment;

  return (
    <>
      <PageHeaderCustom
        title={t('payments.showPayment.title')}
        extra={
          <div>
            <Link to={`/payments/edit/${id}`}>
              <Button type="primary" icon="edit">
                {t('payments.showPayment.editButton')}
              </Button>
            </Link>
            <Popconfirm
              title={t('payments.showPayment.delete.title')}
              okText={t('payments.showPayment.delete.ok')}
              cancelText={t('payments.showPayment.delete.cancel')}
              onConfirm={deletePayment}
              icon={<Icon type="warning" />}
            >
              <Button type="danger" icon="delete" style={{ margin: '0 4px' }}>
                {t('payments.showPayment.deleteButton')}
              </Button>
            </Popconfirm>
          </div>
        }
      />
      {paymentIsLoading ? (
        <Spin
          style={{
            textAlign: 'center',
            borderRadius: '4px',
            marginBottom: '20px',
            padding: '30px 50px',
            margin: '20px 0',
            width: '100%',
            height: '100%'
          }}
        />
      ) : (
        <div>
          <ContentCustom padding={false}>
            <div
              style={{
                padding: 24,
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                width: '100%'
              }}
            >
              <Descriptions column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
                <Descriptions.Item
                  label={t('payments.showPayment.number')}
                  span={2}
                >
                  {number || '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t('payments.showPayment.paymentDate')}
                  span={1}
                >
                  {moment(payment_date).format('LL') || '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t('payments.showPayment.recipient')}
                  span={2}
                >
                  <Tag color="red">{(recipient && recipient.name) || '-'}</Tag>
                </Descriptions.Item>
                <Descriptions.Item
                  label={t('payments.showPayment.created_at')}
                  span={1}
                >
                  {created_at ? moment(created_at).format('LL') : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t('payments.showPayment.comment')}
                  span={1}
                >
                  {comment || '-'}
                </Descriptions.Item>
              </Descriptions>
              <PaymentInvoices
                handleChange={handleChange}
                invoices={invoices}
                isTableLoading={isTableLoading}
                pagination={pagination}
              />
              <PaymentOrders
                handleChange={handleChange}
                isTableLoading={isTableLoading}
                orders={orders}
                pagination={pagination}
              />
              <Row style={{ margin: '30px 0' }}>
                <Col xs={{ offset: 8 }} md={{ offset: 14 }}>
                  <Skeleton active loading={isTableLoading} paragraph={0}>
                    <Row type="flex" style={{ height: '100%' }}>
                      <Col span={8} style={{ textAlign: 'left' }}>
                        <Typography.Text strong style={{ fontSize: '18px' }}>
                          {t('orders.showOrder.totalDue', {
                            date: payment_date
                              ? `${moment(payment_date).format('L')}`
                              : ''
                          })}
                        </Typography.Text>
                      </Col>
                      <Col
                        span={16}
                        style={{
                          textAlign: 'right',
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Typography.Text strong style={{ fontSize: '18px' }}>
                          {`${numeral(total || 0 + total || 0).format(
                            '0,0.00'
                          )}€`}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Skeleton>
                </Col>
              </Row>
              <UploadDrawer
                badgeCount={badgeCount}
                getResource={getPayment}
                resourceFiles={payment.files}
                resourceId={id}
                resourceName="payments"
                topPosition={50}
              />
            </div>
          </ContentCustom>
        </div>
      )}
    </>
  );
};

export default ShowPayment;
