/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Icon,
  Popconfirm,
  Row,
  Skeleton,
  Table,
  Tag,
  Typography
} from 'antd';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import { ContentCustom } from '../../components';
import AddLOEModal from './AddLOEModal';
import { LOEStatusColors } from '../../utils';

const { Column } = Table;

const LOETable = ({ contract }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [LOEList, setLOEList] = useState([]);
  const [LOEToEdit, setLOEToEdit] = useState({ info: null });
  const [isModalLoading, setIsModalLoading] = useState(false);

  const getContractLOEList = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/loe?contract=${contract._id}`
      });
      setLOEList(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const updateLOEModal = (index) => {
    setLOEToEdit({
      info: LOEList.filter((loe) => loe._id === index),
      index
    });
    setVisible(true);
  };

  const createLOE = async (values) => {
    let status = 'VALID';
    if (moment(values.end_date).isBefore()) status = 'EXPIRED';
    if (moment(values.start_date).isAfter()) status = 'ENATTENTE';
    const body = {
      ...values,
      contract: contract._id,
      rest_amount: values.amount,
      status
    };
    try {
      await dispatchAPI('POST', { url: `/loe`, body });
      await getContractLOEList();
    } catch (e) {
      console.error(e);
    }
    setVisible(false);
    setIsModalLoading(false);
  };

  const deleteLOE = async (index) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/loe/${index}`
      });
      await getContractLOEList();
    } catch (e) {
      console.error(e);
    }
  };

  const updateLOE = async (values, _id) => {
    let status = 'VALID';
    if (moment(values.end_date).isBefore()) status = 'EXPIRED';
    if (moment(values.start_date).isAfter()) status = 'ENATTENTE';
    const body = { ...values, contract: contract._id, status };
    try {
      await dispatchAPI('PATCH', {
        url: `/loe/${_id}`,
        body
      });
      setLOEToEdit({ info: null, index: null });
      await getContractLOEList();
    } catch (e) {
      console.error(e);
    }
    setVisible(false);
    setIsModalLoading(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async (values, id) => {
    setIsModalLoading(true);
    if (!LOEToEdit.info) {
      await createLOE(values);
    } else {
      await updateLOE(values, id);
    }
  };
  const handleCancel = () => {
    setVisible(false);
    setLOEToEdit({ info: null, index: null });
  };

  useEffect(() => {
    if (contract) {
      getContractLOEList();
    }
  }, []);

  return (
    <ContentCustom marginTop={8}>
      <AddLOEModal
        company={contract}
        LOE={LOEToEdit}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        updateSite={updateLOE}
        isLoading={isModalLoading}
      />
      <Row type="flex" justify="space-between">
        <Typography.Title level={4}>
          {t('contracts.showContract.loeList.title')}
        </Typography.Title>
        <Button onClick={showModal} type="primary">
          {t('contracts.showContract.loeList.addLOE')}
        </Button>
      </Row>
      <Skeleton
        loading={loading}
        active
        title={0}
        paragraph={{
          rows: 8,
          width: [
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%'
          ]
        }}
      >
        <Table
          dataSource={LOEList.map(({ _id, ...LOE }) => ({ ...LOE, key: _id }))}
        >
          <Column
            title={t('contracts.list.column.number')}
            dataIndex="number"
            sorter={(a, b) => a.number - b.number}
            sortDirections={['ascend', 'descend']}
          />
          <Column
            title={t('contracts.list.column.amount')}
            dataIndex="amount"
            sorter={(a, b) => a.amount - b.amount}
            sortDirections={['ascend', 'descend']}
          />
          <Column
            title={t('contracts.list.column.rest_amount')}
            dataIndex="rest_amount"
            sorter={(a, b) => a.rest_amount - b.rest_amount}
            sortDirections={['ascend', 'descend']}
          />
          <Column
            title={t('contracts.list.column.startDate')}
            dataIndex="start_date"
            sorter={(a, b) =>
              moment(a.start_date).unix() - moment(b.start_date).unix()
            }
            sortDirections={['ascend', 'descend']}
            render={(start_date) => (
              <Typography>
                {`${moment(start_date).format('LL') || '-'}`}
              </Typography>
            )}
          />
          <Column
            title={t('contracts.list.column.endDate')}
            dataIndex="end_date"
            sorter={(a, b) =>
              moment(a.end_date).unix() - moment(b.end_date).unix()
            }
            sortDirections={['ascend', 'descend']}
            render={(end_date) => (
              <Typography>
                {`${moment(end_date).format('LL') || '-'}`}
              </Typography>
            )}
          />
          <Column
            title={t('contracts.list.column.status')}
            dataIndex="status"
            sorter={(a, b) => a.status.localeCompare(b.status)}
            sortDirections={['ascend', 'descend']}
            render={(status) => (
              <Tag color={LOEStatusColors[status]}>{status}</Tag>
            )}
          />
          <Column
            title={t('users.list.column.actions')}
            render={({ key }) => (
              <span>
                <Button
                  type="link"
                  icon="edit"
                  onClick={() => updateLOEModal(key)}
                />
                <Divider type="vertical" />
                <Popconfirm
                  title={t('contracts.loeList.action.delete.title')}
                  okText={t('contracts.loeList.action.delete.ok')}
                  cancelText={t('contracts.loeList.action.delete.cancel')}
                  onConfirm={() => deleteLOE(key)}
                  icon={<Icon type="warning" />}
                >
                  <Button size="small" type="danger" icon="delete" />
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      </Skeleton>
    </ContentCustom>
  );
};
LOETable.propTypes = {
  contract: PropTypes.instanceOf(Object).isRequired
};
export default LOETable;
