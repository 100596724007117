import React from 'react';
import { Icon, Row, Skeleton, Table, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

const { Title } = Typography;
const { Column } = Table;
const PVArticles = ({ isTableLoading, pv }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ marginTop: 50 }}>
      <Title level={4}>{t('orders.showOrder.articlesTitle')}</Title>
      <Skeleton
        active
        title={false}
        loading={isTableLoading}
        paragraph={{
          rows: 8,
          width: [
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%'
          ]
        }}
      >
        <Table
          dataSource={(pv.articles || []).map(({ _id, ...article }) => ({
            ...article,
            key: _id
          }))}
          loading={isTableLoading}
          pagination={false}
          expandIcon={(expandProps) => {
            if (expandProps.record.description) {
              if (expandProps.expanded) {
                return (
                  <Icon
                    onClick={(e) => {
                      expandProps.onExpand(expandProps.record, e);
                    }}
                    role="button"
                    type="minus-square"
                  />
                );
              }
              return (
                <Icon
                  onClick={(e) => {
                    expandProps.onExpand(expandProps.record, e);
                  }}
                  role="button"
                  type="plus-square"
                />
              );
            }
            return false;
          }}
          expandedRowRender={(record) =>
            record.description ? record.description : 'Pas de description'
          }
        >
          <Column
            title={t('pvs.showPV.articlesTable.label')}
            dataIndex="catalog_label"
          />
          <Column
            title={t('pvs.showPV.articlesTable.quantity')}
            dataIndex="quantity"
            align="right"
          />
          <Column
            title={t('pvs.showPV.articlesTable.unitPrice')}
            dataIndex="unit_price"
            align="right"
            render={(price) => `${numeral(price).format('0,0.00')} €`}
          />
          <Column
            title={t('pvs.showPV.articlesTable.discount')}
            dataIndex="discount"
            align="right"
            render={(discount) => (discount ? `${discount}%` : 'N/A')}
          />
          <Column
            title={t('pvs.showPV.articlesTable.VAT')}
            dataIndex="VAT"
            align="right"
            render={(vat) => `${vat}%`}
          />
          <Column
            title={t('pvs.showPV.articlesTable.subTotal')}
            key="subTotal"
            align="right"
            render={({ quantity, unit_price }) =>
              `${numeral(quantity * unit_price).format('0,0.00')} €`
            }
          />
        </Table>
      </Skeleton>
    </Row>
  );
};

PVArticles.propTypes = {
  isTableLoading: PropTypes.bool.isRequired,
  pv: PropTypes.shape({
    articles: PropTypes.shape({})
  }).isRequired
};

export default PVArticles;
