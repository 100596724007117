import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import AddPhoneContractModal from './AddPhoneContractModal';
import PhoneCardBody from './PhoneCardBody';

const PhoneCard = ({ configId }) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [phoneContracts, setPhoneContracts] = useState([]);
  const [visible, setVisible] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [contractToEdit, setContractToEdit] = useState(null);

  const showModal = () => {
    setVisible(true);
    setModalAction('edit');
  };

  const handleCancel = () => {
    setVisible(false);
    setContractToEdit(null);
  };

  const getConfig = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { phone_contracts }
      } = await dispatchAPI('GET', {
        url: `/client-configs/${configId}?populate=phone_contracts`
      });
      setPhoneContracts([...phone_contracts]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configId]);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const createPhoneContract = async (contract) => {
    setIsLoading(true);
    const body = contract;
    try {
      await dispatchAPI('POST', {
        url: `/client-configs/${configId}/phonecontract`,
        body
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setVisible(false);
    setIsLoading(false);
    await getConfig();
  };

  const updatePhoneContract = async (contract) => {
    setIsLoading(true);
    const { _id } = contractToEdit;
    const body = contract;
    try {
      await dispatchAPI('PATCH', {
        url: `/client-configs/phonecontract/${_id}`,
        body
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setVisible(false);
    setContractToEdit(null);
    setIsLoading(false);
    await getConfig();
  };

  const handleOk = async (contract) => {
    if (contractToEdit) {
      await updatePhoneContract(contract);
    } else {
      await createPhoneContract(contract);
    }
  };

  const openContractModal = async (key, action) => {
    setIsLoading(true);
    setModalAction(action);
    setVisible(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/client-configs/phonecontract/${key}`
      });
      setContractToEdit(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setIsLoading(false);
  };

  const deleteContract = async (key) => {
    setIsLoading(true);
    try {
      await dispatchAPI('DELETE', {
        url: `client-configs/phonecontract/${key}`
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    await getConfig();
    setIsLoading(false);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  return (
    <Card
      title={t('companies.configClient.phoneContract.title')}
      style={{ marginTop: 24 }}
      extra={
        <Button onClick={showModal} type="primary">
          {t('companies.configClient.addContract')}
        </Button>
      }
    >
      <AddPhoneContractModal
        loading={isLoading}
        contract={contractToEdit}
        visible={visible}
        action={modalAction}
        handleOk={handleOk}
        handleCancel={handleCancel}
        switchToEdit={() => setModalAction('edit')}
      />
      <PhoneCardBody
        loading={loading}
        phoneContracts={phoneContracts}
        openContractModal={openContractModal}
        handleSearch={handleSearch}
        handleReset={handleReset}
        deleteContract={deleteContract}
      />
    </Card>
  );
};

PhoneCard.propTypes = {
  configId: PropTypes.string.isRequired
};

export default PhoneCard;
