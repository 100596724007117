import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, Input, InputNumber, Row, Col, Select } from 'antd';
import { buttonStyle, defaultDescriptionLabel, rule } from './utils';

const { Option } = Select;

const AddArticleForm = ({
  articles,
  id,
  pv,
  invoices,
  invoicesArticles,
  getFieldDecorator,
  getFieldProps,
  calculatedValues,
  setCalculatedValues,
  catalog,
  deleteArticle
}) => {
  const { t } = useTranslation();
  return articles.map(
    (
      {
        articleLabel,
        priceLabel,
        vatLabel,
        discountLabel,
        quantityLabel,
        descriptionLabel,
        articleId
      },
      idx
    ) => (
      <Row
        type="flex"
        key={articleLabel}
        gutter={[0, 16]}
        style={{ marginTop: 8 }}
      >
        <Col span={1}>
          <Button
            style={buttonStyle}
            size="small"
            type="danger"
            icon="delete"
            onClick={() => deleteArticle(articleId)}
          />
        </Col>
        <Col span={23}>
          <Row
            type="flex"
            justify="start"
            align="middle"
            gutter={16}
            style={{ background: '#fafafa' }}
          >
            <Col lg={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.articleLabel')}
                style={{ marginBottom: 0 }}
              >
                <Select
                  {...getFieldProps(articleLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        article: value
                      }),
                    initialValue: id
                      ? pv &&
                        pv.articles &&
                        pv.articles[idx] &&
                        pv.articles[idx].catalog_label
                      : invoices &&
                        invoicesArticles &&
                        invoicesArticles[idx] &&
                        invoicesArticles[idx].catalog_label,
                    rules: rule(t)
                  })}
                >
                  {catalog.map(({ _id, name }) => (
                    <Option key={_id} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.quantityLabel')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  {...getFieldProps(quantityLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        quantity: value
                      }),
                    initialValue: id
                      ? pv &&
                        pv.articles &&
                        pv.articles[idx] &&
                        pv.articles[idx].quantity
                      : invoices &&
                        invoicesArticles &&
                        invoicesArticles[idx] &&
                        invoicesArticles[idx].quantity,
                    rules: rule(t)
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.priceLabel')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  {...getFieldProps(priceLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        price: value
                      }),
                    initialValue: id
                      ? pv &&
                        pv.articles &&
                        pv.articles[idx] &&
                        pv.articles[idx].unit_price
                      : invoices &&
                        invoicesArticles &&
                        invoicesArticles[idx] &&
                        invoicesArticles[idx].unit_price,
                    rules: rule(t)
                  })}
                />
                {` €`}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.discountLabel')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  {...getFieldProps(discountLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        discount: value
                      }),
                    initialValue:
                      id && pv
                        ? pv.articles && pv.articles[idx]
                          ? pv.articles[idx].discount
                          : 0
                        : invoices && invoicesArticles && invoicesArticles[idx]
                        ? invoicesArticles[idx].discount
                        : 0
                  })}
                />
                {` %`}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.vatLabel')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  {...getFieldProps(vatLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        vat: value
                      }),
                    initialValue: id
                      ? pv && pv.articles && pv.articles[idx]
                        ? pv.articles[idx].VAT
                        : 20
                      : invoices && invoicesArticles && invoicesArticles[idx]
                      ? invoicesArticles[idx].VAT
                      : 20,
                    rules: rule(t)
                  })}
                />
                {` %`}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.descriptionLabel')}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator(
                  descriptionLabel,
                  defaultDescriptionLabel(
                    id,
                    pv,
                    invoices,
                    invoicesArticles,
                    idx
                  )
                )(<Input />)}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  );
};
AddArticleForm.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
  pv: PropTypes.shape({}).isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  invoicesArticles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  calculatedValues: PropTypes.shape({}).isRequired,
  setCalculatedValues: PropTypes.func.isRequired,
  catalog: PropTypes.arrayOf(PropTypes.string).isRequired,
  deleteArticle: PropTypes.func.isRequired
};
const WrappedAddArticleAntForm = Form.create({
  name: 'addArticles'
})(AddArticleForm);
export default WrappedAddArticleAntForm;
