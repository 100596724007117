import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Typography, Card, Divider, message, Icon, Button } from 'antd';
import { Link } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';

const { Title } = Typography;
const { Column } = Table;
const iconSize = '18px';

const ListModels = () => {
  const { t } = useTranslation();
  const { dispatchAPI, isLoading } = useAuthContext();
  const [models, setModels] = useState([]);

  const fetchModels = async () => {
    try {
      const result = await dispatchAPI('GET', { url: '/assets/models' });
      // eslint-disable-next-line
      setModels(result.data.map(model => ({ ...model, key: model._id })));
    } catch (e) {
      message.error(e.message);
    }
  };

  useEffect(() => {
    fetchModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Title level={2}>{t('models.list.title')}</Title>
          <Link to="/models/create">
            <Button type="primary" icon="file-add">
              {t('models.addModel')}
            </Button>
          </Link>
        </div>
        <Table dataSource={models} loading={isLoading}>
          <Column
            title={t('models.list.column.name')}
            key="name"
            render={(model) => <Typography>{`${model.name}`}</Typography>}
          />
          <Column
            title={t('users.list.column.actions')}
            key="action"
            render={(model) => (
              <span>
                <Link to={`/models/show/${model.key}`}>
                  <Icon type="eye" style={{ fontSize: iconSize }} />
                </Link>
                <Divider type="vertical" />
                <Link to={`/models/edit/${model.key}`}>
                  <Icon type="edit" style={{ fontSize: iconSize }} />
                </Link>
              </span>
            )}
          />
        </Table>
      </Card>
    </>
  );
};

export default ListModels;
