import React from 'react';
import { Icon, Radio, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ExtraOptions = ({
  filter,
  handleFilterChange,
  reset,
  setDisabledPVDateRange,
  setDisabledReceptionDateRange
}) => {
  const { t } = useTranslation();
  return (
    <Row style={{ marginTop: 8 }}>
      <Row>
        <Text strong style={{ marginBottom: 10, marginLeft: 8 }}>
          {t(`header.menu.filteredTab.ordersFilter`)}
        </Text>
        <Icon style={{ marginLeft: 4 }} type="undo" onClick={reset} />
      </Row>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.paid}
        onChange={(e) => handleFilterChange('paid', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&checkout_amount&checkout_amount!=0">
          {t(`header.menu.filteredTab.paidOrders`)}
        </Radio.Button>
        <Radio.Button value="&!checkout_amount&checkout_amount=0">
          {t(`header.menu.filteredTab.unpaidOrders`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.pv}
        onChange={(e) => handleFilterChange('pv', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button
          value="&pv_signing_date&pv_signing_date!=null"
          onChange={() => setDisabledPVDateRange(false)}
        >
          {t(`header.menu.filteredTab.signedOrders`)}
        </Radio.Button>
        <Radio.Button
          value="&!pv_signing_date&pv_signing_date=null"
          onChange={() => setDisabledPVDateRange(true)}
        >
          {t(`header.menu.filteredTab.unsignedOrders`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.order}
        onChange={(e) => handleFilterChange('order', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button
          value="&reception_date"
          onChange={() => setDisabledReceptionDateRange(false)}
        >
          {t(`header.menu.filteredTab.orderReceivedInvoices`)}
        </Radio.Button>
        <Radio.Button
          value="&!reception_date"
          onChange={() => setDisabledReceptionDateRange(true)}
        >
          {t(`header.menu.filteredTab.orderNotReceivedInvoices`)}
        </Radio.Button>
      </Radio.Group>
      <br />
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.type}
        onChange={(e) => handleFilterChange('type', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&type=CESSION">
          {t(`header.menu.filteredTab.cessionOrders`)}
        </Radio.Button>
        <Radio.Button value="&type=BROKERAGE">
          {t(`header.menu.filteredTab.brokerageOrders`)}
        </Radio.Button>
        <Radio.Button value="&type=CONSULTING">
          {t(`header.menu.filteredTab.consultingOrders`)}
        </Radio.Button>
        <Radio.Button value="&type=BUILD">
          {t(`header.menu.filteredTab.buildOrders`)}
        </Radio.Button>
        <Radio.Button value="&type=RUN">
          {t(`header.menu.filteredTab.runOrders`)}
        </Radio.Button>
        <Radio.Button value="&type=FINANCIAL_PORTAGE">
          {t(`header.menu.filteredTab.financialOrders`)}
        </Radio.Button>
        <Radio.Button value="&type=CREDIT">
          {t(`header.menu.filteredTab.creditOrders`)}
        </Radio.Button>
      </Radio.Group>
    </Row>
  );
};

ExtraOptions.propTypes = {
  filter: PropTypes.shape({
    paid: PropTypes.string,
    pv: PropTypes.string,
    order: PropTypes.string,
    type: PropTypes.string
  }),
  handleFilterChange: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  setDisabledPVDateRange: PropTypes.func.isRequired,
  setDisabledReceptionDateRange: PropTypes.func.isRequired
};

ExtraOptions.defaultProps = {
  filter: null
};

export default ExtraOptions;
