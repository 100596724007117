import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Icon, Input, Button, Checkbox, message, Row } from 'antd';
import useAuthContext from '../../contexts/AuthContext';

const LoginForm = ({
  switchForm,
  form: { getFieldDecorator, validateFields }
}) => {
  const location = useLocation();
  const { isValid, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { from } = (location && location.state) || { from: { pathname: '/' } };

  const enterLoading = () => {
    setLoading(true);
  };

  const loginUser = async (email, password, rememberMe) => {
    try {
      await dispatchAPI('LOGIN', { email, password, rememberMe });
      message.success(t('login.success'));
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.error) {
          message.error(e.response.data.error.description);
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        enterLoading();
        await loginUser(
          values.username.toLowerCase(),
          values.password,
          values.remember
        );
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      {isValid && <Redirect to={from} />}
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [{ required: true, message: t('login.usernameMissing') }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={t('login.username')}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: t('login.pwdMissing') }]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder={t('login.password')}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Row align="middle" justify="space-between">
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: true
          })(<Checkbox>{t('login.rememberMe')}</Checkbox>)}
          <Button type="link" onClick={() => switchForm('forgotPwd')}>
            {t('login.forgotPwd')}
          </Button>
        </Row>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{ width: '100%', marginTop: 16 }}
          loading={isLoading}
        >
          {t('login.connect')}
        </Button>
      </Form.Item>
    </Form>
  );
};

LoginForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired,
  switchForm: PropTypes.func.isRequired
};

const WrappedLoginForm = Form.create({ name: 'login' })(LoginForm);

export default WrappedLoginForm;
