import React, { useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Spin, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LazyLoad from 'react-lazyload';
import useAuthContext from '../../../contexts/AuthContext';
import chartsElementsReducer from '../chartsElementsReducer';
import useLanguageContext from '../../../contexts/LanguageContext';
import useFiltersContext from '../context/FiltersContext';
import BasicChart from './BasicChart';
import SquareHierarchyChart from './SquareHierarchyChart';

const { Title } = Typography;

const ChartDash = ({
  title,
  purpose,
  transposed,
  actionPayload,
  withSwitch,
  defaultUnit,
  chart
}) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    timeScale,
    filters: { provider, device, subsidiaries }
  } = useFiltersContext();
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { endPoint, resource } = purpose || {};
  const [unit, setUnit] = useState(defaultUnit);
  const [chartsElements, dispatch] = useReducer(chartsElementsReducer, {
    isLoading: !actionPayload
  });

  let iconType = 'bar-chart';
  if (['average_real_cost_by_month'].includes(endPoint)) {
    iconType = 'line-chart';
  }

  const { titleExtra } = chartsElements;

  let CancelToken;
  let cancel;

  const formatData = useCallback(async () => {
    CancelToken = axios.CancelToken;
    const fetchData = async () => {
      setIsLoading(true);
      let results;

      let providerUrl;
      if (provider) providerUrl = `&provider=${provider}`;

      let deviceUrl;
      if (device.length) deviceUrl = `&devices=${device}`;

      let subsidiariesUrl;
      if (subsidiaries.length)
        subsidiariesUrl = `&subsidiaries=${subsidiaries}`;

      try {
        results = await dispatchAPI('GET', {
          url: `/dashboards/${resource}/${endPoint}?start_date=${
            timeScale[0]
          }&end_date=${timeScale[1]}&unit=${unit}${providerUrl ||
            ''}${deviceUrl || ''}${subsidiariesUrl || ''}`,
          cancelToken: new CancelToken((c) => {
            cancel = c;
          })
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (axios.isCancel(e)) {
          return [];
        }
      }
      setIsLoading(false);
      return (results || {}).data;
    };
    dispatch({
      type: endPoint,
      payload: actionPayload || {
        data: (await fetchData()) || [],
        t,
        timeScale,
        unit
      }
    });
  }, [
    resource,
    endPoint,
    timeScale,
    device,
    subsidiaries,
    actionPayload,
    unit,
    provider,
    locale
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    formatData();
    if (cancel)
      return () => {
        cancel();
      };
  }, [formatData]);

  const handleSwitch = (v) => {
    setUnit(v);
  };

  const extraButton = () => {
    if (withSwitch)
      return (
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <Button
            style={{ color: unit === 'volume' ? '#1890ff' : 'grey' }}
            value="volume"
            type="link"
            icon="database"
            onClick={(e) => handleSwitch(e.target.value)}
          />
          <Button
            style={{ color: unit === 'amount' ? '#1890ff' : 'grey' }}
            value="amount"
            type="link"
            icon="euro"
            onClick={(e) => handleSwitch(e.target.value)}
          />
        </div>
      );
    if (titleExtra) return titleExtra;
    return null;
  };

  return (
    <div>
      <Title style={{ textAlign: 'center' }} level={4}>
        {`${title} `}
        {extraButton()}
      </Title>
      {/* eslint-disable-next-line no-nested-ternary */}
      <LazyLoad
        debounce={500}
        height={100}
        placeholder={
          <Spin size="large" spinning>
            <div style={{ height: 500, width: '100%' }} />
          </Spin>
        }
      >
        <Spin spinning={isLoading} size="large">
          {chart === 'basic' ? (
            <BasicChart
              chartsElements={chartsElements}
              iconType={iconType}
              transposed={transposed}
            />
          ) : (
            <SquareHierarchyChart chartsElements={chartsElements} />
          )}
        </Spin>
      </LazyLoad>
    </div>
  );
};

ChartDash.propTypes = {
  title: PropTypes.string,
  rawData: PropTypes.shape({}),
  purpose: PropTypes.shape({
    resource: PropTypes.string,
    endPoint: PropTypes.string
  }),
  transposed: PropTypes.bool,
  actionPayload: PropTypes.shape({}),
  withSwitch: PropTypes.bool,
  defaultUnit: PropTypes.string,
  chart: PropTypes.string
};

ChartDash.defaultProps = {
  title: null,
  rawData: null,
  purpose: null,
  transposed: false,
  actionPayload: null,
  withSwitch: false,
  defaultUnit: 'volume',
  chart: 'basic'
};

export default ChartDash;
