import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Divider, Icon, Popconfirm, Select, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import AddRACIContactModal from './AddRACIContactModal';

const { Option } = Select;
const { Column } = Table;

const RACICard = ({ configId, raci, companyId, getConfig }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState({
    modelsAreLoading: false
  });
  const [model, setModel] = useState('it');
  const [modelNames, setModelNames] = useState([]);
  const [visible, setVisible] = useState(false);
  const [contactToEdit, setContactToEdit] = useState({ info: null });

  const handleChangeModel = (value) => {
    setModel(value);
  };

  const updateContactList = async (list) => {
    const body = { raci: { ...raci, [model]: list } };
    try {
      await dispatchAPI('PATCH', {
        url: `/client-configs/${configId}`,
        body
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setContactToEdit({ info: null });
    setVisible(false);
    await getConfig();
  };

  const handleOk = async (contact) => {
    const tmp = raci[model];
    tmp.push(contact);
    await updateContactList(tmp);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const getModelNames = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'assets/models' });
      setModelNames(
        Object.keys(data).map((modelName) => {
          if (modelName === 'RealEstate') return 'real_estate';
          if (modelName === 'Car') return 'cars';
          return modelName.toLowerCase();
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setLoading({ ...loading, modelsAreLoading: false });
  };

  const updateContactModal = (index) => {
    setVisible(true);
    setContactToEdit({
      info: raci[model].filter((key, idx) => idx === index),
      index
    });
  };

  const updateContact = async (addContact) => {
    const tmp = raci[model];
    tmp.splice(contactToEdit.index, 1, addContact);
    await updateContactList(tmp);
  };

  const deleteContact = async (index) => {
    const tmp = raci[model].filter((key, idx) => idx !== index);
    await updateContactList(tmp);
  };

  useEffect(() => {
    setLoading({ ...loading, modelsAreLoading: true });
    getModelNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { modelsAreLoading } = loading;

  return (
    <Card
      title="RACI"
      style={{
        marginTop: 24
      }}
      extra={
        <>
          <Select
            style={{ width: 100, marginRight: 10 }}
            onChange={handleChangeModel}
            loading={modelsAreLoading}
            defaultValue={model}
          >
            {modelNames.map((name) => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>
          <Button onClick={showModal} type="primary">
            {t('companies.contacts.addContact')}
          </Button>
        </>
      }
    >
      <AddRACIContactModal
        contact={contactToEdit.info}
        companyId={companyId}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        updateContact={updateContact}
      />
      <Table
        dataSource={raci[model].map((contact, idx) => ({
          ...contact,
          key: idx
        }))}
      >
        <Column
          title={t('companies.contacts.columns.responsability')}
          dataIndex="responsability"
          render={(responsability) => responsability}
        />
        <Column
          title={t('companies.contacts.columns.subject')}
          dataIndex="subject"
          render={(subject) => subject}
        />
        <Column
          title={t('companies.contacts.columns.assign')}
          dataIndex="assign"
          render={(assign) => assign}
        />
        <Column
          title={t('companies.contacts.columns.email')}
          dataIndex="email"
          render={(email) => email}
        />
        <Column
          title={t('companies.contacts.columns.responsible')}
          dataIndex="responsible"
          render={(responsible) => responsible}
        />
        <Column
          title={t('companies.contacts.columns.action')}
          render={({ key }) => (
            <span>
              <Button
                type="link"
                icon="edit"
                onClick={() => updateContactModal(key)}
              />
              <Divider type="vertical" />
              <Popconfirm
                title={t('companies.contacts.action.delete.title')}
                okText={t('companies.contacts.action.delete.ok')}
                cancelText={t('companies.contacts.action.delete.cancel')}
                onConfirm={() => deleteContact(key)}
                icon={<Icon type="warning" />}
              >
                <Button size="small" type="danger" icon="delete" />
              </Popconfirm>
            </span>
          )}
        />
      </Table>
    </Card>
  );
};

RACICard.propTypes = {
  configId: PropTypes.string.isRequired,
  raci: PropTypes.shape({}).isRequired,
  companyId: PropTypes.string.isRequired,
  getConfig: PropTypes.func.isRequired
};

export default RACICard;
