import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import EditAndCreateInvoiceForm from './EditAndCreateInvoiceForm';
import { PageHeaderCustom } from '../../components';
import { formItemLayout, tailFormItemLayout } from '../../utils/formLayouts';

const EditAndCreateInvoice = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { preFilledInfo } = location.state || {};
  const { id, type } = useParams();

  return (
    <>
      <PageHeaderCustom
        title={
          id
            ? t(
                `invoices.edit.title.${
                  type === 'client' ? 'client' : 'supplier'
                }`
              )
            : t(
                `invoices.create.title.${
                  type === 'client' ? 'client' : 'supplier'
                }`
              )
        }
      />
      <EditAndCreateInvoiceForm
        id={id}
        type={type}
        preFilledInfo={preFilledInfo}
        formItemLayout={formItemLayout}
        tailFormItemLayout={tailFormItemLayout}
        history={history}
      />
    </>
  );
};

export default EditAndCreateInvoice;
