import React from 'react';
import { Table, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;
const { Column } = Table;
const PaymentOrders = ({
  handleChange,
  isTableLoading,
  orders,
  pagination
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <>
      <Title level={4} style={{ marginTop: 35 }}>
        {t('payments.showPayment.ordersTitle')}
      </Title>
      <Table
        dataSource={orders}
        onRow={({ _id }) => ({
          onDoubleClick: () => history.push(`/orders/show/${_id}`)
        })}
        loading={isTableLoading}
        pagination={pagination}
        onChange={handleChange}
        rowKey="_id"
      >
        <Column
          title={t(`orders.list.column.order_number`)}
          dataIndex="order_number"
          align="center"
        />
        <Column
          title={t(`orders.list.column.created_at`)}
          dataIndex="created_at"
          align="center"
          render={({ created_at }) => moment(created_at).format('LL')}
        />
        <Column
          title={t(`orders.list.column.reception_date`)}
          dataIndex="reception_date"
          align="center"
          render={(reception_date) =>
            reception_date ? moment(reception_date).format('LL') : '-'
          }
        />
        <Column
          title={t(`orders.list.column.total_ht_amount`)}
          dataIndex="total_ht_amount"
          align="center"
          render={(total_ht_amount) => `${total_ht_amount} €`}
        />
        <Column
          title={t(`orders.list.column.checkout_amount`)}
          dataIndex="checkout_amount"
          align="center"
          render={(checkout_amount) => `${checkout_amount} €`}
        />
      </Table>
    </>
  );
};

PaymentOrders.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({}).isRequired
};

export default PaymentOrders;
