import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Icon, Table, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Column } = Table;
const iconSize = '18px';

const ContractsTab = ({ contractsData }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [contracts, setContracts] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const handlePageChange = (page) => {
    setPagination(page);
  };

  const contractsOptions =
    contracts &&
    contracts.map((contract) => ({
      value: contract._id,
      text: `${contract.number}${
        contract.amendment_number ? ` - ${contract.amendment_number}` : ''
      }`
    }));

  useEffect(() => {
    if (contractsData && contractsData.length) {
      setContracts(
        contractsData.map((contract) => ({ ...contract, key: contract._id }))
      );
      setPagination({ ...pagination, total: contractsData.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsData]);

  return (
    <Table
      dataSource={contracts}
      onChange={handlePageChange}
      pagination={pagination}
      rowClassName="rowStyle"
      onRow={(record) => ({
        onDoubleClick: () => history.push(`/contracts/show/${record.key}`)
      })}
    >
      <Column
        title={t('contracts.list.column.number')}
        key="number"
        filters={contractsOptions}
        filterMultiple
        onFilter={(value, record) => record._id.indexOf(value) === 0}
        render={(contract) => (
          <Typography>
            {contract.number || 'N/R'}
            {contract.amendment_number ? `-${contract.amendment_number}` : ''}
          </Typography>
        )}
      />
      <Column
        title={t('contracts.list.column.duration')}
        key="duration"
        sorter={(a, b) => a.duration - b.duration}
        sortDirections={['ascend', 'descend']}
        render={(contract) => <Typography>{contract.duration}</Typography>}
      />
      <Column
        title={t('users.list.column.actions')}
        key="action"
        render={(contract) => (
          <span>
            <Link to={`/contracts/show/${contract.key}`}>
              <Icon type="eye" style={{ fontSize: iconSize }} />
            </Link>
            <Divider type="vertical" />
            <Link to={`/contracts/edit/${contract.key}`}>
              <Icon type="edit" style={{ fontSize: iconSize }} />
            </Link>
          </span>
        )}
      />
    </Table>
  );
};

ContractsTab.defaultProps = {
  contractsData: null
};

ContractsTab.propTypes = {
  contractsData: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ContractsTab;
