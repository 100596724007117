import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, message, Spin, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import companyFields from './companyFields';
import InputGenerator from '../../components/inputGenerator';
import { formItemLayout, tailFormItemLayout } from '../../utils/formLayouts';
import { ContentCustom } from '../../components';

const EditAndCreateCompanyForm = ({
  id,
  initialType,
  drawer,
  onSuccess,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    resetFields
  }
}) => {
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { dispatchConfigs } = useConfigsContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [enums, setEnums] = useState({ type: [], legal_status: [] });

  const getCompany = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: `/companies/${id}`
      });
      setLoading(false);
      const {
        type,
        legal_status,
        name,
        group,
        address,
        contact,
        siren,
        APE,
        capital
      } = result.data;
      setLoading(false);
      setFieldsValue({
        type,
        legal_status,
        name,
        siren,
        group,
        address,
        contact,
        APE,
        capital
      });
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
      setLoading(false);
    }
  };

  const createCompany = async (values) => {
    const body = { ...values };
    try {
      const result = await dispatchAPI('POST', { url: '/companies', body });
      const n = result.headers.location.search('companies/');
      const companyId = result.headers.location.slice(n + 10);
      await dispatchAPI('POST', {
        url: `companies/${companyId}/config`,
        body: {}
      });
      dispatchConfigs({
        type: 'MODIF',
        modif: true
      });
      setLoading(false);
      resetFields();
      if (history) history.goBack();
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error)
        message.error(e.response.data.error.description);
      message.error(`${t('global.form.error.message')}`);
    }
  };
  const updateCompany = async (values) => {
    const body = { ...values };
    try {
      await dispatchAPI('PATCH', {
        url: `/companies/${id}`,
        body
      });
      dispatchConfigs({
        type: 'MODIF',
        modif: true
      });
      setLoading(false);
      history.goBack();
    } catch (e) {
      message.error(`${t('global.form.error.message')}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (id) {
          await updateCompany(values);
        } else if (!id) {
          await createCompany(values);
          if (onSuccess) onSuccess(values.name);
        }
      }
    });
  };

  const getEnums = async () => {
    try {
      const result = await dispatchAPI('GET', { url: '/companies/enums' });
      setEnums(result.data);
    } catch (e) {
      message.error(e.message);
    }
    setLoading(false);
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setLoading(true);
    if (id) {
      getCompany();
    }
    getEnums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentCustom>
      {isLoading ? (
        <Spin
          style={{
            textAlign: 'center',
            borderRadius: '4px',
            marginBottom: '20px',
            padding: '30px 50px',
            margin: '20px 0',
            width: '100%',
            height: '100%'
          }}
        />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Form {...formItemLayout} onSubmit={handleSubmit}>
          <Row>
            <Col>
              <InputGenerator
                title="companies"
                fields={companyFields(enums, initialType, t)}
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
          </Row>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {t('users.editcreate.form.submitButton')}
            </Button>
            {(initialType && !drawer && (
              <Button
                type="danger"
                onClick={() => goBack()}
                style={{ margin: '0 10px' }}
              >
                {t('users.editcreate.form.cancel')}
              </Button>
            )) ||
              ''}
          </Form.Item>
        </Form>
      )}
    </ContentCustom>
  );
};

EditAndCreateCompanyForm.defaultProps = {
  initialType: [],
  id: null,
  onSuccess: null,
  drawer: false
};

EditAndCreateCompanyForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired
  }).isRequired,
  initialType: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  onSuccess: PropTypes.func,
  drawer: PropTypes.bool
};

const WrappedEditAndCreateCompanyAntForm = Form.create({ name: 'register' })(
  EditAndCreateCompanyForm
);

export default WrappedEditAndCreateCompanyAntForm;
