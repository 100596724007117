/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button, Select, Typography } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import InputGenerator from '../inputGenerator';
import {
  configClientFormItemLayout,
  tailFormItemLayout
} from '../../utils/formLayouts';

const { Option } = Select;
const { Title } = Typography;

const AddRACIContactModal = ({
  contact,
  companyId,
  visible,
  updateContact,
  handleOk,
  handleCancel,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    resetFields,
    setFieldsValue
  }
}) => {
  const { dispatchAPI } = useAuthContext();
  const [areContactsLoading, setAreContactsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const { t } = useTranslation();

  const fields = [
    {
      label: 'addContact.first_name',
      initialValue: contact && contact[0].assign.split(' ')[0]
    },
    {
      label: 'addContact.last_name',
      initialValue: contact && contact[0].assign.split(' ')[1]
    },
    {
      label: 'addContact.responsability',
      initialValue: contact && contact[0].responsability
    },
    {
      label: 'addContact.email',
      initialValue: contact && contact[0].email
    },
    {
      label: 'addContact.responsible',
      initialValue: contact && contact[0].responsible
    },
    {
      label: 'addContact.subject',
      initialValue: contact && contact[0].subject
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { addContact } = values;
        const contactValues = {
          ...addContact,
          assign: `${addContact.first_name} ${addContact.last_name}`
        };
        if (contact) {
          updateContact(contactValues);
        } else {
          handleOk(contactValues);
          resetFields();
        }
      }
    });
  };

  const handleContactSelect = (index) => {
    Object.entries(contacts[index])
      .filter(
        ([key]) =>
          key === 'first_name' || key === 'last_name' || key === 'email'
      )
      .forEach(([key, value]) => {
        setFieldsValue({ [`addContact.${key}`]: value });
      });
  };

  const getContactList = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${companyId}`
      });
      setContacts(data.contacts);
    } catch (e) {
      console.error(e);
    }
    setAreContactsLoading(false);
  };

  useEffect(() => {
    if (visible) {
      setAreContactsLoading(true);
      getContactList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      width={800}
      title={
        contact
          ? t('companies.contacts.editContact')
          : t('companies.contacts.addContact')
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Title level={4}>
        {t('companies.form.addContact.selectExistingContact')}
      </Title>
      <Select
        style={{ width: 200, margin: 16 }}
        onChange={handleContactSelect}
        loading={areContactsLoading}
      >
        {contacts.map((ctct, idx) => (
          <Option key={idx} value={idx}>
            {`${ctct.first_name} ${ctct.last_name}`}
          </Option>
        ))}
      </Select>
      <Title level={4}>{t('companies.form.addContact.enterDetails')}</Title>
      <Form {...configClientFormItemLayout} onSubmit={handleSubmit}>
        <InputGenerator
          title="companies"
          fields={fields}
          getFieldDecorator={getFieldDecorator}
        />
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t('users.editcreate.form.submitButton')}
          </Button>
          <Button
            type="danger"
            onClick={handleCancel}
            style={{ margin: '0 10px' }}
          >
            {t('users.editcreate.form.cancel')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddRACIContactModal.propTypes = {
  contact: PropTypes.arrayOf(
    PropTypes.shape({
      assign: PropTypes.string,
      responsability: PropTypes.string,
      email: PropTypes.string,
      responsible: PropTypes.string,
      subject: PropTypes.string
    })
  ),
  companyId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

AddRACIContactModal.defaultProps = {
  contact: null
};

const WrappedAddRACIContactAntForm = Form.create({ name: 'addRACIContact' })(
  AddRACIContactModal
);

export default WrappedAddRACIContactAntForm;
