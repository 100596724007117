import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { Card, Col, Row, Spin } from 'antd';
import InProgress from '../InProgress';

const DashDisplayContainer = ({ children }) => {
  return (
    <Row
      type="flex"
      justify="center"
      gutter={[16, 24]}
      style={{ marginTop: 16 }}
    >
      {(Array.isArray(children) ? children : [children]).map(
        (dashboard, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col xxl={8} xl={12} sm={24} xs={0} key={idx}>
            <Card
              style={{ minHeight: '500px', height: '100%' }}
              bodyStyle={{ padding: 0, paddingTop: 24 }}
            >
              {dashboard.props.inProgress && (
                <InProgress rotation={40} height={100} width={400} />
              )}
              <LazyLoad
                debounce={500}
                height={100}
                placeholder={
                  <Spin size="large" spinning>
                    <div style={{ height: 500, width: '100%' }} />
                  </Spin>
                }
              >
                {dashboard}
              </LazyLoad>
            </Card>
          </Col>
        )
      )}
    </Row>
  );
};

DashDisplayContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default DashDisplayContainer;
