/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Select, Button, message, Spin, Icon, Drawer } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import useConfigsContext from '../../contexts/ConfigsContext';
import InputGenerator from '../../components/inputGenerator';
import fields from './fields';
import EditAndCreateCompanyForm from '../companies/EditAndCreateCompanyForm';
import { ContentCustom, PageHeaderCustom } from '../../components';
import {
  formItemLayout,
  companyFormItemLayout,
  companyTailFormItemLayout,
  tailFormItemLayout
} from '../../utils/formLayouts';
import { createUserUtil, updateUserUtil } from './utils';

const { Option } = Select;

const EditAndCreateUser = ({
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFields,
    setFieldsValue,
    getFieldValue
  }
}) => {
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { configs } = useConfigsContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [companies, setCompanies] = useState([]);
  const [roles, setRoles] = useState([]);
  const [visible, setVisible] = useState(false);
  const [companyName, setCompanyName] = useState(null);

  const companyField = getFieldValue('company');

  const enterLoading = () => {
    setLoading(true);
  };

  const getUnderRoles = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/under-roles`
      });
      setRoles(data.map((role) => ({ value: role, text: role })));
      setLoading(false);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
  };

  const getUser = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}`
      });
      setLoading(false);
      setUser(data);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
  };

  const createUser = (values) => {
    createUserUtil(values, dispatchAPI, history, setFields, setLoading, t);
  };
  const updateUser = (values) => {
    updateUserUtil(values, dispatchAPI, history, id, setFields, setLoading, t);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (id) {
          enterLoading();
          await updateUser(values);
        } else if (!id) {
          await createUser(values);
        }
      }
    });
  };

  const prefixSelector = (number) =>
    getFieldDecorator(`${number}.country_code`, {
      initialValue: '+33'
    })(
      <Select style={{ width: 70 }}>
        <Option value="+33">+33</Option>
      </Select>
    );

  const getCompanyNames = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: '/companies?populate=sites'
      });
      result.data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
      setCompanies(
        result.data.map((singleCompany) => ({
          ...singleCompany,
          key: singleCompany._id,
          value: singleCompany._id,
          text: singleCompany.name
        }))
      );
    } catch (e) {
      message.error(e.message);
    }
    setLoading(false);
  };

  const handleCompanyCreated = async (name) => {
    setVisible(false);
    await getCompanyNames();
    setCompanyName(name);
  };

  const buttonAddCompany = () => (
    <Button type="primary" onClick={() => setVisible(true)}>
      <Icon type="plus" />
      {t('users.editcreate.form.addCompany')}
    </Button>
  );

  useEffect(() => {
    setLoading(true);
    if (id) {
      getUser();
    }
    getCompanyNames();
    getUnderRoles();
  }, []);

  useEffect(() => {
    if (configs && companies && roles && !isLoading)
      setFieldsValue({ company: configs.client });
  }, [configs, companies, roles, isLoading, setFieldsValue]);

  useEffect(() => {
    if (companyField !== user.company) setFieldsValue({ site: null });
  }, [companyField]);

  return (
    <>
      <PageHeaderCustom
        title={id ? t('users.edit.title') : t('users.create.title')}
      />
      <ContentCustom>
        <Drawer
          title={t('users.editcreate.form.addCompany')}
          width={720}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <EditAndCreateCompanyForm
            title="company"
            formItemLayout={companyFormItemLayout}
            tailFormItemLayout={companyTailFormItemLayout}
            onSuccess={handleCompanyCreated}
          />
        </Drawer>
        {isLoading ? (
          <Spin
            style={{
              textAlign: 'center',
              borderRadius: '4px',
              marginBottom: '20px',
              padding: '30px 50px',
              margin: '20px 0',
              width: '100%',
              height: '100%'
            }}
          />
        ) : (
          <Form {...formItemLayout} onSubmit={handleSubmit}>
            <InputGenerator
              title="users"
              fields={fields(
                user,
                companies,
                roles,
                companyName,
                companyField,
                prefixSelector,
                t,
                !!configs,
                setFieldsValue
              )}
              getFieldDecorator={getFieldDecorator}
              extra={buttonAddCompany}
            />
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('users.editcreate.form.submitButton')}
              </Button>
              <Button
                type="danger"
                onClick={() => history.goBack()}
                style={{ margin: '0 10px' }}
              >
                {t('users.editcreate.form.cancel')}
              </Button>
            </Form.Item>
          </Form>
        )}
      </ContentCustom>
    </>
  );
};

EditAndCreateUser.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired
  }).isRequired
};

const WrappedEditAndCreateUserForm = Form.create({ name: 'register' })(
  EditAndCreateUser
);

export default WrappedEditAndCreateUserForm;
