export const filterProvider = [
  {
    text: 'SFR',
    value: 'SFR'
  },
  {
    text: 'Orange',
    value: 'ORANGE'
  },
  {
    text: 'Bouygues',
    value: 'BOUYGUES'
  }
];

export const filterPhoneContractDevice = [
  {
    text: 'Smartphone Voix',
    value: 'SMARTPHONE_VOICE'
  },
  {
    text: 'Smartphone Data',
    value: 'SMARTPHONE_DATA'
  },
  {
    text: 'Smartphone Voix/Data',
    value: 'SMARTPHONE'
  },
  {
    text: 'Data mobile',
    value: 'DATA'
  },
  {
    text: 'M2M',
    value: 'M2M'
  },
  {
    text: 'Internet',
    value: 'INTERNET'
  },
  {
    text: 'Fixe',
    value: 'LANDLINE'
  }
];
