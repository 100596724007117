import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Form,
  Icon,
  Popconfirm,
  Table,
  Typography,
  Descriptions,
  Tag,
  Input
} from 'antd';
import { useTranslation } from 'react-i18next';
import { providerColors } from '../../utils';
import { filterProvider, filterPhoneContractDevice } from './filters';

const { Column } = Table;
const PhoneCardBody = ({
  loading,
  phoneContracts,
  openContractModal,
  handleSearch,
  handleReset,
  deleteContract
}) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(null);
  return (
    <Table
      loading={loading}
      dataSource={(phoneContracts || []).map(({ _id, ...contract }) => ({
        ...contract,
        key: _id
      }))}
      rowClassName="rowStyle"
      onRow={({ key }) => ({
        onDoubleClick: () => openContractModal(key, 'show')
      })}
      expandIcon={(expandProps) => {
        if (expandProps.record.options && expandProps.record.options.length) {
          if (expandProps.expanded) {
            return (
              <Button
                onClick={(e) => {
                  expandProps.onExpand(expandProps.record, e);
                }}
                type="link"
                size="small"
              >
                {t('companies.configClient.phoneContract.hideOptions')}
              </Button>
            );
          }
          return (
            <Button
              onClick={(e) => {
                expandProps.onExpand(expandProps.record, e);
              }}
              type="link"
              size="small"
            >
              {t('companies.configClient.phoneContract.showOptions')}
            </Button>
          );
        }
        return false;
      }}
      expandedRowRender={(record) =>
        record.options.length ? (
          <>
            <Descriptions title="Options" size="small">
              {record.options.map((opt, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Descriptions.Item key={idx} label={opt.label}>
                  <Typography style={{ fontWeight: 700 }}>
                    {opt.price}
                  </Typography>
                </Descriptions.Item>
              ))}
            </Descriptions>
          </>
        ) : (
          'No options'
        )
      }
    >
      <Column
        title="Forfait"
        dataIndex="name"
        filterDropdown={({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                setSearchInput(node);
              }}
              placeholder="Nom de forfait"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 110, marginRight: 8 }}
            >
              Rechercher
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        )}
        filterIcon={(filtered) => (
          <Icon
            type="search"
            style={{ color: filtered ? '#1890ff' : undefined }}
          />
        )}
        onFilter={(value, record) =>
          record.name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        }
        onFilterDropdownVisibleChange={(filterVisible) => {
          if (filterVisible) {
            setTimeout(() => searchInput.select());
          }
        }}
      />
      <Column
        title="Opérateur"
        dataIndex="provider"
        onFilter={(value, record) => record.provider.indexOf(value) === 0}
        filters={filterProvider}
        render={(provider) => (
          <Tag color={providerColors[provider]}>{provider}</Tag>
        )}
      />
      <Column
        title="Type"
        dataIndex="phoneContractDevice"
        render={(device) =>
          device ? `${t(`companies.form.addContract.${device}`)}` : ' - '
        }
        onFilter={(value, record) =>
          record.phoneContractDevice &&
          record.phoneContractDevice.indexOf(value) === 0
        }
        filters={filterPhoneContractDevice}
      />
      <Column
        title="Action"
        render={({ key }) => (
          <span>
            <Button
              type="link"
              icon="eye"
              onClick={() => openContractModal(key, 'show')}
            />
            <Divider type="vertical" />
            <Button
              type="link"
              icon="edit"
              onClick={() => openContractModal(key, 'edit')}
            />
            <Divider type="vertical" />
            <Popconfirm
              title={t('companies.contacts.action.delete.title')}
              okText={t('companies.contacts.action.delete.ok')}
              cancelText={t('companies.contacts.action.delete.cancel')}
              onConfirm={() => deleteContract(key)}
              icon={<Icon type="warning" />}
            >
              <Button size="small" type="danger" icon="delete" />
            </Popconfirm>
          </span>
        )}
      />
    </Table>
  );
};

PhoneCardBody.propTypes = {
  loading: PropTypes.bool.isRequired,
  phoneContracts: PropTypes.arrayOf(PropTypes.string).isRequired,
  openContractModal: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  deleteContract: PropTypes.func.isRequired
};

const WrappedAddRACIContactAntForm = Form.create({ name: 'phoneCardBody' })(
  PhoneCardBody
);

export default WrappedAddRACIContactAntForm;
