/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { message, Button, Icon, Popconfirm, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import PeopleAssets from './PeopleAssets';
import PeopleInformation from './PeopleInformation';

const ShowPeople = () => {
  const { id } = useParams();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { Title } = Typography;
  const [person, setPerson] = useState({});
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(null);
  const [assets, setAssets] = useState({
    assetChosen: null,
    assetsLinked: null,
    assetToLinked: null
  });
  const [visible, setVisible] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [areModelsLoading, setAreModelsLoading] = useState(false);

  const getAssetsLinked = async () => {
    try {
      const results = await dispatchAPI('GET', {
        url: `/assets?people=${id}&populate=telecomInvoices`
      });
      setAssets({ ...assets, assetsLinked: results.data });
    } catch (e) {
      console.error(e);
    }
  };

  const getPeople = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: `/people/${id}?populate=assets.contract,company,subsidiary`
      });
      setPerson(
        result.data.subsidiary
          ? { ...result.data, subsidiary: result.data.subsidiary.name }
          : result.data
      );
      await getAssetsLinked();
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
    setIsMainLoading(false);
  };

  const updateAssetLinked = async () => {
    const { assetsLinked, assetChosen } = assets;
    const tmp = assetsLinked;
    tmp.push(assetChosen);
    const body = { assets: tmp };
    try {
      await dispatchAPI('PATCH', {
        url: `/people/${id}`,
        body
      });
    } catch (e) {
      message.error(e);
    }
    await getPeople();
    setModel(null);
  };

  const fetchModels = async () => {
    try {
      const result = await dispatchAPI('GET', { url: '/assets/models' });
      // eslint-disable-next-line
      setModels(Object.keys(result.data));
    } catch (e) {
      message.error(e.message);
    }
    setAreModelsLoading(false);
  };

  const deletePeople = async () => {
    await dispatchAPI('DELETE', { url: `/people/${id}` });
    history.push('../../people');
  };

  const showModal = (name) => {
    setModel(name);
    setVisible(true);
  };

  const handleOk = async () => {
    setVisible(false);
    await updateAssetLinked();
  };
  const handleCancel = () => {
    setVisible(false);
    setAssets({ ...assets, assetChosen: null });
  };

  const handleModelChange = (value) => {
    setModel(value);
  };

  const handleAssetChange = (value) => {
    setAssets({ ...assets, assetChosen: value });
  };

  const assetsSelect =
    model === null
      ? { display: 'none', marginTop: '10px' }
      : { marginTop: '10px' };

  useEffect(() => {
    setIsMainLoading(true);
    setAreModelsLoading(true);
    if (id) {
      (async () => {
        await getPeople();
        await fetchModels();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('people.showPeople.title')}
        extra={
          <>
            <Popconfirm
              title={t('people.showPeople.delete.title')}
              okText={t('people.showPeople.delete.ok')}
              cancelText={t('people.showPeople.delete.cancel')}
              onConfirm={() => deletePeople()}
              icon={<Icon type="warning" />}
            >
              <Button
                type="danger"
                icon="delete"
                style={{ float: 'right', margin: '0 4px' }}
              >
                {t('people.showPeople.deleteButton')}
              </Button>
            </Popconfirm>
            <Link to={`/people/edit/${id}`} style={{ float: 'right' }}>
              <Button type="primary" icon="edit">
                {t('people.showPeople.editButton')}
              </Button>
            </Link>
          </>
        }
      />
      <ContentCustom>
        <PeopleInformation
          assets={assets}
          assetsSelect={assetsSelect}
          handleAssetChange={handleAssetChange}
          handleOk={handleOk}
          handleCancel={handleCancel}
          handleModelChange={handleModelChange}
          isMainLoading={isMainLoading}
          model={model}
          models={models}
          person={person}
          visible={visible}
        />
      </ContentCustom>
      <ContentCustom marginTop={8}>
        <Title level={4}>{t('people.showPeople.assetsList.title')}</Title>
        <PeopleAssets
          areModelsLoading={areModelsLoading}
          assets={assets}
          models={models}
          showModal={showModal}
        />
      </ContentCustom>
    </>
  );
};

export default ShowPeople;
