import React from 'react';
import { Tag } from 'antd';
import { categoryTypesColors } from '../../utils';

export default (
  product,
  productType,
  invoiceType,
  invoiceTypes,
  productTypes,
  productEnums,
  invoiceTypeEnums,
  t
) => {
  const categoryOptions = invoiceTypeEnums.map((category) => ({
    value: category,
    text: <Tag color={categoryTypesColors[category]}>{category}</Tag>
  }));

  return [
    {
      label: 'category',
      input: 'select',
      required: true,
      options: categoryOptions,
      initialValue: invoiceType && invoiceType.category
    },
    {
      label: 'label',
      required: true,
      initialValue: invoiceType && invoiceType.label
    }
  ];
};
