/* eslint-disable no-console */
import Papa from 'papaparse';
import { message } from 'antd';

export const uploadUtil = async (
  connectedHeaders,
  setIsModalLoading,
  resourceToImport,
  fileData,
  collection,
  owner,
  company,
  dispatchAPI,
  setVisible,
  setOwner,
  setCompany
) => {
  setIsModalLoading(true);
  let url;
  const formData = new FormData();
  if (resourceToImport === 'assets') {
    formData.append('import', fileData);
    formData.append('collection', collection);
    formData.append('extra', JSON.stringify({ company: owner }));
    formData.append('fields', JSON.stringify(connectedHeaders));
    url = '/formats';
  }
  if (resourceToImport === 'invoices') {
    formData.append('file', fileData);
    formData.append('extra', JSON.stringify({ company }));
    formData.append('fields', JSON.stringify(connectedHeaders));
    url = '/telecom-invoices/import';
  }
  if (resourceToImport === 'servicesAndCreditsInvoices') {
    formData.append('file', fileData);
    formData.append('extra', JSON.stringify({ company }));
    formData.append('fields', JSON.stringify(connectedHeaders));
    url = '/formats';
  }
  try {
    const result = await dispatchAPI('POST', {
      url,
      body: formData
    });
    if (result.status === 202) {
      setVisible(false);
      setOwner(null);
      setCompany(null);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  setIsModalLoading(false);
};

export const assetsPropsUtil = (
  setResourceToImport,
  setCollection,
  setFileData,
  setHeaders,
  setVisible,
  type
) => {
  return {
    name: 'file',
    headers: {
      authorization: 'authorization-text'
    },
    beforeUpload(file) {
      setResourceToImport('assets');
      setCollection(type);
      setFileData(file);
      Papa.parse(file, {
        header: true,
        encoding: 'ISO-8859-1',
        delimiter: ';',
        complete: (results) => {
          try {
            setHeaders(results.meta.fields);
            setVisible(true);
          } catch (e) {
            console.error(e);
          }
        }
      });
      return false;
    }
  };
};

export const invoicesPropsUtil = (
  setResourceToImport,
  setCollection,
  setFileData,
  setHeaders,
  setVisible,
  setSchemaHeaders,
  dispatchAPI
) => {
  return {
    name: 'file',
    headers: {
      authorization: 'authorization-text'
    },
    beforeUpload: async (file) => {
      const { data } = await dispatchAPI('GET', {
        url: '/models/TelecomInvoice'
      });
      setSchemaHeaders(Object.keys(data).map((header) => header));
      setResourceToImport('invoices');
      setFileData(file);
      setCollection('TelecomInvoices');
      Papa.parse(file, {
        header: true,
        encoding: 'ISO-8859-1',
        delimiter: ';',
        complete: (results) => {
          try {
            setHeaders(results.meta.fields);
            setVisible(true);
          } catch (e) {
            console.error(e);
          }
        }
      });
      return false;
    }
  };
};

export const servicesAndCreditsInvoicesPropsUtil = (
  setResourceToImport,
  setFileData,
  setHeaders,
  setVisible,
  setSchemaHeaders,
  dispatchAPI
) => {
  return {
    name: 'file',
    headers: {
      authorization: 'authorization-text'
    },
    beforeUpload: async (file) => {
      const { data } = await dispatchAPI('GET', {
        url: '/models/ServicesAndCreditsInvoice'
      });
      setSchemaHeaders(Object.keys(data).map((header) => header));
      setResourceToImport('servicesAndCreditsInvoices');
      setFileData(file);
      Papa.parse(file, {
        header: true,
        encoding: 'ISO-8859-1',
        delimiter: ';',
        complete: (results) => {
          try {
            setHeaders(results.meta.fields);
            setVisible(true);
          } catch (e) {
            console.error(e);
          }
        }
      });
      return false;
    }
  };
};

export const defaultFilter = {
  status: undefined,
  overage: undefined
};

export const fetchHeadersUtil = async (dispatchAPI, setSchemaHeaders, type) => {
  try {
    const { data: baseAssetSchema } = await dispatchAPI('GET', {
      url: `/assets/models/base-asset`
    });
    const { data: modelSchemas } = await dispatchAPI('GET', {
      url: '/assets/models'
    });
    setSchemaHeaders([
      ...Object.keys(baseAssetSchema),
      ...Object.keys(modelSchemas[type])
    ]);
  } catch (e) {
    message.error(e.message);
  }
};

export const fetchCompaniesUtil = async (
  setCompaniesIsLoading,
  dispatchAPI,
  setCompanies
) => {
  setCompaniesIsLoading(true);
  try {
    const { data } = await dispatchAPI('GET', { url: '/companies' });
    data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
    setCompanies(
      data.map(({ _id, name, type: companyType }) => ({
        _id,
        name,
        type: companyType
      }))
    );
  } catch (e) {
    console.error(e);
  }
  setCompaniesIsLoading(false);
};
