import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Select,
  DatePicker
} from 'antd';
import { tupleNum } from 'antd/lib/_util/type';
import {
  buttonStyle,
  defaultDescriptionLabel,
  defaultReceptionDateLabel,
  rule
} from './utils';

const { Option } = Select;

const AddArticleForm = ({
  articles,
  order,
  getFieldDecorator,
  getFieldProps,
  calculatedValues,
  setCalculatedValues,
  catalog,
  deleteArticle
}) => {
  const { t } = useTranslation();
  return articles.map(
    (
      {
        articleLabel,
        priceLabel,
        vatLabel,
        discountLabel,
        quantityLabel,
        descriptionLabel,
        receptionDateLabel,
        articleId
      },
      idx
    ) => (
      <Row type="flex" key={articleLabel} gutter={[0, 16]}>
        <Col span={1}>
          <Button
            style={buttonStyle}
            size="small"
            type="danger"
            icon="delete"
            onClick={() => deleteArticle(articleId)}
          />
        </Col>
        <Col span={23}>
          <Row
            type="flex"
            justify="start"
            align="middle"
            gutter={16}
            style={{ background: '#fafafa' }}
          >
            <Col lg={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.articleLabel')}
                style={{ marginBottom: 0 }}
              >
                <Select
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...getFieldProps(articleLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        article: value
                      }),
                    initialValue:
                      order &&
                      order.articles &&
                      order.articles[idx] &&
                      order.articles[idx].catalog_label,
                    rules: rule(t)
                  })}
                >
                  {catalog.map(({ _id, name }) => (
                    <Option key={_id} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.quantityLabel')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...getFieldProps(quantityLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        quantity: value
                      }),
                    initialValue:
                      order &&
                      order.articles &&
                      order.articles[idx] &&
                      order.articles[idx].quantity,
                    rules: rule(t)
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.priceLabel')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...getFieldProps(priceLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        price: value
                      }),
                    initialValue:
                      order &&
                      order.articles &&
                      order.articles[idx] &&
                      order.articles[idx].unit_price,
                    rules: rule(t)
                  })}
                />
                {` €`}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                // eslint-disable-next-line react/jsx-props-no-spreading
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.discountLabel')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...getFieldProps(discountLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        discount: value
                      }),
                    initialValue:
                      order && order.articles && order.articles[idx]
                        ? order.articles[idx].discount
                        : 0
                  })}
                />
                {` %`}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                // eslint-disable-next-line react/jsx-props-no-spreading
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.vatLabel')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...getFieldProps(vatLabel, {
                    onChange: (value) =>
                      setCalculatedValues({
                        ...calculatedValues,
                        vat: value
                      }),
                    initialValue:
                      order && order.articles && order.articles[idx]
                        ? order.articles[idx].VAT
                        : 20,
                    rules: tupleNum()
                  })}
                />
                {` %`}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.receptionDateLabel')}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator(
                  receptionDateLabel,
                  defaultReceptionDateLabel(order, idx)
                )(<DatePicker />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{ lg: { span: 6 }, md: { span: 24 } }}
                wrapperCol={{ lg: { span: 4 }, md: { span: 20 } }}
                label={t('orders.form.addArticle.descriptionLabel')}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator(
                  descriptionLabel,
                  defaultDescriptionLabel(order, idx)
                )(<Input />)}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  );
};
AddArticleForm.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  order: PropTypes.shape({
    articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  getFieldProps: PropTypes.func.isRequired,
  calculatedValues: PropTypes.shape({}).isRequired,
  setCalculatedValues: PropTypes.func.isRequired,
  catalog: PropTypes.arrayOf(PropTypes.string).isRequired,
  deleteArticle: PropTypes.func.isRequired
};
const WrappedAddArticleAntForm = Form.create({
  name: 'addArticles'
})(AddArticleForm);

export default WrappedAddArticleAntForm;
