export default (companies, clientView) => {
  return [
    {
      label: 'id_number',
      type: 'string'
    },
    {
      label: 'last_name',
      type: 'string'
    },
    {
      label: 'first_name',
      type: 'string'
    },
    {
      label: 'company',
      input: 'select',
      options: companies,
      disabled: clientView
    },
    {
      label: 'site',
      type: 'string'
    }
  ];
};
